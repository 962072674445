import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputBase, styled } from '@mui/material';
import NumberInput from '../inputs/NumberInput';
import { RawDatePicker } from '../form/DatePicker';

export const EditableFieldControl = styled(InputBase)({
  width: '100%',
  height: '100%',
  padding: '0px',
  fontFamily: 'unset',
  fontSize: 'unset',
  lineHeight: 'unset',
  fontWeight: 'unset',
  color: 'unset',

  '& .MuiInputBase-input': {
    width: '100%',
    height: '100%',
    padding: '0px',
  },

  '&.Mui-disabled': {
    color: 'unset',
    '& .MuiInputBase-input': {
      WebkitTextFillColor: 'unset',
    },
  },
});

const DateInput = () => {
  const [fromDateValue, setFromDateValue] = useState(new Date());

  return (
    <RawDatePicker
      inputFormat="MM/dd/yyyy"
      name="date"
      label="Date"
      value={fromDateValue}
      onChange={(date) => {
        setFromDateValue(date);
      }}
      sx={{ width: '50%' }}
    />
  );
};

const makeInputComponent = (type) => {
  switch (type) {
    case 'currency':
    case 'number':
      return NumberInput;
    case 'date':
      return DateInput;
    default:
      return undefined;
  }
};

const makeInputProps = (type, clearPrefix) => {
  switch (type) {
    case 'currency':
      return {
        currency: true,
        showPrefix: !clearPrefix,
      };

    default:
      return undefined;
  }
};

const makePlaceholder = (type, clearPrefix) => {
  switch (type) {
    case 'currency':
      return `${!clearPrefix ? '$ ' : ''}0`;
    default:
      return null;
  }
};

const makeControlStyle = (type, clearPrefix) => {
  switch (type) {
    case 'currency':
      return clearPrefix
        ? {
            '&:before': {
              content: '"$\\00a0 "',
              opacity: 0.5,
            },
          }
        : {};
    default:
      return {};
  }
};

const EditableField = ({
  type,
  value,
  disabled,
  multiline,
  maxRows,
  sx,
  onChange,
  onClick,
  inputRef,
  placeholder,
  clearPrefix,
}) => (
  <EditableFieldControl
    className="editable-field"
    sx={{ ...makeControlStyle(type, clearPrefix), ...sx }}
    value={value}
    disabled={disabled}
    multiline={multiline}
    maxRows={multiline ? maxRows : null}
    inputComponent={makeInputComponent(type)}
    inputProps={makeInputProps(type, clearPrefix)}
    onChange={!disabled ? (event) => onChange(event.target.value) : null}
    onClick={onClick}
    inputRef={inputRef}
    placeholder={placeholder !== null ? placeholder : makePlaceholder(type, clearPrefix)}
  />
);

EditableField.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  maxRows: PropTypes.number,
  sx: PropTypes.shape(),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.any,
  placeholder: PropTypes.string,
  clearPrefix: PropTypes.bool,
};

EditableField.defaultProps = {
  type: 'text',
  disabled: false,
  multiline: null,
  maxRows: 4,
  sx: null,
  onChange: null,
  onClick: null,
  inputRef: null,
  placeholder: null,
  clearPrefix: true,
};

export default EditableField;

import React from 'react';
import { styled } from '@mui/material';
import colors from 'utils/colors';
import FormDocuments from './FormDocuments';
import FormImages from './FormImages';

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  borderTop: `1px solid ${colors.main.controlBorder}`,
  padding: '20px 0px 0px 0px',
});

const Section = styled('div')({
  width: 'calc(50% - 1px)',

  '&:first-of-type': {
    borderRight: `1px solid ${colors.main.controlBorder}`,
    paddingRight: '16px',
  },

  '&:last-child': {
    borderLeft: `1px solid ${colors.main.controlBorder}`,
    paddingLeft: '16px',
  },
});

const FormUploader = ({ formik, disabled, entityPath }) => (
  <Container className="form-uploader">
    <Section>
      <FormDocuments formik={formik} disabled={disabled} entityPath={entityPath} />
    </Section>
    <Section>
      <FormImages formik={formik} disabled={disabled} entityPath={entityPath} />
    </Section>
  </Container>
);

export default FormUploader;

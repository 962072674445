import React from 'react';
import useOpenItems from 'hooks/components/useOpenItems';
import AddOpenItemModal from 'components/open-items/form/AddOpenItemModal';
import EditOpenItemModal from 'components/open-items/form/EditOpenItemModal';
import OpenItemsModal from 'components/open-items/list/OpenItemsModal';

const OpenItemProvider = ({ children }) => {
  const { list, critical, overDue, propertyId, openItemId, onCloseList, onCloseDetails } =
    useOpenItems();

  return (
    <>
      {children}
      {!!openItemId &&
        (openItemId === 'new' ? (
          <AddOpenItemModal critical={critical} onClose={onCloseDetails} />
        ) : (
          <EditOpenItemModal
            propertyId={propertyId}
            openItemId={openItemId}
            onClose={onCloseDetails}
          />
        ))}
      {!!list && <OpenItemsModal critical={critical} overDue={overDue} onClose={onCloseList} />}
    </>
  );
};

export default OpenItemProvider;

const getElement = (element) =>
  typeof element === 'string' ? document.getElementById(element) : element;

export const scrollTop = (rootElement) => {
  const element = getElement(rootElement);
  if (element) element.scrollTop = 0;
};

export const scrollBottom = (rootElement) => {
  const element = getElement(rootElement);
  if (element) element.scrollTop = element.scrollHeight;
};

/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ModalMui, Box, styled } from '@mui/material';
import colors from 'utils/colors';
import TransitionContainer from '../transition/TransitionContainer';

export const ModalContainer = styled(Box)({
  position: 'absolute',
  borderRadius: '4px',
  backgroundColor: colors.main.surface,
  '& .MuiBox-root': {
    height: '100%',
  },
});

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 22px 0 16px',
  gap: '24px',
  width: '100%',
  height: '60px',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  backgroundColor: colors.secondary[50],
  overflow: 'hidden',
});

const Title = styled('p')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: '0px',
  marginLeft: '-24px',
  paddingLeft: '24px',
  zIndex: 1,
  backgroundColor: colors.secondary[50],
  fontFamily: 'Mulish ',
  fontWeight: 'bold',
  color: colors.tertiary[900],
  fontSize: '20px',
  lineHeight: '36px',
});

const ButtonsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
  marginLeft: 'auto',
});

const makeTransition = ({ transitionVariant, transitionProps }) => {
  if (transitionVariant) return [transitionVariant, transitionProps];
  return [null, transitionProps];
};

const Notification = (props) => {
  const { headerContent, title, open, onClose, children, top, left, width, height, buttons } =
    props;

  const [transitionVariant, transitionProps] = makeTransition(props);

  return (
    <ModalMui
      BackdropProps={{
        timeout: 500,
      }}
      open={open}
      onClose={onClose}
    >
      <div>
        <TransitionContainer
          variant={transitionVariant}
          transitionProps={transitionProps}
          show={open}
        >
          <ModalContainer
            sx={{ top: `${top}`, left: `${left}`, width: `${width}`, height: `${height}` }}
          >
            <>
              <Header className="modal-header">
                {headerContent}

                {!!title && <Title className="modal-title">{title}</Title>}
                <ButtonsContainer>{!!buttons && buttons}</ButtonsContainer>
              </Header>
              {children}
            </>
          </ModalContainer>
        </TransitionContainer>
      </div>
    </ModalMui>
  );
};

Notification.propTypes = {
  headerContent: PropTypes.element,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  renderContent: PropTypes.func,
  transitionVariant: PropTypes.string,
  transitionProps: PropTypes.shape(),
  top: PropTypes.string,
  left: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Notification.defaultProps = {
  open: true,
  headerContent: null,
  title: '',
  renderContent: null,
  transitionVariant: null,
  transitionProps: {},
  top: '50%',
  left: '50%',
  width: '100%',
  height: '400px',
};
export default Notification;

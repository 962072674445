import React from 'react';
import { makeValidatorSchema, stringValidator } from 'utils/validators';
import FormModal from 'components/shared/modal/FormModal';
import FormRow from 'components/shared/form/FormRow';
import TextInput from 'components/shared/form/TextInput';
import FormImages from 'components/shared/uploader/FormImages';

const validationSchema = makeValidatorSchema({
  name: stringValidator({ required: 'Must enter a name' }),
  designate_beneficiaries: stringValidator(),
  power_of_attorney: stringValidator(),
  healthcare_directives: stringValidator(),
  funeral_and_burial_instructions: stringValidator(),
  frepaid_burial_plans: stringValidator(),
  estate_taxes: stringValidator(),
  usernames_and_pwds: stringValidator(),
});

const EndOfLifePropertyModal = ({ currentProperty, title, onSubmit, onClose }) => {
  const initalizeSummary = () => {
    const summary = (currentProperty && currentProperty.summary) || {};
    return {
      designate_beneficiaries: summary.designate_beneficiaries || '',
      power_of_attorney: summary.power_of_attorney || '',
      healthcare_directives: summary.healthcare_directives || '',
      funeral_and_burial_instructions: summary.funeral_and_burial_instructions || '',
      frepaid_burial_plans: summary.frepaid_burial_plans || '',
      estate_taxes: summary.estate_taxes || '',
      usernames_and_pwds: summary.usernames_and_pwds || '',
    };
  };

  const initializeForm = () => ({
    ...(currentProperty
      ? {
          name: currentProperty.name,
          logo: currentProperty.logo ? [currentProperty.logo] : [],
        }
      : { name: '', logo: [] }),
    ...initalizeSummary(),
  });

  const getErrorMessage = () => (currentProperty ? `Error editing item` : `Error creating item`);

  const makeFormRequest = (values) => ({
    name: values.name,
    logo: values.logo,
    summary: {
      designate_beneficiaries: values.designate_beneficiaries,
      power_of_attorney: values.power_of_attorney,
      healthcare_directives: values.healthcare_directives,
      funeral_and_burial_instructions: values.funeral_and_burial_instructions,
      frepaid_burial_plans: values.frepaid_burial_plans,
      estate_taxes: values.estate_taxes,
      usernames_and_pwds: values.usernames_and_pwds,
    },
  });

  const handleSubmit = async (values) => {
    const request = makeFormRequest(values);
    return onSubmit(request);
  };

  return (
    <FormModal
      open
      title={title}
      onClose={onClose}
      initialValues={initializeForm()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError={getErrorMessage()}
      submitButton={{ label: currentProperty ? 'Save' : 'Create' }}
      cancelButton
    >
      {(formik) => (
        <>
          <FormRow>
            <TextInput formik={formik} name="name" label="Account Name" sx={{ width: '100%' }} />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="designate_beneficiaries"
              label="Designate Beneficiaries"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="power_of_attorney"
              label="Upload or Create Power of Attorney"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="healthcare_directives"
              label="Healthcare Directives"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="funeral_and_burial_instructions"
              label="Funeral and Burial Instructions"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="frepaid_burial_plans"
              label="Prepaid Burial Plans"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="estate_taxes"
              label="Estate Taxes"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="usernames_and_pwds"
              label="Usernames and PWs"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormImages
            formik={formik}
            title={false}
            name="logo"
            addButtonLabel="Set Logo"
            singleImage
          />
        </>
      )}
    </FormModal>
  );
};
export default EndOfLifePropertyModal;

import pluralizeRoot from 'pluralize';

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const pluralize = (text, count = 2, includeNumber = false) =>
  pluralizeRoot(text, count, includeNumber);

export const shortName = (name) => {
  const nameParts = name.split(' ');
  return nameParts.length > 0 ? `${nameParts[0][0]}. ${nameParts[nameParts.length - 1]}` : name;
};

export const getFilenameAndExtension = (file) => {
  const extension = file.substring(file.lastIndexOf('.') + 1);
  const filename = file.replace(`.${extension}`, '');
  return { filename, extension };
};

export const replaceUnderscores = (str) => {
  const string = str.replaceAll('_', ' ');
  return capitalize(string);
};

export const counterLabel = (label, counter) => {
  if (!counter) return label;

  return `${label} (${counter})`;
};

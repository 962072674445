import React, { useEffect } from 'react';
import { styled } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import useOpenItems from 'hooks/components/useOpenItems';
import { fetchOpenItemCounters } from 'slices/openItem';
import Button from 'components/shared/button/Button';

const SidebarOpenItemsControl = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: '12px',
  '& > *': {
    width: '134px',
  },
});

const SidebarPastDueControl = styled('div')({
  display: 'flex',
  width: '100%',
  padding: '12px',
  marginTop: '-16px',
  '& > *': {
    width: '100%',
  },
});

const SideBarUser = () => {
  const dispatch = useDispatch();
  const { onShowList } = useOpenItems();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const globalCounters = useSelector((state) => state.openItem.counters);
  const {
    critical_items_count: criticalCount,
    open_items_count: openCount,
    over_due_items_count: overDue,
  } = currentProperty || globalCounters || {};

  useEffect(() => {
    if (!currentProperty) {
      dispatch(fetchOpenItemCounters());
    }
  }, [currentProperty]);

  return (
    <>
      <SidebarOpenItemsControl>
        <Button variant="red" onClick={() => onShowList('critical')}>
          Critical ({criticalCount || 0})
        </Button>
        <Button variant="clear" onClick={() => onShowList('open')}>
          Open ({openCount || 0})
        </Button>
      </SidebarOpenItemsControl>
      {overDue > 0 && (
        <SidebarPastDueControl>
          <Button variant="alert" onClick={() => onShowList('over_due')}>
            Over due ({overDue || 0})
          </Button>
        </SidebarPastDueControl>
      )}
    </>
  );
};

export default SideBarUser;

import React from 'react';
import { useSelector } from 'react-redux';
import { hasPolicies } from 'domain/policies';
import InsuranceBoard from './InsuranceBoard';
import SummaryBoard from './SummaryBoard';

const getPropertyBoard = (currentProperty) => {
  if (hasPolicies(currentProperty)) return InsuranceBoard;

  return SummaryBoard;
};

export const hasPropertyBoard = (currentProperty) => !!getPropertyBoard(currentProperty);

const PropertyBoard = ({ headerSize }) => {
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const Board = getPropertyBoard(currentProperty);

  return Board ? <Board headerSize={headerSize} /> : null;
};

export default PropertyBoard;

import { isPropertyAdmin } from 'domain/roles';

const defineRulesForDocuments = (can, user) => {
  can('view', 'Documents');
  can('create', 'Documents');

  if (isPropertyAdmin(user)) {
    can('update', 'Documents');
    can('delete', 'Documents');
    can('move', 'Documents');
    can('manage_permissions', 'Documents');
  } else {
    can('update', 'Documents', { user_id: user.id_s });
    can('delete', 'Documents', { user_id: user.id_s });
    can('move', 'Documents', { user_id: user.id_s });
    can('manage_permissions', 'Documents', { user_id: user.id_s });
  }
};

export default defineRulesForDocuments;

import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useAbility from 'hooks/shared/useAbility';
import { fetchCurrentProperty, clearCurrentProperty } from 'slices/property';
import MainLayout from 'components/main/MainLayout';
import Documents from './Documents/Documents';
import Dashboard from './Dashboard/Dashboard';
import Directory from './Directory/Directory';
import Receipts from './Receipts/Receipts';
import PleasePay from './PleasePay/PleasePay';
import Balance from './Balance/Balance';

const sections = {
  dashboard: {
    component: Dashboard,
  },
  balance: {
    component: Balance,
  },
  directory: {
    component: Directory,
    permission: 'PropertyDirectory',
  },
  documents: {
    component: Documents,
    permission: 'Documents',
  },
  receipts: {
    component: Receipts,
    permission: 'PropertyReceipts',
  },
  'please-pay': {
    component: PleasePay,
    permission: 'PropertyPleasePay',
  },
};

const Property = () => {
  const ability = useAbility();
  const dispatch = useDispatch();
  const { id: currentPropertyId, section: sectionId } = useParams();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const section = sections[sectionId];

  useEffect(() => {
    dispatch(fetchCurrentProperty(currentPropertyId));

    return () => {
      dispatch(clearCurrentProperty());
    };
  }, [currentPropertyId]);

  if (!currentProperty) return null;
  if (!section || (section.permission && !ability.can('view', section.permission))) {
    return <Navigate to={`/properties/${currentPropertyId}/dashboard`} />;
  }
  const Component = section.component;

  return (
    <MainLayout>
      <Component />
    </MainLayout>
  );
};

export default Property;

import React from 'react';
import SummaryRow from './SummaryRow';

const EndOfLifeSummary = ({ property }) => {
  const summary = property.summary || {};

  return (
    <>
      <SummaryRow name="Account Name" value={property.name} />
      <SummaryRow name="Designate Beneficiaries" value={summary.designate_beneficiaries} />
      <SummaryRow name="Upload or Create Power of Attorney" value={summary.power_of_attorney} />
      <SummaryRow name="Healthcare Directives" value={summary.healthcare_directives} />
      <SummaryRow
        name="Funeral and Burial Instructions"
        value={summary.funeral_and_burial_instructions}
      />
      <SummaryRow name="Prepaid Burial Plans" value={summary.frepaid_burial_plans} />
      <SummaryRow name="Estate Taxes" value={summary.estate_taxes} type="currency" />
      <SummaryRow name="Usernames and PWs" value={summary.usernames_and_pwds} />
    </>
  );
};

export default EndOfLifeSummary;

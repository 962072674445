import { createSlice } from '@reduxjs/toolkit';
import { getPrivateChats, getPrivateChat } from 'services/privateChats';

const initialState = {
  privateChats: null,
  currentPrivateChat: null,
};

const privateChatSlice = createSlice({
  name: 'privateChat',
  initialState,
  reducers: {
    setPrivateChats: (state, action) => ({ ...state, privateChats: action.payload }),
    clearPrivateChats: (state) => ({ ...state, privateChats: null }),
    setCurrentPrivateChat: (state, action) => ({ ...state, currentPrivateChat: action.payload }),
    clearCurrentPrivateChat: (state) => ({ ...state, currentPrivateChat: null }),
  },
});

const { reducer, actions } = privateChatSlice;

export const {
  setPrivateChats,
  clearPrivateChats,
  setCurrentPrivateChat,
  clearCurrentPrivateChat,
} = actions;

export const fetchPrivateChats =
  (propertyId = null, filters = {}, page = 0) =>
  async (dispatch) => {
    const privateChats = await getPrivateChats(propertyId, filters, page);
    dispatch(setPrivateChats(privateChats.records));
  };

export const fetchCurrentPrivateChat = (propertyId, privateChatId) => async (dispatch) => {
  const privateChat = await getPrivateChat(propertyId, privateChatId);
  dispatch(setCurrentPrivateChat(privateChat));
};

export default reducer;

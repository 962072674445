import { isPropertyAdmin } from 'domain/roles';

const defineRulesForPleasePay = (can, user) => {
  can('view', 'PropertyPleasePay');
  can('create', 'PleasePay');
  can('export', 'PleasePay');
  can('view', 'PleasePay');

  if (isPropertyAdmin(user)) {
    can('update', 'PleasePay');
    can('delete', 'PleasePay');
  } else {
    can('update', 'PleasePay', { user_id: user.id_s });
    can('delete', 'PleasePay', { user_id: user.id_s });
  }
};

export default defineRulesForPleasePay;

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import colors from 'utils/colors';
import EditableField from 'components/shared/editable/EditableField';
import IconButton from 'components/shared/button/IconButton';

export const TYPING_TIMEOUT = 500;

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  gap: '8px',
});

const SearchableTitle = ({ title, disabled, rightContent, onChange, onBack }) => {
  const [searching, setSearching] = useState(false);
  const [text, setText] = useState('');
  const inputRef = useRef();

  const isSearchable = !searching && !disabled;

  const debouncedTextChange = useDebouncedCallback((value) => {
    onChange(value);
  }, TYPING_TIMEOUT);

  const handleOpenSearch = () => {
    setSearching(true);
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  };

  const handleCloseSearch = () => {
    setSearching(false);
    setText('');
    if (onChange) debouncedTextChange('');
  };

  const handleTextChange = (value) => {
    setText(value);
    if (onChange) debouncedTextChange(value);
  };

  return (
    <Container>
      {onBack && <IconButton icon="back" color={colors.main.blackText} onClick={onBack} />}
      <EditableField
        value={searching ? text : title}
        placeholder={title}
        disabled={!searching}
        onChange={handleTextChange}
        onClick={isSearchable ? handleOpenSearch : null}
        inputRef={inputRef}
      />
      {searching && (
        <IconButton icon="clear" color={colors.main.blackText} onClick={handleCloseSearch} />
      )}
      {isSearchable && (
        <IconButton icon="search" color={colors.main.blackText} onClick={handleOpenSearch} />
      )}
      {rightContent}
    </Container>
  );
};

SearchableTitle.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  rightContent: PropTypes.element,
  onChange: PropTypes.func,
  onBack: PropTypes.func,
};

SearchableTitle.defaultProps = {
  disabled: false,
  rightContent: null,
  onChange: null,
  onBack: null,
};

export default SearchableTitle;

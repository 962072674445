import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeValidatorSchema, dateValidator, stringValidator } from 'utils/validators';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import FormModal from 'components/shared/modal/FormModal';
import { fetchCurrentProperty } from 'slices/property';
import { fetchOpenItemCounters } from 'slices/openItem';
import { uploadFormFiles } from 'services/directUpload';
import {
  updateOpenItem,
  createOpenItem,
  deleteOpenItem,
  permanentDeleteOpenItem,
} from 'services/openItems';
import useSnackbar from 'hooks/shared/useSnackBar';
import useAbility from 'hooks/shared/useAbility';
import TextInput from 'components/shared/form/TextInput';
import Select from 'components/shared/form/Select';
import DatePicker from 'components/shared/form/DatePicker';
import Checkbox from 'components/shared/form/Checkbox';
import FormUploader from 'components/shared/uploader/FormUploader';
import { getUsers } from 'services/users';
import FormRow from 'components/shared/form/FormRow';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import {
  getRepeatNumber,
  getRepeatPeriod,
  getRepeatPeriodLabel,
  repeatPeriod,
} from 'domain/openItems';

const validationSchema = makeValidatorSchema({
  dueDate: dateValidator(),
  description: stringValidator({ required: 'Must enter a description' }),
});

const OpenItemFormModal = ({ critical, title, onClose }) => {
  const dispatch = useDispatch();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const [propertyUsers, setPropertyUsers] = useState(null);
  const [permanentDeleteConfirmation, setPermanentDeleteConfirmation] = useState(false);
  const currentOpenItem = useSelector((state) => state.openItem.currentOpenItem);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { showSuccess, showError } = useSnackbar();
  const ability = useAbility();

  const isDraft = !currentOpenItem || currentOpenItem.status === 'draft';
  const isArchived = currentOpenItem && currentOpenItem.status === 'archived';
  const canUpdate = ability.can('update', currentOpenItem);
  const canContribute = ability.can('contribute', currentOpenItem);
  const canSetRequestedBy = ability.can('setRequestedBy', 'OpenItem');
  const canDelete = ability.can('delete', currentOpenItem);
  const canPermanentDelete = ability.can('permanentDelete', currentOpenItem);
  const disabled = !!currentOpenItem && !canUpdate && !canContribute;
  const isContributor = !!currentOpenItem && !canUpdate && canContribute;
  // TODO: if we want to enable creation from global list it can be null
  const propertyId = currentOpenItem ? currentOpenItem.property_id : currentProperty.id;
  const entityPath = currentOpenItem
    ? `properties/${propertyId}/open_items/${currentOpenItem.id}`
    : null;

  const initializeForm = () =>
    currentOpenItem
      ? {
          assignedFromId: currentOpenItem.requested_by_user_id,
          assignedFrom: currentOpenItem.requested_by_user_name,
          description: currentOpenItem.description || '',
          assignedTo: currentOpenItem.assigned_to_user_id || '',
          dueDate: currentOpenItem.due_date || '',
          reminderDate: currentOpenItem.reminder_date || '',
          repeatPeriod:
            getRepeatPeriodLabel(currentOpenItem.repeat_number, currentOpenItem.repeat_period) ||
            '',
          repeatDate: currentOpenItem.repeat_date || '',
          ccs: currentOpenItem.ccs || [],
          resolved: currentOpenItem.status === 'resolved',
          documents: currentOpenItem.documents || [],
          images: currentOpenItem.images || [],
        }
      : {
          assignedFromId: currentUser.id,
          description: '',
          assignedTo: '',
          dueDate: '',
          repeatPeriod: '',
          repeatNumber: '',
          repeatDate: '',
          ccs: [],
          documents: [],
          images: [],
        };

  const getStatus = ({ draft, resolved }) => {
    if (draft) return 'draft';
    if (resolved) return 'resolved';
    return 'pending';
  };

  const makeFormRequest = (values) => ({
    requested_by_user_id: canSetRequestedBy ? values.assignedFromId : undefined,
    description: values.description,
    assigned_to_user_id: values.assignedTo || null,
    due_date: values.dueDate || null,
    reminder_date: values.reminderDate || null,
    repeat_period: getRepeatPeriod(values.repeatPeriod),
    repeat_number: getRepeatNumber(values.repeatPeriod),
    repeat_date: values.repeatDate || null,
    ccs: values.ccs,
    status: getStatus(values),
    critical,
    documents: values.documents,
    images: values.images,
  });

  const saveOpenItem = async (request) =>
    currentOpenItem
      ? updateOpenItem(propertyId, currentOpenItem.id, request)
      : createOpenItem(propertyId, request);

  const updateCounters = () => {
    if (currentProperty) {
      dispatch(fetchCurrentProperty(propertyId));
    }
    dispatch(fetchOpenItemCounters());
  };

  const handleSubmit = async (values) => {
    let request = makeFormRequest(values);
    request = await uploadFormFiles(request);
    await saveOpenItem(request);
    showSuccess(currentOpenItem ? 'Open Item saved' : 'Open Item created');
    onClose();
    updateCounters();
  };

  const handleDiscardDraft = async () => {
    try {
      await deleteOpenItem(propertyId, currentOpenItem.id);
      showSuccess('Draft Open Item discarded');
      onClose();
    } catch {
      showError('Error discarding draft');
    }
  };

  const handleArchive = async () => {
    try {
      await deleteOpenItem(propertyId, currentOpenItem.id);
      showSuccess('Open Item archived');
      onClose();
    } catch {
      showError('Error archiving open item');
    }
  };

  const handlePermanentDelete = async () => {
    setPermanentDeleteConfirmation(false);
    try {
      await permanentDeleteOpenItem(propertyId, currentOpenItem.id);
      showSuccess('Open Item deleted');
      onClose();
    } catch {
      showError('Error deleting open item');
    }
  };

  const getSaveLabel = () => {
    if (isDraft) return 'Submit';
    if (isArchived) return 'Reopen';
    return 'Save';
  };

  const makeDeleteButton = () => {
    if (currentOpenItem && isDraft && canDelete)
      return { label: 'Discard', onClick: handleDiscardDraft };

    if (currentOpenItem && !isDraft && canPermanentDelete)
      return { label: 'Delete', onClick: () => setPermanentDeleteConfirmation(true) };

    return null;
  };

  const makeArchiveButton = () => {
    if (!currentOpenItem || isDraft || isArchived || !canDelete) return null;

    return { onClick: handleArchive };
  };

  useAsyncEffect(async () => {
    setPropertyUsers(await getUsers(propertyId));
  });

  if (!propertyUsers) return null;

  return (
    <FormModal
      open
      chat={currentOpenItem ? currentOpenItem.chat : null}
      title={title}
      onClose={onClose || null}
      initialValues={initializeForm()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError="Error saving Open Item"
      submitButton={!disabled && { label: getSaveLabel() }}
      draftButton={!disabled && isDraft}
      deleteButton={makeDeleteButton()}
      archiveButton={makeArchiveButton()}
      cancelButton
      avoidNoChangesSave={!isArchived}
    >
      {(formik) => (
        <>
          {!isDraft && (
            <>
              <Checkbox
                formik={formik}
                name="resolved"
                labeledVariant="background"
                checkedLabel="Resolved"
                uncheckedLabel="Pending"
                disabled={disabled || isContributor}
              />
              {!canSetRequestedBy && (
                <TextInput formik={formik} name="assignedFrom" label="Assigned From" disabled />
              )}
            </>
          )}
          {canSetRequestedBy && (
            <Select
              formik={formik}
              name="assignedFromId"
              label="Assigned From"
              options={propertyUsers}
              disabled={disabled}
            />
          )}
          <Select
            formik={formik}
            name="assignedTo"
            label="Assigned To"
            options={propertyUsers}
            disabled={disabled || isContributor}
          />
          <Select
            formik={formik}
            name="ccs"
            label="CC Recipients"
            multiple
            options={propertyUsers}
            disabled={disabled || isContributor}
          />
          <FormRow>
            <DatePicker
              formik={formik}
              name="dueDate"
              label="Due Date"
              disabled={disabled || isContributor}
            />
            <DatePicker
              formik={formik}
              name="reminderDate"
              label="Reminder Date"
              disabled={disabled || isContributor}
            />
          </FormRow>
          <FormRow>
            <Select
              formik={formik}
              name="repeatPeriod"
              label="Repeat Period"
              options={repeatPeriod}
              disabled={disabled || isContributor}
              sx={{ width: '243px' }}
            />
            {formik.values.repeatPeriod === 'Custom' && (
              <DatePicker
                formik={formik}
                name="repeatDate"
                label="Repeat Date"
                disabled={disabled || isContributor}
              />
            )}
          </FormRow>
          <TextInput
            formik={formik}
            name="description"
            label="Description"
            multiline
            disabled={disabled || isContributor}
          />
          <FormUploader formik={formik} disabled={disabled} entityPath={entityPath} />
          {permanentDeleteConfirmation && (
            <ConfirmationModal
              title="Open Item will be permanent deleted"
              description="Are you sure?"
              onCancel={() => setPermanentDeleteConfirmation(false)}
              onConfirm={() => handlePermanentDelete()}
            />
          )}
        </>
      )}
    </FormModal>
  );
};

export default OpenItemFormModal;

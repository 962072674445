import { styled } from '@mui/material';
import React, { useState } from 'react';
import { FieldArray } from 'formik';
import { v4 as uuid } from 'uuid';
import colors from 'utils/colors';
import { fieldNumber } from 'utils/number';
import DatePicker from 'components/shared/form/DatePicker';
import TextInput from 'components/shared/form/TextInput';
import Select from 'components/shared/form/Select';
import Checkbox from 'components/shared/form/Checkbox';
import FormRow from 'components/shared/form/FormRow';
import {
  dateValidator,
  makeValidatorSchema,
  positiveNumberValidator,
  stringValidator,
} from 'utils/validators';
import RawForm from 'components/shared/form/RawForm';
import Button from 'components/shared/button/Button';
import IconButton from 'components/shared/button/IconButton';
import { useSelector } from 'react-redux';
import { createPolicy, deletePolicy, updatePolicy } from 'services/policies';
import useSnackbar from 'hooks/shared/useSnackBar';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import FormUploader from 'components/shared/uploader/FormUploader';
import {
  POLICY_PREMIUM_FRECUENCIES,
  AVIATION_POLICY_LIMIT_TYPES,
  POLICY_LOSS_OF_USE_LIMITS,
  getPolicyKey,
} from 'domain/policies';
import useOpenItems from 'hooks/components/useOpenItems';

const ROW_GAP = '6';
const INPUT_PROPERTIES = {
  width: `calc(${100 / 4}% - 3px)`,
};
const BUTTON_PROPERTIES = {
  padding: '0px',
  minWidth: 'unset',
  '& .MuiButton-startIcon': {
    marginLeft: '0px',
    marginRight: '2px',
  },
};

const ButtonsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',
});
const COLLPASED_BUTTONS_PROPERTIES = {
  position: 'absolute',
  bottom: '0px',
  right: '0px',
  marginTop: '4px',
  padding: '12px 16px 16px 16px',
  background: colors.main.whiteOpacity,
  zIndex: '1',
};

const RowButtons = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingBottom: '8px',
  gap: '4px',
});

const Subtitle = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  fontSize: '12px',
  fontWeight: 'bold',
  color: colors.primary[900],
});

const validationSchema = makeValidatorSchema({
  insuranceCompany: stringValidator(),
  insured: stringValidator(),
  policy: stringValidator(),
  addlInsured: stringValidator(),
  agencyName: stringValidator(),
  agentContact: stringValidator(),
  agentEmail: stringValidator(),
  agentPhoneNo: stringValidator(),
  aircraftModel: stringValidator(),
  aircraftRegistrationNo: stringValidator(),
  autoDebit: stringValidator(),
  autoDebitAccount: stringValidator(),
  notes: stringValidator(),
  premiumFrecuency: stringValidator(),
  premium: positiveNumberValidator(),
  deductible: positiveNumberValidator(),
  appraisedValue: positiveNumberValidator(),
  appraisedDate: dateValidator(),
  premiumDueDate: dateValidator(),
});

const emptyLimit = () => ({
  id: uuid(),
  limitType: '',
  limit: '',
  lossOfUseLimit: 'undefined_loss_of_use',
});

const initializeForm = (policy = null) =>
  policy
    ? {
        id: policy.id,
        insuranceCompany: policy.insurance_company,
        insured: policy.insured,
        policy: policy.policy_number,
        premium: fieldNumber(policy.premium),
        premiumDueDate: policy.premium_due_date,
        addlInsured: policy.addl_insured,
        agencyName: policy.agency_name,
        agentContact: policy.agent_contact,
        agentEmail: policy.agent_email,
        agentPhoneNo: policy.agent_phone_no,
        aircraftModel: policy.aircraft_model,
        aircraftRegistrationNo: policy.aircraft_registration_no,
        autoDebit: policy.auto_debit,
        autoDebitAccount: policy.auto_debit_account,
        notes: policy.notes,
        premiumFrecuency: policy.premium_frequency,
        deductible: fieldNumber(policy.deductible),
        appraisedValue: fieldNumber(policy.appraised_value),
        appraisedDate: policy.appraised_date,
        limits:
          policy.limits.length > 0
            ? policy.limits.map((limit) => ({
                id: limit.id,
                limitType: limit.limit_type,
                limit: fieldNumber(limit.limit),
                lossOfUseLimit: limit.loss_of_use_limit || 'undefined_loss_of_use',
              }))
            : [emptyLimit()],
        documents: policy.documents || [],
        images: policy.images || [],
      }
    : {
        id: '',
        insuranceCompany: '',
        insured: '',
        policy: '',
        premium: '',
        premiumDueDate: '',
        addlInsured: '',
        agencyName: '',
        agentContact: '',
        agentEmail: '',
        agentPhoneNo: '',
        aircraftModel: '',
        aircraftRegistrationNo: '',
        autoDebit: false,
        autoDebitAccount: '',
        notes: '',
        premiumFrecuency: POLICY_PREMIUM_FRECUENCIES[0].id,
        deductible: '',
        appraisedValue: '',
        appraisedDate: '',
        limits: [emptyLimit()],
        documents: [],
        images: [],
      };

const AviationPolicyForm = ({
  policy,
  editing,
  collapsed,
  canUpdate,
  canDelete,
  onSave,
  onDeleted,
  onCancel,
  onEdit,
  onSelect,
}) => {
  const { showSuccess, showError } = useSnackbar();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const propertyId = currentProperty.id;
  const policyKey = getPolicyKey(currentProperty);
  const [deletingInsurance, setDeletingInsurance] = useState(false);
  const { onShowDetails: handleShowOpenItem } = useOpenItems();
  const openItemId = (policy && policy.open_item_id) || null;

  const makeFormRequest = (values) => ({
    insurance_company: values.insuranceCompany,
    insured: values.insured,
    policy_number: values.policy,
    premium: values.premium || '0',
    premium_due_date: values.premiumDueDate || null,
    addl_insured: values.addlInsured,
    agency_name: values.agencyName,
    agent_contact: values.agentContact,
    agent_email: values.agentEmail,
    agent_phone_no: values.agentPhoneNo,
    aircraft_model: values.aircraftModel,
    aircraft_registration_no: values.aircraftRegistrationNo,
    auto_debit: values.autoDebit,
    auto_debit_account: values.autoDebit ? values.autoDebitAccount : null,
    deductible: values.deductible || '0',
    appraised_value: values.appraisedValue || '0',
    appraised_date: values.appraisedDate || null,
    notes: values.notes,
    premium_frequency: values.premiumFrecuency,
    limits: values.limits
      .filter(({ limitType, limit }) => limitType || limit)
      .map(({ limitType, limit, lossOfUseLimit }) => ({
        limit_type: limitType,
        limit,
        loss_of_use_limit: lossOfUseLimit || null,
      })),
  });

  const handleGetEntityPath = (document) =>
    `properties/${propertyId}/open_items/${document.record_id}`;

  const handleCancel = (formik) => {
    formik.resetForm();
    onCancel();
  };

  const handleEdit = () => {
    onEdit();
  };

  const handleDelete = async (lifeInsurancePolicyId) => {
    try {
      await deletePolicy(propertyId, policyKey, lifeInsurancePolicyId);
      showSuccess('Policy Deleted');
      onDeleted();
    } catch {
      showError('Error deleting policy');
    }
    setDeletingInsurance(false);
  };

  const handleAskDeletion = () => {
    setDeletingInsurance(true);
  };

  const handleCancelDelete = () => {
    setDeletingInsurance(false);
  };

  const savePolicy = async (request) =>
    policy
      ? updatePolicy(propertyId, policyKey, policy.id, request)
      : createPolicy(propertyId, policyKey, request);

  const handleSubmit = async (values, formik) => {
    const request = makeFormRequest(values);
    const savedPolicy = await savePolicy(request);
    formik.resetForm({ values: initializeForm(savedPolicy) });
    showSuccess(policy ? 'Policy Updated' : 'Policy Created');
    onSave(savedPolicy);
  };

  return (
    <RawForm
      initialValues={initializeForm(policy)}
      validationSchema={validationSchema}
      submit={false}
      messageAlwaysVisible={false}
      onSubmit={handleSubmit}
      scroll={false}
      maxHeight={null}
    >
      {(formik) => {
        const formHeaders = (
          <>
            <FormRow gap={ROW_GAP} onClick={onSelect}>
              <TextInput
                formik={formik}
                name="insured"
                label="Named Insured"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="addlInsured"
                label="Add'l Insured"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="insuranceCompany"
                label="Insurance Company"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="policy"
                label="Policy Number"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
            </FormRow>
            <FormRow gap={ROW_GAP} onClick={onSelect}>
              <DatePicker
                formik={formik}
                name="premiumDueDate"
                label="Premium Due Date"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <Select
                formik={formik}
                name="premiumFrecuency"
                label="Premium Frequency"
                options={POLICY_PREMIUM_FRECUENCIES.map((type) => ({
                  id: type.id,
                  label: type.name,
                }))}
                sx={INPUT_PROPERTIES}
                disabled={!editing}
                size="small"
              />
              <TextInput
                formik={formik}
                name="premium"
                label="Premium"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="currency"
                clearPrefix={false}
              />
              <TextInput
                formik={formik}
                name="deductible"
                label="Deductible"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="currency"
                clearPrefix={false}
              />
            </FormRow>
            <FormRow gap={ROW_GAP} onClick={onSelect}>
              <TextInput
                formik={formik}
                name="appraisedValue"
                label="Appraised Value"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="currency"
                clearPrefix={false}
              />
              <DatePicker
                formik={formik}
                name="appraisedDate"
                label="Appraised Date"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              {formik.values.autoDebit ? (
                <TextInput
                  formik={formik}
                  name="autoDebitAccount"
                  label="Debit Account"
                  disabled={!editing}
                  sx={INPUT_PROPERTIES}
                  size="small"
                  onCancel={editing ? () => formik.setFieldValue('autoDebit', false) : null}
                />
              ) : (
                <Checkbox
                  sx={INPUT_PROPERTIES}
                  formik={formik}
                  name="autoDebit"
                  label="Auto Debit"
                  disabled={!editing}
                  size="small"
                />
              )}
              <div style={INPUT_PROPERTIES} />
            </FormRow>
          </>
        );

        const formButtons = (
          <>
            <FormRow gap={ROW_GAP} sx={collapsed ? COLLPASED_BUTTONS_PROPERTIES : null}>
              <ButtonsContainer>
                {editing ? (
                  <>
                    <Button
                      startIcon="cancel"
                      variant="transparent"
                      iconSize={20}
                      color={colors.tertiary[900]}
                      onClick={() => {
                        handleCancel(formik);
                      }}
                      sx={BUTTON_PROPERTIES}
                    >
                      Cancel
                    </Button>
                    <Button
                      startIcon="confirm"
                      variant="transparent"
                      iconSize={20}
                      color={colors.primary[900]}
                      onClick={() => formik.submitForm()}
                      sx={BUTTON_PROPERTIES}
                    >
                      Confirm
                    </Button>
                  </>
                ) : (
                  <>
                    {canDelete && (
                      <Button
                        startIcon="delete"
                        variant="transparent"
                        iconSize={20}
                        sx={BUTTON_PROPERTIES}
                        onClick={() => {
                          handleAskDeletion();
                        }}
                      >
                        Delete
                      </Button>
                    )}
                    {canUpdate && (
                      <Button
                        startIcon="edit"
                        variant="transparent"
                        iconSize={20}
                        sx={BUTTON_PROPERTIES}
                        onClick={handleEdit}
                      >
                        Edit
                      </Button>
                    )}
                    {openItemId && (
                      <Button
                        startIcon="openItem"
                        variant="transparent"
                        iconSize={20}
                        sx={BUTTON_PROPERTIES}
                        onClick={() => {
                          handleShowOpenItem(currentProperty.id, openItemId);
                        }}
                      >
                        Open Item
                      </Button>
                    )}
                  </>
                )}
              </ButtonsContainer>
            </FormRow>
            {deletingInsurance && (
              <ConfirmationModal
                title="Policy will be deleted"
                description="Are you sure?"
                onCancel={handleCancelDelete}
                onConfirm={() => {
                  handleDelete(policy.id);
                }}
              />
            )}
          </>
        );

        if (collapsed)
          return (
            <>
              {formHeaders}
              {formButtons}
            </>
          );

        return (
          <>
            {formHeaders}
            {formik.values.limits.some((limit) => editing || limit.limitType || limit.limit) && (
              <Subtitle>Limits</Subtitle>
            )}
            <FieldArray
              name="limits"
              render={(arrayHelpers) => (
                <>
                  {formik.values.limits
                    .filter((limit) => editing || limit.limitType || limit.limit)
                    .map((limit, index) => (
                      <FormRow gap={ROW_GAP} key={limit.id}>
                        <Select
                          formik={formik}
                          name={`limits[${index}].limitType`}
                          fixedValue={limit.limitType}
                          label="Coverage Type"
                          options={AVIATION_POLICY_LIMIT_TYPES.map((type) => ({
                            id: type.id,
                            label: type.name,
                          }))}
                          sx={INPUT_PROPERTIES}
                          disabled={!editing}
                          size="small"
                        />
                        <TextInput
                          formik={formik}
                          name={`limits[${index}].limit`}
                          fixedValue={limit.limit}
                          label="Limit Amount"
                          disabled={!editing}
                          sx={INPUT_PROPERTIES}
                          size="small"
                          type="currency"
                          clearPrefix={false}
                        />
                        {limit.limitType === 'loss_of_use' ? (
                          <Select
                            formik={formik}
                            name={`limits[${index}].lossOfUseLimit`}
                            fixedValue={limit.lossOfUseLimit}
                            label="Loss Of Use"
                            options={POLICY_LOSS_OF_USE_LIMITS.map((type) => ({
                              id: type.id,
                              label: type.name,
                            }))}
                            sx={INPUT_PROPERTIES}
                            disabled={!editing}
                            size="small"
                          />
                        ) : (
                          <div style={INPUT_PROPERTIES} />
                        )}
                        {editing ? (
                          <RowButtons style={INPUT_PROPERTIES}>
                            {formik.values.limits.length > 1 && (
                              <IconButton
                                icon="delete"
                                color={colors.primary[900]}
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            )}
                            <IconButton
                              icon="add"
                              color={colors.primary[900]}
                              onClick={() => arrayHelpers.insert(index + 1, emptyLimit())}
                            />
                          </RowButtons>
                        ) : (
                          <div style={INPUT_PROPERTIES} />
                        )}
                      </FormRow>
                    ))}
                </>
              )}
            />
            <FormRow gap={ROW_GAP}>
              <TextInput
                formik={formik}
                name="agencyName"
                label="Agent Company"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="agentContact"
                label="Agent Contact"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="agentEmail"
                label="Agent Email"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="agentPhoneNo"
                label="Agent Phone No"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="phone"
              />
            </FormRow>
            <FormRow gap={ROW_GAP}>
              <TextInput
                formik={formik}
                name="aircraftModel"
                label="Aircraft Make/Model"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="aircraftRegistrationNo"
                label="Aircraft Registration No"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="notes"
                label="Notes"
                disabled={!editing}
                sx={{
                  width: `calc(${100 / 2}% - 3px)`,
                  '& .MuiInputBase-root': {
                    minHeight: '35.5px',
                  },
                }}
                size="small"
                multiline
              />
            </FormRow>
            {!editing &&
              (formik.values.documents.length > 0 || formik.values.images.length > 0) && (
                <FormUploader formik={formik} disabled entityPath={handleGetEntityPath} />
              )}
            {formButtons}
          </>
        );
      }}
    </RawForm>
  );
};

export default AviationPolicyForm;

import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { styled } from '@mui/material';
import colors from 'utils/colors';
import { formatCurrency, formatPercentage } from 'utils/number';

const Card = styled(Link)({
  display: 'flex',
  width: '100%',
  borderRadius: '4px',
  backgroundColor: colors.main.surface,
  boxShadow: '0px 1px 3px rgba(52, 36, 9, 0.14), 0px 1px 8px rgba(52, 36, 9, 0.2)',
  textDecoration: 'none',
  overflow: 'hidden',

  '& > *': {
    zIndex: '3',
  },

  '&.bar-charts': {
    '&:not(:last-child)': {
      borderBottom: 'none',
    },

    '& .card-bar-background': {
      zIndex: '1',
      backgroundColor: colors.main.surface,
    },

    '& .card-bar': {
      zIndex: '1',
    },

    '&.positive-value': {
      '& .card-bar-background': {
        backgroundColor: '#b4be8320',
      },
      '& .card-bar': {
        backgroundColor: '#b4be83',
      },
    },

    '&.negative-value': {
      '& .card-bar-background': {
        backgroundColor: '#D7978B20',
      },
      '& .card-bar': {
        backgroundColor: '#D7978B',
      },
    },
  },
});

const CardBarBackground = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '36px',
});

const CardBar = styled('div')({
  position: 'absolute',
  height: '36px',
  borderRadius: '0px 8px 8px 0px',
});

const CardPercentage = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '60px',
  maxWidth: '60px',
  height: '36px',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '600',
  color: colors.main.text,
});

const CardContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: 'calc(100% - 198px)',
  height: '36px',
  padding: '0px 12px',
});

const CardTitle = styled('div')({
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: colors.main.text,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const CardActions = styled('div')({
  display: 'flex',
  marginLeft: '8px',
  gap: '6px',
});

const CardIndicator = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'center',
  width: '150px',
  paddingRight: '8px',
});

const CardIndicatorTitle = styled('div')({
  fontSize: '10px',
  lineHeight: '16px',
  color: colors.main.greyIcon,
});

const CardIndicatorValue = styled('div')({
  fontFamily: 'Lustria',
  fontSize: '18px',
  lineHeight: '20px',
  color: colors.main.text,
});

const PropertyBarChart = ({ property, indicator, total }) => {
  const { id, name } = property;
  const percentage = formatPercentage(indicator.value, total);

  return (
    <Card
      key={id}
      to={`/properties/${id}/dashboard`}
      className={classnames({
        'bar-charts': true,
        'positive-value': indicator.value > 0,
        'negative-value': indicator.value < 0,
      })}
    >
      <CardBarBackground className="card-bar-background" />
      <CardBar className="card-bar" sx={{ width: `${percentage || 0}%` }} />
      <CardPercentage>
        {percentage !== '' ? `${indicator.value < 0 ? '-' : ''}${percentage}%` : ''}
      </CardPercentage>
      <CardContent>
        <CardTitle>{name}</CardTitle>
        <CardActions />
      </CardContent>
      {indicator && (
        <CardIndicator>
          <CardIndicatorTitle>{indicator.name}</CardIndicatorTitle>
          <CardIndicatorValue>{formatCurrency(indicator.value)}</CardIndicatorValue>
        </CardIndicator>
      )}
    </Card>
  );
};

export default PropertyBarChart;

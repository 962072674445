import React from 'react';
import useProperties from 'hooks/components/useProperties';
import NewPropertyModal from 'components/properties/form/NewPropertyModal';
import EditPropertyModal from 'components/properties/form/EditPropertyModal';

const PropertyProvider = ({ children }) => {
  const { newProperty, editProperty, onCloseNewProperty, onCloseEditProperty } = useProperties();

  return (
    <>
      {children}
      {!!newProperty && <NewPropertyModal onClose={onCloseNewProperty} />}
      {!!editProperty && (
        <EditPropertyModal propertyId={editProperty} onClose={onCloseEditProperty} />
      )}
    </>
  );
};

export default PropertyProvider;

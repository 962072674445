import axios from 'axios';
import { setSubject, setListSubject } from 'utils/ability';
import { makeListUrl } from './base';

export const getReceipts = async (propertyId = null, filters = {}, page = 0) => {
  const res = await axios.get(
    makeListUrl(propertyId ? `properties/${propertyId}/receipts` : 'receipts', filters, page)
  );
  return setListSubject('Receipt', res.data);
};

export const getCounters = async () => {
  const res = await axios.get(`receipts/counters`);
  return res.data.count;
};

export const getReceipt = async (propertyId, receiptId) => {
  const res = await axios.get(`properties/${propertyId}/receipts/${receiptId}`);
  return setSubject('Receipt', res.data);
};

export const updateReceipt = async (propertyId, receiptId, receipt) => {
  const res = await axios.put(`properties/${propertyId}/receipts/${receiptId}`, {
    receipt,
  });
  return setSubject('Receipt', res.data);
};

export const createReceipt = async (propertyId, receipt) => {
  const res = await axios.post(`properties/${propertyId}/receipts`, {
    receipt,
  });
  return setSubject('Receipt', res.data);
};

export const deleteReceipt = async (propertyId, receiptId) => {
  await axios.delete(`properties/${propertyId}/receipts/${receiptId}`);
};

export const getGlCodes = async () => {
  const res = await axios.get(`gl_codes`);
  return res.data;
};

export const getVendors = async () => {
  const res = await axios.get(`receipt_vendors`);
  return res.data;
};

export const getPaymentMethods = async () => {
  const res = await axios.get(`receipt_payment_methods`);
  return res.data;
};

import { createSlice } from '@reduxjs/toolkit';
import { getCategories } from 'services/categories';

const initialState = {
  categories: null,
  selectedCategory: null,
  selectedSubcategory: null,
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategories: (state, action) => ({ ...state, categories: action.payload }),
    setSelectedCategory: (state, action) => ({
      ...state,
      selectedCategory: action.payload || null,
      selectedSubcategory: null,
    }),
    setSelectedSubcategory: (state, action) => ({
      ...state,
      selectedSubcategory: action.payload || null,
    }),
  },
});

const { reducer, actions } = categorySlice;

export const { setCategories, setSelectedCategory, setSelectedSubcategory } = actions;

export const fetchCategories = () => async (dispatch) => {
  const categories = await getCategories();
  dispatch(setCategories(categories));
};

export default reducer;

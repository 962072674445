import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import { fetchCurrentUser } from 'slices/auth';
import { logout, setAuthHeaders } from 'services/auth';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { updateAuthRules } from 'ability/ability';

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const location = window.location.pathname;
  const isLogin = location === '/login';

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = (error.response && error.response.status) || -1;
      if (!isLogin && (status === 401 || status === 403)) {
        logout(true);
      }
      return Promise.reject(error);
    }
  );

  useAsyncEffect(async () => {
    if (isLogin) return;

    try {
      dispatch(fetchCurrentUser());
    } catch {
      logout(true);
    }
  });

  if (auth.headers) {
    setAuthHeaders(auth);
  } else if (!isLogin) {
    logout(true);
  }

  if (!isLogin && auth.headers && !auth.user.fetched) return null;

  updateAuthRules(auth);

  return children;
};

export default AuthProvider;

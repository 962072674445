import React from 'react';
import { styled } from '@mui/material';
import colors from 'utils/colors';
import HeaderBackground from 'assets/img/header.png';

const ChatHeaderControl = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: '28px 25px',
  backgroundImage: `url(${HeaderBackground})`,
  backgroundSize: 'contain',
  filter:
    'drop-shadow(0px 4px 5px rgba(52, 36, 9, 0.14)) drop-shadow(0px 2px 4px rgba(52, 36, 9, 0.2))',
  color: colors.secondary[900],
  fontSize: '20px',
  lineHeight: '24px',
  fontWeight: 'bold',
  borderTopLeftRadius: '16px',
});

const ChatTitle = styled('p')({
  color: colors.secondary[900],
  fontSize: '20px',
  lineHeight: '24px',
  fontWeight: 'bold',
});

const ChatHeader = () => (
  <ChatHeaderControl>
    <ChatTitle>Chat</ChatTitle>
  </ChatHeaderControl>
);

export default ChatHeader;

import { createSlice } from '@reduxjs/toolkit';
import { getNotificationsCounters } from 'services/notifications';

const initialState = { notificationsCounter: null, messagesCounter: null };

const notificationSlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    setNotificationsCounter: (state, action) => ({
      ...state,
      notificationsCounter: action.payload,
    }),
    setMessagesCounter: (state, action) => ({ ...state, messagesCounter: action.payload }),
  },
});

const { reducer, actions } = notificationSlice;

export const { setNotificationsCounter, setMessagesCounter } = actions;

export const fetchNotificationsCounter = () => async (dispatch) => {
  const counter = await getNotificationsCounters();
  dispatch(setNotificationsCounter(counter.counters));
};

export default reducer;

import { isPropertyAdmin } from 'domain/roles';

const defineRulesForPolicy = (can, user) => {
  can('view', 'Policy');
  can('report', 'Policy');

  if (isPropertyAdmin(user)) {
    can('create', 'Policy');
    can('update', 'Policy');
    can('batchUpdate', 'Policy');
    can('delete', 'Policy');
  }
};

export default defineRulesForPolicy;

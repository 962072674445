import axios from 'axios';

export const getCurrentUser = async () => {
  const res = await axios.get('users/current');
  return res.data;
};

export const setUserSettings = async (settings) => {
  await axios.put('users/settings', { settings });
};

export const getUsers = async (propertyId = null) => {
  const url = `${propertyId ? `properties/${propertyId}/` : ''}/users?all=true`;
  const res = await axios.get(url);
  return res.data.records;
};

export const inviteUserToProperty = async (propertyId, userId) => {
  await axios.post(`properties/${propertyId}/invite_existent_user`, { user_id: userId });
};

export const newUser = async (userData) => {
  await axios.post(`users`, { user: userData });
};

export const getUser = async (userId) => {
  const res = await axios.get(`users/${userId}`);
  return res.data;
};

export const getUserPermissions = async (userId) => {
  const res = await axios.get(`users/${userId}/permissions`);
  return res.data.records;
};

export const getAvailablePermissions = async () => {
  const res = await axios.get('users/available_permissions');
  return res.data;
};

export const updateUser = async (userId, userData) => {
  await axios.put(`users/${userId}`, { user: userData });
};

export const updateUserPermissions = async (userId, permissions) => {
  await axios.put(`users/${userId}/set_permissions`, { permissions });
};

export const deleteUser = async (userId) => {
  await axios.delete(`users/${userId}`);
};

import React, { useState } from 'react';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import { getProperty } from 'services/properties';
import { pluralize } from 'utils/text';
import PropertyFormModal from './PropertyFormModal';

const EditPropertyModal = ({ propertyId, onClose }) => {
  const [currentProperty, setCurrentProperty] = useState(null);

  useAsyncEffect(async () => {
    setCurrentProperty(await getProperty(propertyId));
  });

  if (!currentProperty) return null;

  const title = `Edit ${pluralize(currentProperty.subcategory.name, 1)}`;
  return (
    <PropertyFormModal
      currentProperty={currentProperty}
      category={currentProperty.category}
      subcategory={currentProperty.subcategory}
      title={title}
      onClose={onClose}
    />
  );
};
export default EditPropertyModal;

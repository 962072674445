import { createSlice } from '@reduxjs/toolkit';
import { getPaymentMethods } from 'services/receipts';

const initialState = { paymentMethods: [] };

const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState,
  reducers: {
    setPaymentMethods: (state, action) => ({ ...state, paymentMethods: action.payload }),
    clearPaymentMethods: (state) => ({ ...state, paymentMethods: null }),
  },
});

const { reducer, actions } = paymentMethodSlice;

export const { clearPaymentMethods, setPaymentMethods } = actions;

export const fetchPaymentMethods = () => async (dispatch) => {
  const paymentMethods = await getPaymentMethods();
  dispatch(setPaymentMethods(paymentMethods));
};

export default reducer;

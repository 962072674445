export const makeImportantStyle = (style) =>
  Object.keys(style).reduce(
    (importantStyle, key) => ({ ...importantStyle, [key]: `${style[key]}!important` }),
    {}
  );

export const makeImportantStyles = (styles) =>
  Object.keys(styles).reduce(
    (importantStyles, key) => ({ ...importantStyles, [key]: makeImportantStyle(styles[key]) }),
    {}
  );

export const roles = {
  admin: 'admin',
  family_member: 'family_member',
  staff_member: 'staff_member',
  consultant: 'consultant',
  account_admin: 'account_admin',
  other: 'other',
};

export const sortedRoles = [
  roles.admin,
  roles.family_member,
  roles.staff_member,
  roles.consultant,
  roles.account_admin,
  roles.other,
];

export const rolesLabels = {
  [roles.admin]: 'Admin',
  [roles.family_member]: 'Family',
  [roles.staff_member]: 'Staff',
  [roles.consultant]: 'Consultant',
  [roles.account_admin]: 'Account Admin',
  [roles.other]: 'Other',
};

export const rolesPluralLabels = {
  [roles.admin]: 'Admins',
  [roles.family_member]: 'Family',
  [roles.staff_member]: 'Staff',
  [roles.consultant]: 'Consultants',
  [roles.account_admin]: 'Account Admins',
  [roles.other]: 'Others',
};

export const hasRole = ({ role: userRole }, role) => userRole === role;

export const isAdmin = (user) => hasRole(user, roles.admin);
export const isFamily = (user) => hasRole(user, roles.family_member);
export const isStaff = (user) => hasRole(user, roles.staff_member);
export const isConsultant = (user) => hasRole(user, roles.consultant);
export const isAccountAdmin = (user) => hasRole(user, roles.account_admin);
export const isOther = (user) => hasRole(user, roles.other);

export const isPropertyAdmin = (user) => isAdmin(user) || isAccountAdmin(user);

export const hasPropertyScope = (user) =>
  isStaff(user) || isConsultant(user) || isAccountAdmin(user) || isOther(user);

export const isGlobalUser = (user) => !hasPropertyScope(user) || user.all_properties;

export const belongsToProperty = ({ properties }, propertyId) =>
  (properties || []).includes(propertyId);

export const hasPermission = (user, key, scope) =>
  (user.user_permissions || []).some(
    (permission) => permission.key === key && (!scope || permission.scope === scope)
  );

export default roles;

import React, { useState } from 'react';
import MainContainer from 'components/shared/main/MainContainer';
import Button from 'components/shared/button/Button';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import { getDocument } from 'services/documents';
import DocumentsTable from './DocumentsTable';
import UploadFile from './form/UploadFile.';
import CreateFolder from './form/CreateFolder';

const getFolderId = (folderIdParam) => {
  if (!folderIdParam) return null;

  const parts = folderIdParam.split('_');
  return parts.length === 2 ? parts[1] : folderIdParam;
};

const getFolderPropertyId = (folderIdParam) => {
  if (!folderIdParam) return null;

  const parts = folderIdParam.split('_');
  return parts.length === 2 ? parts[0] : null;
};

const DocumentsList = () => {
  const { folder_id: folderIdParam } = useParams();
  const navigate = useNavigate();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const [documentsModal, setDocumentsModal] = useState(null);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const currentFolderId = getFolderId(folderIdParam) || null;
  const currentPropertyId = currentProperty?.id || getFolderPropertyId(folderIdParam) || null;
  const globalView = !currentProperty;

  const loadCurrentFolder = async () => {
    const folder = currentFolderId ? await getDocument(currentPropertyId, currentFolderId) : null;
    setCurrentFolder(folder || null);
  };

  useAsyncEffect(loadCurrentFolder, undefined, [currentFolderId]);

  const handleGoBack = async () => {
    const url = globalView
      ? `/documents/${
          currentFolder?.folder_id ? `${currentFolder.property_id}_${currentFolder.folder_id}` : ''
        }`
      : `/properties/${currentPropertyId}/documents/${currentFolder?.folder_id || ''}`;
    navigate(url);
  };

  const onClose = () => {
    setDocumentsModal(null);
    setRefreshTable(true);
  };

  const getModal = () => {
    switch (documentsModal) {
      case 'upload':
        return (
          <UploadFile
            propertyId={currentPropertyId}
            onClose={() => onClose()}
            openFolderId={currentFolderId}
          />
        );
      case 'create_folder':
        return (
          <CreateFolder
            propertyId={currentPropertyId}
            onClose={() => onClose()}
            openFolderId={currentFolderId}
          />
        );
      default:
        return <UploadFile onClose={() => onClose()} openFolderId={currentFolderId} />;
    }
  };

  return (
    <MainContainer
      title={currentFolder ? `${currentFolder.name}/` : 'Documents'}
      backButton={!!currentFolder?.name}
      onClick={() => handleGoBack()}
      buttons={
        currentPropertyId
          ? [
              <Button
                key={1}
                variant="clear"
                startIcon="upload"
                onClick={() => setDocumentsModal('upload')}
              >
                UPLOAD FILE
              </Button>,
              <Button
                key={2}
                variant="clear"
                startIcon="folder"
                onClick={() => setDocumentsModal('create_folder')}
              >
                CREATE FOLDER
              </Button>,
            ]
          : null
      }
    >
      <DocumentsTable
        propertyId={currentPropertyId}
        folderId={currentFolderId}
        currentFolder={currentFolder}
        refreshTable={refreshTable}
        onRefreshTable={() => setRefreshTable(false)}
        globalView={globalView}
      />

      {!!documentsModal && getModal()}
    </MainContainer>
  );
};

export default DocumentsList;

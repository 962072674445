import axios from 'axios';
import { makeListUrl } from './base';

export const getProperties = async (filters = {}) => {
  const res = await axios.get(makeListUrl('properties/', filters));
  return res.data;
};

export const getProperty = async (propertyId) => {
  const res = await axios.get(`properties/${propertyId}`);
  return res.data;
};

export const createProperty = async (property) => {
  const res = await axios.post(`properties`, { property });
  return res.data;
};

export const updateProperty = async (propertyId, property) => {
  const res = await axios.put(`properties/${propertyId}`, { property });
  return res.data;
};

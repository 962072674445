import React from 'react';
import SummaryRow from './SummaryRow';
import SummaryTitle from './SummaryTitle';

const DigitalAssetSummary = ({ property }) => {
  const summary = property.summary || {};

  return (
    <>
      <SummaryTitle>Asset Information:</SummaryTitle>
      <SummaryRow name="Asset Name" value={property.name} />
      <SummaryRow
        name="Asset Type (e.g., cryptocurrency, digital artwork, domain name)"
        value={summary.asset_type}
      />
      <SummaryRow
        name="Unique Identifier (e.g., wallet address, token ID)"
        value={summary.unique_id}
      />
      <SummaryRow name="Acquisition Date" value={summary.acquisition_date} type="date" />
      <SummaryRow name="Current Value" value={summary.current_value} type="currency" />
      <SummaryRow
        name="Original Purchase Price"
        value={summary.original_purchase_price}
        type="currency"
      />

      <SummaryTitle>Storage Details:</SummaryTitle>
      <SummaryRow
        name="Storage Method (e.g., digital wallet, online platform, hardware wallet)"
        value={summary.storage_method}
      />
      <SummaryRow
        name="Access Credentials (e.g., wallet keys, passwords, account details)"
        value={summary.access_credentials}
      />
      <SummaryRow
        name="Backup Information (e.g., recovery phrases, backup locations)"
        value={summary.backup_information}
      />

      <SummaryTitle>Legal and Compliance:</SummaryTitle>
      <SummaryRow name="Ownership Proof/Documentation" value={summary.ownership_proof} />
      <SummaryRow name="Regulatory Compliance Information" value={summary.regulatory_compliance} />
      <SummaryRow name="Tax Implications/Records" value={summary.tax_implications} />
      <SummaryRow
        name="Contract/Agreement Details (if applicable)"
        value={summary.contract_details}
      />

      <SummaryTitle>Valuation and Performance:</SummaryTitle>
      <SummaryRow
        name="Market Value (with date of valuation)"
        value={summary.market_value}
        type="currency"
      />
      <SummaryRow name="Historical Performance Data" value={summary.historical_performance_data} />
      <SummaryRow
        name="Expected Returns/Forecasts"
        value={summary.expected_returns}
        type="currency"
      />
      <SummaryRow name="Risk Assessment" value={summary.risk_assessment} />

      <SummaryTitle>Management and Maintenance:</SummaryTitle>
      <SummaryRow
        name="Renewal Dates (for domain names, subscriptions, etc.)"
        value={summary.renewal_date}
        type="date"
      />
      <SummaryRow name="Maintenance Fees" value={summary.maintenance_fee} type="currency" />
      <SummaryRow
        name="Software/Platform Updates (for assets like software licenses)"
        value={summary.software_platform_updates}
      />
      <SummaryRow
        name="Instructions for Transfer to Beneficiary"
        value={summary.instructions_to_transfer}
      />
    </>
  );
};

export default DigitalAssetSummary;

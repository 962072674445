import { isPropertyAdmin } from 'domain/roles';

const defineRulesForReceipts = (can, user) => {
  can('view', 'PropertyReceipts');
  can('create', 'Receipt');
  can('export', 'Receipt');
  can('view', 'Receipt');

  if (isPropertyAdmin(user)) {
    can('update', 'Receipt');
    can('delete', 'Receipt');
  } else {
    can('update', 'Receipt', { user_id: user.id_s });
    can('delete', 'Receipt', { user_id: user.id_s });
  }
};

export default defineRulesForReceipts;

import { createSlice } from '@reduxjs/toolkit';
import { getIndicators } from 'services/propertyIndicators';

const initialState = {
  indicators: null,
  consolidatedDate: null,
  batchUpdateAt: null,
  historicalDates: null,
  selectedHistoricalDate: null,
  historicalIndicators: null,
  selectedComposedIndicatorKey: null,
};

const propertyIndicatorSlice = createSlice({
  name: 'propertyIndicator',
  initialState,
  reducers: {
    setIndicators: (state, action) => ({ ...state, indicators: action.payload }),
    setHistoricalIndicators: (state, action) => ({
      ...state,
      historicalIndicators: action.payload,
    }),
    clearIndicators: (state) => ({
      ...state,
      indicators: null,
      selectedHistoricalDate: null,
      historicalIndicators: null,
    }),
    setConsolidatedDate: (state, action) => ({ ...state, consolidatedDate: action.payload }),
    setBatchUpdateAt: (state, action) => ({ ...state, batchUpdateAt: action.payload }),
    setHistoricalDates: (state, action) => ({ ...state, historicalDates: action.payload }),
    setSelectedHistoricalDate: (state, action) => ({
      ...state,
      selectedHistoricalDate: action.payload,
    }),
    setSelectedComposedIndicatorKey: (state, action) => ({
      ...state,
      selectedComposedIndicatorKey: action.payload,
    }),
  },
});

const { reducer, actions } = propertyIndicatorSlice;

export const {
  setIndicators,
  clearIndicators,
  setConsolidatedDate,
  setBatchUpdateAt,
  setHistoricalDates,
  setHistoricalIndicators,
  setSelectedHistoricalDate,
  setSelectedComposedIndicatorKey,
} = actions;

export const fetchIndicators = (propertyId, categoryId, subcategoryId) => async (dispatch) => {
  const indicators = await getIndicators(propertyId, categoryId, subcategoryId);
  dispatch(setIndicators(indicators.records));
  dispatch(setConsolidatedDate(indicators.date));
  dispatch(setBatchUpdateAt(indicators.batch_indicators_at));
  dispatch(setHistoricalDates(indicators.historical_dates));
};

export const fetchHistoricalIndicators = (propertyId, historicalDate) => async (dispatch) => {
  const indicators = await getIndicators(propertyId, null, null, historicalDate);
  dispatch(setHistoricalIndicators(indicators.records));
};

export default reducer;

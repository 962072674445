import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { styled, Autocomplete as AutocompleteMui } from '@mui/material';
import TextField from '@mui/material/TextField';
import colors from 'utils/colors';
import { valueFromObjectKeys } from 'utils/values';
import { showFieldError } from './FormControl';
import { TextInputControl, RawTextInput } from './TextInput';
import useInputChange from './useInputChange';

const SIZE_PADDINGS = {
  medium: null,
  small: '0px!important',
};

export const AutocompleteTextControl = styled(TextInputControl)({
  width: '100%',

  '& .MuiInput-input': {
    padding: '0px',
  },

  '& .MuiButtonBase-root': {
    marginTop: '-8px',
    padding: '14px',
    color: colors.primary[900],

    '&:hover': {
      background: 'none',
    },
  },

  '& .MuiInput-root.Mui-error .MuiButtonBase-root': {
    color: colors.main.error,
  },
});

export const AutocompleteTextField = styled(TextField)(({ size = 'medium' }) => ({
  '& .MuiInput-input': {
    height: '20px',
    padding: SIZE_PADDINGS[size],
  },
}));

const AutocompleteControl = styled(AutocompleteMui)({});

export const RawAutocomplete = ({
  value,
  error,
  onChange,
  name,
  label,
  disabled,
  size,
  leftLabel,
  textAlign,
  variant,
  sx,
  options: rawOptions,
  free,
}) => {
  if (disabled && !value) {
    return (
      <RawTextInput
        value="-"
        name={name}
        label={label}
        disabled
        sx={sx}
        size={size}
        leftlabel={leftLabel}
        textalign={textAlign}
        variant={variant}
      />
    );
  }

  const optionId = (option) =>
    typeof option === 'object' ? valueFromObjectKeys(option, ['id', 'value']) : option;

  const optionName = (option) =>
    typeof option === 'object' ? valueFromObjectKeys(option, ['name', 'label']) : option;

  const options = (rawOptions || []).map((option) => ({
    id: optionId(option),
    name: optionName(option),
  }));

  return (
    <AutocompleteTextControl
      className={classnames({
        disabled,
        [`${variant}-variant`]: true,
        'left-label': !!leftLabel,
      })}
      sx={sx}
      size={size}
      leftlabel={leftLabel ? leftLabel + 40 : null}
      textalign={textAlign}
      variant={variant}
    >
      {free ? (
        <AutocompleteControl
          freeSolo
          options={options}
          disabled={disabled}
          onInputChange={onChange}
          getOptionLabel={(option) => option.name}
          inputValue={value || ''}
          renderInput={(params) => (
            <AutocompleteTextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              ref={params.InputProps.ref}
              label={label}
              variant="standard"
              size={size}
              error={error}
            />
          )}
        />
      ) : (
        <AutocompleteControl
          options={options}
          disabled={disabled}
          onChange={onChange}
          getOptionLabel={(option) => option.name}
          value={(value && options.find((option) => option.id === value)) || null}
          renderInput={(params) => (
            <AutocompleteTextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              ref={params.InputProps.ref}
              label={label}
              variant="standard"
              size={size}
              error={error}
            />
          )}
        />
      )}
    </AutocompleteTextControl>
  );
};

const getEventValue = (_event, newValue) =>
  typeof newValue === 'string' ? newValue : newValue?.id;

const Autocomplete = (props) => {
  const { name, formik, onChange, fixedValue } = props;

  const { value, onChange: onOptimizedChange } = useInputChange({
    value: fixedValue !== null ? fixedValue : formik.values[name],
    onChange:
      onChange ||
      ((event, newValue) => {
        formik.setFieldValue(name, getEventValue(event, newValue));
      }),
    getEventValue,
  });

  return (
    <RawAutocomplete
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={value}
      error={showFieldError(formik, name)}
      onChange={onOptimizedChange}
    />
  );
};

Autocomplete.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  fixedValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  sx: PropTypes.shape(),
  free: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.string,
  leftLabel: PropTypes.number,
  textAlign: PropTypes.string,
  variant: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
        label: PropTypes.string,
      }),
    ])
  ),
  onChange: PropTypes.func,
};

Autocomplete.defaultProps = {
  label: '',
  disabled: false,
  sx: null,
  free: false,
  fixedValue: null,
  type: null,
  size: 'medium',
  leftLabel: null,
  textAlign: null,
  variant: 'standard',
  options: [],
  onChange: null,
};

export default Autocomplete;

import React from 'react';
import MainLayout from 'components/main/MainLayout';
import DirectoryList from 'components/directory/DirectoryList';

const Directory = () => (
  <MainLayout>
    <DirectoryList />
  </MainLayout>
);

export default Directory;

import React from 'react';
import SummaryRow from './SummaryRow';

const FinancialAccountSummary = ({ property }) => {
  const summary = property.summary || {};
  const subcategoryKey = property.subcategory.key;

  return (
    <>
      <SummaryRow name="Account Name" value={summary.account_name} />
      <SummaryRow name="Name of Institution" value={summary.institution_name} />
      <SummaryRow name="Account #" value={summary.account_number} />
      <SummaryRow name="Value" value={summary.value} type="currency" />
      <SummaryRow name="Value as of" value={summary.value_as_of} type="date" />
      {subcategoryKey === 'certificate_of_deposit' && (
        <SummaryRow name="Maturity Date" value={summary.maturity_date} type="date" />
      )}
      <SummaryRow name="Beneficiary" value={summary.beneficiary} />
      {subcategoryKey === 'checking_account' && (
        <>
          <SummaryRow name="Direct Deposits" value={summary.direct_deposits} type="currency" />
          <SummaryRow
            name="Auto Bill Payments"
            value={summary.auto_bill_payments}
            type="currency"
          />
          <SummaryRow name="Direct Deposit Payee" value={summary.direct_deposit_payee} />
          <SummaryRow
            name="Direct Deposit Amount"
            value={summary.direct_deposit_amount}
            type="currency"
          />
        </>
      )}
    </>
  );
};

export default FinancialAccountSummary;

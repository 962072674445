import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayValidator, makeValidatorSchema } from 'utils/validators';
import FormModal from 'components/shared/modal/FormModal';
import Select from 'components/shared/form/Select';
import { createPrivateChat } from 'services/privateChats';
import { propertyUsersNoCurrent } from 'domain/user';
import { fetchPrivateChats } from 'slices/privateChat';
import { fetchPropertyUsers } from 'slices/property';

const validationSchema = makeValidatorSchema({
  members: arrayValidator({ required: 'Must select a member' }),
});

const NewChatModal = ({ propertyId, onClose, onNewChat }) => {
  const dispatch = useDispatch();
  const propertyUsers = useSelector((state) => state.property.users);
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchPropertyUsers(propertyId));
  }, []);

  const initializeForm = {
    members: [],
  };

  const setMembers = (members) => {
    members.push(currentUser.id);
    return members;
  };

  const makeFormRequest = (values) => ({
    members: setMembers(values.members),
  });

  const handleSubmit = async (values) => {
    const request = makeFormRequest(values);
    const privateChat = await createPrivateChat(propertyId, request);
    onNewChat(privateChat);
    dispatch(fetchPrivateChats(propertyId));
  };

  if (!propertyUsers) return null;

  return (
    <FormModal
      open
      title="New Private Chat"
      onClose={onClose}
      initialValues={initializeForm}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError="Error Creating Chat"
      submitButton={true && { label: 'Start' }}
      cancelButton
    >
      {(formik) => (
        <Select
          formik={formik}
          name="members"
          label="Users"
          multiple
          options={propertyUsersNoCurrent(propertyUsers, currentUser)}
        />
      )}
    </FormModal>
  );
};

export default NewChatModal;

import { styled } from '@mui/material';
import colors from 'utils/colors';

const SummaryTitle = styled('div')({
  width: '100%',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: colors.main.blackText,
  marginTop: '12px',
  marginBottom: '4px',
});

export default SummaryTitle;

import { useSearchParams } from 'react-router-dom';

const usePrivateChats = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const propertyId = searchParams.get('private_chat_property');
  const privateChatId = searchParams.get('private_chat');

  const handleShowDetailsModal = (showPropertyId, showPrivateChatId = 'new') => {
    searchParams.set('private_chat_property', showPropertyId);
    searchParams.set('private_chat', showPrivateChatId);
    setSearchParams(searchParams);
  };

  const handleCloseDetailsModal = () => {
    searchParams.delete('private_chat_property');
    searchParams.delete('private_chat');
    setSearchParams(searchParams);
  };

  return {
    propertyId,
    privateChatId,
    onShowDetails: handleShowDetailsModal,
    onCloseDetails: handleCloseDetailsModal,
  };
};

export default usePrivateChats;

import React from 'react';
import { useDispatch } from 'react-redux';
import useSnackbar from 'hooks/shared/useSnackBar';
import { useNavigate } from 'react-router-dom';
import { createProperty, updateProperty } from 'services/properties';
import { fetchCurrentProperty } from 'slices/property';
import { uploadAddedFiles } from 'services/directUpload';
import { fetchIndicators } from 'slices/propertyIndicator';
import { fetchCategories } from 'slices/category';
import RealEstatePropertyFormModal from './RealEstatePropertyFormModal';
import FinancialAccountPropertyFormModal from './FinancialAccountPropertyFormModal';
import DefaultPropertyFormModal from './DefaultPropertyFormModal';
import InvestmentAccountPropertyModal from './InvestmentAccountPropertyModal';
import PersonalPropertyPropertyModal from './PersonalPropertyPropertyModal';
import BusinessInterestPropertyModal from './BusinessInterestPropertyModal';
import TrustPropertyModal from './TrustPropertyModal';
import LoanAndReceivablePropertyModal from './LoanAndReceivablePropertyModal';
import DigitalAssetPropertyModal from './DigitalAssetPropertyModal';
import EndOfLifePropertyModal from './EndOfLifePropertyModal';

const getModal = ({ key: categoryKey }) => {
  switch (categoryKey) {
    case 'real_estate':
      return RealEstatePropertyFormModal;
    case 'financial_account':
      return FinancialAccountPropertyFormModal;
    case 'investment_account':
      return InvestmentAccountPropertyModal;
    case 'personal_property':
      return PersonalPropertyPropertyModal;
    case 'business_interest':
      return BusinessInterestPropertyModal;
    case 'loan_and_receivable':
      return LoanAndReceivablePropertyModal;
    case 'trust':
      return TrustPropertyModal;
    case 'digital_asset':
      return DigitalAssetPropertyModal;
    case 'end_of_life':
      return EndOfLifePropertyModal;
    default:
      return DefaultPropertyFormModal;
  }
};

const PropertyFormModal = ({ currentProperty, category, subcategory, title, onClose }) => {
  const dispatch = useDispatch();
  const { showSuccess } = useSnackbar();
  const navigate = useNavigate();

  const handleUpdate = async (request) => {
    const property = await updateProperty(currentProperty.id, request);
    dispatch(fetchCurrentProperty(currentProperty.id));
    showSuccess(`${property.name} updated`);
    return property;
  };

  const handleCreate = async (request) => {
    request.category_id = category.id;
    request.subcategory_id = subcategory.id;
    const property = await createProperty(request);
    dispatch(fetchCategories());
    showSuccess(`${property.name} created`);
    return property;
  };

  const handleSubmit = async (request) => {
    request.logo = await uploadAddedFiles(request.logo);
    const property = await (currentProperty ? handleUpdate(request) : handleCreate(request));
    dispatch(fetchIndicators(property.id, category.id, subcategory.id));
    onClose();
    navigate(`/properties/${property.id}/dashboard`);
  };

  const Modal = getModal(category, subcategory);

  return (
    <Modal
      category={category}
      subcategory={subcategory}
      currentProperty={currentProperty}
      title={title}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
export default PropertyFormModal;

import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  ChatBubble as ChatBubbleIcon,
  Notifications as NotificationsIcon,
} from '@mui/icons-material';
import colors from 'utils/colors';
import useCategories from 'hooks/components/useCategories';
import LogoImage from 'assets/svg/logo_nav.svg';
import NotificationCounter from 'components/shared/notification/NotificationCounter';
import { fetchNotificationsCounter } from 'slices/notification';
import { isPropertyDarkColor } from 'domain/properties';
import { useInterval } from 'react-use';
import MessagesModal from '../notifications/MessagesModal';
import NotificationsModal from '../notifications/NotificationsModal';

const HeaderControl = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '12px 16px',
});

const HeaderLogo = styled(Link)({
  display: 'flex',
  alignItems: 'center',
});

const HeaderLogoIcon = styled('div')({
  width: '101px',
  height: '56px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPositionY: 'center',
});

const HeaderButtons = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  gap: '12px',
  '& > *': {
    fontSize: '24px',
    color: colors.tertiary[900],
  },
});

const PropertyHeaderDarkControl = styled(HeaderControl)({
  '& .sidebar-header-buttons': {
    '& > *': {
      color: colors.secondary[50],
    },
  },
});

const ClickeableZone = styled('div')({
  padding: '12px 6px 12px 6px',
  cursor: 'pointer',
});

const SideBarHeader = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const notificationsCounter = useSelector((state) => state.notification.notificationsCounter);
  const selectedCategory = useSelector((state) => state.category.selectedCategory);
  const selectedSubcategory = useSelector((state) => state.category.selectedSubcategory);
  const { onSetCategoryId, onSetSubcategoryId } = useCategories();
  const [notificationModal, setNotificationModal] = useState(null);

  const Control =
    currentProperty && isPropertyDarkColor(currentProperty)
      ? PropertyHeaderDarkControl
      : HeaderControl;

  useEffect(() => {
    if (!notificationsCounter) {
      dispatch(fetchNotificationsCounter());
    }
  }, []);

  useInterval(() => {
    dispatch(fetchNotificationsCounter());
  }, 10000);

  const getModal = () => {
    switch (notificationModal) {
      case 'messages':
        return <MessagesModal onClose={() => setNotificationModal(null)} />;
      case 'notifications':
        return <NotificationsModal onClose={() => setNotificationModal(null)} />;

      default:
        return <NotificationsModal onClose={() => setNotificationModal(null)} />;
    }
  };

  const getPropertyBackLink = () => {
    const home = `/properties/${currentProperty.id}/dashboard`;
    if (location.pathname !== home) return home;

    let link = '/';
    if (selectedCategory) link += `?category_id=${selectedCategory}`;
    if (selectedSubcategory) link += `&subcategory_id=${selectedSubcategory}`;
    return link;
  };

  const getGlobalBackLink = () => {
    const fromHome = location.pathname === '/';
    let link = '/';
    if (selectedCategory && (!fromHome || selectedSubcategory))
      link += `?category_id=${selectedCategory}`;
    if (selectedSubcategory && !fromHome) link += `&subcategory_id=${selectedSubcategory}`;
    return link;
  };

  const onGlobalBack = () => {
    const fromHome = location.pathname === '/';
    if (!fromHome || !selectedCategory) return;

    if (selectedSubcategory) onSetSubcategoryId();
    else onSetCategoryId();
  };

  const getBackgroundImage = () =>
    currentProperty && currentProperty.logo && currentProperty.logo.url
      ? `url(${currentProperty.logo.url || null})`
      : `url(${LogoImage})`;

  return (
    <Control>
      <HeaderLogo
        to={currentProperty ? getPropertyBackLink() : getGlobalBackLink()}
        onClick={currentProperty ? null : () => onGlobalBack()}
      >
        <HeaderLogoIcon
          sx={{
            backgroundImage: getBackgroundImage(),
          }}
        />
      </HeaderLogo>
      <HeaderButtons className="sidebar-header-buttons">
        <ClickeableZone onClick={() => setNotificationModal('messages')}>
          <NotificationCounter
            counter={notificationsCounter ? notificationsCounter.messages : null}
          >
            <ChatBubbleIcon />
          </NotificationCounter>
        </ClickeableZone>

        <ClickeableZone onClick={() => setNotificationModal('notifications')}>
          <NotificationCounter
            counter={notificationsCounter ? notificationsCounter.notifications : null}
          >
            <NotificationsIcon />
          </NotificationCounter>
        </ClickeableZone>
      </HeaderButtons>
      {!!notificationModal && getModal()}
    </Control>
  );
};

export default SideBarHeader;

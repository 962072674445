import React from 'react';
import { makeValidatorSchema, stringValidator, dateValidator } from 'utils/validators';
import FormModal from 'components/shared/modal/FormModal';
import FormRow from 'components/shared/form/FormRow';
import TextInput from 'components/shared/form/TextInput';
import DatePicker from 'components/shared/form/DatePicker';
import FormImages from 'components/shared/uploader/FormImages';
import { styled } from '@mui/material';
import colors from 'utils/colors';

const validationSchema = makeValidatorSchema({
  name: stringValidator({ required: 'Must enter a name' }),
  asset_type: stringValidator(),
  unique_id: stringValidator(),
  acquisition_date: dateValidator(),
  current_value: stringValidator(),
  original_purchase_price: stringValidator(),
  storage_method: stringValidator(),
  access_credentials: stringValidator(),
  backup_information: stringValidator(),
  contract_details: stringValidator(),
  market_value: stringValidator(),
  expected_returns: stringValidator(),
  historical_performance_data: stringValidator(),
  risk_assessment: stringValidator(),
  renewal_date: dateValidator(),
  maintenance_fee: stringValidator(),
  software_platform_updates: stringValidator(),
  instructions_to_transfer: stringValidator(),
});

const SummaryTitle = styled('div')({
  width: '100%',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: colors.main.blackText,
  marginTop: '12px',
  marginBottom: '4px',
});

const DigitalAssetPropertyModal = ({ currentProperty, title, onSubmit, onClose }) => {
  const initalizeSummary = () => {
    const summary = (currentProperty && currentProperty.summary) || {};
    return {
      asset_type: summary.asset_type || '',
      unique_id: summary.unique_id || '',
      acquisition_date: summary.acquisition_date || '',
      current_value: summary.current_value || '',
      original_purchase_price: summary.original_purchase_price || '',
      storage_method: summary.storage_method || '',
      access_credentials: summary.access_credentials || '',
      backup_information: summary.backup_information || '',
      contract_details: summary.contract_details || '',
      market_value: summary.market_value || '',
      expected_returns: summary.expected_returns || '',
      historical_performance_data: summary.historical_performance_data || '',
      risk_assessment: summary.risk_assessment || '',
      renewal_date: summary.renewal_date || '',
      maintenance_fee: summary.maintenance_fee || '',
      software_platform_updates: summary.software_platform_updates || '',
      instructions_to_transfer: summary.instructions_to_transfer || '',
    };
  };

  const initializeForm = () => ({
    ...(currentProperty
      ? {
          name: currentProperty.name,
          logo: currentProperty.logo ? [currentProperty.logo] : [],
        }
      : { name: '', logo: [] }),
    ...initalizeSummary(),
  });

  const getErrorMessage = () => (currentProperty ? `Error editing item` : `Error creating item`);

  const makeFormRequest = (values) => ({
    name: values.name,
    logo: values.logo,
    summary: {
      asset_type: values.asset_type,
      unique_id: values.unique_id,
      acquisition_date: values.acquisition_date,
      current_value: values.current_value,
      original_purchase_price: values.original_purchase_price,
      storage_method: values.storage_method,
      access_credentials: values.access_credentials,
      backup_information: values.backup_information,
      contract_details: values.contract_details,
      market_value: values.market_value,
      expected_returns: values.expected_returns,
      historical_performance_data: values.historical_performance_data,
      risk_assessment: values.risk_assessment,
      renewal_date: values.renewal_date,
      maintenance_fee: values.maintenance_fee,
      software_platform_updates: values.software_platform_updates,
      instructions_to_transfer: values.instructions_to_transfer,
    },
  });

  const handleSubmit = async (values) => {
    const request = makeFormRequest(values);
    return onSubmit(request);
  };

  return (
    <FormModal
      open
      title={title}
      onClose={onClose}
      initialValues={initializeForm()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError={getErrorMessage()}
      submitButton={{ label: currentProperty ? 'Save' : 'Create' }}
      cancelButton
    >
      {(formik) => (
        <>
          <SummaryTitle>Asset Information:</SummaryTitle>
          <FormRow>
            <TextInput formik={formik} name="name" label="Asset Name" sx={{ width: '50%' }} />
            <TextInput
              formik={formik}
              name="asset_type"
              label="Asset Type (e.g., cryptocurrency, digital artwork, domain name)"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="unique_id"
              label="Unique Identifier (e.g., wallet address, token ID)"
              sx={{ width: '50%' }}
            />
            <DatePicker
              formik={formik}
              name="acquisition_date"
              label="Acquisition Date"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="current_value"
              label="Current Value"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
            <TextInput
              formik={formik}
              name="original_purchase_price"
              label="Original Purchase Price"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
          </FormRow>

          <SummaryTitle>Storage Details:</SummaryTitle>
          <FormRow>
            <TextInput
              formik={formik}
              name="storage_method"
              label="Storage Method (e.g., digital wallet, online platform, hardware wallet)"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="access_credentials"
              label="Access Credentials (e.g., wallet keys, passwords, account details)"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="backup_information"
              label="Backup Information (e.g., recovery phrases, backup locations)"
              sx={{ width: '50%' }}
            />
          </FormRow>

          <SummaryTitle>Legal and Compliance:</SummaryTitle>
          <FormRow>
            <TextInput
              formik={formik}
              name="ownership_proof"
              label="Ownership Proof/Documentation"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="regulatory_compliance"
              label="Regulatory Compliance Information"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="tax_implications"
              label="Tax Implications/Records"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="contract_details"
              label="Contract/Agreement Details (if applicable)"
              sx={{ width: '50%' }}
            />
          </FormRow>

          <SummaryTitle>Valuation and Performance:</SummaryTitle>
          <FormRow>
            <TextInput
              formik={formik}
              name="market_value"
              label="Market Value (with date of valuation)"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
            <TextInput
              formik={formik}
              name="historical_performance_data"
              label="Historical Performance Data"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="expected_returns"
              label="Expected Returns/Forecasts"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
            <TextInput
              formik={formik}
              name="risk_assessment"
              label="Risk Assessment"
              sx={{ width: '50%' }}
            />
          </FormRow>

          <SummaryTitle>Management and Maintenance:</SummaryTitle>
          <FormRow>
            <DatePicker
              formik={formik}
              name="renewal_date"
              label="Renewal Dates (for domain names, subscriptions, etc.)"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="maintenance_fee"
              label="Maintenance Fees"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="software_platform_updates"
              label="Software/Platform Updates (for assets like software licenses)"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="instructions_to_transfer"
              label="Instructions for Transfer to Beneficiary"
              sx={{ width: '50%' }}
            />
          </FormRow>

          <FormImages
            formik={formik}
            title={false}
            name="logo"
            addButtonLabel="Set Logo"
            singleImage
          />
        </>
      )}
    </FormModal>
  );
};
export default DigitalAssetPropertyModal;

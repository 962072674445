export const getUserFullName = ({ first_name: firstName, last_name: lastName }) =>
  `${firstName} ${lastName}`;

export const getUserShortName = ({ first_name: firstName, last_name: lastName }) =>
  `${firstName.at(0)}. ${lastName}`;

export const getUserFullNameById = (users, userId) => {
  const user = userId && users.find((currentUser) => currentUser.id === userId);
  return user ? getUserFullName(user) : '';
};

export const propertyUsersNoCurrent = (users, currentUser) => {
  const noCurrentUser = users.filter((user) =>
    user.id !== undefined ? user.id !== currentUser.id : user !== currentUser.id
  );
  return noCurrentUser;
};

export const USER_PERMISSION_SCOPES = ['global', 'property'];

export const USER_PERMISSION_SCOPE_LABELS = {
  global: 'Global View',
  property: 'Asset View',
};

export const USER_PERMISSION_SECTIONS = ['dashboard', 'indicators'];

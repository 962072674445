import React from 'react';
import { makeValidatorSchema, stringValidator, dateValidator } from 'utils/validators';
import FormModal from 'components/shared/modal/FormModal';
import FormRow from 'components/shared/form/FormRow';
import TextInput from 'components/shared/form/TextInput';
import DatePicker from 'components/shared/form/DatePicker';
import FormImages from 'components/shared/uploader/FormImages';

const validationSchema = makeValidatorSchema({
  name: stringValidator({ required: 'Must enter a name' }),
  owner_name: stringValidator(),
  tax_assessesment: stringValidator(),
  maintenance_schedule: stringValidator(),
  mortage_loan_number: stringValidator(),
  purchase_date: dateValidator(),
  appraisal_date: dateValidator(),
  tax_payment_due_date: dateValidator(),
  principal_balance_as_of: dateValidator(),
  purchase_price: stringValidator(),
  tax_amount: stringValidator(),
  principal_balance: stringValidator(),
});

const RealEstatePropertyFormModal = ({ currentProperty, title, onSubmit, onClose }) => {
  const initalizeSummary = () => {
    const summary = (currentProperty && currentProperty.summary) || {};
    return {
      owner_name: summary.owner_name || '',
      tax_assessesment: summary.tax_assessesment || '',
      maintenance_schedule: summary.maintenance_schedule || '',
      mortage_loan_number: summary.mortage_loan_number || '',
      mortage_lender: summary.mortage_lender || '',
      purchase_date: summary.purchase_date || '',
      appraisal_date: summary.appraisal_date || '',
      tax_payment_due_date: summary.tax_payment_due_date || '',
      principal_balance_as_of: summary.principal_balance_as_of || '',
      purchase_price: summary.purchase_price || '',
      appraised_value: summary.appraised_value || '',
      tax_amount: summary.tax_amount || '',
      principal_balance: summary.principal_balance || '',
    };
  };

  const initializeForm = () => ({
    ...(currentProperty
      ? {
          name: currentProperty.name,
          logo: currentProperty.logo ? [currentProperty.logo] : [],
        }
      : { name: '', logo: [] }),
    ...initalizeSummary(),
  });

  const getErrorMessage = () => (currentProperty ? `Error editing item` : `Error creating item`);

  const makeFormRequest = (values) => ({
    name: values.name,
    logo: values.logo,
    summary: {
      owner_name: values.owner_name,
      tax_assessesment: values.tax_assessesment,
      maintenance_schedule: values.maintenance_schedule,
      mortage_loan_number: values.mortage_loan_number,
      mortage_lender: values.mortage_lender,
      purchase_date: values.purchase_date,
      appraisal_date: values.appraisal_date,
      tax_payment_due_date: values.tax_payment_due_date,
      principal_balance_as_of: values.principal_balance_as_of,
      purchase_price: values.purchase_price,
      appraised_value: values.appraised_value,
      tax_amount: values.tax_amount,
      principal_balance: values.principal_balance,
    },
  });

  const handleSubmit = async (values) => {
    const request = makeFormRequest(values);
    return onSubmit(request);
  };

  return (
    <FormModal
      open
      title={title}
      onClose={onClose}
      initialValues={initializeForm()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError={getErrorMessage()}
      submitButton={{ label: currentProperty ? 'Save' : 'Create' }}
      cancelButton
    >
      {(formik) => (
        <>
          <FormRow>
            <TextInput formik={formik} name="name" label="Property Address" sx={{ width: '50%' }} />
            <TextInput
              formik={formik}
              name="owner_name"
              label="Owner Name(s)"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <DatePicker
              formik={formik}
              name="purchase_date"
              label="Purchase Date"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="purchase_price"
              label="Purchase Price"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="appraised_value"
              label="Appraised Value"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
            <DatePicker
              formik={formik}
              name="appraisal_date"
              label="Appraisal Date"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="tax_assessesment"
              label="Property Tax Authority"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="tax_amount"
              label="Property Tax Amount"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
          </FormRow>
          <FormRow>
            <DatePicker
              formik={formik}
              name="tax_payment_due_date"
              label="Property Tax Payment Due Dates"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="maintenance_schedule"
              label="Maintenace Schedule"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="mortage_lender"
              label="Mortage Lender"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="mortage_loan_number"
              label="Mortgage Loan #"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="principal_balance"
              label="Principal Balance"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
            <DatePicker
              formik={formik}
              name="principal_balance_as_of"
              label="Principal Balance as of"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormImages
            formik={formik}
            title={false}
            name="logo"
            addButtonLabel="Set Logo"
            singleImage
          />
        </>
      )}
    </FormModal>
  );
};
export default RealEstatePropertyFormModal;

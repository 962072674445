import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox as CheckboxMui, styled } from '@mui/material';
import colors from 'utils/colors';
import useInputChange from './useInputChange';

const MARGIN_BOTTOMS = {
  medium: '12px',
  small: '0px',
};

export const Container = styled('div')(({ size = 'medium' }) => ({
  marginBottom: MARGIN_BOTTOMS[size],
}));

export const CheckboxControl = styled(CheckboxMui)({
  color: colors.primary[600],
  '&.Mui-checked': {
    color: colors.primary[600],
  },
  '&:hover': {
    background: 'none',
  },
  '&.Mui-disabled': {
    color: colors.main.text,
  },
});

export const LabeledCheckboxControl = styled(FormControlLabel)({
  margin: '0px',
  paddingRight: '12px',
  '& .MuiTypography-root': {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '600',
    color: colors.main.text,
  },

  '&.Mui-disabled': {
    '& .MuiTypography-root': {
      color: colors.main.text,
    },
  },
});

export const BackgroundCheckboxControl = styled(LabeledCheckboxControl)({
  backgroundColor: colors.primary[50],
  borderRadius: '4px',
  '& .MuiTypography-root': {
    textTransform: 'uppercase',
  },

  '&.Mui-disabled': {
    backgroundColor: colors.main.disabledBackground,
    border: `1px solid ${colors.main.controlBorder}`,
  },
});

const getLabeledControl = (labeledVariant) => {
  switch (labeledVariant) {
    case 'background':
      return BackgroundCheckboxControl;
    default:
      return LabeledCheckboxControl;
  }
};

export const RawCheckbox = ({
  checked,
  onChange,
  onClick,
  name,
  label: defaultLabel,
  checkedLabel,
  uncheckedLabel,
  labeledVariant,
  disabled,
  size,
  sx,
}) => {
  const label = defaultLabel || (checked ? checkedLabel : uncheckedLabel);

  const checkbox = (
    <CheckboxControl sx={sx} name={name} checked={checked} onChange={onChange} onClick={onClick} />
  );
  if (!label) return checkbox;

  const LabeledControl = getLabeledControl(labeledVariant);
  return (
    <Container size={size} sx={sx}>
      <LabeledControl disabled={disabled} control={checkbox} label={label} />
    </Container>
  );
};

const Checkbox = (props) => {
  const { fixedValue, name, formik, onChange } = props;

  const { value, onChange: onOptimizedChange } = useInputChange({
    value: fixedValue !== null ? fixedValue : formik.values[name],
    onChange: onChange || formik.handleChange,
    getEventValue: (event) => event.target.checked,
    initialValue: false,
  });

  return (
    <RawCheckbox
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      checked={value}
      onChange={onOptimizedChange}
    />
  );
};

Checkbox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  fixedValue: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  checkedLabel: PropTypes.string,
  uncheckedLabel: PropTypes.string,
  labeledVariant: PropTypes.string,
  disabled: PropTypes.bool,
  sx: PropTypes.shape(),
  size: PropTypes.string,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  fixedValue: null,
  label: null,
  checkedLabel: null,
  uncheckedLabel: null,
  labeledVariant: 'default',
  disabled: false,
  sx: null,
  size: 'medium',
  onChange: null,
};

export default Checkbox;

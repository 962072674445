import { AbilityBuilder, Ability } from '@casl/ability';
import store from '../app/store';
import defineRulesForOpenItems from './openItems';
import defineRulesForDirectory from './directory';
import defineRulesForDocuments from './documents';
import defineRulesForPropertyInputs from './propertyInputs';
import defineRulesForPropertyIndicators from './propertyIndicators';
import defineRulesForReceipts from './receipts';
import defineRulesForPleasePay from './pleasePay';
import defineRulesForPolicy from './policy';
import defineRulesForDebtLog from './debtLog';
import defineRulesForCategories from './categories';
import defineRulesForProperties from './properties';

const ability = new Ability([]);

const defineRulesFor = ({ user }) => {
  const { can, cannot, rules } = new AbilityBuilder(Ability);
  if (!user) return rules;

  defineRulesForOpenItems(can, user);
  defineRulesForDirectory(can, user);
  defineRulesForDocuments(can, user);
  defineRulesForPropertyInputs(can, cannot, user);
  defineRulesForPropertyIndicators(can, user);
  defineRulesForReceipts(can, user);
  defineRulesForPleasePay(can, user);
  defineRulesForPolicy(can, user);
  defineRulesForDebtLog(can, user);
  defineRulesForCategories(can, user);
  defineRulesForProperties(can, user);
  return rules;
};

let currentAuth;

export const updateAuthRules = (auth) => {
  const prevAuth = currentAuth;
  currentAuth = auth;
  if (prevAuth !== currentAuth) {
    ability.update(defineRulesFor(currentAuth));
  }
};

store.subscribe(() => {
  updateAuthRules(store.getState().auth);
});

export default ability;

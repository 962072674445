import axios from 'axios';
import { setSubject, setListSubject } from 'utils/ability';
import { makeListUrl } from './base';

export const getCounters = async () => {
  const res = await axios.get(`open_items/counters`);
  return res.data;
};

export const getOpenItems = async (propertyId = null, filters = {}, page = 0) => {
  const res = await axios.get(
    makeListUrl(propertyId ? `properties/${propertyId}/open_items` : 'open_items', filters, page)
  );
  return setListSubject('OpenItem', res.data);
};

export const getOpenItem = async (propertyId, openItemId) => {
  const res = await axios.get(`properties/${propertyId}/open_items/${openItemId}`);
  return setSubject('OpenItem', res.data);
};

export const updateOpenItem = async (propertyId, openItemId, openItem) => {
  const res = await axios.put(`properties/${propertyId}/open_items/${openItemId}`, {
    open_item: openItem,
  });
  return setSubject('OpenItem', res.data);
};

export const createOpenItem = async (propertyId, openItem) => {
  const res = await axios.post(`properties/${propertyId}/open_items`, { open_item: openItem });
  return setSubject('OpenItem', res.data);
};

export const deleteOpenItem = async (propertyId, openItemId) => {
  await axios.delete(`properties/${propertyId}/open_items/${openItemId}`);
};

export const permanentDeleteOpenItem = async (propertyId, openItemId) => {
  await axios.delete(`properties/${propertyId}/open_items/${openItemId}/permanent_destroy`);
};

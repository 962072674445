import { isAdmin } from 'domain/roles';

const defineRulesForPropertyIndicators = (can, user) => {
  if (user.has_indicators) can('view', 'PropertyIndicator');
  if (user.has_global_indicators) can('view', 'GlobalIndicator');
  if (user.has_indicators) can('view', 'PropertyIndicator');

  if (isAdmin(user)) {
    // can('batchUpdate', 'PropertyIndicator');
    can('update', 'PropertyIndicator', { editable: true });
  }
};

export default defineRulesForPropertyIndicators;

import React from 'react';
import { styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { getPropertyColor } from 'domain/properties';
import colors from 'utils/colors';
import SideBarHeader from './SideBarHeader';
import SideBarUser from './SideBarUser';

const SideBarControl = styled('div')({
  minWidth: '300px',
  maxWidth: '300px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.surface,
  boxShadow: '0px 8px 10px 0px #00000033, 0px 6px 24px 0px #0000001F, 0px 16px 16px 0px #00000024',
});

const SideBarHeaderContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const SideBarBody = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flex: 1,
});

const SideBarContainer = ({ headers, children }) => {
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const color = currentProperty
    ? getPropertyColor(currentProperty)
    : colors.sidebar.headerBackground;

  return (
    <SideBarControl>
      <SideBarHeaderContainer sx={{ backgroundColor: color }}>
        <SideBarHeader />
        <SideBarUser />
        {headers}
      </SideBarHeaderContainer>
      <SideBarBody
        sx={{
          background: colors.main.surface,
          height: `calc(100% - ${currentProperty ? '116' : '116'}px)`,
        }}
      >
        {children}
      </SideBarBody>
    </SideBarControl>
  );
};

export default SideBarContainer;

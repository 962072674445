import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserPermissions, getAvailablePermissions, updateUserPermissions } from 'services/users';
import {
  USER_PERMISSION_SCOPES,
  USER_PERMISSION_SCOPE_LABELS,
  USER_PERMISSION_SECTIONS,
} from 'domain/user';
import ListModal from 'components/shared/modal/ListModal';
import { renderCheckboxCell } from 'components/shared/table/Cell';
import useSnackbar from 'hooks/shared/useSnackBar';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';

const makePermissions = (userPermissions, availablePermissions) => {
  const userPermissionsDict = userPermissions.reduce(
    (permissions, { key, scope }) => ({
      ...permissions,
      [`${scope}_${key}`]: true,
    }),
    {}
  );
  return Object.keys(availablePermissions)
    .map((key) => {
      const permission = availablePermissions[key];
      return {
        ...permission,
        key,
        id: key,
        sectionPosition: USER_PERMISSION_SECTIONS.indexOf(permission.section),
        ...permission.scopes.reduce(
          (allScopes, scope) => ({
            ...allScopes,
            [scope]: userPermissionsDict[`${scope}_${key}`] || false,
          }),
          {}
        ),
      };
    })
    .sort((permission1, permission2) => {
      if (permission1.sectionPosition !== permission2.sectionPosition)
        return permission1.sectionPosition - permission2.sectionPosition;
      return permission1.name.localeCompare(permission2.name);
    });
};

const UserPermissions = ({ onClose, userId }) => {
  const { showSuccess, showError } = useSnackbar();
  const [permissions, setPermissions] = useState(null);
  const { user: currentUser } = useSelector((state) => state.auth);

  const handlePermissionChange = (scope, key, checked) =>
    setPermissions(
      permissions.map((permission) =>
        permission.key === key
          ? {
              ...permission,
              [scope]: checked,
            }
          : permission
      )
    );

  const makePermissionsRequest = () =>
    permissions.reduce(
      (selectedPermissions, permission) => [
        ...selectedPermissions,
        ...USER_PERMISSION_SCOPES.filter((scope) => permission[scope]).map((scope) => ({
          key: permission.key,
          scope,
          section: permission.section,
        })),
      ],
      []
    );

  const handelSavePermissions = async () => {
    try {
      await updateUserPermissions(userId, makePermissionsRequest());
      showSuccess('Permissions Updated');
      onClose();
      if (userId === currentUser.id) window.location.reload();
    } catch (_e) {
      showError('Error saving permissions');
    }
  };

  useAsyncEffect(async () => {
    const userPermissions = await getUserPermissions(userId);
    const availablePermissions = await getAvailablePermissions();
    setPermissions(makePermissions(userPermissions, availablePermissions));
  });

  if (!permissions) return null;

  return (
    <ListModal
      maximized={false}
      width="600px"
      title="User Permissions"
      onClose={onClose}
      rows={permissions}
      pageSize={permissions.length}
      columns={[
        {
          headerName: 'Permission',
          field: 'name',
          flex: 2,
        },
        ...USER_PERMISSION_SCOPES.map((scope) => ({
          headerName: USER_PERMISSION_SCOPE_LABELS[scope],
          field: scope,
          flex: 1,
          renderCell: ({ value, row: { key } }) =>
            renderCheckboxCell({
              value,
              onChange: (checked) => handlePermissionChange(scope, key, checked),
            }),
        })),
      ]}
      addButton={{
        label: 'Save',
        startIcon: 'save',
        onClick: handelSavePermissions,
      }}
    />
  );
};
export default UserPermissions;

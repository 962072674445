import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { styled } from '@mui/material';
import TextInput, { RawTextInput } from 'components/shared/form/TextInput';
import DatePicker from 'components/shared/form/DatePicker';
import FormRow from 'components/shared/form/FormRow';
import Select from 'components/shared/form/Select';
import Button from 'components/shared/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { clearFilters, setFilters } from 'slices/receipt';
import IconButton from 'components/shared/button/IconButton';
import RawForm from 'components/shared/form/RawForm';
import { DATE_FORMAT, formatDate } from 'utils/date';
import colors from 'utils/colors';
import { TYPING_TIMEOUT } from 'components/shared/search/SearchableTitle';

const FilterButtonWrapper = styled('div')({
  marginBottom: '9px',
  marginLeft: '8px',
});

const ApplyButtonWrapper = styled('div')({
  marginTop: '8px',
  display: 'flex',
  justifyContent: 'right',
});
const FiltersBar = styled('div')((props) => ({
  position: 'fixed',
  top: props.currentProperty ? '17px' : '12px',
  left: '472px',
  width: props.currentProperty ? 'calc(100% - 764px)' : 'calc(100% - 642px)',
  display: 'flex',
  margin: '15px 0 25px 0',
  justifyContent: 'center',
  alignItems: 'center',
}));

const FiltersContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const DelimiterContainer = styled('div')({
  marginTop: '5px',
  fontWeight: 'bold',
  '&::after': {
    content: '"-"',
  },
});

const ReceiptsFilters = ({ changeFilter }) => {
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = useState(false);
  const [search, setSearch] = useState('');

  const vendors = useSelector((state) => state.vendor.vendors);
  const glCodesList = useSelector((state) => state.glCode.glCodes);
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const propertyUsers = useSelector((state) => state.property.users);
  const globalUsers = useSelector((state) => state.user);
  const users = currentProperty ? propertyUsers : globalUsers;
  const allProperties = useSelector((state) => state.property.allProperties);
  const paymentMethods = useSelector((state) => state.paymentMethod.paymentMethods);

  const initializeForm = () => ({
    dateFrom: '',
    dateTo: '',
    amountFrom: 0,
    amountTo: 0,
    vendor: [],
    glCodes: [],
    paidBy: [],
    portfolioAssets: [],
    paidVia: [],
  });

  // eslint-disable-next-line prefer-const
  let filters = {};

  const applyFilters = (values) => {
    filters.by_description = search;

    if (values.dateFrom) {
      filters.by_start_date = formatDate(values.dateFrom, DATE_FORMAT.RAILS_DATE);
    }
    if (values.dateTo) {
      filters.by_end_date = formatDate(values.dateTo, DATE_FORMAT.RAILS_DATE);
    }
    if (values.amountFrom > 0) {
      filters.by_start_amount = values.amountFrom;
    }
    if (values.amountTo > 0) {
      filters.by_end_amount = values.amountTo;
    }
    if (values.vendor.length > 0) {
      filters.by_vendor = values.vendor;
    }
    if (values.glCodes.length > 0) {
      filters.by_gl_code = values.glCodes;
    }
    if (values.paidBy.length > 0) {
      filters.by_user = values.paidBy;
    }
    if (values.portfolioAssets.length > 0) {
      filters.by_property = values.portfolioAssets;
    }
    if (values.paidVia.length > 0) {
      filters.by_payment_method = values.paidVia;
    }

    dispatch(setFilters(filters));
    changeFilter();
  };

  const handleSubmit = async (values) => {
    applyFilters(values);
  };

  const debouncedSearchChange = useDebouncedCallback((value) => {
    filters.by_description = value;
    dispatch(setFilters(filters));
    changeFilter();
  }, TYPING_TIMEOUT);

  const handleSearchChange = (value) => {
    setSearch(value);
    debouncedSearchChange(value);
  };

  return (
    <>
      <FiltersBar currentProperty={!!currentProperty}>
        <RawTextInput
          size="small"
          name="search"
          label="Search"
          onChange={(e) => handleSearchChange(e.target.value)}
          sx={{ width: 'calc(100% - 34px)' }}
        />
        <FilterButtonWrapper>
          <IconButton
            icon="filter"
            color={colors.primary[900]}
            iconSize={35}
            onClick={() => setOpenFilter(!openFilter)}
          />
        </FilterButtonWrapper>
      </FiltersBar>
      {openFilter && (
        <FiltersContainer>
          <RawForm
            initialValues={initializeForm()}
            validationSchema={null}
            submit={false}
            messageAlwaysVisible={false}
            onSubmit={handleSubmit}
            submitButton={{ label: 'Apply' }}
            sx={{
              marginBottom: '12px',
            }}
          >
            {(formik) => (
              <>
                <FormRow>
                  <FormRow gap="12">
                    <DatePicker
                      formik={formik}
                      size="small"
                      name="dateFrom"
                      label="Date From"
                      sx={{ width: '100%' }}
                    />
                    <DelimiterContainer />
                    <DatePicker
                      formik={formik}
                      size="small"
                      name="dateTo"
                      label="Date To"
                      sx={{ width: '100%' }}
                    />
                  </FormRow>
                  <FormRow gap="12">
                    <TextInput
                      formik={formik}
                      size="small"
                      name="amountFrom"
                      label="Amount from"
                      sx={{ width: '100%' }}
                    />
                    <DelimiterContainer />
                    <TextInput
                      formik={formik}
                      size="small"
                      name="amountTo"
                      label="Amount To"
                      sx={{ width: '100%' }}
                    />
                  </FormRow>
                </FormRow>

                <FormRow>
                  <Select
                    formik={formik}
                    size="small"
                    name="vendor"
                    label="Vendor"
                    multiple
                    options={vendors.map((vendor) => ({
                      id: vendor.id,
                      label: vendor.name,
                    }))}
                    sx={{ width: '100%' }}
                  />
                  <Select
                    formik={formik}
                    size="small"
                    name="glCodes"
                    label="GL Code"
                    multiple
                    options={glCodesList.map((glCode) => ({
                      id: glCode.id,
                      label: glCode.key === 'other' ? `${glCode.name}` : `${glCode.key}`,
                      itemLabel: `${glCode.key} - ${glCode.name}`,
                    }))}
                    sx={{ width: '100%' }}
                  />
                  <Select
                    formik={formik}
                    size="small"
                    name="paidBy"
                    label="Paid By"
                    multiple
                    options={users.map((user) => ({
                      id: user.id,
                      label: user.name,
                    }))}
                    sx={{ width: '100%' }}
                  />
                  <Select
                    formik={formik}
                    size="small"
                    name="paidVia"
                    label="Paid Via"
                    multiple
                    options={paymentMethods.map((paymentMethod) => ({
                      id: paymentMethod.id,
                      label: paymentMethod.name,
                    }))}
                    sx={{ width: '100%' }}
                  />
                </FormRow>

                {!currentProperty && (
                  <Select
                    formik={formik}
                    size="small"
                    name="portfolioAssets"
                    label="Portfolio"
                    multiple
                    options={allProperties.map((property) => ({
                      id: property.id,
                      label: property.name,
                    }))}
                    sx={{ width: '100%' }}
                  />
                )}
                <ApplyButtonWrapper>
                  <Button
                    variant="transparent"
                    iconSize={20}
                    onClick={() => {
                      setSearch('');
                      formik.resetForm();
                      dispatch(clearFilters());
                      changeFilter();
                    }}
                  >
                    CLEAR
                  </Button>
                  <Button
                    variant="clear"
                    onClick={() => {
                      handleSubmit(formik.values);
                    }}
                  >
                    APPLY
                  </Button>
                </ApplyButtonWrapper>
              </>
            )}
          </RawForm>
        </FiltersContainer>
      )}
    </>
  );
};

export default ReceiptsFilters;

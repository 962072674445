import React, { useState } from 'react';
import { Menu as MenuMui, MenuItem, Button, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { ArrowDropDown as ArrowDropDownIcon, Logout as LogoutIcon } from '@mui/icons-material';
import colors from 'utils/colors';
import { getUserFullName } from 'domain/user';
import { logout } from 'services/auth';
import { isPropertyDarkColor } from 'domain/properties';

const UserControl = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
  height: '36px',
  padding: '0px 16px',
  cursor: 'pointer',
});

const UserName = styled('div')({
  fontSize: '16px',
  fontWeight: '700',
  color: colors.secondary[900],
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const UserMenuIcon = styled(ArrowDropDownIcon)({
  fontSize: '24px',
  color: colors.main.greyIcon,
});

const DarkPropertyUserControl = styled(UserControl)({
  '& .sidebar-user-menu-user-name': {
    color: colors.secondary[50],
  },

  '& .sidebar-user-menu-user-icon': {
    color: colors.secondary[50],
  },
});

const Menu = styled(MenuMui)({
  '& .MuiPaper-root': {
    borderRadius: '0px',
    width: '276px',
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    backgroundColor: colors.main.surface,
  },
  '& .MuiList-root': {
    padding: '8px 0px',
  },
  '& .MuiMenuItem-root': {
    padding: '0px',
    '&:hover': {
      backgroundColor: colors.main.surfaceOverlay,
    },
  },
  '& .MuiButton-root': {
    width: '100%',
    justifyContent: 'space-between',
    padding: '12px 16px',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'bold',
    color: colors.main.text,
    textTransform: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
    '& .MuiButton-endIcon': {
      color: colors.main.iconColor,
    },
  },
});

const SideBarUser = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const [showMenu, setShowMenu] = useState(false);
  const isDark = currentProperty && isPropertyDarkColor(currentProperty);

  const handleLogout = () => {
    logout();
  };

  const Control = isDark ? DarkPropertyUserControl : UserControl;

  return (
    <>
      <Control id="sidebar-user-menu-selector" onClick={() => setShowMenu(true)}>
        <UserName className="sidebar-user-menu-user-name">{getUserFullName(currentUser)}</UserName>
        <UserMenuIcon className="sidebar-user-menu-user-icon" />
      </Control>
      {showMenu && (
        <Menu
          id="user-menu"
          anchorEl={document.getElementById('sidebar-user-menu-selector')}
          open
          onClose={() => setShowMenu(false)}
          anchorOrigin={{ vertical: 36, horizontal: 0 }}
        >
          <MenuItem onClick={handleLogout}>
            <Button endIcon={<LogoutIcon />}>Log Out</Button>
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

export default SideBarUser;

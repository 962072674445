import axios from 'axios';
import { setSubject, setListSubject } from 'utils/ability';

const rootUrl = (propertyId) => `${propertyId ? `properties/${propertyId}/` : ''}property_inputs`;

export const getInputs = async (propertyId) => {
  const res = await axios.get(rootUrl(propertyId));
  return setListSubject('PropertyInput', res.data);
};

export const updateInput = async (propertyId, inputId, input) => {
  const res = await axios.put(`${rootUrl(propertyId)}/${inputId}`, {
    property_input: input,
  });
  return setSubject('PropertyInput', res.data);
};

export const createInput = async (propertyId, input) => {
  const res = await axios.post(rootUrl(propertyId), {
    property_input: input,
  });
  return setSubject('PropertyInput', res.data);
};

export const deleteInput = async (propertyId, inputId) => {
  await axios.delete(`${rootUrl(propertyId)}/${inputId}`);
};

import axios from 'axios';
import { setListSubject, setSubject } from 'utils/ability';
import { makeListUrl } from './base';

export const getPrivateChats = async (propertyId, filters = {}, page = 0) => {
  const res = await axios.get(makeListUrl(`properties/${propertyId}/private_chats`, filters, page));
  return setListSubject('PrivateChats', res.data);
};

export const getPrivateChat = async (propertyId, privateChatId) => {
  const res = await axios.get(`properties/${propertyId}/private_chats/${privateChatId}`);
  return setSubject('PrivateChats', res.data);
};

export const createPrivateChat = async (propertyId, privateChat) => {
  const res = await axios.post(`properties/${propertyId}/private_chats`, {
    private_chat: privateChat,
  });
  return setSubject('PrivateChats', res.data);
};

import React from 'react';
import { styled } from '@mui/material';
import colors from 'utils/colors';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { formatDate, DATE_FORMAT } from 'utils/date';
import DocumentItem from '../uploader/DocumentItem';
import ImageItem from '../uploader/ImageItem';

const MessageControl = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '50%',
  maxWidth: '70%',
  marginBottom: '16px',
  padding: '8px 12px 4px 12px',
  boxShadow: '0px 1px 1px rgba(52, 36, 9, 0.14), 0px 1px 3px rgba(52, 36, 9, 0.2)',

  '&:first-of-type': {
    marginTop: 'auto',
  },

  '&.with-documents': {
    minWidth: '70%',
  },

  '& .chat-message-user': {
    marginBottom: '8px',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 'bold',
  },

  '& .chat-message-text': {
    marginBottom: '8px',
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.main.text,
    whiteSpace: 'pre-line',
  },

  '& .chat-message-date': {
    marginLeft: 'auto',
    fontSize: '10px',
    lineHeight: '16px',
    color: colors.main.text,
  },

  '& .document-item': {
    marginBottom: '8px',
  },

  '& .image-item': {
    margin: '0px -8px 8px -8px',
  },
});

const MessageControlMine = styled(MessageControl)({
  marginLeft: 'auto',
  backgroundColor: colors.secondary[50],
  borderRadius: '10px 10px 0px 10px',

  '& .chat-message-user': {
    color: colors.primary[900],
  },
});

const MessageControlTheir = styled(MessageControl)({
  marginRight: 'auto',
  backgroundColor: colors.main.surface,
  borderRadius: '10px 10px 10px 0px',

  '& .chat-message-user': {
    color: colors.secondary[900],
  },
});

const ChatMessage = ({
  chat,
  message: {
    id,
    user_id: userId,
    user_name: userName,
    created_at: createdAt,
    text,
    documents,
    images,
  },
}) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const Control = userId === currentUser.id_s ? MessageControlMine : MessageControlTheir;
  const entityPath = `chats/${chat.id}/chat_messages/${id}`;

  return (
    <Control className={classnames({ 'with-documents': documents.length > 0 })}>
      <div className="chat-message-user">{userName}</div>
      {images.map((image) => (
        <ImageItem
          key={image.id}
          image={image}
          width="213px"
          height="146px"
          entityPath={`${entityPath}/images`}
        />
      ))}
      {!!text && <div className="chat-message-text">{text}</div>}
      {documents.map((document) => (
        <DocumentItem
          key={document.id}
          document={document}
          entityPath={`${entityPath}/documents`}
        />
      ))}
      <div className="chat-message-date">{formatDate(createdAt, DATE_FORMAT.DATE_TIME)}</div>
    </Control>
  );
};

export default ChatMessage;

import { useSearchParams } from 'react-router-dom';

const useDirectory = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const roleFilter = searchParams.get('directory_role');
  const userId = searchParams.get('directory_user');
  const userModal = searchParams.get('directory_user_modal') || 'details';

  const handleSetRoleFilter = (newRoleFilter) => {
    if (newRoleFilter) {
      searchParams.set('directory_role', newRoleFilter);
    } else {
      searchParams.delete('directory_role');
    }
    setSearchParams(searchParams);
  };

  const handleShowDetailsModal = (showUserId = 'new', showModal = 'details') => {
    searchParams.set('directory_user', showUserId);
    searchParams.set('directory_user_modal', showModal);
    setSearchParams(searchParams);
  };

  const handleCloseDetailsModal = () => {
    searchParams.delete('directory_user');
    searchParams.delete('directory_user_modal');
    setSearchParams(searchParams);
  };

  return {
    roleFilter,
    userId,
    userModal,
    onSetRoleFilter: handleSetRoleFilter,
    onShowDetails: handleShowDetailsModal,
    onCloseDetails: handleCloseDetailsModal,
  };
};

export default useDirectory;

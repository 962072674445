import React from 'react';
import useAbility from 'hooks/shared/useAbility';
import { Can } from 'app/Can';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { List, ListItem, ListItemButton, Typography, styled, Button } from '@mui/material';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import BalanceIcon from '@mui/icons-material/Balance';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import colors from 'utils/colors';
import { counterLabel } from 'utils/text';
import ChatsList from 'components/private-chats/list/ChatsList';
import { hasBalance } from 'domain/properties';

const OptionsContainer = styled(List)({
  padding: '0px 12px',
  marginBottom: '12px',
  overflow: 'auto',
});

const Option = styled(ListItem)({
  padding: '0px',
  marginBottom: '8px',
});

const OptionButton = styled(ListItemButton)({
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  margin: '0px',
  '& > .MuiSvgIcon-root': {
    marginLeft: '16px',
    color: colors.main.disabledLabel,
  },
  '& > .MuiTypography-root': {
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.main.text,
  },
  '&:hover': {
    backgroundColor: colors.main.menuHover,
  },
  '&.Mui-selected': {
    backgroundColor: colors.main.surface,
    '&:hover': {
      backgroundColor: colors.main.menuHover,
    },
    '& > .MuiSvgIcon-root': {
      color: colors.primary[700],
    },
    '& > .MuiTypography-root': {
      fontWeight: 'bold',
    },
  },
});

const OptionText = styled(Typography)({
  marginLeft: '12px',
});

const InvoiceButton = styled(Button)({
  justifyContent: 'space-between',
  width: '180px',
  height: '48px',
  margin: '0px 12px',
  padding: '0px 20px',
  borderRadius: '8px',
  backgroundColor: colors.secondary[900],
  fontSize: '14px',
  lineHeight: '26px',
  fontWeight: '500',
  color: colors.main.surface,
  boxShadow: '0px 4px 5px rgba(52, 36, 9, 0.14), 0px 2px 4px rgba(52, 36, 9, 0.2)',

  '&:hover': {
    backgroundColor: colors.secondary[900],
    opacity: '0.8',
  },

  '&::before': {
    content: "'+'",
    fontSize: '23px',
    marginTop: '-2px',
  },
});

const PropertyMenu = () => {
  const ability = useAbility();
  const location = useLocation();
  const currentProperty = useSelector((state) => state.property.currentProperty);

  const propertyPath = `/properties/${currentProperty.id}`;

  const options = [
    {
      label: 'Dashboard',
      icon: DashboardOutlinedIcon,
      path: '/dashboard',
    },
    hasBalance(currentProperty) && {
      label: 'Balance',
      icon: BalanceIcon,
      path: '/balance',
    },
    {
      label: 'Directory',
      icon: ContactsOutlinedIcon,
      permission: 'PropertyDirectory',
      path: '/directory',
    },

    {
      label: 'Bills for approval',
      icon: ReceiptLongOutlinedIcon,
      permission: 'Bills',
      path: '/bills',
    },
    {
      label: 'Documents',
      icon: FolderOutlinedIcon,
      permission: 'Documents',
      path: '/documents',
    },
    {
      label: 'Receipts',
      icon: ReceiptOutlinedIcon,
      permission: 'PropertyReceipts',
      path: '/receipts',
    },
    {
      label: counterLabel('Please Pay', currentProperty.please_pays_count),
      icon: ReceiptLongOutlinedIcon,
      permission: 'PropertyPleasePay',
      path: '/please-pay',
    },
    {
      label: 'Inventory',
      icon: InventoryOutlinedIcon,
      permission: 'Inventory',
      path: '/inventory',
    },
  ];

  return (
    <>
      <OptionsContainer>
        {options
          .filter((option) => option)
          .filter(({ permission }) => !permission || ability.can('view', permission))
          .map((option) => {
            const { label, path, icon: Icon } = option;
            const fullPath = propertyPath + path;
            const selected = fullPath === location.pathname;

            return (
              <Option key={label}>
                <OptionButton selected={selected} component={Link} to={fullPath}>
                  <Icon name={label} />
                  <OptionText variant="body1">{label}</OptionText>
                </OptionButton>
              </Option>
            );
          })}
      </OptionsContainer>
      <ChatsList />
      <Can I="create" a="Invoice" ability={ability}>
        <InvoiceButton>CREATE INVOICE</InvoiceButton>
      </Can>
    </>
  );
};

export default PropertyMenu;

import React from 'react';
import SummaryRow from './SummaryRow';
import SummaryTitle from './SummaryTitle';

const LoanAndReceivableSummary = ({ property }) => {
  const summary = property.summary || {};

  return (
    <>
      <SummaryRow name="Account Name" value={property.name} />

      <SummaryTitle>Borrower Information:</SummaryTitle>
      <SummaryRow name="Full Name" value={summary.full_name} />
      <SummaryRow name="Contact Details (Phone, Email, Address)" value={summary.contact_details} />
      <SummaryRow
        name="Identification Number (e.g., Social Security Number or equivalent)"
        value={summary.identification_number}
        type="currency"
      />

      <SummaryTitle>Loan Details:</SummaryTitle>
      <SummaryRow name="Loan Type (e.g., personal, mortgage, business)" value={summary.loan_type} />
      <SummaryRow name="Principal Amount" value={summary.principal_amount} type="currency" />
      <SummaryRow name="Interest Rate" value={summary.interest_rate} type="number" />
      <SummaryRow name="Loan Term Start Date" value={summary.start_date} type="date" />
      <SummaryRow name="Loan Term End Date" value={summary.end_date} type="date" />
      <SummaryRow
        name="Repayment Schedule (e.g., monthly, quarterly)"
        value={summary.repayment_schedule}
      />
      <SummaryRow name="Payment Due Dates" value={summary.payment_due_date} type="date" />

      <SummaryTitle>Payment Information:</SummaryTitle>
      <SummaryRow name="Payment Amount" value={summary.payment_amount} type="currency" />
      <SummaryRow name="Payment Date" value={summary.payment_date} type="date" />
      <SummaryRow
        name="Payment Method (e.g., bank transfer, check)"
        value={summary.payment_method}
      />
      <SummaryRow name="Outstanding Balance" value={summary.outstanding_balance} />
      <SummaryRow
        name="Late Payment Fees (if applicable)"
        value={summary.late_payment_fees}
        type="currency"
      />

      <SummaryTitle>Collateral Information (if applicable):</SummaryTitle>
      <SummaryRow name="Description of Collateral" value={summary.description_of_collateral} />
      <SummaryRow name="Value of Collateral" value={summary.value_of_colleteral} type="currency" />
      <SummaryRow name="Collateral Location" value={summary.collateral_location} />
    </>
  );
};

export default LoanAndReceivableSummary;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DocumentsList from './DocumentsList';

const DocumentsRoute = () => (
  <Routes>
    <Route path="/:folder_id" element={<DocumentsList />} />
    <Route path="/" element={<DocumentsList />} />
  </Routes>
);

export default DocumentsRoute;

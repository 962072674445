import React from 'react';
import { ListItem, Typography, ListItemButton, styled } from '@mui/material';
import classnames from 'classnames';
import colors from 'utils/colors';
import { getCategoryIcon } from 'domain/categories';

const Option = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px',
  marginBottom: '8px',
});

const OptionButton = styled(ListItemButton)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  height: '37px',
  margin: '0px',
  padding: '0',
  '& > svg:first-of-type': {
    fill: 'currentColor',
    marginLeft: '16px',
    color: colors.main.disabledLabel,
  },
  '& > .MuiTypography-root': {
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.main.text,
    fontWeight: 'bold',
  },
  '&:hover': {
    backgroundColor: colors.main.menuHover,
  },
  '&.Mui-selected': {
    backgroundColor: colors.main.surfaceOverlay,
    '&:hover': {
      backgroundColor: colors.main.menuHover,
    },
    '& > svg:first-of-type': {
      color: colors.primary[700],
    },
    '& > .MuiTypography-root': {
      fontWeight: 'normal',
    },
  },
});

const OptionText = styled(Typography)({
  marginLeft: '6px',
});

const OptionCounter = styled(Typography)({
  marginLeft: 'auto',
  marginRight: '16px',
  fontWeight: 'bold',
});

const SubcategoriesContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
});

const SubcategoryContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '3px 16px 3px 46px',
  fontSize: '14px',
  userSelect: 'none',

  '&.selected': {
    fontWeight: 'bold',
  },
});

const SubcategoryText = styled('div')({});

const SubcategoryCounter = styled('div')({});

const Category = ({
  category,
  canManage,
  selected,
  selectedSubcategoryId,
  onClick,
  onSubcategoryClick,
}) => {
  const Icon = getCategoryIcon(category);
  const subcategories = category.subcategories.filter(
    (subcategory) => subcategory.properties_count || canManage
  );

  return (
    <Option key={category.id}>
      <OptionButton selected={selected} onClick={onClick}>
        <Icon />
        <OptionText variant="body1"> {category.name}</OptionText>
        <OptionCounter>{category.properties_count || 0}</OptionCounter>
      </OptionButton>
      {selected && (subcategories.length > 0 || canManage) && (
        <SubcategoriesContainer>
          {subcategories.map((subcategory) => (
            <SubcategoryContainer
              key={subcategory.key}
              onClick={() => onSubcategoryClick(subcategory)}
              className={classnames({ selected: subcategory.id === selectedSubcategoryId })}
            >
              <SubcategoryText>{subcategory.name}</SubcategoryText>
              <SubcategoryCounter>{subcategory.properties_count || 0}</SubcategoryCounter>
            </SubcategoryContainer>
          ))}
        </SubcategoriesContainer>
      )}
    </Option>
  );
};

export default Category;

import React, { useEffect } from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { List, ListItem, ListItemButton, Typography, styled } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useAbility from 'hooks/shared/useAbility';
import useCategories from 'hooks/components/useCategories';
import { fetchPleasePayCounters } from 'slices/pleasePay';
import colors from 'utils/colors';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { counterLabel } from 'utils/text';

const OptionsContainer = styled(List)({
  padding: '0px 12px',
});

const Option = styled(ListItem)({
  padding: '0px',
  marginBottom: '8px',
});

const OptionButton = styled(ListItemButton)({
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  margin: '0px',
  padding: '0',
  '& > .MuiSvgIcon-root': {
    marginLeft: '16px',
    color: colors.tertiary[900],
  },
  '& > .MuiTypography-root': {
    marginLeft: '12px',
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.main.text,
  },
  '&:hover': {
    backgroundColor: colors.main.menuHover,
  },
  '&.Mui-selected': {
    backgroundColor: colors.main.surface,
    '&:hover': {
      backgroundColor: colors.main.menuHover,
    },
    '& > .MuiSvgIcon-root': {
      color: colors.primary[900],
    },
    '& > .MuiTypography-root': {
      fontWeight: 'bold',
    },
  },
});

const GlobalMenu = () => {
  const dispatch = useDispatch();
  const ability = useAbility();
  const location = useLocation();
  const pleasePayCounters = useSelector((state) => state.pleasePay.counters);
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const { onSetCategoryId } = useCategories();

  useEffect(() => {
    if (currentProperty) return;

    dispatch(fetchPleasePayCounters());
  }, [currentProperty]);

  const options = [
    {
      label: 'Home',
      icon: HomeOutlinedIcon,
      path: '/',
      onClick: () => onSetCategoryId(),
    },
    {
      label: 'Open Items Report',
      icon: FeedOutlinedIcon,
      permission: 'OpenItemReports',
      path: '/open-items-report',
    },
    {
      label: 'Directory',
      icon: ContactsOutlinedIcon,
      permission: 'Directory',
      path: '/directory',
    },
    {
      label: 'Documents',
      icon: FolderOutlinedIcon,
      permission: 'Documents',
      path: '/documents',
    },
    {
      label: 'Receipts',
      icon: ReceiptOutlinedIcon,
      permission: 'Receipt',
      path: '/receipts',
    },
    {
      label: counterLabel('Please Pay', pleasePayCounters),
      icon: ReceiptLongOutlinedIcon,
      permission: 'PleasePay',
      path: '/please-pay',
    },
  ];

  return (
    <OptionsContainer>
      {options
        .filter(({ permission }) => !permission || ability.can('view', permission))
        .map((option) => {
          const { label, path, icon: Icon, onClick } = option;
          return (
            <Option key={label}>
              <OptionButton
                to={path}
                component={Link}
                selected={path === location.pathname}
                onClick={onClick}
              >
                <Icon name={label} />
                <Typography variant="body1">{label}</Typography>
              </OptionButton>
            </Option>
          );
        })}
    </OptionsContainer>
  );
};

export default GlobalMenu;

import React from 'react';
import SummaryRow from './SummaryRow';

const BusinessInterestSummary = ({ property }) => {
  const summary = property.summary || {};

  return (
    <>
      <SummaryRow name="Business Name" value={property.name} />
      <SummaryRow name="Type of Businesss" value={summary.type_of_business} />
      <SummaryRow name="Industry" value={summary.industry} />
      <SummaryRow name="Business Address" value={summary.business_addresss} />
      <SummaryRow name="Ownership Details" value={summary.ownership_details} />
    </>
  );
};

export default BusinessInterestSummary;

import React, { useEffect } from 'react';
import { List, Typography, styled } from '@mui/material';
import { fetchCategories } from 'slices/category';
import { useDispatch, useSelector } from 'react-redux';
import useAbility from 'hooks/shared/useAbility';
import useCategories from 'hooks/components/useCategories';
import colors from 'utils/colors';
import IconButton from 'components/shared/button/IconButton';
import Category from './Category';

const OptionsContainer = styled(List)({
  padding: '0px 12px',
  marginBottom: '12px',
  overflow: 'auto',
});

const Separator = styled('hr')({
  margin: '0px 12px 24px 12px',
  border: 'none',
  borderBottom: `1px solid ${colors.main.controlBorder}`,
});

const PropertiesHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '0px 12px 12px 12px',
});

const PropertiesTitle = styled(Typography)({
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 'bold',
  color: colors.primary[900],
});

const CategoriesFilter = () => {
  const dispatch = useDispatch();
  const ability = useAbility();
  const categories = useSelector((state) => state.category.categories);
  const { categoryId, subcategoryId, onShowGlobalSettings, onSetCategoryId, onSetSubcategoryId } =
    useCategories();
  const canManage = ability.can('manage', 'Category');

  useEffect(() => {
    if (!categories) dispatch(fetchCategories());
  }, []);

  const handleListItemClick = (selectedCategoryId) => {
    onSetCategoryId(selectedCategoryId !== categoryId || subcategoryId ? selectedCategoryId : null);
  };

  const handleSubcategoryClick = (selectedSubcategoryId) => {
    onSetSubcategoryId(selectedSubcategoryId !== subcategoryId ? selectedSubcategoryId : null);
  };

  const handleSettingsClick = () => onShowGlobalSettings();

  if (!categories) return null;

  return (
    <>
      <Separator />
      <PropertiesHeader>
        <PropertiesTitle>Portfolio</PropertiesTitle>
        {canManage && (
          <IconButton icon="settings" tooltip="Select categories" onClick={handleSettingsClick} />
        )}
      </PropertiesHeader>
      <OptionsContainer>
        {categories
          .filter((category) => category.properties_count || canManage)
          .map((category) => (
            <Category
              key={category.key}
              category={category}
              canManage={canManage}
              selected={categoryId === category.id}
              selectedSubcategoryId={subcategoryId}
              onClick={() => handleListItemClick(category.id)}
              onSubcategoryClick={(subcategory) => handleSubcategoryClick(subcategory.id)}
            />
          ))}
      </OptionsContainer>
    </>
  );
};

export default CategoriesFilter;

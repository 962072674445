import { makeDate, now } from 'utils/date';

export const openItemStatuses = {
  draft: 'draft',
  pending: 'pending',
  resolved: 'resolved',
  archived: 'archived',
};

export const isOpen = ({ status }) =>
  ![openItemStatuses.resolved, openItemStatuses.archived].includes(status);

export const isOverdue = ({ status, due_date: dueDate }) =>
  status === openItemStatuses.pending && !!dueDate && makeDate(dueDate) < now().startOf('day');

export const repeatPeriod = ['Never', 'Weekly', 'Monthly', 'Quarterly', 'Annually', 'Custom'];

export const getRepeatPeriodLabel = (number, period) => {
  if (number === 1 && period === 'weeks') return 'Weekly';
  if (number === 1 && period === 'months') return 'Monthly';
  if (number === 3 && period === 'months') return 'Quarterly';
  if (number === 1 && period === 'years') return 'Annually';
  if (period === 'custom') return 'Custom';
  return 'Never';
};

export const getRepeatPeriod = (period) => {
  if (period === 'Weekly') return 'weeks';
  if (period === 'Monthly' || period === 'Quarterly') return 'months';
  if (period === 'Annually') return 'years';
  if (period === 'Custom') return 'custom';
  return null;
};

export const getRepeatNumber = (period) => {
  if (period === 'Weekly' || period === 'Monthly' || period === 'Annually') return 1;
  if (period === 'Quarterly') return 3;

  return null;
};

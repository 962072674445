import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { styled, Menu as MenuMui, MenuItem, Button } from '@mui/material';
import colors from 'utils/colors';
import { getIcon } from 'components/shared/button/Button';
import IconButton from 'components/shared/button/IconButton';

const MenuContainer = styled(MenuMui)({
  '& .MuiPaper-root': {
    borderRadius: '0px',
    width: '230px',
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    backgroundColor: colors.main.surface,
  },
  '& .MuiList-root': {
    padding: '8px 0px',
  },
  '& .MuiMenuItem-root': {
    padding: '0px',

    '& .MuiButton-root': {
      width: '100%',
      justifyContent: 'space-between',
      padding: '12px 16px',
      fontSize: '14px',
      lineHeight: '20px',
      color: colors.main.text,
      textTransform: 'unset',
      '&:hover': {
        backgroundColor: 'unset',
      },
      '& .MuiButton-endIcon': {
        color: colors.main.iconColor,
      },
    },

    '&:hover': {
      backgroundColor: colors.main.surfaceOverlay,
    },

    '&.selected': {
      '& .MuiButton-root': {
        fontWeight: 'bold',
        color: colors.tertiary[900],
      },
    },
  },
});

const renderDefaultButton = ({ id, onClick, disabled }) => (
  <IconButton icon="moreVert" id={id} onClick={onClick} disabled={disabled} />
);

const Menu = ({ id, options, renderButton, hideOnEmpty }) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuId = `${id}_table-cell-menu`;
  const selectorId = `${id}_table-cell-menu-selector`;
  const empty = options.length === 0;

  const handleMenuClick = (onClick) => {
    setShowMenu(false);
    if (onClick) onClick();
  };

  return (
    <>
      {!empty || !hideOnEmpty
        ? renderButton({
            id: selectorId,
            onClick: () => setShowMenu(true),
            disabled: empty,
          })
        : null}
      {showMenu && !empty ? (
        <MenuContainer
          id={menuId}
          anchorEl={document.getElementById(selectorId)}
          open
          onClose={() => setShowMenu(false)}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {options.map(({ key, icon, onClick, label, selected }) => {
            const Icon = getIcon(icon);
            return (
              <MenuItem
                key={key}
                className={classnames({
                  selected,
                })}
                onClick={() => handleMenuClick(onClick)}
              >
                <Button endIcon={Icon ? <Icon /> : null}>{label}</Button>
              </MenuItem>
            );
          })}
        </MenuContainer>
      ) : null}
    </>
  );
};

Menu.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        key: PropTypes.string,
        icon: PropTypes.string,
        label: PropTypes.string,
        selected: PropTypes.bool,
        onClick: PropTypes.func,
      }),
    ])
  ).isRequired,
  renderButton: PropTypes.func,
  hideOnEmpty: PropTypes.bool,
};

Menu.defaultProps = {
  renderButton: renderDefaultButton,
  hideOnEmpty: false,
};

export default Menu;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeValidatorSchema } from 'utils/validators';
import useSnackbar from 'hooks/shared/useSnackBar';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import FormModal from 'components/shared/modal/FormModal';
import FormRow from 'components/shared/form/FormRow';
import Checkbox from 'components/shared/form/Checkbox';
import { getCategories, getCategoryTemplates, selectCategories } from 'services/categories';
import { fetchCategories } from 'slices/category';

const validationSchema = makeValidatorSchema({});

const GlobalSettingsModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const { showSuccess } = useSnackbar();
  const [categories, setCategories] = useState(null);
  const [categoryTemplates, setCategoryTemplates] = useState(null);

  const initializeForm = () =>
    categoryTemplates.reduce((values, { key }) => ({ ...values, [key]: categories.has(key) }), {});

  const makeFormRequest = (values) => ({
    keys: Object.keys(values).filter((key) => values[key]),
  });

  const handleSubmit = async (values) => {
    const request = makeFormRequest(values);
    await selectCategories(request);
    dispatch(fetchCategories());
    showSuccess('Categories saved');
    onClose();
  };

  useAsyncEffect(async () => {
    setCategories(new Set((await getCategories()).map(({ key }) => key)));
    setCategoryTemplates(await getCategoryTemplates());
  });

  if (!categories || !categoryTemplates) return null;

  return (
    <FormModal
      open
      title="Select Categories"
      onClose={onClose}
      initialValues={initializeForm()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError="Error saving categories"
      submitButton={{ label: 'Save' }}
      cancelButton
    >
      {(formik) =>
        categoryTemplates.map(({ key, name }) => (
          <FormRow key={key}>
            <Checkbox formik={formik} name={key} label={name} />
          </FormRow>
        ))
      }
    </FormModal>
  );
};
export default GlobalSettingsModal;

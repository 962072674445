import React from 'react';
import usePrivateChats from 'hooks/components/usePrivateChats';
import NewChatModal from 'components/private-chats/new-chat/NewChatModal';
import ChatModal from 'components/private-chats/chat/ChatModal';

const PrivateChatProvider = ({ children }) => {
  const { propertyId, privateChatId, onCloseDetails, onShowDetails } = usePrivateChats();

  const handleNewChat = (privateChat) => onShowDetails(propertyId, privateChat.id);

  if (!propertyId) return children;

  return (
    <>
      {children}
      {!!privateChatId &&
        (privateChatId === 'new' ? (
          <NewChatModal
            propertyId={propertyId}
            onClose={onCloseDetails}
            onNewChat={handleNewChat}
          />
        ) : (
          <ChatModal
            propertyId={propertyId}
            privateChatId={privateChatId}
            onClose={onCloseDetails}
          />
        ))}
    </>
  );
};

export default PrivateChatProvider;

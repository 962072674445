export const switchArrayElements = (array, index1, index2) => {
  const result = [...array];
  const element1 = array[index1];
  const element2 = array[index2];
  result[index1] = element2;
  result[index2] = element1;
  return result;
};

export const reorderArrayElement = (array, startIndex, endIndex) => {
  const result = Array.from(array);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import colors from 'utils/colors';
import { scrollBottom } from 'utils/scroll';
import { fetchCurrentChatMessages } from 'slices/chat';
import { useInterval } from 'react-use';
import ChatHeader from './ChatHeader';
import ChatMessage from './ChatMessage';
import ChatFooter from './ChatFooter';

const ChatBodyControl = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  background: colors.main.surface,
});

const ChatMessages = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  marginTop: 'auto',
  padding: '12px 16px 0px 16px',
  overflow: ['auto', 'overlay'],
});

const ChatHeaderContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '80px',
});

const ChatFooterContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxHeight: '70%',
  backgroundColor: colors.main.surface,
});

const ChatBody = ({ bottomWindow }) => {
  const dispatch = useDispatch();
  const currentChat = useSelector((state) => state.chat.currentChat);
  const messages = useSelector((state) => state.chat.currentChatMessages);
  const [messagesCount, setMessagesCount] = useState(0);

  useInterval(async () => {
    dispatch(fetchCurrentChatMessages(currentChat.id, messages));
  }, 10000);

  useEffect(() => {
    if (!messages || messages.records.size === messagesCount) return;

    scrollBottom('chat-messages-container');
    setMessagesCount(messages.records.size);
  }, [messages]);

  return (
    <ChatBodyControl>
      {!bottomWindow && (
        <ChatHeaderContainer>
          <ChatHeader />
        </ChatHeaderContainer>
      )}
      <ChatMessages id="chat-messages-container">
        {(messages?.records || [])
          .slice(0)
          .reverse()
          .map((message) => (
            <ChatMessage key={message.id} chat={currentChat} message={message} />
          ))}
      </ChatMessages>
      <ChatFooterContainer>
        <ChatFooter bottomWindow={bottomWindow} />
      </ChatFooterContainer>
    </ChatBodyControl>
  );
};

export default ChatBody;

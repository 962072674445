import React, { useEffect } from 'react';
import MainContainer from 'components/shared/main/MainContainer';
import useAbility from 'hooks/shared/useAbility';
import Button from 'components/shared/button/Button';
import { useSelector, useDispatch } from 'react-redux';
import { fetchVendors } from 'slices/vendor';
import { fetchGlCodes } from 'slices/glCode';
import { fetchAllProperties, fetchPropertyUsers } from 'slices/property';
import { fetchAllUsers } from 'slices/user';
import usePleasePays from 'hooks/components/usePleasePays';
import PleasePayTable from './PleasePayTable';
import ExportPleasePay from './form/ExportPleasePay';
import AddPleasePay from './form/AddPleasePay';
import UpdatePleasePay from './form/UpdatePleasePay';

const PleasePayList = () => {
  const dispatch = useDispatch();
  const ability = useAbility();
  const {
    propertyId: selectedPleasePayProperty,
    pleasePayId: selectedPleasePay,
    updateCounters,
    onShowDetails,
    onCloseDetails,
  } = usePleasePays();
  const glCodes = useSelector((state) => state.glCode.glCodes);
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const propertyUsers = useSelector((state) => state.property.users);
  const globalUsers = useSelector((state) => state.user);
  const users = currentProperty ? propertyUsers : globalUsers;
  const allProperties = useSelector((state) => state.property.allProperties);

  useEffect(() => {
    if (glCodes.length === 0) {
      dispatch(fetchGlCodes());
    }
    if (!users && currentProperty) {
      dispatch(fetchPropertyUsers(currentProperty.id));
    } else {
      dispatch(fetchAllUsers());
    }
    dispatch(fetchVendors());
    if (!allProperties) dispatch(fetchAllProperties());
    updateCounters();
  }, []);

  const getModal = () => {
    switch (selectedPleasePay) {
      case 'export':
        return <ExportPleasePay propertyId={selectedPleasePayProperty} onClose={onCloseDetails} />;
      case 'new':
        return <AddPleasePay propertyId={selectedPleasePayProperty} onClose={onCloseDetails} />;
      default:
        return (
          <UpdatePleasePay
            propertyId={selectedPleasePayProperty}
            pleasePayId={selectedPleasePay}
            onClose={onCloseDetails}
          />
        );
    }
  };

  if (!currentProperty && !allProperties) return null;

  return (
    <MainContainer
      title="Please Pay"
      buttons={
        <>
          {!!currentProperty && ability.can('create', 'PleasePay') && (
            <Button
              variant="clear"
              startIcon="add"
              onClick={() => onShowDetails(currentProperty.id, 'new')}
              sx={{ marginRight: '120px' }}
            >
              CREATE
            </Button>
          )}
          {/* {ability.cam('export', 'PleasePay') ? && ( 
            <Button variant="clear" startIcon="download" onClick={() => window.print()}>
               EXPORT
             </Button>) 
             } */}
        </>
      }
    >
      <PleasePayTable />
      {!!selectedPleasePay && getModal()}
    </MainContainer>
  );
};

export default PleasePayList;

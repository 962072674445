import React from 'react';
import { styled } from '@mui/material';
import { useSelector } from 'react-redux';
import PropertyIndicators from 'components/property-indicators/PropertyIndicators';
import PropertyInputs from 'components/property-inputs/PropertyInputs';
import PropertyBoard, { hasPropertyBoard } from 'components/property-board/PropertyBoard';
import { Can } from 'app/Can';
import useAbility from 'hooks/shared/useAbility';
import usePropertyIndicators from 'hooks/components/usePropertyIndicators';
import colors from 'utils/colors';

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
});

const BoardContainer = styled('div')({
  width: '50%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '30px 20px 20px 20px',
  overflow: ['auto', 'overlay'],
});

const RightContainer = styled('div')({
  width: '50%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: 'linear-gradient(0deg, #F5F5F4, #F5F5F4), #FFFFFF',

  '& .property-indicators-container': {
    padding: '30px 12px 16px 12px',
  },
});

const PropertyIndicatorsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${colors.main.controlBorder}`,
});

const PropertyInputsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const Dashboard = () => {
  const ability = useAbility();
  const hasIndicators = ability.can('view', 'PropertyIndicator');
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const hasBoard = hasPropertyBoard(currentProperty);
  const { indicators, indicatorsSize } = usePropertyIndicators();
  const loadingIndicators = hasIndicators && !indicators;

  return (
    <Container>
      <BoardContainer>{hasBoard && <PropertyBoard headerSize={indicatorsSize} />}</BoardContainer>
      <RightContainer>
        {hasIndicators && (
          <PropertyIndicatorsContainer
            sx={{
              height: `${indicatorsSize}px`,
              display: loadingIndicators ? 'none' : null,
            }}
          >
            {hasIndicators && <PropertyIndicators />}
          </PropertyIndicatorsContainer>
        )}
        <Can I="view" a="PropertyInput" ability={ability}>
          <PropertyInputsContainer
            sx={{
              height: hasIndicators ? `calc(100% - ${indicatorsSize}px)` : '100%',
              display: loadingIndicators ? 'none' : null,
            }}
          >
            <PropertyInputs />
          </PropertyInputsContainer>
        </Can>
      </RightContainer>
    </Container>
  );
};

export default Dashboard;

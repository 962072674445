import React from 'react';
import useAbility from 'hooks/shared/useAbility';
import UserModal from './UserModal';

const InviteUser = ({ onClose }) => {
  const ability = useAbility();
  const disabled = !ability.can('create', 'Directory');

  return <UserModal title="Add User" disabled={disabled} onClose={onClose} />;
};

export default InviteUser;

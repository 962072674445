import { createSlice } from '@reduxjs/toolkit';
import { getUsers } from 'services/users';

const initialState = [];

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAllUsers: (state, action) => [...action.payload],
    clearAllUsers: () => [],
  },
});

const { reducer, actions } = userSlice;

export const { setAllUsers, clearAllUsers } = actions;

export const fetchAllUsers = () => async (dispatch) => {
  const users = await getUsers();
  dispatch(setAllUsers(users));
};

export default reducer;

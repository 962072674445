import { useState, useEffect, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const INPUT_DELAY = 200;
export const getInputValue = (event) => event.target.value;

const useInputChange = ({
  value,
  onChange,
  delay = INPUT_DELAY,
  getEventValue = getInputValue,
  initialValue = '',
}) => {
  const [innerValue, setInnerValue] = useState(initialValue);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const debouncedHandleOnChange = useDebouncedCallback(onChange, delay);

  const handleChange = useCallback((...args) => {
    setInnerValue(getEventValue(...args));
    debouncedHandleOnChange(...args);
  }, []);

  return {
    value: innerValue,
    onChange: handleChange,
  };
};

export default useInputChange;

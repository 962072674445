/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ModalMui, Box, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import colors from 'utils/colors';
import TransitionContainer from '../transition/TransitionContainer';

export const ModalContainer = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '16px',
  backgroundColor: colors.main.surface,
  '& .MuiBox-root': {
    height: '100%',
  },
});

export const MaximizedModalContainer = styled(Box)({
  position: 'absolute',
  top: '0px',
  left: '300px',
  width: 'calc(100% - 300px)',
  height: '100%',
  backgroundColor: colors.main.surface,
  '& .MuiBox-root': {
    height: '100%',
  },
});

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '0px 24px',
  gap: '24px',
  width: '100%',
  height: '80px',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  backgroundColor: colors.main.surface,
  boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  overflow: 'hidden',
});

const Title = styled('p')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: '0px',
  marginLeft: '-24px',
  paddingLeft: '24px',
  zIndex: 1,
  backgroundColor: colors.main.surface,
  color: colors.tertiary[900],
  fontSize: '24px',
  lineHeight: '36px',
});

const RightHeaderContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
  marginLeft: 'auto',
});

const CloseButton = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '48px',
  height: '48px',
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  color: colors.main.text,
});

const makeTransition = ({ transitionVariant, transitionProps, maximized }) => {
  if (transitionVariant) return [transitionVariant, transitionProps];
  if (maximized) return ['slide', { direction: 'left', ...transitionProps }];
  return [null, transitionProps];
};

const Modal = (props) => {
  const {
    leftHeaderContent,
    rightHeaderContent,
    title,
    open,
    onClose,
    renderContent: customRenderContent,
    maximized,
    children,
  } = props;

  const [transitionVariant, transitionProps] = makeTransition(props);

  const closeButton = (
    <CloseButton className="modal-close-button" onClick={onClose}>
      <CloseIcon />
    </CloseButton>
  );

  const renderContent =
    customRenderContent ||
    ((modalChildren) => {
      const Container = maximized ? MaximizedModalContainer : ModalContainer;
      return <Container>{modalChildren}</Container>;
    });

  return (
    <ModalMui
      BackdropProps={{
        timeout: 500,
      }}
      open={open}
      onClose={onClose}
    >
      <div>
        <TransitionContainer
          variant={transitionVariant}
          transitionProps={transitionProps}
          show={open}
        >
          {renderContent(
            <>
              <Header className="modal-header">
                {maximized && closeButton}
                {leftHeaderContent}

                {!!title && <Title className="modal-title">{title}</Title>}

                {(!!rightHeaderContent || !maximized) && (
                  <RightHeaderContent>
                    {rightHeaderContent}
                    {!maximized && closeButton}
                  </RightHeaderContent>
                )}
              </Header>
              {children}
            </>
          )}
        </TransitionContainer>
      </div>
    </ModalMui>
  );
};

Modal.propTypes = {
  leftHeaderContent: PropTypes.element,
  rightHeaderContent: PropTypes.element,
  title: PropTypes.string,
  maximized: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  renderContent: PropTypes.func,
  transitionVariant: PropTypes.string,
  transitionProps: PropTypes.shape(),
};

Modal.defaultProps = {
  maximized: false,
  open: true,
  leftHeaderContent: null,
  rightHeaderContent: null,
  title: '',
  renderContent: null,
  transitionVariant: null,
  transitionProps: {},
};

export default Modal;

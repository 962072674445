import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import colors from 'utils/colors';
import { removeFile, addFiles } from './uploaderUtils';
import DocumentItem from './DocumentItem';
import Uploader from './Uploader';

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const Title = styled('div')({
  marginBottom: '12px',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: colors.primary[900],
});

const getEntityPath = (entityPath, name) =>
  entityPath
    ? (document) =>
        `${typeof entityPath === 'function' ? entityPath(document) : entityPath}/${name}`
    : null;

const FormDocuments = ({
  formik: { values, setFieldValue },
  type,
  title,
  name,
  disabled,
  multiple,
  entityPath,
}) => {
  const documents = values[name];
  const documentsPath = getEntityPath(entityPath, name);

  return (
    <Container className="form-uploader-documents">
      {!!title && (
        <Title>
          {title}
          {documents.length > 0 ? ` (${documents.length})` : ''}
        </Title>
      )}
      {documents.map((document, index) => (
        <DocumentItem
          // eslint-disable-next-line react/no-array-index-key
          key={document.key || index}
          entityPath={documentsPath}
          document={document}
          onDelete={!disabled ? () => setFieldValue(name, removeFile(documents, index)) : null}
        />
      ))}
      {!disabled && (
        <Uploader
          type={type}
          multiple={multiple}
          onChange={(files) => setFieldValue(name, addFiles(multiple ? documents : [], files))}
        />
      )}
    </Container>
  );
};

FormDocuments.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string,
  entityPath: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
};

FormDocuments.defaultProps = {
  type: 'document',
  title: 'Documents',
  name: 'documents',
  entityPath: null,
  disabled: false,
  multiple: true,
};

export default FormDocuments;

import React from 'react';
import { styled } from '@mui/material';
import { makeValidatorSchema, passwordValidator, emailValidator } from 'utils/validators';
import { basicLogin } from 'services/auth';
import RawForm from 'components/shared/form/RawForm';
import TextInput from 'components/shared/form/TextInput';

const FormContainer = styled('div')({
  mt: 8,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const validationSchema = makeValidatorSchema({
  email: emailValidator({ required: 'Must enter an email address' }),
  password: passwordValidator({ required: 'Must enter a password' }),
});

const initializeForm = () => ({
  email: '',
  password: '',
});

const BasicAuthentication = ({ onNextStep, onLogin }) => {
  const handleSubmit = async ({ email, password }) => {
    const result = await basicLogin(email, password);

    if (result.twoFactor) return onNextStep({ type: result.twoFactor, session: result.session });

    return onLogin();
  };

  return (
    <RawForm
      initialValues={initializeForm()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError="Invalid credentials"
      submitButton={{ label: 'Log In' }}
    >
      {(formik) => (
        <FormContainer>
          <TextInput
            formik={formik}
            name="email"
            label="Email"
            type="email"
            sx={{ width: '100%' }}
          />
          <TextInput
            formik={formik}
            name="password"
            label="Password"
            type="password"
            sx={{ width: '100%' }}
          />
        </FormContainer>
      )}
    </RawForm>
  );
};

export default BasicAuthentication;

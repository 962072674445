import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import {
  fetchIndicators,
  setIndicators,
  setHistoricalIndicators,
  clearIndicators,
  setSelectedComposedIndicatorKey,
} from 'slices/propertyIndicator';
import { updateIndicator } from 'services/propertyIndicators';
import useAbility from 'hooks/shared/useAbility';
import useSnackbar from 'hooks/shared/useSnackBar';
import usePropertyIndicators from 'hooks/components/usePropertyIndicators';
import useCategories from 'hooks/components/useCategories';
import IconButton from 'components/shared/button/IconButton';
import TotalIndicator from './TotalIndicator';
import ComposedIndicator from './ComposedIndicator';
import HeaderIndicator from './HeaderIndicator';
import ConsolidatedDate from './ConsolidatedDate';
import BatchUpdateIndicators from './BatchUpdateIndicators';

const Header = styled('div')({
  width: '100%',
  display: 'flex',
  gap: '8px',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const HeaderRight = styled('div')({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

const TotalsContainer = styled('div')({
  width: '100%',
  display: 'grid',
  gridGap: '8px',
  gridAutoRows: '48px',
});

const totalsGridOptions = (number) => {
  if (number < 2) return null;
  if (number === 2 || number === 4) return 'repeat(2, 1fr)';
  return 'repeat(3, 1fr)';
};

const PropertyIndicators = ({ withComposedIndicators, sx }) => {
  const ability = useAbility();
  const dispatch = useDispatch();
  const { showSuccess, showError } = useSnackbar();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const propertyId = currentProperty ? currentProperty.id : null;
  const { categoryId, subcategoryId } = useCategories();

  const [showBatchUpdate, setShowBatchUpdate] = useState(false);

  const canBatchUpdate = !propertyId && ability.can('batchUpdate', 'PropertyIndicator');

  const {
    indicators,
    historicalIndicators,
    headerIndicator,
    totalIndicators,
    composedIndicators,
    selectedComposedIndicator,
    indicatorsSize,
  } = usePropertyIndicators();

  const handleUpdateIndicator = async (indicatorId, data) => {
    try {
      const updatedIndicator = await updateIndicator(propertyId, indicatorId, data);
      dispatch(
        setIndicators(
          indicators.map((indicator) =>
            indicator.id === indicatorId ? updatedIndicator : indicator
          )
        )
      );
      if (historicalIndicators) {
        dispatch(
          setHistoricalIndicators(
            historicalIndicators.map((indicator) =>
              indicator.id === indicatorId ? updatedIndicator : indicator
            )
          )
        );
      }
      showSuccess('Indicator updated');
      return true;
    } catch {
      showError('Error updating indicator');
      return false;
    }
  };

  const handleChangeComposedIndicator = (selectedKey) =>
    dispatch(setSelectedComposedIndicatorKey(selectedKey));

  useEffect(
    () => {
      dispatch(fetchIndicators(propertyId, categoryId, subcategoryId));

      return () => dispatch(clearIndicators());
    },
    !propertyId ? [categoryId, subcategoryId] : []
  );

  if (!indicators || !indicators.length) return null;

  const handleShowBatchUpdate = () => setShowBatchUpdate(true);
  const handleCloseBatchUpdate = () => setShowBatchUpdate(false);

  return (
    <Container className="property-indicators-container" sx={sx}>
      <Header>
        <ConsolidatedDate />
        <HeaderRight>
          {headerIndicator ? (
            <HeaderIndicator
              canEdit={ability.can('update', headerIndicator)}
              onUpdate={handleUpdateIndicator}
              indicator={headerIndicator}
            />
          ) : null}
          {canBatchUpdate && <IconButton icon="csv" onClick={handleShowBatchUpdate} />}
        </HeaderRight>
      </Header>
      <TotalsContainer
        className="property-indicators-totals"
        sx={{
          gridTemplateColumns: totalsGridOptions(totalIndicators.length),
        }}
      >
        {totalIndicators.map((indicator) => (
          <TotalIndicator
            key={indicator.name}
            canEdit={ability.can('update', indicator)}
            onUpdate={handleUpdateIndicator}
            indicator={indicator}
          />
        ))}
      </TotalsContainer>
      {withComposedIndicators && !!propertyId && selectedComposedIndicator && (
        <ComposedIndicator
          key={selectedComposedIndicator.id}
          canEdit={ability.can('update', selectedComposedIndicator)}
          onUpdate={handleUpdateIndicator}
          onChangeIndicator={handleChangeComposedIndicator}
          indicator={selectedComposedIndicator}
          allIndicators={composedIndicators}
          headerSize={indicatorsSize}
        />
      )}
      {showBatchUpdate && <BatchUpdateIndicators onClose={handleCloseBatchUpdate} />}
    </Container>
  );
};

export default PropertyIndicators;

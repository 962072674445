import React from 'react';
import { makeValidatorSchema, stringValidator, dateValidator } from 'utils/validators';
import FormModal from 'components/shared/modal/FormModal';
import FormRow from 'components/shared/form/FormRow';
import TextInput from 'components/shared/form/TextInput';
import DatePicker from 'components/shared/form/DatePicker';
import FormImages from 'components/shared/uploader/FormImages';

const validationSchema = makeValidatorSchema({
  name: stringValidator({ required: 'Must enter a name' }),
  type_of_trust: stringValidator(),
  creation_date: dateValidator(),
  trustee_name: stringValidator(),
  co_trustee_name: stringValidator(),
  beneficiaries: stringValidator(),
  assets_description: stringValidator(),
  asset_value: stringValidator(),
  location_of_asset: stringValidator(),
  account_number: stringValidator(),
  bank_financial_institution: stringValidator(),
  account_balance: stringValidator(),
  tin_number: stringValidator(),
});

const TrustPropertyModal = ({ currentProperty, title, onSubmit, onClose }) => {
  const initalizeSummary = () => {
    const summary = (currentProperty && currentProperty.summary) || {};
    return {
      institution_name: summary.institution_name || '',
      type_of_trust: summary.type_of_trust || '',
      creation_date: summary.creation_date || '',
      trustee_name: summary.trustee_name || '',
      co_trustee_name: summary.co_trustee_name || '',
      beneficiaries: summary.beneficiaries || '',
      assets_description: summary.assets_description || '',
      asset_value: summary.asset_value || '',
      location_of_asset: summary.location_of_asset || '',
      account_number: summary.account_number || '',
      bank_financial_institution: summary.bank_financial_institution || '',
      account_balance: summary.account_balance || '',
      tin_number: summary.tin_number || '',
    };
  };

  const initializeForm = () => ({
    ...(currentProperty
      ? {
          name: currentProperty.name,
          logo: currentProperty.logo ? [currentProperty.logo] : [],
        }
      : { name: '', logo: [] }),
    ...initalizeSummary(),
  });

  const getErrorMessage = () => (currentProperty ? `Error editing item` : `Error creating item`);

  const makeFormRequest = (values) => ({
    name: values.name,
    logo: values.logo,
    summary: {
      type_of_trust: values.type_of_trust,
      creation_date: values.creation_date,
      trustee_name: values.trustee_name,
      co_trustee_name: values.co_trustee_name,
      beneficiaries: values.beneficiaries,
      assets_description: values.assets_description,
      asset_value: values.asset_value,
      location_of_asset: values.location_of_asset,
      account_number: values.account_number,
      bank_financial_institution: values.bank_financial_institution,
      account_balance: values.account_balance,
      tin_number: values.tin_number,
    },
  });

  const handleSubmit = async (values) => {
    const request = makeFormRequest(values);
    return onSubmit(request);
  };

  return (
    <FormModal
      open
      title={title}
      onClose={onClose}
      initialValues={initializeForm()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError={getErrorMessage()}
      submitButton={{ label: currentProperty ? 'Save' : 'Create' }}
      cancelButton
    >
      {(formik) => (
        <>
          <FormRow>
            <TextInput formik={formik} name="name" label="Trust Name" sx={{ width: '50%' }} />
            <TextInput
              formik={formik}
              name="type_of_trust"
              label="Type of Trust"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <DatePicker
              formik={formik}
              name="creation_date"
              label="Trust Creation Date"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="trustee_name"
              label="Trustee Name"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="co_trustee_name"
              label="Co Trustee Name if applicable"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="beneficiaries"
              label="Beneficiaries"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="assets_description"
              label="Trust Assets Description"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="asset_value"
              label="Asset Value"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="location_of_asset"
              label="Location of Asset"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="account_number"
              label="Trust Account #"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="bank_financial_institution"
              label="Bank/Financial Institution"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="account_balance"
              label="Account Balance"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
          </FormRow>
          <FormRow>
            <TextInput formik={formik} name="tin_number" label="TIN #" sx={{ width: '50%' }} />
          </FormRow>
          <FormImages
            formik={formik}
            title={false}
            name="logo"
            addButtonLabel="Set Logo"
            singleImage
          />
        </>
      )}
    </FormModal>
  );
};
export default TrustPropertyModal;

import axios from 'axios';

export const getRootDocuments = async (propertyId) => {
  const res = await axios.get(
    `${propertyId ? `properties/${propertyId}/` : ''}document_entries?by_folder=root&all=true`
  );
  return res.data.records;
};

export const getFolderDocuments = async (propertyId, folderId) => {
  const res = await axios.get(
    `properties/${propertyId}/document_entries?by_folder=${folderId}&all=true `
  );
  return res.data.records;
};

export const createDocument = async (propertyId, documentEntry) => {
  const res = await axios.post(`properties/${propertyId}/document_entries`, {
    document_entry: documentEntry,
  });
  return res;
};

export const getDocument = async (propertyId, documentId) => {
  const res = await axios.get(`properties/${propertyId}/document_entries/${documentId}`);
  return res.data;
};

export const deleteDocument = async (propertyId, documentId) => {
  await axios.delete(`properties/${propertyId}/document_entries/${documentId}`);
};

export const renameDocument = async (propertyId, documentId, params) => {
  await axios.post(`properties/${propertyId}/document_entries/${documentId}/rename`, params);
};

export const replaceDocument = async (propertyId, documentId, file) => {
  const res = await axios.put(`properties/${propertyId}/document_entries/${documentId}`, file);
  return res;
};

export const moveDocument = async (propertyId, documentId, request) => {
  await axios.post(`properties/${propertyId}/document_entries/${documentId}/move`, request);
};

export const changeDocumentPermissions = async (propertyId, documentId, permissions) => {
  const res = await axios.post(
    `properties/${propertyId}/document_entries/${documentId}/permissions`,
    {
      document_entry: permissions,
    }
  );
  return res;
};

export const getDocumentUrl = async (path, documentId) => {
  const res = await axios.get(`${path}/${documentId}`);
  return res.data?.url;
};

import React, { useState, useRef } from 'react';
import { styled } from '@mui/material';
import colors from 'utils/colors';
import { fieldNumber } from 'utils/number';
import EditableField from 'components/shared/editable/EditableField';
import IconButton from 'components/shared/button/IconButton';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '0px 8px',
  backgroundColor: colors.main.surface,
  border: `1px solid ${colors.main.controlBorder}`,
  borderRadius: '4px',

  '& .edit-indicator-button .MuiButton-startIcon': {
    display: 'none',
  },

  '&:hover .edit-indicator-button .MuiButton-startIcon': {
    display: 'block',
  },
});

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const Name = styled('div')({
  fontSize: '13px',
  lineHeight: '20px',
  fontWeight: 'bold',
  color: colors.main.greyIcon,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const ValueContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Value = styled('div')({
  fontFamily: 'Lustria',
  fontSize: '18px',
  lineHeight: '24px',
  color: colors.primary[800],
});

const ButtonsContainer = styled('div')({
  marginLeft: 'auto',
  display: 'flex',
  gap: '8px',
});

const TotalIndicator = ({
  indicator: { id, name, total: originalTotalRaw },
  canEdit,
  onUpdate,
}) => {
  const originalTotal = fieldNumber(originalTotalRaw);
  const [total, setTotal] = useState(originalTotal);
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();

  const handleEdit = () => {
    setTotal(fieldNumber(originalTotal, true));
    setEditing(true);
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  };

  const handleCancel = () => {
    setTotal(fieldNumber(originalTotal));
    setEditing(false);
  };

  const handleUpdate = async () => {
    if (await onUpdate(id, { total })) {
      setTotal(fieldNumber(total));
      setEditing(false);
    }
  };

  return (
    <Container
      onDoubleClick={canEdit && !editing ? handleEdit : null}
      sx={canEdit && !editing ? { cursor: 'pointer' } : null}
    >
      <Header>
        <Name sx={editing ? { fontSize: '13px' } : null}>{name}</Name>
        {canEdit && (
          <ButtonsContainer>
            {editing ? (
              <>
                <IconButton
                  icon="cancel"
                  iconSize={16}
                  color={colors.tertiary[900]}
                  onClick={handleCancel}
                />
                <IconButton
                  icon="confirm"
                  iconSize={16}
                  color={colors.primary[900]}
                  onClick={handleUpdate}
                />
              </>
            ) : (
              <IconButton
                className="edit-indicator-button"
                icon="edit"
                onClick={handleEdit}
                iconSize={16}
              />
            )}
          </ButtonsContainer>
        )}
      </Header>
      <ValueContainer>
        <Value>
          <EditableField
            type="currency"
            value={editing ? total : originalTotal}
            disabled={!editing}
            onChange={(value) => setTotal(value)}
            inputRef={inputRef}
            clearPrefix={false}
          />
        </Value>
      </ValueContainer>
    </Container>
  );
};

export default TotalIndicator;

// eslint-disable-next-line import/prefer-default-export

export const LIFE_INSURANCE_POLICY_SUB_TYPES = [
  { id: 'whole', name: 'Whole' },
  { id: 'term', name: 'Term' },
  { id: 'disability', name: 'Disability' },
];

export const LIFE_INSURANCE_POLICY_SUB_CATEGORIES = [
  { types: ['whole', 'term'], name: 'Life Policies' },
  { types: ['disability'], name: 'Disability Policies' },
];

export const LIFE_INSURANCE_EXTRA_OPEN_ITEMS = [
  { id: 'premium_due_date', name: 'Premium Due Date' },
];

export const HOME_INSURANCE_POLICY_SUB_TYPES = [
  { id: 'personal_excess', name: 'Excess Liability' },
  { id: 'liability', name: 'Liability' },
  { id: 'homeowners', name: 'Homeowners' },
  { id: 'business', name: 'Business' },
  { id: 'bldrs_risk', name: 'Bldrs Risk' },
  { id: 'commercial_farm', name: 'Commercial Farm' },
  { id: 'commercial_inland_marine', name: 'Commercial Inland Marine' },
];

export const HOME_NOT_LOCATION_CATEGORIES = [
  'tech',
  'children_trust',
  'marketable_securities',
  'political',
  'philanthropy',
  'insurance',
];

export const HOME_POLICY_LIMIT_TYPES = [
  { id: 'replacement_cost', name: 'Replacement Cost' },
  { id: 'other_structures', name: 'Other Structures' },
  { id: 'personal_property', name: 'Personal Property' },
  { id: 'loss_of_use', name: 'Loss Of Use' },
  { id: 'flood', name: 'Flood' },
  { id: 'earthquake', name: 'Earthquake' },
  { id: 'wind', name: 'Wind' },
  { id: 'business_interruption', name: 'Business Interruption' },
  { id: 'auto_liability', name: 'Auto Liability' },
  { id: 'not_apply', name: 'N/A' },
];

export const AVIATION_POLICY_LIMIT_TYPES = [
  { id: 'physical_damage_coverage', name: 'Physical Damage Coverage' },
  { id: 'aircraft_liability', name: 'Aircraft Liability Limit' },
];

export const VEHICLE_INSURANCE_POLICY_SUB_TYPES = [
  { id: 'personal_auto', name: 'Personal Auto' },
  { id: 'business_auto', name: 'Business Auto' },
];

export const VEHICLE_INSURANCE_CONDITIONS = [
  { id: 'good', name: 'Good' },
  { id: 'fair', name: 'Fair' },
  { id: 'poor', name: 'Poor' },
  { id: 'inoperable', name: 'Inoperable' },
];

export const VEHICLE_INSURANCE_POLICY_USES = [
  { id: 'business_use', name: 'Personal Use' },
  { id: 'personal_use', name: 'Business Use' },
];

export const VEHICLE_INSURANCE_POLICY_TITLE_LOCATIONS = [
  'Simpson Safe',
  'Lehman Hot Springs',
  'McMinnville',
];

export const POLICY_PREMIUM_FRECUENCIES = [
  { id: 'annual', name: 'Annual' },
  { id: 'semi_annual', name: 'Semi Annual' },
  { id: 'quarterly', name: 'Quarterly' },
  { id: 'monthly', name: 'Monthly' },
];

export const POLICY_LOSS_OF_USE_LIMITS = [
  { id: 'undefined_loss_of_use', name: '-' },
  { id: 'actual_loss_sustained', name: 'Actual Loss Sustained' },
  { id: 'six_months', name: '6 Months' },
  { id: 'twelve_months', name: '12 Months' },
];

export const hasPolicies = ({ category: { key: categoryKey } }) => {
  switch (categoryKey) {
    case 'life_insurance':
      return true;
    default:
      return false;
  }
};

export const getPolicyKey = ({
  category: { key: categoryKey },
  subcategory: { key: subcategoryKey },
}) => {
  switch (categoryKey) {
    case 'personal_property':
      switch (subcategoryKey) {
        case 'vehicle':
        case 'aviation':
          return subcategoryKey;
        default:
          return 'home';
      }
    case 'life_insurance':
      return 'life';
    default:
      return categoryKey;
  }
};

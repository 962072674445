import axios from 'axios';
import { setSubject, setListSubject } from 'utils/ability';
import { makeListUrl } from './base';

export const getPleasePays = async (propertyId = null, filters = {}, page = 0) => {
  const res = await axios.get(
    makeListUrl(propertyId ? `properties/${propertyId}/please_pays` : 'please_pays', filters, page)
  );
  return setListSubject('PleasePay', res.data);
};

export const getCounters = async () => {
  const res = await axios.get(`please_pays/counters`);
  return res.data.count;
};

export const getPleasePay = async (propertyId, pleasePayId) => {
  const res = await axios.get(`properties/${propertyId}/please_pays/${pleasePayId}`);
  return setSubject('PleasePay', res.data);
};

export const updatePleasePay = async (propertyId, pleasePayId, pleasePay) => {
  const res = await axios.put(`properties/${propertyId}/please_pays/${pleasePayId}`, {
    please_pay: pleasePay,
  });
  return setSubject('PleasePay', res.data);
};

export const createPleasePay = async (propertyId, pleasePay) => {
  const res = await axios.post(`properties/${propertyId}/please_pays`, {
    please_pay: pleasePay,
  });
  return setSubject('PleasePay', res.data);
};

export const deletePleasePay = async (propertyId, pleasePayId) => {
  await axios.delete(`properties/${propertyId}/please_pays/${pleasePayId}`);
};

export const getGlCodes = async () => {
  const res = await axios.get(`gl_codes`);
  return res.data;
};

export const getVendors = async () => {
  const res = await axios.get(`receipt_vendors`);
  return res.data;
};

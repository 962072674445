import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'slices/auth';
import categoryReducer from 'slices/category';
import propertyReducer from 'slices/property';
import openItemReducer from 'slices/openItem';
import chatReducer from 'slices/chat';
import userReducer from 'slices/user';
import propertyIndicatorReducer from 'slices/propertyIndicator';
import propertyInputReducer from 'slices/propertyInput';
import paymentMethodReducer from 'slices/paymentMethod';
import vendorsReducer from 'slices/vendor';
import glCodesReducer from 'slices/glCode';
import notificationReducer from 'slices/notification';
import receiptReducer from 'slices/receipt';
import pleasePayReducer from 'slices/pleasePay';
import privateChatReducer from 'slices/privateChat';
import policyReducer from 'slices/policy';
import debtLogReducer from 'slices/debtLog';

const reducer = {
  auth: authReducer,
  category: categoryReducer,
  property: propertyReducer,
  openItem: openItemReducer,
  chat: chatReducer,
  user: userReducer,
  propertyIndicator: propertyIndicatorReducer,
  propertyInput: propertyInputReducer,
  paymentMethod: paymentMethodReducer,
  vendor: vendorsReducer,
  glCode: glCodesReducer,
  notification: notificationReducer,
  receipt: receiptReducer,
  pleasePay: pleasePayReducer,
  privateChat: privateChatReducer,
  policy: policyReducer,
  debtLog: debtLogReducer,
};

const store = configureStore({
  reducer,
  devTools: true,
});

export default store;

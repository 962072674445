import React from 'react';
import { styled } from '@mui/material';
import colors from 'utils/colors';
import IconButton from '../button/IconButton';

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  marginBottom: '24px',
});

const Title = styled('span')({
  fontSize: '24px',
  lineHeight: '24px',
  color: colors.main.blackText,
});

const ButtonsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  justifyContent: 'space-between',
});

const TitleContainer = styled('div')({
  color: colors.main.blackText,
});

const BackButton = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: colors.main.blackText,
  gap: '12px',
});

const MainHeader = ({ title, buttons, backButton, onClick }) => (
  <Header>
    <Title>
      {backButton ? (
        <BackButton>
          <IconButton icon="back" onClick={onClick} />
          <TitleContainer>{title}</TitleContainer>
        </BackButton>
      ) : (
        title
      )}
    </Title>
    <ButtonsContainer>{buttons}</ButtonsContainer>
  </Header>
);
export default MainHeader;

import React from 'react';
import { useInterval } from 'react-use';
import { styled } from '@mui/material';
import ClipLoader from 'react-spinners/ClipLoader';
import colors from 'utils/colors';
import Button from 'components/shared/button/Button';
import { requestLoginCode, codeLogin } from 'services/auth';
import useSnackbar from 'hooks/shared/useSnackBar';

const CodeTitle = styled('div')({
  display: 'flex',
  padding: '21px 0 10px 0',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '22px',
});

const Text = styled('div')({
  display: 'flex',
  padding: '0px 0 30px 0',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '13px',
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  padding: '30px 0 0 0',
  gap: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '13px',
});

const WaitingApproval = ({ step, onNextStep, onLogin }) => {
  const { showError } = useSnackbar();

  const handleCancel = async () => {
    await requestLoginCode('code');
    onNextStep({ ...step, type: 'code' });
  };

  useInterval(async () => {
    try {
      const result = await codeLogin({ type: 'push_auth' }, step.session);
      if (result.waiting) return;

      onLogin();
    } catch (e) {
      showError('Login not approved');
      onNextStep({ ...step, type: 'basic' });
    }
  }, 1000);

  return (
    <>
      <CodeTitle>Waiting for the response</CodeTitle>
      <Text>Approve this login by hitting &apos;Approve&apos; in the app</Text>
      <ClipLoader
        color={colors.primary[900]}
        loading
        // cssOverride={override}
        size={50}
      />
      <ButtonWrapper>
        <Button variant="white" onClick={handleCancel}>
          CANCEL
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default WaitingApproval;

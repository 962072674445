import axios from 'axios';

export const getCategoryTemplates = async () => {
  const res = await axios.get(`category_templates`);
  return res.data;
};

export const getSubcategoryTemplates = async (categoryId) => {
  const res = await axios.get(`categories/${categoryId}/subcategory_templates`);
  return res.data;
};

export const getCategories = async () => {
  const res = await axios.get(`categories`);
  return res.data;
};

export const getSubcategories = async (categoryId) => {
  const res = await axios.get(`categories/${categoryId}/subcategories`);
  return res.data;
};

export const selectCategories = async (data) => {
  const res = await axios.post(`categories/select`, data);
  return res.data;
};

export const createSubcategory = async (categoryId, subcategory) => {
  const res = await axios.post(`categories/${categoryId}/subcategories`, { subcategory });
  return res.data;
};

export const deleteSubcategory = async (categoryId, subcategoryId) => {
  await axios.delete(`categories/${categoryId}/subcategories/${subcategoryId}`);
};

import axios from 'axios';
import { setSubject, setListSubject } from 'utils/ability';
import { makeListUrl, downloadFile } from './base';

export const getPolicies = async (propertyId, propertyKey, filters = {}, page = 0) => {
  const res = await axios.get(
    makeListUrl(`properties/${propertyId}/${propertyKey}_insurance_policies`, filters, page)
  );
  return setListSubject('Policy', res.data.records);
};

export const getPolicy = async (propertyId, propertyKey, policyId) => {
  const res = await axios.get(
    `properties/${propertyId}/${propertyKey}_insurance_policies/${policyId}`
  );
  return setSubject('Policy', res.data);
};

export const updatePolicy = async (propertyId, propertyKey, policyId, policy) => {
  const res = await axios.put(
    `properties/${propertyId}/${propertyKey}_insurance_policies/${policyId}`,
    {
      policy,
    }
  );
  return setSubject('Policy', res.data);
};

export const createPolicy = async (propertyId, propertyKey, policy) => {
  const res = await axios.post(`properties/${propertyId}/${propertyKey}_insurance_policies`, {
    policy,
  });
  return setSubject('Policy', res.data);
};

export const batchUpdatePolicies = async (propertyId, propertyKey, policies) =>
  axios.put(`properties/${propertyId}/${propertyKey}_insurance_policies/batch_update`, {
    request: { policies },
  });

export const deletePolicy = async (propertyId, propertyKey, policyId) => {
  await axios.delete(`properties/${propertyId}/${propertyKey}_insurance_policies/${policyId}`);
};

export const excelExportPolicies = async (propertyId, propertyKey, fileName, filters = {}) => {
  await downloadFile({
    fileUrl: makeListUrl(
      `properties/${propertyId}/${propertyKey}_insurance_policies/excel_report`,
      filters
    ),
    fileName: `${fileName}.xlsx`,
  });
};

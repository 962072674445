import React, { useState } from 'react';
import { makeValidatorSchema } from 'utils/validators';
import useSnackbar from 'hooks/shared/useSnackBar';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import FormModal from 'components/shared/modal/FormModal';
import { changeDocumentPermissions } from 'services/documents';
import Select from 'components/shared/form/Select';
import { getUsers } from 'services/users';
import { PERMISSIONS } from 'domain/permissions';

const validationSchema = makeValidatorSchema({});

const ManagePermissions = ({ onClose, document }) => {
  const { showSuccess } = useSnackbar();
  const [propertyUsers, setPropertyUsers] = useState(null);

  const initializeForm = {
    permission: document.permission,
    shareds: document.shareds,
  };

  const makeFormRequest = (values) => ({
    permission: values.permission,
    shareds: values.shareds,
  });

  const handleSubmit = async (values) => {
    const request = makeFormRequest(values);
    await changeDocumentPermissions(document.property_id, document.id, request);
    showSuccess('Permissions edited');
    onClose();
  };

  useAsyncEffect(async () => {
    setPropertyUsers(await getUsers(document.property_id));
  });

  if (!propertyUsers) return null;

  return (
    <FormModal
      open
      title={`${'Manage Permissions'}  - ${document.name}`}
      onClose={onClose}
      initialValues={initializeForm}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError="Error editing permissions"
      submitButton={true && { label: 'Save' }}
      cancelButton
    >
      {(formik) => (
        <>
          <Select
            formik={formik}
            name="permission"
            label="Permission"
            options={PERMISSIONS}
            sx={{ width: '100%' }}
          />
          {formik.values.permission === 'shared_access' && (
            <Select formik={formik} name="shareds" label="Users" multiple options={propertyUsers} />
          )}
        </>
      )}
    </FormModal>
  );
};

export default ManagePermissions;

import React, { useEffect } from 'react';
import { IconButton, InputBase, styled } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as SendSvg } from 'assets/svg/send_chat.svg';
import { uploadFormFiles } from 'services/directUpload';
import { sendChatMessage } from 'services/chat';
import { clearCurrentChatMessages, fetchCurrentChatMessages } from 'slices/chat';
import colors from 'utils/colors';
import { getErrorMessage } from 'utils/errors';
import useSnackbar from 'hooks/shared/useSnackBar';
import FormDocuments from '../uploader/FormDocuments';
import FormImages from '../uploader/FormImages';
import CircularProgress from '../form/CircularProgress';

const ChatFooterControl = styled('div')((props) => ({
  width: '100%',
  minHeight: '75px',
  display: 'flex',
  alignItems: 'center',
  padding: props.bottomwindow ? '14px 24px 34px 24px' : '16px 24px',
  backgroundColor: colors.main.surface,
  borderTop: `1px solid ${colors.main.controlBorder}`,
  zIndex: 1,
  marginBottom: props.bottomwindow ? '34px' : '0px',
}));

const ShowAttachmentsButton = styled(IconButton)({
  width: '36px',
  height: '36px',
  padding: '0px',
  borderRadius: '4px',
  color: colors.primary[900],
  '&:hover': {
    backgroundColor: colors.main.surfaceOverlay,
  },
});

const ChatInputContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: 'calc(100% - 44px)',
  minHeight: '40px',
  marginLeft: '8px',
  borderRadius: '4px',
  backgroundColor: colors.main.surfaceOverlay,
});

const ChatInput = styled(InputBase)({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 'bold',
  padding: '0px',
  width: 'calc(100% - 48px)',
  color: colors.main.text,
  '& .MuiInputBase-input': {
    padding: '8px 14px',
    overflow: ['auto', 'overlay'],
  },
});

const SendChatButton = styled(IconButton)({
  color: colors.primary[900],
  width: '48px',
  height: '40px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-disabled': {
    opacity: 0.7,
  },
});

const AttachmentsHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '48px',
  padding: '16px 12px 8px 12px',
  backgroundColor: colors.primary[50],
});

const AttachmentsTitle = styled('div')({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: colors.primary[900],
});

const CloseAttachmentsButton = styled(IconButton)({
  color: colors.primary[900],
  width: '24px',
  height: '24px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

const AttachmentsBody = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'auto',
});

const FormDocumentsContainer = styled('div')({
  padding: '24px 12px 24px 12px',
  borderBottom: `1px solid ${colors.main.controlBorder}`,
});

const FormImagesContainer = styled('div')({
  padding: '24px 12px 24px 12px',
});

const ChatFooter = ({ bottomWindow }) => {
  const dispatch = useDispatch();
  const { showError } = useSnackbar();

  const currentChat = useSelector((state) => state.chat.currentChat);
  const messages = useSelector((state) => state.chat.currentChatMessages);
  const chatId = currentChat.id;

  const initialValues = () => ({
    text: '',
    showAttachments: false,
    documents: [],
    images: [],
  });

  const validateForm = ({ text, documents, images }) =>
    !!text || documents.length > 0 || images.length > 0;

  const makeFormRequest = ({ text, documents, images }) => ({
    text,
    documents,
    images,
  });

  const handleSendMessage = async (values, { resetForm }) => {
    if (!validateForm(values)) return;

    try {
      let request = makeFormRequest(values);
      request = await uploadFormFiles(request);
      await sendChatMessage(chatId, request);
      dispatch(fetchCurrentChatMessages(chatId, messages));
      resetForm();
    } catch (e) {
      showError(getErrorMessage(e, 'Error sending message'));
    }
  };

  useEffect(() => {
    dispatch(fetchCurrentChatMessages(chatId));

    return () => {
      dispatch(clearCurrentChatMessages());
    };
  }, []);

  return (
    <Formik initialValues={initialValues()} onSubmit={handleSendMessage}>
      {(formik) => (
        <>
          {formik.values.showAttachments && (
            <>
              <AttachmentsHeader>
                <AttachmentsTitle>Attachments</AttachmentsTitle>
                <CloseAttachmentsButton
                  onClick={() => formik.setFieldValue('showAttachments', false)}
                >
                  <CloseIcon />
                </CloseAttachmentsButton>
              </AttachmentsHeader>
              <AttachmentsBody>
                <FormDocumentsContainer>
                  <FormDocuments formik={formik} title={false} />
                </FormDocumentsContainer>
                <FormImagesContainer>
                  <FormImages formik={formik} title={false} />
                </FormImagesContainer>
              </AttachmentsBody>
            </>
          )}

          <ChatFooterControl bottomwindow={bottomWindow ? 1 : 0}>
            <ShowAttachmentsButton
              sx={{
                backgroundColor: formik.values.showAttachments
                  ? colors.main.overlayFocused
                  : 'transparent',
              }}
              onClick={() =>
                formik.setFieldValue('showAttachments', !formik.values.showAttachments)
              }
            >
              <AddIcon />
            </ShowAttachmentsButton>
            <ChatInputContainer>
              <ChatInput
                placeholder="Send Message"
                name="text"
                multiline
                maxRows={4}
                value={formik.values.text}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    formik.submitForm();
                  }
                }}
                onChange={formik.handleChange}
              />
              <SendChatButton onClick={formik.submitForm} disabled={!validateForm(formik.values)}>
                <SendSvg />
              </SendChatButton>
            </ChatInputContainer>
          </ChatFooterControl>
          {formik.isSubmitting && <CircularProgress />}
        </>
      )}
    </Formik>
  );
};

export default ChatFooter;

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import { removeFile, addFiles } from './uploaderUtils';
import DocumentItem from './DocumentItem';
import Uploader from './Uploader';

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const UploaderContainer = styled('div')({
  paddingBottom: '12px',
});

const FormFiles = ({
  formik: { values, setFieldValue },
  name,
  disabled,
  multiple,
  type,
  extension,
  entityPath,
}) => {
  const documents = values[name];
  const documentsPath = entityPath ? `${entityPath}/${name}` : null;

  return (
    <Container className="form-uploader-documents">
      {!disabled && (
        <UploaderContainer>
          <Uploader
            multiple={multiple}
            type={type}
            extension={extension}
            onChange={(files) => setFieldValue(name, addFiles(multiple ? documents : [], files))}
          />
        </UploaderContainer>
      )}

      {documents.map((document, index) => (
        <DocumentItem
          // eslint-disable-next-line react/no-array-index-key
          key={document.key || index}
          entityPath={documentsPath}
          document={document}
          onDelete={!disabled ? () => setFieldValue(name, removeFile(documents, index)) : null}
        />
      ))}
    </Container>
  );
};

FormFiles.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  name: PropTypes.string,
  entityPath: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
};

FormFiles.defaultProps = {
  type: 'any',
  name: 'documents',
  entityPath: null,
  disabled: false,
  multiple: true,
};

export default FormFiles;

import {
  styled,
  Menu as MenuMui,
  MenuItem as MenuItemMui,
  Button as ButtonMui,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { FieldArray } from 'formik';
import colors from 'utils/colors';
import { fieldNumber } from 'utils/number';
import {
  VEHICLE_INSURANCE_POLICY_SUB_TYPES,
  VEHICLE_INSURANCE_CONDITIONS,
  POLICY_PREMIUM_FRECUENCIES,
  VEHICLE_INSURANCE_POLICY_USES,
  VEHICLE_INSURANCE_POLICY_TITLE_LOCATIONS,
  getPolicyKey,
} from 'domain/policies';
import DatePicker from 'components/shared/form/DatePicker';
import TextInput from 'components/shared/form/TextInput';
import Select from 'components/shared/form/Select';
import Autocomplete from 'components/shared/form/Autocomplete';
import Checkbox from 'components/shared/form/Checkbox';
import FormRow from 'components/shared/form/FormRow';
import FormSection from 'components/shared/form/FormSection';
import {
  dateValidator,
  makeValidatorSchema,
  positiveNumberValidator,
  stringValidator,
} from 'utils/validators';
import RawForm from 'components/shared/form/RawForm';
import Button from 'components/shared/button/Button';
import IconButton from 'components/shared/button/IconButton';
import { useSelector } from 'react-redux';
import { createPolicy, deletePolicy, updatePolicy } from 'services/policies';
import useSnackbar from 'hooks/shared/useSnackBar';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import FormUploader from 'components/shared/uploader/FormUploader';
import useOpenItems from 'hooks/components/useOpenItems';

const ROW_GAP = '6';
const INPUT_PROPERTIES = {
  width: `calc(${100 / 4}% - 3px)`,
};
const BUTTON_PROPERTIES = {
  padding: '0px',
  minWidth: 'unset',
  '& .MuiButton-startIcon': {
    marginLeft: '0px',
    marginRight: '2px',
  },
};
const COLLPASED_BUTTONS_PROPERTIES = {
  position: 'absolute',
  bottom: '0px',
  right: '0px',
  marginTop: '4px',
  padding: '12px 16px 16px 16px',
  background: colors.main.whiteOpacity,
  zIndex: '1',
};
const OPEN_BUTTONS_PROPERTIES = {
  marginTop: '12px',
};

const ButtonsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',
});

const Subtitle = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '12px',
  fontWeight: 'bold',
  color: colors.primary[900],
});

const SubtitleText = styled('div')({
  fontSize: '12px',
  fontWeight: 'bold',
  color: colors.primary[900],
});

const SubtitleButtons = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '4px',
});

// TODO: refactor and abstract this one
const OpenItemMenu = styled(MenuMui)({
  '& .MuiPaper-root': {
    borderRadius: '0px',
    width: '230px',
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    backgroundColor: colors.main.surface,
  },
  '& .MuiList-root': {
    padding: '8px 0px',
  },
  '& .MuiMenuItem-root': {
    padding: '0px',
    '&:hover': {
      backgroundColor: colors.main.surfaceOverlay,
    },
  },
  '& .MuiButton-root': {
    width: '100%',
    justifyContent: 'space-between',
    padding: '12px 16px',
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.main.text,
    textTransform: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
    '& .MuiButton-endIcon': {
      color: colors.main.iconColor,
    },
  },
});

const validationSchema = makeValidatorSchema({
  type: stringValidator({ required: 'Must enter policy type' }),
  insuranceCompany: stringValidator(),
  insured: stringValidator(),
  policy: stringValidator(),
  addlInsured: stringValidator(),
  agencyName: stringValidator(),
  agentContact: stringValidator(),
  agentEmail: stringValidator(),
  agentPhoneNo: stringValidator(),
  autoDebit: stringValidator(),
  autoDebitAccount: stringValidator(),
  premiumFrecuency: stringValidator(),
  premium: positiveNumberValidator(),
  deductible: positiveNumberValidator(),
  liabilityLimit: positiveNumberValidator(),
  bodilyInjury: positiveNumberValidator(),
  propertyDamage: positiveNumberValidator(),
  uninsuredMotorists: positiveNumberValidator(),
  premiumDueDate: dateValidator(),
  plateNumber: stringValidator(),
  notes: stringValidator(),
});

const emptyVehicle = () => ({
  id: uuid(),
  new: true,
  registeredOwner: '',
  makeModel: '',
  year: '',
  vin: '',
  dmvRenewalDate: '',
  titleLocation: '',
  condition: VEHICLE_INSURANCE_CONDITIONS[0].id,
  maintenceDueDate: '',
  use: VEHICLE_INSURANCE_POLICY_USES[0].id,
  vehicleLocation: '',
  createOpenItem: false,
  plateNumber: '',
  notes: '',
});

const initializeForm = (policy = null) =>
  policy
    ? {
        id: policy.id,
        type: policy.sub_type,
        insuranceCompany: policy.insurance_company,
        insured: policy.insured,
        policy: policy.policy_number,
        premium: fieldNumber(policy.premium),
        premiumDueDate: policy.premium_due_date,
        addlInsured: policy.addl_insured,
        agencyName: policy.agency_name,
        agentContact: policy.agent_contact,
        agentEmail: policy.agent_email,
        agentPhoneNo: policy.agent_phone_no,
        autoDebit: policy.auto_debit,
        autoDebitAccount: policy.auto_debit_account,
        premiumFrecuency: policy.premium_frequency,
        deductible: fieldNumber(policy.deductible),
        liabilityLimit: fieldNumber(policy.liability_limit),
        bodilyInjury: fieldNumber(policy.bodily_injury),
        propertyDamage: fieldNumber(policy.property_damage),
        uninsuredMotorists: fieldNumber(policy.uninsured_motorists),
        notes: policy.notes || '',
        vehicles:
          policy.vehicles.length > 0
            ? policy.vehicles.map((vehicle) => ({
                id: vehicle.id,
                registeredOwner: vehicle.registered_owner || '',
                makeModel: vehicle.make_model || '',
                year: vehicle.year || '',
                vin: vehicle.vin || '',
                dmvRenewalDate: vehicle.dmv_renewal_date || '',
                titleLocation: vehicle.title_location || '',
                condition: vehicle.condition || '',
                maintenceDueDate: vehicle.maintence_due_date || '',
                use: vehicle.use || '',
                vehicleLocation: vehicle.vehicle_location || '',
                plateNumber: vehicle.plate_number || '',
                notes: vehicle.notes || '',
                createOpenItem: !!vehicle.open_item_id,
              }))
            : [emptyVehicle()],
        documents: policy.documents || [],
        images: policy.images || [],
      }
    : {
        id: '',
        type: VEHICLE_INSURANCE_POLICY_SUB_TYPES[0].id,
        insuranceCompany: '',
        insured: '',
        policy: '',
        premium: '',
        premiumDueDate: '',
        addlInsured: '',
        agencyName: '',
        agentContact: '',
        agentEmail: '',
        agentPhoneNo: '',
        autoDebit: false,
        autoDebitAccount: '',
        premiumFrecuency: POLICY_PREMIUM_FRECUENCIES[0].id,
        deductible: '',
        liabilityLimit: '',
        bodilyInjury: '',
        propertyDamage: '',
        uninsuredMotorists: '',
        plateNumber: '',
        notes: '',
        vehicles: [emptyVehicle()],
        documents: [],
        images: [],
      };

const getPolicyOpenItems = (policy) => {
  const openItems = [];
  if (policy?.open_item_id)
    openItems.push({ id: policy.open_item_id, name: 'Policy Expiration Date' });
  (policy?.vehicles || []).forEach((vehicle, vehicleIndex) => {
    if (vehicle.open_item_id)
      openItems.push({
        id: vehicle.open_item_id,
        name: `Vehicle #${vehicleIndex + 1}`,
      });
    if (vehicle.dmv_open_item_id)
      openItems.push({
        id: vehicle.dmv_open_item_id,
        name: `Vehicle #${vehicleIndex + 1} DMV`,
      });
  });
  return openItems;
};

const getNewOpenItems = (oldPolicy, newPolicy) => {
  const existingItems = new Set(
    (oldPolicy?.vehicles || [])
      .filter((vehicle) => vehicle.open_item_id)
      .map((vehicle) => vehicle.open_item_id)
  );
  return (newPolicy?.vehicles || [])
    .filter((vehicle) => vehicle.open_item_id && !existingItems.has(vehicle.open_item_id))
    .map((vehicle) => vehicle.open_item_id);
};

const VehiclePolicyForm = ({
  policy,
  editing,
  collapsed,
  canUpdate,
  canDelete,
  onSave,
  onDeleted,
  onCancel,
  onEdit,
  onSelect,
}) => {
  const { showSuccess, showError } = useSnackbar();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const propertyId = currentProperty.id;
  const policyKey = getPolicyKey(currentProperty);
  const [showOpenItemMenu, setShowOpenItemMenu] = useState(false);
  const [deletingInsurance, setDeletingInsurance] = useState(false);
  const [newOpenItems, setNewOpenItems] = useState([]);
  const { onShowDetails: handleShowOpenItem, openItemId: currentOpenItemId } = useOpenItems();

  const openItemMenuId = `life-insurance-policy-menu-${policy?.id || 'new'}`;
  const openItems = getPolicyOpenItems(policy);

  const makeFormRequest = (values) => ({
    sub_type: values.type,
    insurance_company: values.insuranceCompany,
    insured: values.insured,
    policy_number: values.policy,
    premium: values.premium || '0',
    premium_due_date: values.premiumDueDate || null,
    addl_insured: values.addlInsured,
    agency_name: values.agencyName,
    agent_contact: values.agentContact,
    agent_email: values.agentEmail,
    agent_phone_no: values.agentPhoneNo,
    auto_debit: values.autoDebit,
    auto_debit_account: values.autoDebit ? values.autoDebitAccount : null,
    deductible: values.deductible || '0',
    liability_limit: values.liabilityLimit || '0',
    bodily_injury: values.bodilyInjury || '0',
    property_damage: values.propertyDamage || '0',
    uninsured_motorists: values.uninsuredMotorists || '0',
    premium_frequency: values.premiumFrecuency,
    notes: values.notes,
    vehicles: values.vehicles
      .filter((vehicle) => vehicle.registeredOwner || vehicle.makeModel)
      .map((vehicle) => ({
        id: !vehicle.new ? vehicle.id : null,
        registered_owner: vehicle.registeredOwner,
        make_model: vehicle.makeModel,
        year: vehicle.year,
        vin: vehicle.vin,
        dmv_renewal_date: vehicle.dmvRenewalDate,
        title_location: vehicle.titleLocation,
        condition: vehicle.condition,
        maintence_due_date: vehicle.maintenceDueDate,
        use: vehicle.use,
        vehicle_location: vehicle.vehicleLocation,
        plate_number: vehicle.plateNumber,
        notes: vehicle.notes,
        create_open_item: vehicle.createOpenItem,
      })),
  });

  const handleGetEntityPath = (document) =>
    `properties/${propertyId}/open_items/${document.record_id}`;

  const handleCancel = (formik) => {
    formik.resetForm();
    onCancel();
  };

  const handleEdit = () => {
    onEdit();
  };

  const handleDelete = async (lifeInsurancePolicyId) => {
    try {
      await deletePolicy(propertyId, policyKey, lifeInsurancePolicyId);
      showSuccess('Policy Deleted');
      onDeleted();
    } catch {
      showError('Error deleting policy');
    }
    setDeletingInsurance(false);
  };

  const handleAskDeletion = () => {
    setDeletingInsurance(true);
  };

  const handleCancelDelete = () => {
    setDeletingInsurance(false);
  };

  const savePolicy = async (request) =>
    policy
      ? updatePolicy(propertyId, policyKey, policy.id, request)
      : createPolicy(propertyId, policyKey, request);

  const handleSubmit = async (values, formik) => {
    const request = makeFormRequest(values);
    const savedPolicy = await savePolicy(request);
    formik.resetForm({ values: initializeForm(savedPolicy) });
    showSuccess(policy ? 'Policy Updated' : 'Policy Created');
    setNewOpenItems(getNewOpenItems(policy, savedPolicy));
    onSave(savedPolicy);
  };

  useEffect(() => {
    if (currentOpenItemId || !newOpenItems.length) return;

    handleShowOpenItem(currentProperty.id, newOpenItems.shift());
    setNewOpenItems([...newOpenItems]);
  }, [newOpenItems, currentOpenItemId]);

  return (
    <RawForm
      initialValues={initializeForm(policy)}
      validationSchema={validationSchema}
      submit={false}
      messageAlwaysVisible={false}
      onSubmit={handleSubmit}
      scroll={false}
      maxHeight={null}
    >
      {(formik) => {
        const formHeaders = (
          <>
            <FormRow gap={ROW_GAP} onClick={onSelect}>
              <TextInput
                formik={formik}
                name="insured"
                label="Named Insured"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="addlInsured"
                label="Add'l Insured"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <Select
                formik={formik}
                name="type"
                label="Policy Type"
                options={VEHICLE_INSURANCE_POLICY_SUB_TYPES.map((type) => ({
                  id: type.id,
                  label: type.name,
                }))}
                sx={INPUT_PROPERTIES}
                disabled={!editing}
                size="small"
              />
              <TextInput
                formik={formik}
                name="insuranceCompany"
                label="Insurance Company"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
            </FormRow>
            <FormRow gap={ROW_GAP} onClick={onSelect}>
              <TextInput
                formik={formik}
                name="policy"
                label="Policy Number"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <DatePicker
                formik={formik}
                name="premiumDueDate"
                label="Premium Due Date"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <Select
                formik={formik}
                name="premiumFrecuency"
                label="Premium Frequency"
                options={POLICY_PREMIUM_FRECUENCIES.map((type) => ({
                  id: type.id,
                  label: type.name,
                }))}
                sx={INPUT_PROPERTIES}
                disabled={!editing}
                size="small"
              />
              <TextInput
                formik={formik}
                name="premium"
                label="Premium"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="currency"
                clearPrefix={false}
              />
            </FormRow>
            <FormRow gap={ROW_GAP} onClick={onSelect}>
              <TextInput
                formik={formik}
                name="deductible"
                label="Deductible"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="currency"
                clearPrefix={false}
              />
              <TextInput
                formik={formik}
                name="liabilityLimit"
                label="Liability"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="currency"
                clearPrefix={false}
              />
              <TextInput
                formik={formik}
                name="bodilyInjury"
                label="Bodily Injury"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="currency"
                clearPrefix={false}
              />
              <TextInput
                formik={formik}
                name="propertyDamage"
                label="Property Damage"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="currency"
                clearPrefix={false}
              />
            </FormRow>
          </>
        );

        const formButtons = (
          <>
            <FormRow
              gap={ROW_GAP}
              sx={collapsed ? COLLPASED_BUTTONS_PROPERTIES : OPEN_BUTTONS_PROPERTIES}
            >
              <ButtonsContainer>
                {editing ? (
                  <>
                    <Button
                      startIcon="cancel"
                      variant="transparent"
                      iconSize={20}
                      color={colors.tertiary[900]}
                      onClick={() => {
                        handleCancel(formik);
                      }}
                      sx={BUTTON_PROPERTIES}
                    >
                      Cancel
                    </Button>
                    <Button
                      startIcon="confirm"
                      variant="transparent"
                      iconSize={20}
                      color={colors.primary[900]}
                      onClick={() => formik.submitForm()}
                      sx={BUTTON_PROPERTIES}
                    >
                      Confirm
                    </Button>
                  </>
                ) : (
                  <>
                    {canDelete && (
                      <Button
                        startIcon="delete"
                        variant="transparent"
                        iconSize={20}
                        sx={BUTTON_PROPERTIES}
                        onClick={() => {
                          handleAskDeletion();
                        }}
                      >
                        Delete
                      </Button>
                    )}
                    {canUpdate && (
                      <Button
                        startIcon="edit"
                        variant="transparent"
                        iconSize={20}
                        sx={BUTTON_PROPERTIES}
                        onClick={handleEdit}
                      >
                        Edit
                      </Button>
                    )}
                    {openItems.length > 0 && (
                      <>
                        <Button
                          id={openItemMenuId}
                          startIcon="openItem"
                          variant="transparent"
                          iconSize={20}
                          sx={BUTTON_PROPERTIES}
                          onClick={() => setShowOpenItemMenu(true)}
                        >
                          Open Item
                        </Button>
                        {showOpenItemMenu && (
                          <OpenItemMenu
                            id={openItemMenuId}
                            anchorEl={document.getElementById(openItemMenuId)}
                            open
                            onClose={() => setShowOpenItemMenu(false)}
                            elevation={0}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                          >
                            {openItems.map(({ id, name }) => (
                              <MenuItemMui
                                key={id}
                                onClick={() => {
                                  handleShowOpenItem(currentProperty.id, id);
                                  setShowOpenItemMenu(false);
                                }}
                              >
                                <ButtonMui>{name}</ButtonMui>
                              </MenuItemMui>
                            ))}
                          </OpenItemMenu>
                        )}
                      </>
                    )}
                  </>
                )}
              </ButtonsContainer>
            </FormRow>

            {deletingInsurance && (
              <ConfirmationModal
                title="Policy will be deleted"
                description="Are you sure?"
                onCancel={handleCancelDelete}
                onConfirm={() => {
                  handleDelete(policy.id);
                }}
              />
            )}
          </>
        );

        if (collapsed)
          return (
            <>
              {formHeaders}
              {formButtons}
            </>
          );

        return (
          <>
            {formHeaders}
            <FormRow gap={ROW_GAP}>
              <TextInput
                formik={formik}
                name="uninsuredMotorists"
                label="Uninsured Motorists"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="currency"
                clearPrefix={false}
              />
              {formik.values.autoDebit ? (
                <TextInput
                  formik={formik}
                  name="autoDebitAccount"
                  label="Debit Account"
                  disabled={!editing}
                  sx={INPUT_PROPERTIES}
                  size="small"
                  onCancel={editing ? () => formik.setFieldValue('autoDebit', false) : null}
                />
              ) : (
                <Checkbox
                  sx={INPUT_PROPERTIES}
                  formik={formik}
                  name="autoDebit"
                  label="Auto Debit"
                  disabled={!editing}
                  size="small"
                />
              )}
              <TextInput
                formik={formik}
                name="notes"
                label="Notes"
                disabled={!editing}
                sx={{
                  width: `calc(${100 / 2}% - 3px)`,
                }}
                size="small"
                multiline
              />
            </FormRow>
            <FormRow gap={ROW_GAP} sx={{ marginTop: '8px' }}>
              <TextInput
                formik={formik}
                name="agentContact"
                label="Agent Contact"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="agencyName"
                label="Agent Company"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="agentEmail"
                label="Agent Email"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="agentPhoneNo"
                label="Agent Phone No"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="phone"
              />
            </FormRow>
            <FieldArray
              name="vehicles"
              render={(vehicleArrayHelpers) => (
                <>
                  {formik.values.vehicles
                    .filter((vehicle) => editing || vehicle.registeredOwner || vehicle.makeModel)
                    .map((vehicle, vehicleIndex) => (
                      <FormSection key={vehicle.id}>
                        <Subtitle sx={editing ? { marginBottom: '8px' } : null}>
                          <SubtitleText>Vehicle #{vehicleIndex + 1}</SubtitleText>
                          {editing ? (
                            <SubtitleButtons>
                              {formik.values.vehicles.length > 1 && (
                                <IconButton
                                  icon="delete"
                                  color={colors.primary[900]}
                                  onClick={() => vehicleArrayHelpers.remove(vehicleIndex)}
                                />
                              )}
                              <IconButton
                                icon="add"
                                color={colors.primary[900]}
                                onClick={() =>
                                  vehicleArrayHelpers.insert(vehicleIndex + 1, emptyVehicle())
                                }
                              />
                            </SubtitleButtons>
                          ) : null}
                        </Subtitle>
                        <FormRow gap={ROW_GAP}>
                          <TextInput
                            formik={formik}
                            name={`vehicles[${vehicleIndex}].registeredOwner`}
                            fixedValue={vehicle.registeredOwner}
                            label="Registered Owner"
                            disabled={!editing}
                            sx={INPUT_PROPERTIES}
                            size="small"
                          />
                          <TextInput
                            formik={formik}
                            name={`vehicles[${vehicleIndex}].year`}
                            fixedValue={vehicle.year}
                            label="Year"
                            disabled={!editing}
                            sx={INPUT_PROPERTIES}
                            size="small"
                          />
                          <TextInput
                            formik={formik}
                            name={`vehicles[${vehicleIndex}].makeModel`}
                            fixedValue={vehicle.makeModel}
                            label="Make/Model"
                            disabled={!editing}
                            sx={INPUT_PROPERTIES}
                            size="small"
                          />
                          <TextInput
                            formik={formik}
                            name={`vehicles[${vehicleIndex}].plateNumber`}
                            fixedValue={vehicle.plateNumber}
                            label="Plate #"
                            disabled={!editing}
                            sx={INPUT_PROPERTIES}
                            size="small"
                          />
                        </FormRow>
                        <FormRow gap={ROW_GAP}>
                          <TextInput
                            formik={formik}
                            name={`vehicles[${vehicleIndex}].vin`}
                            fixedValue={vehicle.vin}
                            label="VIN #"
                            disabled={!editing}
                            sx={INPUT_PROPERTIES}
                            size="small"
                          />
                          <DatePicker
                            formik={formik}
                            name={`vehicles[${vehicleIndex}].dmvRenewalDate`}
                            fixedValue={vehicle.dmvRenewalDate}
                            label="DMV Renewal Date"
                            disabled={!editing}
                            sx={INPUT_PROPERTIES}
                            size="small"
                          />
                          <Autocomplete
                            formik={formik}
                            name={`vehicles[${vehicleIndex}].titleLocation`}
                            fixedValue={vehicle.titleLocation}
                            label="Title Location"
                            options={VEHICLE_INSURANCE_POLICY_TITLE_LOCATIONS}
                            free
                            sx={INPUT_PROPERTIES}
                            disabled={!editing}
                            size="small"
                          />
                          <Select
                            formik={formik}
                            name={`vehicles[${vehicleIndex}].condition`}
                            fixedValue={vehicle.condition}
                            label="Condition"
                            options={VEHICLE_INSURANCE_CONDITIONS.map((condition) => ({
                              id: condition.id,
                              label: condition.name,
                            }))}
                            sx={INPUT_PROPERTIES}
                            disabled={!editing}
                            size="small"
                          />
                        </FormRow>
                        <FormRow gap={ROW_GAP}>
                          <DatePicker
                            formik={formik}
                            name={`vehicles[${vehicleIndex}].maintenceDueDate`}
                            fixedValue={vehicle.maintenceDueDate}
                            label="Maintence Due Date"
                            disabled={!editing}
                            sx={INPUT_PROPERTIES}
                            size="small"
                          />
                          <TextInput
                            formik={formik}
                            name={`vehicles[${vehicleIndex}].vehicleLocation`}
                            fixedValue={vehicle.vehicleLocation}
                            label="Vehicle Location"
                            disabled={!editing}
                            sx={INPUT_PROPERTIES}
                            size="small"
                          />
                          <Select
                            formik={formik}
                            name={`vehicles[${vehicleIndex}].use`}
                            fixedValue={vehicle.use}
                            label="Use"
                            options={VEHICLE_INSURANCE_POLICY_USES.map((use) => ({
                              id: use.id,
                              label: use.name,
                            }))}
                            sx={INPUT_PROPERTIES}
                            disabled={!editing}
                            size="small"
                          />
                          <Checkbox
                            sx={INPUT_PROPERTIES}
                            formik={formik}
                            name={`vehicles[${vehicleIndex}].createOpenItem`}
                            fixedValue={vehicle.createOpenItem}
                            label="Create Open Item?"
                            disabled={!editing || (vehicle.createOpenItem && !vehicle.new)}
                            size="small"
                          />
                        </FormRow>
                        <FormRow gap={ROW_GAP}>
                          <TextInput
                            formik={formik}
                            name={`vehicles[${vehicleIndex}].notes`}
                            fixedValue={vehicle.notes}
                            label="Notes"
                            disabled={!editing}
                            sx={{
                              width: '100%',
                            }}
                            size="small"
                            multiline
                          />
                        </FormRow>
                      </FormSection>
                    ))}
                </>
              )}
            />
            {!editing &&
              (formik.values.documents.length > 0 || formik.values.images.length > 0) && (
                <FormUploader formik={formik} disabled entityPath={handleGetEntityPath} />
              )}
            {formButtons}
          </>
        );
      }}
    </RawForm>
  );
};

export default VehiclePolicyForm;

import React from 'react';
import { useSelector } from 'react-redux';
import usePropertyIndicators from 'hooks/components/usePropertyIndicators';
import PropertyIndicators from 'components/property-indicators/PropertyIndicators';
import DebtLog from 'components/debt-log/DebtLog';
import PropertyInputs from 'components/property-inputs/PropertyInputs';
import { styled } from '@mui/material';
import MainLayout from 'components/main/MainLayout';
import CategoriesFilter from 'components/sidebar/global/CategoriesFilter';
import PropertiesList from 'components/properties/list/PropertiesList';
import useAbility from 'hooks/shared/useAbility';
import { Can } from 'app/Can';
import colors from 'utils/colors';

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
});

const PropertiesContainer = styled('div')({
  width: '50%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '30px 20px 20px 20px',
  overflow: ['auto', 'overlay'],
});

const RightContainer = styled('div')({
  width: '50%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: colors.rightsection.background,

  '& .property-indicators-container': {
    padding: '30px 12px 16px 12px',
  },
});

const PropertyIndicatorsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${colors.main.controlBorder}`,
  borderBottom: 'unset',
});

const PropertyInputsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const Dashboard = () => {
  const ability = useAbility();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const hasIndicators = ability.can('view', 'GlobalIndicator');
  const { indicators, indicatorsSize, hasDebtLog } = usePropertyIndicators();

  if (currentProperty) return null;

  const loadingIndicators = hasIndicators && !indicators;

  return (
    <MainLayout sideBarContent={<CategoriesFilter />}>
      <Container>
        <PropertiesContainer>
          <PropertiesList />
        </PropertiesContainer>
        <RightContainer>
          {(hasDebtLog || hasIndicators) && (
            <PropertyIndicatorsContainer
              sx={{
                height: `${indicatorsSize}px`,
                display: loadingIndicators ? 'none' : null,
              }}
            >
              {hasIndicators && <PropertyIndicators />}
              {hasDebtLog && <DebtLog />}
            </PropertyIndicatorsContainer>
          )}
          <Can I="view" a="PropertyInput" ability={ability}>
            <PropertyInputsContainer
              sx={{
                height: hasIndicators ? `calc(100% - ${indicatorsSize}px)` : '100%',
                display: loadingIndicators ? 'none' : null,
              }}
            >
              <PropertyInputs />
            </PropertyInputsContainer>
          </Can>
        </RightContainer>
      </Container>
    </MainLayout>
  );
};

export default Dashboard;

import { isAdmin, isAccountAdmin } from 'domain/roles';

const defineRulesForPropertyInputs = (can, cannot, user) => {
  can('view', 'PropertyInput');
  can('create', 'PropertyInput');
  can('update', 'PropertyInput', { user_id: user.id_s });
  can('delete', 'PropertyInput', { user_id: user.id_s });

  if (isAdmin(user)) {
    can('update', 'PropertyInput');
    can('delete', 'PropertyInput');
  } else if (isAccountAdmin(user)) {
    can('update', 'PropertyInput', { property_id: { $ne: null } });
    can('delete', 'PropertyInput', { property_id: { $ne: null } });
  }
};

export default defineRulesForPropertyInputs;

import axios from 'axios';

export const sendChatMessage = async (chatId, message) => {
  await axios.post(`chats/${chatId}/chat_messages`, { chat_message: message });
};

export const getChatMessages = async (chatId) => {
  const res = await axios.get(`chats/${chatId}/chat_messages?all=true`);
  return res.data;
};

import { createSlice } from '@reduxjs/toolkit';
import { getProperties, getProperty } from 'services/properties';
import { getUsers } from 'services/users';

const initialState = { allProperties: null, currentProperty: null, users: null, barCharts: false };

const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    setAllProperties: (state, action) => ({ ...state, allProperties: action.payload }),
    setCurrentProperty: (state, action) => ({ ...state, currentProperty: action.payload }),
    setPropertyUsers: (state, action) => ({ ...state, users: action.payload }),
    clearCurrentProperty: (state) => ({ ...state, currentProperty: null, users: null }),
    setBarCharts: (state, action) => ({ ...state, barCharts: action.payload }),
  },
});

const { reducer, actions } = propertySlice;

export const {
  setAllProperties,
  setCurrentProperty,
  clearCurrentProperty,
  setPropertyUsers,
  setBarCharts,
} = actions;

export const fetchAllProperties = () => async (dispatch) => {
  const properties = await getProperties({ all: true });
  dispatch(setAllProperties(properties.records));
};

export const fetchCurrentProperty = (propertyId) => async (dispatch) => {
  const property = await getProperty(propertyId);
  dispatch(setCurrentProperty(property));
};

export const fetchPropertyUsers = (propertyId) => async (dispatch) => {
  const users = await getUsers(propertyId);
  dispatch(setPropertyUsers(users));
};

export default reducer;

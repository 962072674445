import moment from 'moment';

export const DATE_FORMAT = {
  DATE: 'MM/DD/YYYY',
  DATE_TIME: 'MM/DD/YYYY',
  SERVER_DATE: 'YYYY-MM-DD',
  RAILS_DATE: 'YYYYMMDD',
};

export const makeDate = (value) => moment(value);

export const formatDate = (value, format = DATE_FORMAT.DATE, empty = '') =>
  value ? makeDate(value).format(format) : empty;

export const now = () => moment();

export const monthName = (value) => moment(value).format('MMMM');

export const nextYear = (next = 1) => moment().add(next, 'years').format('YYYY');

import { createSlice } from '@reduxjs/toolkit';
import { getVendors } from 'services/receipts';

const initialState = { vendors: [] };

const vendorsSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    setVendors: (state, action) => ({ ...state, vendors: action.payload }),
    clearVendors: (state) => ({ ...state, vendors: null }),
  },
});

const { reducer, actions } = vendorsSlice;

export const { clearVendors, setVendors } = actions;

export const fetchVendors = () => async (dispatch) => {
  const vendors = await getVendors();
  dispatch(setVendors(vendors));
};

export default reducer;

import colors from 'utils/colors';
import RealEstateIcon from '@mui/icons-material/HomeWorkOutlined';
import FinancialAccountIcon from '@mui/icons-material/AccountBalance';
import InvestmentAccountIcon from '@mui/icons-material/Paid';
import LifeIcon from '@mui/icons-material/Favorite';
import { ReactComponent as PersonalPropertyIcon } from 'assets/svg/inventory.svg';
import BusinessInterestIcon from '@mui/icons-material/BusinessCenter';
import TrustIcon from '@mui/icons-material/Gavel';
import LoanAndReceivableIcon from '@mui/icons-material/CreditCard';
import DigitalAssetIcon from '@mui/icons-material/CloudQueue';
import EndOfLifeIcon from '@mui/icons-material/HourglassEmpty';
import { ReactComponent as OtherIcon } from 'assets/svg/object.svg';
import PhilanthropyIcon from '@mui/icons-material/VolunteerActivism';

export const getCategoryColor = ({ key }) => colors.categories[key] || colors.categories.default;

export const isCategoryDarkColor = ({ key }) => {
  switch (key) {
    case 'real_estate':
    case 'financial_account':
    case 'personal_property':
    case 'business_interest':
    case 'digital_asset':
    case 'other':
      return true;
    default:
      return false;
  }
};

export const getCategoryIcon = ({ key }) => {
  switch (key) {
    case 'real_estate':
      return RealEstateIcon;
    case 'investment_account':
      return InvestmentAccountIcon;
    case 'financial_account':
      return FinancialAccountIcon;
    case 'life_insurance':
      return LifeIcon;
    case 'personal_property':
      return PersonalPropertyIcon;
    case 'business_interest':
      return BusinessInterestIcon;
    case 'trust':
      return TrustIcon;
    case 'loan_and_receivable':
      return LoanAndReceivableIcon;
    case 'digital_asset':
      return DigitalAssetIcon;
    case 'end_of_life':
      return EndOfLifeIcon;
    case 'philanthropy':
      return PhilanthropyIcon;
    case 'other':
      return OtherIcon;
    default:
      return OtherIcon;
  }
};

import React from 'react';
import { SnackbarProvider as SnackbarProviderRoot } from 'notistack';
import { makeStyles } from '@mui/styles';
import colors from 'utils/colors';
import { makeImportantStyles } from 'utils/styles';

const useStyles = makeStyles(
  makeImportantStyles({
    variantSuccess: {
      color: colors.main.text,
      backgroundColor: colors.primary[300],
    },
    variantError: {
      color: colors.main.error,
      backgroundColor: colors.main.errorBackground,
    },
    variantWarning: {
      color: colors.main.text,
      backgroundColor: colors.secondary[300],
    },
    variantInfo: {
      color: colors.main.text,
      backgroundColor: colors.main.surfaceOverlay,
    },
  })
);

const SnackbarProvider = ({ children }) => {
  const classes = useStyles();
  return (
    <SnackbarProviderRoot
      maxSnack={3}
      classes={{
        variantSuccess: classes.variantSuccess,
        variantError: classes.variantError,
        variantWarning: classes.variantWarning,
        variantInfo: classes.variantInfo,
      }}
    >
      {children}
    </SnackbarProviderRoot>
  );
};

export default SnackbarProvider;

import { createSlice } from '@reduxjs/toolkit';
import { getCounters } from 'services/receipts';

const initialState = {
  counters: null,
  filters: {},
};

const receiptSlice = createSlice({
  name: 'receipt',
  initialState,
  reducers: {
    setCounters: (state, action) => ({ ...state, counters: action.payload }),
    setFilters: (state, action) => ({ ...state, filters: action.payload }),
    clearFilters: (state) => ({ ...state, filters: null }),
  },
});

const { reducer, actions } = receiptSlice;

export const { setCounters, setFilters, clearFilters } = actions;

export const fetchReceiptCounters = () => async (dispatch) => {
  const counters = await getCounters();
  dispatch(setCounters(counters));
};

export default reducer;

import { isAdmin, hasPermission } from 'domain/roles';

const defineRulesForDebtLog = (can, user) => {
  if (hasPermission(user, 'debt_log')) can('view', 'DebtLog');
  if (isAdmin(user)) {
    can('update', 'DebtLog');
  }
};

export default defineRulesForDebtLog;

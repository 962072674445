import axios from 'axios';
import { setSubject, setListSubject } from 'utils/ability';

export const getSummaryIndicators = async (categoryId, subcategoryId) => {
  const res = await axios.get(
    `property_indicators/summary${categoryId ? `?category=${categoryId}` : ''}${
      subcategoryId ? `&subcategory=${subcategoryId}` : ''
    }`
  );
  return setListSubject('PropertyIndicator', res.data);
};

export const getPropertyIndicators = async (propertyId, date) => {
  const res = await axios.get(
    `properties/${propertyId}/property_indicators${date ? `?date=${date}` : ''}`
  );
  return setListSubject('PropertyIndicator', res.data);
};

export const getIndicators = async (propertyId, categoryId, subcategoryId, date) =>
  propertyId
    ? getPropertyIndicators(propertyId, date)
    : getSummaryIndicators(categoryId, subcategoryId);

export const updateIndicator = async (propertyId, indicatorId, indicator) => {
  const res = await axios.put(`properties/${propertyId}/property_indicators/${indicatorId}`, {
    property_indicator: indicator,
  });
  return setSubject('PropertyIndicator', res.data);
};

export const updateConsolidatedDate = async (propertyId, date) => {
  const res = await axios.post(
    propertyId
      ? `properties/${propertyId}/property_indicators/set_date`
      : 'property_indicators/set_date',
    { date }
  );
  return res.data;
};

export const batchUpdateIndicators = async (request) => {
  const res = await axios.put(`property_indicators/batch_update_from_csv`, request);
  return res.data;
};

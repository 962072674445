import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import { DataGrid as DataGridMui, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import colors from 'utils/colors';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { useSelector } from 'react-redux';

const DataGrid = styled(DataGridMui)({
  border: 'none',
  borderRadius: 'unset',

  '& .MuiDataGrid-main': {
    border: `1px solid ${colors.main.controlBorder}`,
    borderRadius: '4px',
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'overlay',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: colors.main.surfaceOverlay,
    borderBottom: `1px solid ${colors.main.controlBorder}`,
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: '0px',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 'bold',
    color: colors.tertiary[900],
    whiteSpace: 'break-spaces',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-cell': {
    borderBottom: `1px solid ${colors.main.controlBorder}`,

    '&:focus, &:focus-within': {
      outline: 'unset',
    },
  },
  '& .MuiDataGrid-row': {
    color: colors.main.text,

    '&:hover': {
      backgroundColor: colors.main.surfaceOverlay,
    },
    '&.disabled-row': {
      opacity: 0.6,
    },
    '&.alerted-row': {
      color: colors.main.error,
      fontWeight: 'bold',
    },
    '&.dragging-row': {
      backgroundColor: colors.primary[50],
      opacity: 0.4,
    },
    '&.dropping-row': {
      backgroundColor: colors.primary[50],
      border: `1px solid ${colors.primary[900]}`,
    },
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: colors.main.draggerBackground,
  },
});

const NoRows = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '& > .no-rows-icon': {
    width: '180px',
    height: 'auto',
    color: colors.main.emptyTableIcon,
  },
});

const NoRowsTitle = styled('div')({
  marginTop: '24px',
  fontSize: '20px',
  lineHeight: '24px',
  fontWeight: '700',
  color: colors.main.greyIcon,
});

const ExportButton = styled(GridToolbarContainer)({
  display: 'flex',
  alignItems: 'right',
  justifyContent: 'right',
});

const Export = () => (
  <ExportButton>
    <GridToolbarExport
      printOptions={{
        hideToolbar: true,
        hideFooter: true,
      }}
    />
  </ExportButton>
);

const getRowById = (rows, id) => rows.find((row) => row.id === id);

const getRowElementId = (element) => element && element.getAttribute('data-id');

const getRowElement = (element) => {
  let current = element;
  while (current.parentElement) {
    if (current.classList.contains('MuiDataGrid-row')) return current;

    current = current.parentElement;
  }
  return null;
};

const useDraggableTable = ({ rows, draggable, canDragRow, canDropRow, onDropRow }) => {
  if (!draggable)
    return {
      draggableProps: {},
    };

  const droppableRows = new Set(
    (canDropRow ? rows.filter((row) => canDropRow(row)) : rows).map((row) => row.id)
  );

  const handleDragStart = (event) => {
    const row = getRowById(rows, getRowElementId(event.target));
    if (!row || (canDragRow && !canDragRow(row))) {
      event.preventDefault();
      return;
    }

    event.dataTransfer.setData('text', row.id);
    event.target.classList.add('dragging-row');
  };

  const handleDragEnd = (event) => {
    event.target.classList.remove('dragging-row');
    [...event.target.parentElement.children].forEach((element) =>
      element.classList.remove('dropping-row')
    );
  };

  const handleDragOver = (event) => {
    const target = getRowElement(event.target);
    const targetRow = getRowById(rows, getRowElementId(target));
    if (!targetRow || !droppableRows.has(targetRow.id)) return;

    event.preventDefault();
    target.classList.add('dropping-row');
  };

  const handleDragLeave = (event) => {
    const target = getRowElement(event.target);
    if (!target) return;

    target.classList.remove('dropping-row');
  };

  const handleDrop = (event) => {
    const sourceRow = getRowById(rows, event.dataTransfer.getData('text'));
    const targetRow = getRowById(rows, getRowElementId(getRowElement(event.target)));
    if (
      !sourceRow ||
      !targetRow ||
      sourceRow.id === targetRow.id ||
      !canDragRow(sourceRow) ||
      !droppableRows.has(targetRow.id)
    )
      return;

    if (onDropRow) onDropRow(sourceRow, targetRow);
  };

  return {
    draggableProps: {
      row: {
        draggable: true,
        onDragStart: handleDragStart,
        onDragEnd: handleDragEnd,
        onDragOver: handleDragOver,
        onDragLeave: handleDragLeave,
        onDrop: handleDrop,
      },
    },
  };
};

const Table = ({
  rows,
  columns,
  stretch,
  pageSize,
  onRowClick,
  onRowDoubleClick,
  getRowClassName,
  noRowsTitle,
  NoRowsIcon,
  checkboxSelection,
  onSelectionModelChange,
  allowExport,
  draggable,
  canDragRow,
  canDropRow,
  onDropRow,
}) => {
  const currentProperty = useSelector((state) => state.property.currentProperty);

  const { draggableProps } = useDraggableTable({
    rows,
    draggable,
    canDragRow,
    canDropRow,
    onDropRow,
  });

  // eslint-disable-next-line react/no-unstable-nested-components
  const NoRowsOverlay = () => (
    <NoRows>
      <NoRowsIcon className="no-rows-icon" />
      <NoRowsTitle>{noRowsTitle}</NoRowsTitle>
    </NoRows>
  );

  return (
    <DataGrid
      disableSelectionOnClick
      scrollX="hidden"
      isCellEditable={() => false}
      rowHeight={stretch ? 40 : 52}
      headerHeight={48}
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      rowsPerPageOptions={[pageSize]}
      onRowClick={onRowClick}
      onRowDoubleClick={onRowDoubleClick}
      getRowClassName={getRowClassName}
      checkboxSelection={checkboxSelection}
      onSelectionModelChange={onSelectionModelChange}
      sx={{
        '& .MuiDataGrid-row': {
          cursor: onRowClick ? 'pointer' : null,
        },
        '& .MuiDataGrid-toolbarContainer': {
          // hack to move fit export button on community version
          '& .MuiCheckbox-root svg': {
            color: colors.primary[900],
          },
          '& .MuiButton-root': {
            position: 'fixed',
            color: 'black',
            fontWeight: '600',
            backgroundColor: colors.primary[50],
            borderRadius: '4px',
            padding: '8px 14px',
            fontSize: '14px',
            lineHeight: '16px',
            border: `1px solid ${colors.primary[50]}`,
            top: currentProperty ? '32px' : '28px',
            marginBottom: '8px',
            marginRight: '-4px',
            '&:hover': { backgroundColor: colors.primary[50] },
          },
          '& .MuiButton-startIcon': {
            color: colors.primary[600],
          },
        },
        '@media print': {
          '.MuiDataGrid-main': {
            fontSize: '10px',
            height: 'fit-content',
            overflow: 'visible',
          },
          '.MuiDataGrid-columnHeaders': {
            width: 'fit-content',
            height: 'fit-content',
            overflow: 'visible',
          },
        },
      }}
      components={{
        NoRowsOverlay,
        Toolbar: allowExport ? Export : null,
      }}
      componentsProps={{
        ...draggableProps,
      }}
    />
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pageSize: PropTypes.number,
  stretch: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  NoRowsIcon: PropTypes.any,
  noRowsTitle: PropTypes.string,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  getRowClassName: PropTypes.func,
  allowExport: PropTypes.bool,
  draggable: PropTypes.bool,
  canDragRow: PropTypes.func,
  canDropRow: PropTypes.func,
  onDropRow: PropTypes.func,
};

Table.defaultProps = {
  rows: [],
  pageSize: null,
  onRowClick: null,
  onRowDoubleClick: null,
  getRowClassName: null,
  stretch: false,
  noRowsTitle: 'No rows found',
  NoRowsIcon: TableRowsIcon,
  allowExport: false,
  draggable: false,
  canDragRow: null,
  canDropRow: null,
  onDropRow: null,
};

export default Table;

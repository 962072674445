import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedCategory, setSelectedSubcategory } from 'slices/category';

const useCategories = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const globalSettings = searchParams.has('categories_settings');
  const newSubcategory = searchParams.get('new_subcategory');
  const categoryId = searchParams.get('category_id');
  const subcategoryId = searchParams.get('subcategory_id');

  const handleShowGlobalSettingsModal = () => {
    searchParams.set('categories_settings', true);
    setSearchParams(searchParams);
  };

  const handleCloseGlobalSettingsModal = () => {
    searchParams.delete('categories_settings');
    setSearchParams(searchParams);
  };

  const handleShowNewSubcategoryModal = (newSubcategoryCategoryId) => {
    searchParams.set('new_subcategory', newSubcategoryCategoryId);
    setSearchParams(searchParams);
  };

  const handleCloseNewSubcategoryModal = () => {
    searchParams.delete('new_subcategory');
    setSearchParams(searchParams);
  };

  const handleSetCategoryId = (selectedCategoryId = null) => {
    if (selectedCategoryId) searchParams.set('category_id', selectedCategoryId);
    else searchParams.delete('category_id');
    searchParams.delete('subcategory_id');
    setSearchParams(searchParams);
    dispatch(setSelectedCategory(selectedCategoryId));
  };

  const handleSetSubcategoryId = (selectedSubcategoryId = null) => {
    if (selectedSubcategoryId) searchParams.set('subcategory_id', selectedSubcategoryId);
    else searchParams.delete('subcategory_id');
    setSearchParams(searchParams);
    dispatch(setSelectedSubcategory(selectedSubcategoryId));
  };

  return {
    globalSettings,
    newSubcategory,
    categoryId,
    subcategoryId,
    onShowGlobalSettings: handleShowGlobalSettingsModal,
    onCloseGlobalSettings: handleCloseGlobalSettingsModal,
    onShowNewSubcategory: handleShowNewSubcategoryModal,
    onCloseNewSubcategory: handleCloseNewSubcategoryModal,
    onSetCategoryId: handleSetCategoryId,
    onSetSubcategoryId: handleSetSubcategoryId,
  };
};

export default useCategories;

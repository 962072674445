import { useEffect } from 'react';

const useAsyncEffect = (effectMethod, returnMethod = undefined, target = []) =>
  useEffect(() => {
    async function caller() {
      await effectMethod();
    }
    caller();
    return returnMethod;
  }, target);

export default useAsyncEffect;

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import colors from 'utils/colors';
import { removeFile, addFiles } from './uploaderUtils';
import Uploader from './Uploader';
import ImageItem from './ImageItem';

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const Title = styled('div')({
  marginBottom: '12px',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: colors.primary[900],

  '& + *': {
    marginTop: '-6px',
    marginBottom: '12px',
  },
});

const ImagesContainer = styled('div')({
  width: '100%',
  display: 'inline-flex',
  flexWrap: 'wrap',
  gap: '12px',
});

const getEntityPath = (entityPath, name) =>
  entityPath
    ? (image) => `${typeof entityPath === 'function' ? entityPath(image) : entityPath}/${name}`
    : null;

const FormImages = ({
  formik: { values, setFieldValue },
  title,
  name,
  disabled,
  entityPath,
  singleImage,
  addButtonLabel,
}) => {
  const images = values[name];
  const imagesPath = getEntityPath(entityPath, name);

  return (
    <Container className="form-uploader-images">
      <Header>
        {!!title && (
          <Title>
            Photos
            {images.length > 0 ? ` (${images.length})` : ''}
          </Title>
        )}
        {!disabled && (
          <Uploader
            type="image"
            variant="button"
            multiple={!singleImage}
            maxFiles={singleImage ? 1 : 0}
            label={addButtonLabel}
            onChange={(files) => setFieldValue(name, addFiles(images, files, singleImage))}
          />
        )}
      </Header>
      <ImagesContainer>
        {images.map((image, index) => (
          <ImageItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            image={image}
            entityPath={imagesPath}
            onDelete={!disabled ? () => setFieldValue(name, removeFile(images, index)) : null}
          />
        ))}
      </ImagesContainer>
    </Container>
  );
};

FormImages.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  title: PropTypes.bool,
  name: PropTypes.string,
  entityPath: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabled: PropTypes.bool,
  singleImage: PropTypes.bool,
  addButtonLabel: PropTypes.string,
};

FormImages.defaultProps = {
  title: true,
  name: 'images',
  entityPath: null,
  disabled: false,
  singleImage: false,
  addButtonLabel: null,
};

export default FormImages;

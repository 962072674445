import axios from 'axios';
import { setSubject } from 'utils/ability';

export const getDebtLog = async () => {
  const res = await axios.get('debt_log');
  return setSubject('DebtLog', res.data);
};

export const updateDebtLog = async (debtLog) => {
  const res = await axios.put('debt_log', {
    debt_log: debtLog,
  });
  return setSubject('DebtLog', res.data);
};

import React from 'react';
import SideBarContainer from './shared/SideBarContainer';
import SideBarOpenItems from './shared/SideBarOpenItems';
import CurrentProperty from './property/CurrentProperty';
import PropertyMenu from './property/PropertyMenu';

const PropertySideBar = () => (
  <SideBarContainer headers={<CurrentProperty />}>
    <SideBarOpenItems />
    <PropertyMenu />
  </SideBarContainer>
);

export default PropertySideBar;

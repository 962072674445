import React, { useEffect, useState } from 'react';
import Notification from 'components/shared/modal/Notification';
import { styled } from '@mui/material';
import Button from 'components/shared/button/Button';
import colors from 'utils/colors';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  getNotifications,
  setAllNotificationsAsRead,
  setAllNotificationsAsUnread,
  setNotificationAsRead,
  setNotificationAsUnread,
} from 'services/notifications';
import { useDispatch } from 'react-redux';
import useOpenItems from 'hooks/components/useOpenItems';
import usePrivateChats from 'hooks/components/usePrivateChats';
import { fetchNotificationsCounter } from 'slices/notification';

const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '6px 16px 16px 16px',
  height: '340px',
  overflow: 'auto',
});

const Order = styled('div')({
  fontSize: '10px',
  color: colors.categories.default,
});

const Text = styled('div')({
  fontSize: '16px',
  color: colors.main.text,
  '&:hover': {
    color: colors.primary[700],
  },
  cursor: 'pointer',
});

const NotificationContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 0 12px 0',
  borderBottom: `1px solid ${colors.main.controlBorder}`,
});
const MainNotification = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const MessagesModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const { onShowDetails: showOpenItem } = useOpenItems();
  const { onShowDetails: showPrivateChat } = usePrivateChats();
  const [messages, setMessages] = useState([]);
  const allUnread = messages.find((message) => !message.read_at);

  const getAllMessages = async () => {
    const allMessages = await getNotifications('message', { all: true });
    setMessages(allMessages.records);
  };

  useEffect(() => {
    dispatch(fetchNotificationsCounter());
    getAllMessages();
  }, []);

  const markAllMessages = async () => {
    if (!allUnread) {
      await setAllNotificationsAsUnread({ type: 'message' });
    } else {
      await setAllNotificationsAsRead({ type: 'message' });
    }
    dispatch(fetchNotificationsCounter());
    getAllMessages();
  };

  const markMessage = async (notificationId, action) => {
    if (action === 'read') {
      await setNotificationAsRead(notificationId, { type: 'message' });
    } else {
      await setNotificationAsUnread(notificationId, { type: 'message' });
    }
    getAllMessages();

    dispatch(fetchNotificationsCounter());
  };

  const handleOpenNotification = async ({
    id,
    property_id: propertyId,
    record_type: recordType,
    record_id: recordId,
    read_at: readAt,
  }) => {
    switch (recordType) {
      case 'OpenItem':
        showOpenItem(propertyId, recordId);
        break;
      case 'PrivateChat':
        showPrivateChat(propertyId, recordId);
        break;
      default:
        break;
    }
    if (!readAt) await setNotificationAsRead(id, { type: 'message' });
    dispatch(fetchNotificationsCounter());
    onClose();
  };

  return (
    <Notification
      title="Messages"
      top="72px"
      left="244px"
      width="400px"
      onClose={onClose}
      buttons={
        messages.length > 0 && (
          <Button variant="white" onClick={() => markAllMessages()}>
            MARK ALL AS {allUnread ? 'READ' : 'UNREAD'}
          </Button>
        )
      }
    >
      <MainContainer>
        {messages.map((message) => (
          <NotificationContainer key={message.id}>
            <Order>{message.title}</Order>
            <MainNotification>
              <Text
                style={{ fontWeight: message.read_at ? null : 'bold' }}
                onClick={() => handleOpenNotification(message)}
              >
                <div>{message.message}</div>
              </Text>
              <DoneAllIcon
                sx={{
                  color: message.read_at ? colors.main.disabledLabel : colors.main.read,
                  cursor: 'pointer',
                }}
                onClick={() => markMessage(message.id, message.read_at ? 'unread' : 'read')}
              />
            </MainNotification>
          </NotificationContainer>
        ))}
      </MainContainer>
    </Notification>
  );
};

export default MessagesModal;

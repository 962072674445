import { isMobile } from 'react-device-detect';

const MobileAppProvider = ({ children }) => {
  const makeMobilePath = () => `${window.location.pathname}${window.location.search}`;

  const redirectToMobileApp = () => {
    const mobileBaseUrl = process.env.REACT_APP_MOBILE_URL;
    if (!mobileBaseUrl) return false;

    window.location.href = `${mobileBaseUrl}/${makeMobilePath()}`;
    return true;
  };

  if (isMobile && redirectToMobileApp()) return null;

  return children;
};

export default MobileAppProvider;

import React from 'react';
import { useSelector } from 'react-redux';
import OpenItemFormModal from './OpenItemFormModal';

const AddOpenItem = ({ critical, onClose }) => {
  const currentProperty = useSelector((state) => state.property.currentProperty);

  return (
    <OpenItemFormModal
      title={`New ${critical ? 'Critical' : 'Open'} Item - ${currentProperty.name}`}
      onClose={onClose}
      critical={critical}
    />
  );
};

export default AddOpenItem;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import classnames from 'classnames';
import ClearIcon from '@mui/icons-material/Clear';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import colors from 'utils/colors';
import { ReactComponent as DocumentSvg } from 'assets/svg/document.svg';
import { ReactComponent as DownloadSvg } from 'assets/svg/download.svg';
import { getDocumentUrl } from 'services/documents';
import { downloadProps } from './uploaderUtils';

const Control = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
  padding: '4px 8px',
  borderRadius: '4px',
  backgroundColor: colors.primary[50],
  cursor: 'pointer',
  '&.deleted': {
    backgroundColor: colors.main.surfaceOverlay,
  },
});

const IconContainer = styled('a')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
  color: colors.primary[900],
});

const Text = styled('a')({
  width: 'calc(100% - 72px)',
  margin: '0px 12px',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: colors.main.text,
  textDecoration: 'none',
});

const getIcon = (deleted, onDelete) => {
  if (deleted) return <RestoreFromTrashIcon />;
  if (onDelete) return <ClearIcon />;
  return <DownloadSvg />;
};

const getDownloadUrl = async ({ entityPath, id, url }) => {
  if (!entityPath || !id) return url;

  try {
    return await getDocumentUrl(entityPath, id);
  } catch (_e) {
    return null;
  }
};

const getEntityPath = (entityPath, document) =>
  typeof entityPath === 'function' ? entityPath(document) : entityPath;

const DocumentItem = ({ entityPath, document, onDelete }) => {
  const handleDownload = async () => {
    const url = await getDownloadUrl({
      ...document,
      entityPath: getEntityPath(entityPath, document),
    });
    if (!url) return;

    window.open(downloadProps({ ...document, url }).href);
  };

  const deleted = document.action === 'delete';
  return (
    <Control className={classnames('document-item', { deleted })}>
      <IconContainer onClick={handleDownload}>
        <DocumentSvg />
      </IconContainer>
      <Text onClick={handleDownload}>{document.filename}</Text>
      <IconContainer sx={{ marginLeft: 'auto' }} onClick={onDelete || handleDownload}>
        {getIcon(deleted, onDelete)}
      </IconContainer>
    </Control>
  );
};

DocumentItem.propTypes = {
  document: PropTypes.shape().isRequired,
  entityPath: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onDelete: PropTypes.func,
};

DocumentItem.defaultProps = {
  entityPath: null,
  onDelete: null,
};

export default DocumentItem;

import React from 'react';
import useAbility from 'hooks/shared/useAbility';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { logout } from 'services/auth';

const PrivateRoute = ({ permission, children }) => {
  const ability = useAbility();
  const { isLoggedIn } = useSelector((state) => state.auth);

  if (!isLoggedIn) {
    logout(true);
    return null;
  }
  if (permission && !ability.can('view', permission)) {
    return <Navigate to="/" />;
  }

  return children;
};

export default PrivateRoute;

import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { fetchCurrentProperty } from 'slices/property';
import { fetchPleasePayCounters } from 'slices/pleasePay';

const usePleasePays = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const propertyId = searchParams.get('please_pay_property');
  const pleasePayId = searchParams.get('please_pay');
  const currentProperty = useSelector((state) => state.property.currentProperty);

  const updateCounters = () => {
    if (currentProperty) {
      dispatch(fetchCurrentProperty(currentProperty.id));
    }
    dispatch(fetchPleasePayCounters());
  };

  const handleShowDetailsModal = (showProperty, showPleasePayId = 'new') => {
    searchParams.set('please_pay_property', showProperty);
    searchParams.set('please_pay', showPleasePayId);
    setSearchParams(searchParams);
  };

  const handleCloseDetailsModal = () => {
    searchParams.delete('please_pay_property');
    searchParams.delete('please_pay');
    setSearchParams(searchParams);
    updateCounters();
  };

  return {
    propertyId,
    pleasePayId,
    updateCounters,
    onShowDetails: handleShowDetailsModal,
    onCloseDetails: handleCloseDetailsModal,
  };
};

export default usePleasePays;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pluralize } from 'utils/text';
import { makeValidatorSchema, stringValidator } from 'utils/validators';
import useSnackbar from 'hooks/shared/useSnackBar';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import useProperties from 'hooks/components/useProperties';
import FormModal from 'components/shared/modal/FormModal';
import FormRow from 'components/shared/form/FormRow';
import Select from 'components/shared/form/Select';
import TextInput from 'components/shared/form/TextInput';
import { getSubcategories, getSubcategoryTemplates, createSubcategory } from 'services/categories';
import { fetchCategories } from 'slices/category';

const validationSchema = makeValidatorSchema({
  key: stringValidator({ required: 'Must select a template' }),
  name: stringValidator({ required: 'Must enter a name' }),
});

const NewSubcategoryModal = ({ categoryId, onClose }) => {
  const dispatch = useDispatch();
  const { showSuccess } = useSnackbar();
  const categories = useSelector((state) => state.category.categories);
  const [subcategories, setSubcategories] = useState(null);
  const [subcategoryTemplates, setSubcategoryTemplates] = useState(null);
  const { onShowNewProperty } = useProperties();

  const initializeForm = { key: '', name: '' };

  const makeAvailableSubcategoryTemplates = () => [
    ...subcategoryTemplates
      .filter(({ key }) => !subcategories.has(key))
      .map(({ key, name }) => ({
        value: key,
        label: name,
      })),
    { value: 'custom', label: 'Custom' },
  ];

  const handleTemplateChange = ({ setFieldValue }, key) => {
    setFieldValue('key', key);
    setFieldValue(
      'name',
      key && key !== 'custom'
        ? subcategoryTemplates.find((template) => template.key === key).name
        : ''
    );
  };

  const makeFormRequest = (values) => ({
    key: values.key,
    name: values.name,
  });

  const handleSubmit = async (values) => {
    const request = makeFormRequest(values);
    const subcategory = await createSubcategory(categoryId, request);
    dispatch(fetchCategories());
    showSuccess('Subcategory added');
    onShowNewProperty(categoryId, subcategory.id);
  };

  useAsyncEffect(async () => {
    setSubcategories(new Set((await getSubcategories(categoryId)).map(({ key }) => key)));
    setSubcategoryTemplates(await getSubcategoryTemplates(categoryId));
  });

  if (!subcategories || !subcategoryTemplates) return null;

  const selectedCategory = categories.find((category) => category.id === categoryId);
  const title = `New ${selectedCategory ? pluralize(selectedCategory.name, 1) : 'subcategory'}`;

  return (
    <FormModal
      open
      title={title}
      onClose={onClose}
      initialValues={initializeForm}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError="Error adding subcategory"
      submitButton={{ label: 'Add' }}
      cancelButton
    >
      {(formik) => (
        <FormRow>
          <Select
            formik={formik}
            name="key"
            label="Choose from"
            options={makeAvailableSubcategoryTemplates()}
            onChange={(event) => handleTemplateChange(formik, event.target.value)}
            sx={{ width: '50%' }}
          />
          {formik.values.key === 'custom' && (
            <TextInput formik={formik} name="name" label="Name" sx={{ width: '50%' }} />
          )}
        </FormRow>
      )}
    </FormModal>
  );
};
export default NewSubcategoryModal;

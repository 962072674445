import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'components/shared/table/Table';
import { fetchAllProperties, fetchPropertyUsers } from 'slices/property';
import { fetchAllUsers } from 'slices/user';
import { capitalize } from 'utils/text';
import useSnackbar from 'hooks/shared/useSnackBar';
import useAbility from 'hooks/shared/useAbility';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import colors from 'utils/colors';
import {
  renderActionsCell,
  renderMailCell,
  renderPhoneCell,
  renderLongCell,
} from 'components/shared/table/Cell';
import { getUserFullName } from 'domain/user';
import { rolesLabels, hasRole, isGlobalUser } from 'domain/roles';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import { deleteUser } from 'services/users';
import useDirectory from 'hooks/components/useDirectory';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';

const DirectoryTable = () => {
  const ability = useAbility();
  const dispatch = useDispatch();
  const { showSuccess, showError } = useSnackbar();
  const { roleFilter, userId: selectedUser, onShowDetails } = useDirectory();
  const allProperties = useSelector((state) => state.property.allProperties);
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const propertyUsers = useSelector((state) => state.property.users);
  const globalUsers = useSelector((state) => state.user);
  const users = currentProperty ? propertyUsers : globalUsers;
  const [deletingUser, setDeletingUser] = useState(null);
  const canEdit = ability.can('update', 'Directory');
  const canManagePermissions = ability.can('manage_permissions', 'Directory');
  const canDelete = ability.can('delete', 'Directory');

  useAsyncEffect(async () => {
    if (allProperties) return;
    dispatch(fetchAllProperties());
  });

  useAsyncEffect(
    async () => {
      if (deletingUser || selectedUser) return;

      if (currentProperty) {
        dispatch(fetchPropertyUsers(currentProperty.id));
      } else {
        dispatch(fetchAllUsers());
      }
    },
    undefined,
    [selectedUser, deletingUser]
  );

  const handleAskDeletion = (user) => {
    setDeletingUser(user);
  };

  const handleCancelDelete = () => {
    setDeletingUser(null);
  };

  const handleDelete = async () => {
    try {
      await deleteUser(deletingUser.id);
      showSuccess('User deleted');
      setDeletingUser(null);
    } catch {
      showError('Error deleting user');
    }
  };

  const filterUsers = () =>
    roleFilter ? users.filter((user) => hasRole(user, roleFilter)) : users;

  const columns = [
    {
      headerName: 'Name',
      field: 'fullName',
      flex: currentProperty ? 3 : 2,
      editable: true,
      valueGetter: ({ row: user }) => getUserFullName(user),
    },
    {
      headerName: 'Role',
      field: 'role',
      flex: 1,
      valueGetter: ({ row: { role } }) => (role ? rolesLabels[role] : null),
    },
    {
      headerName: 'Title',
      field: 'title',
      flex: 1,
      valueGetter: ({ row: { title } }) => (title ? capitalize(title) : null),
    },
    !currentProperty && {
      headerName: 'Portfolio',
      field: 'properties',
      flex: 2,
      valueGetter: ({ row: user }) =>
        !isGlobalUser(user)
          ? user.properties
              ?.map(
                (propertyId) =>
                  allProperties.find((property) => property.id === propertyId)?.short_name
              )
              .filter((property) => property)
              .join(', ')
          : 'All',
      renderCell: renderLongCell,
    },
    {
      headerName: 'Email',
      field: 'email',
      renderCell: renderMailCell,
      flex: 2,
    },
    {
      headerName: 'Phone Number',
      field: 'phone_number',
      renderCell: renderPhoneCell,
      flex: 1,
    },
    canManagePermissions && {
      headerName: 'Financials',
      field: 'has_indicators',
      valueGetter: ({ row: { has_indicators: property, has_global_indicators: global } }) => {
        if (property && global) return 'Yes';
        if (property) return 'Asset';
        if (global) return 'Global';
        return 'No';
      },
      flex: 0.5,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      disableExport: true,
      flex: 1.2,
      renderCell: (params) =>
        renderActionsCell({
          icons: [
            {
              type: canEdit ? 'edit' : 'view',
              onClick: () => onShowDetails(params.row.id),
            },
            canManagePermissions && {
              type: 'permissions',
              onClick: () => onShowDetails(params.row.id, 'permissions'),
            },
            // {
            //   type: 'mail',
            //   onClick: () => {},
            // },
            canDelete && {
              type: 'delete',
              onClick: () => {
                handleAskDeletion(params.row);
              },
            },
          ].filter((icon) => icon),
          color: colors.primary[900],
        }),
    },
  ].filter((column) => column);

  if (!users || !allProperties) return null;

  return (
    <>
      <Table
        rows={filterUsers()}
        columns={columns}
        pageSize={50}
        onRowDoubleClick={({ row }) => onShowDetails(row.id)}
        NoRowsIcon={ContactsOutlinedIcon}
        noRowsTitle="No users found"
      />
      {deletingUser && (
        <ConfirmationModal
          title="User will be deleted"
          description="Are you sure?"
          onCancel={handleCancelDelete}
          onConfirm={handleDelete}
        />
      )}
    </>
  );
};

export default DirectoryTable;

import React from 'react';
import { styled } from '@mui/material';
import colors from 'utils/colors';
import MainHeader from './MainHeader';

const MainBox = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% - 64px)',
  height: 'calc(100% - 64px)',
  margin: '32px 24px',
  borderRadius: '8px',
  background: colors.main.surface,
  overflow: 'auto',
});

const MainContainer = ({ buttons, children, title, backButton, onClick }) => (
  <MainBox>
    <MainHeader buttons={buttons} title={title} backButton={backButton} onClick={onClick} />
    {children}
  </MainBox>
);

export default MainContainer;

import { styled } from '@mui/material';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FieldArray } from 'formik';
import colors from 'utils/colors';
import { fieldNumber } from 'utils/number';
import DatePicker from 'components/shared/form/DatePicker';
import TextInput from 'components/shared/form/TextInput';
import Select from 'components/shared/form/Select';
import Autocomplete from 'components/shared/form/Autocomplete';
import Checkbox from 'components/shared/form/Checkbox';
import FormRow from 'components/shared/form/FormRow';
import FormSection from 'components/shared/form/FormSection';
import {
  dateValidator,
  makeValidatorSchema,
  positiveNumberValidator,
  stringValidator,
} from 'utils/validators';
import RawForm from 'components/shared/form/RawForm';
import Button from 'components/shared/button/Button';
import IconButton from 'components/shared/button/IconButton';
import { useSelector } from 'react-redux';
import { createPolicy, deletePolicy, updatePolicy } from 'services/policies';
import useSnackbar from 'hooks/shared/useSnackBar';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import FormUploader from 'components/shared/uploader/FormUploader';
import {
  HOME_INSURANCE_POLICY_SUB_TYPES,
  HOME_NOT_LOCATION_CATEGORIES,
  POLICY_PREMIUM_FRECUENCIES,
  HOME_POLICY_LIMIT_TYPES,
  POLICY_LOSS_OF_USE_LIMITS,
  getPolicyKey,
} from 'domain/policies';
import useOpenItems from 'hooks/components/useOpenItems';

const ROW_GAP = '6';
const INPUT_PROPERTIES = {
  width: `calc(${100 / 4}% - 3px)`,
};
const BUTTON_PROPERTIES = {
  padding: '0px',
  minWidth: 'unset',
  '& .MuiButton-startIcon': {
    marginLeft: '0px',
    marginRight: '2px',
  },
};
const COLLPASED_BUTTONS_PROPERTIES = {
  position: 'absolute',
  bottom: '0px',
  right: '0px',
  marginTop: '4px',
  padding: '12px 16px 16px 16px',
  background: colors.main.whiteOpacity,
  zIndex: '1',
};

const ButtonsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',
});

const RowButtons = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingBottom: '8px',
  gap: '4px',
});

const SectionTitle = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 'bold',
  color: colors.primary[900],
  marginTop: '12px',
});

const Subtitle = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '12px',
  fontWeight: 'bold',
  color: colors.primary[900],
});

const SubtitleText = styled('div')({
  fontSize: '12px',
  fontWeight: 'bold',
  color: colors.primary[900],
});

const SubtitleButtons = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '4px',
});

const validationSchema = makeValidatorSchema({
  type: stringValidator({ required: 'Must enter policy type' }),
  insuranceCompany: stringValidator(),
  insured: stringValidator(),
  policy: stringValidator(),
  addlInsured: stringValidator(),
  agencyName: stringValidator(),
  agentContact: stringValidator(),
  agentEmail: stringValidator(),
  agentPhoneNo: stringValidator(),
  autoDebit: stringValidator(),
  autoDebitAccount: stringValidator(),
  notes: stringValidator(),
  premiumFrecuency: stringValidator(),
  premium: positiveNumberValidator(),
  deductible: positiveNumberValidator(),
  liabilityLimit: positiveNumberValidator(),
  excessLiabilityLimit: positiveNumberValidator(),
  premiumDueDate: dateValidator(),
});

const emptyLimit = () => ({
  id: uuid(),
  limitType: '',
  limit: '',
  lossOfUseLimit: 'undefined_loss_of_use',
});

const emptyLocation = () => ({
  id: uuid(),
  propertyId: '',
  description: '',
  appraisedValue: '',
  appraisedDate: '',
  limits: [emptyLimit()],
});

const initializeForm = (allLocationProperties, policy = null) =>
  policy
    ? {
        id: policy.id,
        type: policy.sub_type,
        insuranceCompany: policy.insurance_company,
        insured: policy.insured,
        policy: policy.policy_number,
        premium: fieldNumber(policy.premium),
        premiumDueDate: policy.premium_due_date,
        addlInsured: policy.addl_insured,
        agencyName: policy.agency_name,
        agentContact: policy.agent_contact,
        agentEmail: policy.agent_email,
        agentPhoneNo: policy.agent_phone_no,
        autoDebit: policy.auto_debit,
        autoDebitAccount: policy.auto_debit_account,
        notes: policy.notes,
        premiumFrecuency: policy.premium_frequency,
        deductible: fieldNumber(policy.deductible),
        liabilityLimit: fieldNumber(policy.liability_limit),
        excessLiabilityLimit: fieldNumber(policy.excess_liability_limit),
        locations:
          policy.locations.length > 0
            ? policy.locations.map((location) => ({
                id: location.id,
                propertyId:
                  (location.property_id &&
                    allLocationProperties.find((property) => property.id === location.property_id)
                      ?.name) ||
                  location.custom_property ||
                  '',
                description: location.description || '',
                appraisedValue: fieldNumber(location.appraised_value),
                appraisedDate: location.appraised_date,
                limits:
                  location.limits.length > 0
                    ? location.limits.map((limit) => ({
                        id: limit.id,
                        limitType: limit.limit_type,
                        limit: fieldNumber(limit.limit),
                        lossOfUseLimit: limit.loss_of_use_limit || 'undefined_loss_of_use',
                      }))
                    : [emptyLimit()],
              }))
            : [emptyLocation()],
        documents: policy.documents || [],
        images: policy.images || [],
      }
    : {
        id: '',
        type: HOME_INSURANCE_POLICY_SUB_TYPES[0].id,
        insuranceCompany: '',
        insured: '',
        policy: '',
        premium: '',
        premiumDueDate: '',
        addlInsured: '',
        agencyName: '',
        agentContact: '',
        agentEmail: '',
        agentPhoneNo: '',
        autoDebit: false,
        autoDebitAccount: '',
        notes: '',
        premiumFrecuency: POLICY_PREMIUM_FRECUENCIES[0].id,
        deductible: '',
        liabilityLimit: '',
        excessLiabilityLimit: '',
        locations: [emptyLocation()],
        documents: [],
        images: [],
      };

const HomePolicyForm = ({
  policy,
  editing,
  collapsed,
  canUpdate,
  canDelete,
  onSave,
  onDeleted,
  onCancel,
  onEdit,
  onSelect,
}) => {
  const { showSuccess, showError } = useSnackbar();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const propertyId = currentProperty.id;
  const policyKey = getPolicyKey(currentProperty);
  const allProperties = useSelector((state) => state.property.allProperties);
  const [deletingInsurance, setDeletingInsurance] = useState(false);
  const { onShowDetails: handleShowOpenItem } = useOpenItems();

  const openItemId = (policy && policy.open_item_id) || null;
  const allLocationProperties = allProperties.filter(
    ({ category: { key: categoryKey } }) => !HOME_NOT_LOCATION_CATEGORIES.includes(categoryKey)
  );
  const allLocationPropertyNames = allLocationProperties.map((property) => property.name);

  const makeFormRequest = (values) => ({
    sub_type: values.type,
    insurance_company: values.insuranceCompany,
    insured: values.insured,
    policy_number: values.policy,
    premium: values.premium || '0',
    premium_due_date: values.premiumDueDate || null,
    addl_insured: values.addlInsured,
    agency_name: values.agencyName,
    agent_contact: values.agentContact,
    agent_email: values.agentEmail,
    agent_phone_no: values.agentPhoneNo,
    auto_debit: values.autoDebit,
    auto_debit_account: values.autoDebit ? values.autoDebitAccount : null,
    deductible: values.deductible || '0',
    liability_limit: values.liabilityLimit || '0',
    excess_liability_limit: values.excessLiabilityLimit || '0',
    notes: values.notes,
    premium_frequency: values.premiumFrecuency,
    locations: values.locations
      .filter((location) => location.propertyId || location.description)
      .map((location) => {
        const locationProperty =
          location.propertyId &&
          allLocationProperties.find((property) => property.name === location.propertyId);

        return {
          property_id: locationProperty?.id || null,
          custom_property: !locationProperty ? location.propertyId : null,
          description: location.description,
          appraised_value: location.appraisedValue,
          appraised_date: location.appraisedDate,
          limits: location.limits
            .filter(({ limitType, limit }) => limitType || limit)
            .map(({ limitType, limit, lossOfUseLimit }) => ({
              limit_type: limitType,
              limit,
              loss_of_use_limit: lossOfUseLimit || null,
            })),
        };
      }),
  });

  const handleGetEntityPath = (document) =>
    `properties/${propertyId}/open_items/${document.record_id}`;

  const handleCancel = (formik) => {
    formik.resetForm();
    onCancel();
  };

  const handleEdit = () => {
    onEdit();
  };

  const handleDelete = async (lifeInsurancePolicyId) => {
    try {
      await deletePolicy(propertyId, policyKey, lifeInsurancePolicyId);
      showSuccess('Policy Deleted');
      onDeleted();
    } catch {
      showError('Error deleting policy');
    }
    setDeletingInsurance(false);
  };

  const handleAskDeletion = () => {
    setDeletingInsurance(true);
  };

  const handleCancelDelete = () => {
    setDeletingInsurance(false);
  };

  const savePolicy = async (request) =>
    policy
      ? updatePolicy(propertyId, policyKey, policy.id, request)
      : createPolicy(propertyId, policyKey, request);

  const handleSubmit = async (values, formik) => {
    const request = makeFormRequest(values);
    const savedPolicy = await savePolicy(request);
    formik.resetForm({ values: initializeForm(allLocationProperties, savedPolicy) });
    showSuccess(policy ? 'Policy Updated' : 'Policy Created');
    onSave(savedPolicy);
  };

  return (
    <RawForm
      initialValues={initializeForm(allLocationProperties, policy)}
      validationSchema={validationSchema}
      submit={false}
      messageAlwaysVisible={false}
      onSubmit={handleSubmit}
      scroll={false}
      maxHeight={null}
    >
      {(formik) => {
        const formHeaders = (
          <>
            <FormRow gap={ROW_GAP} onClick={onSelect}>
              <TextInput
                formik={formik}
                name="insured"
                label="Named Insured"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="addlInsured"
                label="Add'l Insured"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <Select
                formik={formik}
                name="type"
                label="Policy Type"
                options={HOME_INSURANCE_POLICY_SUB_TYPES.map((type) => ({
                  id: type.id,
                  label: type.name,
                }))}
                sx={INPUT_PROPERTIES}
                disabled={!editing}
                size="small"
              />
              <TextInput
                formik={formik}
                name="insuranceCompany"
                label="Insurance Company"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
            </FormRow>
            <FormRow gap={ROW_GAP} onClick={onSelect}>
              <TextInput
                formik={formik}
                name="policy"
                label="Policy Number"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <DatePicker
                formik={formik}
                name="premiumDueDate"
                label="Premium Due Date"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <Select
                formik={formik}
                name="premiumFrecuency"
                label="Premium Frequency"
                options={POLICY_PREMIUM_FRECUENCIES.map((type) => ({
                  id: type.id,
                  label: type.name,
                }))}
                sx={INPUT_PROPERTIES}
                disabled={!editing}
                size="small"
              />
              <TextInput
                formik={formik}
                name="premium"
                label="Premium"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="currency"
                clearPrefix={false}
              />
            </FormRow>
            <FormRow gap={ROW_GAP} onClick={onSelect}>
              <TextInput
                formik={formik}
                name="deductible"
                label="Deductible"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="currency"
                clearPrefix={false}
              />
              <TextInput
                formik={formik}
                name="liabilityLimit"
                label="Liability Limit"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="currency"
                clearPrefix={false}
              />
              <TextInput
                formik={formik}
                name="excessLiabilityLimit"
                label="Excess Liability Limit"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="currency"
                clearPrefix={false}
              />
              {formik.values.autoDebit ? (
                <TextInput
                  formik={formik}
                  name="autoDebitAccount"
                  label="Debit Account"
                  disabled={!editing}
                  sx={INPUT_PROPERTIES}
                  size="small"
                  onCancel={editing ? () => formik.setFieldValue('autoDebit', false) : null}
                />
              ) : (
                <Checkbox
                  sx={INPUT_PROPERTIES}
                  formik={formik}
                  name="autoDebit"
                  label="Auto Debit"
                  disabled={!editing}
                  size="small"
                />
              )}
            </FormRow>
          </>
        );

        const formButtons = (
          <>
            <FormRow gap={ROW_GAP} sx={collapsed ? COLLPASED_BUTTONS_PROPERTIES : null}>
              <ButtonsContainer>
                {editing ? (
                  <>
                    <Button
                      startIcon="cancel"
                      variant="transparent"
                      iconSize={20}
                      color={colors.tertiary[900]}
                      onClick={() => {
                        handleCancel(formik);
                      }}
                      sx={BUTTON_PROPERTIES}
                    >
                      Cancel
                    </Button>
                    <Button
                      startIcon="confirm"
                      variant="transparent"
                      iconSize={20}
                      color={colors.primary[900]}
                      onClick={() => formik.submitForm()}
                      sx={BUTTON_PROPERTIES}
                    >
                      Confirm
                    </Button>
                  </>
                ) : (
                  <>
                    {canDelete && (
                      <Button
                        startIcon="delete"
                        variant="transparent"
                        iconSize={20}
                        sx={BUTTON_PROPERTIES}
                        onClick={() => {
                          handleAskDeletion();
                        }}
                      >
                        Delete
                      </Button>
                    )}
                    {canUpdate && (
                      <Button
                        startIcon="edit"
                        variant="transparent"
                        iconSize={20}
                        sx={BUTTON_PROPERTIES}
                        onClick={handleEdit}
                      >
                        Edit
                      </Button>
                    )}
                    {openItemId && (
                      <Button
                        startIcon="openItem"
                        variant="transparent"
                        iconSize={20}
                        sx={BUTTON_PROPERTIES}
                        onClick={() => {
                          handleShowOpenItem(currentProperty.id, openItemId);
                        }}
                      >
                        Open Item
                      </Button>
                    )}
                  </>
                )}
              </ButtonsContainer>
            </FormRow>

            {deletingInsurance && (
              <ConfirmationModal
                title="Policy will be deleted"
                description="Are you sure?"
                onCancel={handleCancelDelete}
                onConfirm={() => {
                  handleDelete(policy.id);
                }}
              />
            )}
          </>
        );

        if (collapsed)
          return (
            <>
              {formHeaders}
              {formButtons}
            </>
          );

        return (
          <>
            {formHeaders}
            {formik.values.locations.some((location) => editing || location.propertyId) && (
              <SectionTitle>Policy Locations</SectionTitle>
            )}
            <FieldArray
              name="locations"
              render={(locationArrayHelpers) => (
                <>
                  {formik.values.locations
                    .filter((location) => editing || location.propertyId || location.description)
                    .map((location, locationIndex) => (
                      <FormSection key={location.id}>
                        <Subtitle sx={editing ? { marginBottom: '8px' } : null}>
                          <SubtitleText>Location #{locationIndex + 1}</SubtitleText>
                          {editing ? (
                            <SubtitleButtons>
                              {formik.values.locations.length > 1 && (
                                <IconButton
                                  icon="delete"
                                  color={colors.primary[900]}
                                  onClick={() => locationArrayHelpers.remove(locationIndex)}
                                />
                              )}
                              <IconButton
                                icon="add"
                                color={colors.primary[900]}
                                onClick={() =>
                                  locationArrayHelpers.insert(locationIndex + 1, emptyLocation())
                                }
                              />
                            </SubtitleButtons>
                          ) : null}
                        </Subtitle>
                        <FormRow gap={ROW_GAP}>
                          <Autocomplete
                            formik={formik}
                            name={`locations[${locationIndex}].propertyId`}
                            fixedValue={location.propertyId}
                            label="Owner"
                            options={allLocationPropertyNames}
                            free
                            sx={INPUT_PROPERTIES}
                            disabled={!editing}
                            size="small"
                          />
                          <TextInput
                            formik={formik}
                            name={`locations[${locationIndex}].description`}
                            fixedValue={location.description}
                            label="Location"
                            disabled={!editing}
                            sx={INPUT_PROPERTIES}
                            size="small"
                          />
                          <TextInput
                            formik={formik}
                            name={`locations[${locationIndex}].appraisedValue`}
                            fixedValue={location.appraisedValue}
                            label="Appraised Value"
                            disabled={!editing}
                            sx={INPUT_PROPERTIES}
                            size="small"
                            type="currency"
                            clearPrefix={false}
                          />
                          <DatePicker
                            formik={formik}
                            name={`locations[${locationIndex}].appraisedDate`}
                            fixedValue={location.appraisedDate}
                            label="Appraised Date"
                            disabled={!editing}
                            sx={INPUT_PROPERTIES}
                            size="small"
                          />
                        </FormRow>
                        {location.limits.some(
                          (limit) => editing || limit.limitType || limit.limit
                        ) && (
                          <Subtitle sx={{ marginTop: '8px' }}>
                            <SubtitleText>Limits</SubtitleText>
                          </Subtitle>
                        )}
                        <FieldArray
                          name={`locations[${locationIndex}].limits`}
                          render={(limitArrayHelpers) => (
                            <>
                              {location.limits
                                .filter((limit) => editing || limit.limitType || limit.limit)
                                .map((limit, limitIndex) => (
                                  <FormRow gap={ROW_GAP} key={limit.id}>
                                    <Select
                                      formik={formik}
                                      name={`locations[${locationIndex}].limits[${limitIndex}].limitType`}
                                      fixedValue={limit.limitType}
                                      label="Coverage Type"
                                      options={HOME_POLICY_LIMIT_TYPES.map((type) => ({
                                        id: type.id,
                                        label: type.name,
                                      }))}
                                      sx={INPUT_PROPERTIES}
                                      disabled={!editing}
                                      size="small"
                                    />
                                    <TextInput
                                      formik={formik}
                                      name={`locations[${locationIndex}].limits[${limitIndex}].limit`}
                                      fixedValue={limit.limit}
                                      label="Limit Amount"
                                      disabled={!editing}
                                      sx={INPUT_PROPERTIES}
                                      size="small"
                                      type="currency"
                                      clearPrefix={false}
                                    />
                                    {limit.limitType === 'loss_of_use' ? (
                                      <Select
                                        formik={formik}
                                        name={`locations[${locationIndex}].limits[${limitIndex}].lossOfUseLimit`}
                                        fixedValue={limit.lossOfUseLimit}
                                        label="Loss Of Use"
                                        options={POLICY_LOSS_OF_USE_LIMITS.map((type) => ({
                                          id: type.id,
                                          label: type.name,
                                        }))}
                                        sx={INPUT_PROPERTIES}
                                        disabled={!editing}
                                        size="small"
                                      />
                                    ) : (
                                      <div style={INPUT_PROPERTIES} />
                                    )}
                                    {editing ? (
                                      <RowButtons style={INPUT_PROPERTIES}>
                                        {location.limits.length > 1 && (
                                          <IconButton
                                            icon="delete"
                                            color={colors.primary[900]}
                                            onClick={() => limitArrayHelpers.remove(limitIndex)}
                                          />
                                        )}
                                        <IconButton
                                          icon="add"
                                          color={colors.primary[900]}
                                          onClick={() =>
                                            limitArrayHelpers.insert(limitIndex + 1, emptyLimit())
                                          }
                                        />
                                      </RowButtons>
                                    ) : (
                                      <div style={INPUT_PROPERTIES} />
                                    )}
                                  </FormRow>
                                ))}
                            </>
                          )}
                        />
                      </FormSection>
                    ))}
                </>
              )}
            />
            <FormRow gap={ROW_GAP} sx={{ marginTop: '8px' }}>
              <TextInput
                formik={formik}
                name="agentContact"
                label="Agent Contact"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="agencyName"
                label="Agent Company"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="agentEmail"
                label="Agent Email"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="agentPhoneNo"
                label="Agent Phone No"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="phone"
              />
            </FormRow>
            <FormRow gap={ROW_GAP}>
              <TextInput
                formik={formik}
                name="notes"
                label="Notes"
                disabled={!editing}
                sx={{
                  width: '100%',
                }}
                size="small"
                multiline
              />
            </FormRow>
            {!editing &&
              (formik.values.documents.length > 0 || formik.values.images.length > 0) && (
                <FormUploader formik={formik} disabled entityPath={handleGetEntityPath} />
              )}
            {formButtons}
          </>
        );
      }}
    </RawForm>
  );
};

export default HomePolicyForm;

import React, { useState } from 'react';
import { makeValidatorSchema, stringValidator } from 'utils/validators';
import useSnackbar from 'hooks/shared/useSnackBar';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import FormModal from 'components/shared/modal/FormModal';
import TextInput from 'components/shared/form/TextInput';
import { createDocument } from 'services/documents';
import { getUsers } from 'services/users';
import Select from 'components/shared/form/Select';
import { PERMISSIONS } from 'domain/permissions';

const validationSchema = makeValidatorSchema({
  documentName: stringValidator({ required: 'Must enter folder name' }),
});

const CreateFolder = ({ onClose, propertyId, openFolderId }) => {
  const { showSuccess } = useSnackbar();
  const [propertyUsers, setPropertyUsers] = useState(null);

  const initializeForm = {
    documentName: '',
    permission: 'public_access',
    shareds: [],
  };

  const makeFormRequest = (values) => ({
    entry_type: 'folder',
    name: values.documentName,
    folder_id: openFolderId,
    permission: values.permission,
    shareds: values.shareds,
  });

  const handleSubmit = async (values) => {
    const request = makeFormRequest(values);
    await createDocument(propertyId, request);
    showSuccess('Folder Created');
    onClose();
  };

  useAsyncEffect(async () => {
    setPropertyUsers(await getUsers(propertyId));
  });

  if (!propertyUsers) return null;

  return (
    <FormModal
      open
      title="Create Folder"
      onClose={onClose}
      initialValues={initializeForm}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError="Error creating folder"
      submitButton={true && { label: 'Create' }}
      cancelButton
    >
      {(formik) => (
        <>
          <TextInput formik={formik} name="documentName" label="Folder Name" disabled={false} />
          <Select
            formik={formik}
            name="permission"
            label="Permission"
            options={PERMISSIONS}
            sx={{ width: '100%' }}
          />
          {formik.values.permission === 'shared_access' && (
            <Select formik={formik} name="shareds" label="Users" multiple options={propertyUsers} />
          )}
        </>
      )}
    </FormModal>
  );
};
export default CreateFolder;

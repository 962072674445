import React from 'react';
import { ThemeProvider as ThemeProviderMui, createTheme, CssBaseline } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: ['"Mulish"', 'sans-serif'].join(','),
  },
});

const ThemeProvider = ({ children }) => (
  <ThemeProviderMui theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProviderMui>
);

export default ThemeProvider;

import React from 'react';
import { makeValidatorSchema } from 'utils/validators';
import useSnackbar from 'hooks/shared/useSnackBar';
import FormModal from 'components/shared/modal/FormModal';
import TextInput from 'components/shared/form/TextInput';
import DatePicker from 'components/shared/form/DatePicker';
import FormRow from 'components/shared/form/FormRow';
import { batchUpdatePolicies } from 'services/policies';
import { getPolicyKey } from 'domain/policies';
import { fieldNumber } from 'utils/number';
import { FieldArray } from 'formik';

const ROW_GAP = '6';

const validationSchema = makeValidatorSchema({});

const LifeInsurancePolicyBatchUpdateForm = ({ currentProperty, policies, onSave, onClose }) => {
  const { showSuccess } = useSnackbar();
  const propertyId = currentProperty.id;
  const policyKey = getPolicyKey(currentProperty);

  const initializeForm = () => ({
    policies: policies.map((policy) => ({
      id: policy.id,
      insured: policy.insured,
      policy: policy.policy_number,
      premium: fieldNumber(policy.premium),
      premiumDueDate: policy.premium_due_date,
    })),
  });

  const handleSubmit = async (values) => {
    const updatedPolicies = values.policies.map((policy) => ({
      id: policy.id,
      premium: policy.premium || '0',
      premium_due_date: policy.premiumDueDate || null,
    }));
    await batchUpdatePolicies(propertyId, policyKey, updatedPolicies);
    showSuccess('Policies updated');
    onSave();
  };

  return (
    <FormModal
      open
      title="Batch update policies"
      onClose={onClose}
      initialValues={initializeForm()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError="Error batch updating policies"
      cancelButton
    >
      {(formik) => (
        <FieldArray
          name="policies"
          render={() => (
            <>
              {formik.values.policies.map((policy, index) => (
                <React.Fragment key={policy.id}>
                  <FormRow gap={ROW_GAP}>
                    <TextInput
                      formik={formik}
                      name={`policies[${index}].insured`}
                      fixedValue={policy.insured}
                      label="Insured"
                      disabled
                      sx={{ width: '100%' }}
                      size="small"
                    />
                    <TextInput
                      formik={formik}
                      name={`policies[${index}].policy`}
                      fixedValue={policy.policy}
                      label="Policy Number"
                      disabled
                      sx={{ width: '100%' }}
                      size="small"
                    />
                    <DatePicker
                      formik={formik}
                      name={`policies[${index}].premiumDueDate`}
                      fixedValue={policy.premiumDueDate}
                      label="Premium Due Date"
                      sx={{ width: '100%' }}
                      size="small"
                    />
                    <TextInput
                      formik={formik}
                      name={`policies[${index}].premium`}
                      fixedValue={policy.premium}
                      label="Premium"
                      sx={{ width: '100%' }}
                      size="small"
                      type="currency"
                      clearPrefix={false}
                    />
                  </FormRow>
                </React.Fragment>
              ))}
            </>
          )}
        />
      )}
    </FormModal>
  );
};

export default LifeInsurancePolicyBatchUpdateForm;

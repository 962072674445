import React from 'react';
import {
  styled,
  Radio,
  RadioGroup as RadioGroupMui,
  FormControlLabel as FormControlLabelMui,
} from '@mui/material';
import PropTypes from 'prop-types';
import colors from 'utils/colors';
import { valueFromObjectKeys } from 'utils/values';
import FormControl from './FormControl';
import useInputChange from './useInputChange';

const RadioButtonControl = styled(FormControl)({
  display: 'flex',
  margin: '0px',
  paddingBottom: '24px',
  paddingRight: '38px',
  '& .MuiTypography-root': {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '600',
    color: colors.main.text,
  },
  '&.Mui-disabled': {
    '& .MuiTypography-root': {
      color: colors.main.text,
    },
  },
});

const RadioControl = styled(Radio)({
  color: colors.primary[600],
  '&.Mui-checked': {
    color: colors.primary[600],
  },
  '&:hover': {
    background: 'none',
  },
  '&.Mui-disabled': {
    color: colors.main.text,
  },
});

const RadioGroupControl = styled(RadioGroupMui)({});

const FormControlLabel = styled(FormControlLabelMui)({});

const optionId = (option) =>
  typeof option === 'object' ? valueFromObjectKeys(option, ['id', 'value']) : option;

const optionName = (option) =>
  typeof option === 'object' ? valueFromObjectKeys(option, ['name', 'label']) : option;

export const RawRadioGroup = ({ name, value, options: rawOptions, onChange }) => {
  const options = (rawOptions || []).map((option) => ({
    id: optionId(option),
    name: optionName(option),
  }));

  return (
    <RadioGroupControl row aria-labelledby={name} name={name} value={value} onChange={onChange}>
      {options.map((option) => (
        <RadioButtonControl key={option.id}>
          <FormControlLabel value={option.id} control={<RadioControl />} label={option.name} />
        </RadioButtonControl>
      ))}
    </RadioGroupControl>
  );
};

const RadioGroup = (props) => {
  const { formik, name, fixedValue, onChange } = props;

  const { value, onChange: onOptimizedChange } = useInputChange({
    value: fixedValue !== null ? fixedValue : formik.values[name],
    onChange: onChange || formik.handleChange,
  });

  return (
    <RawRadioGroup
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={value}
      onChange={onOptimizedChange}
    />
  );
};

RadioGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  fixedValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
      }),
    ])
  ),
  onChange: PropTypes.func,
};

RadioGroup.defaultProps = {
  options: [],
  onChange: PropTypes.func,
  fixedValue: null,
};

export default RadioGroup;

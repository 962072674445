import React from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material';
import colors from 'utils/colors';

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    color: '#fff',
    backgroundColor: `${colors.main.error}`,
  },
}));

const NotificationCounter = ({ children, counter }) => (
  <StyledBadge badgeContent={counter}>{children}</StyledBadge>
);

export default NotificationCounter;

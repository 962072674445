import axios from 'axios';

export const makeListUrl = (path, filters = {}, page = 0) => {
  const queryString = Object.keys(filters)
    .filter((filter) => filters[filter] !== null && filters[filter] !== '')
    .reduce((params, filter) => `${params}&${filter}=${filters[filter]}`, `?page=${page}`);
  return `${path}${queryString}`;
};

export const downloadFile = async ({ fileUrl, fileName }) => {
  const response = await axios.get(fileUrl, {
    responseType: 'blob',
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

import React from 'react';
import MainLayout from 'components/main/MainLayout';
import DocumentsRoute from 'components/documents/DocumentsRoute';

const Documents = () => (
  <MainLayout>
    <DocumentsRoute />
  </MainLayout>
);

export default Documents;

import * as React from 'react';
import PropTypes from 'prop-types';
import { styled, Tabs as MuiTabs, Tab as MuiTab } from '@mui/material';
import { valueFromObjectKeys } from 'utils/values';
import colors from 'utils/colors';

const TabsContainer = styled(MuiTabs)({
  minHeight: '36px',

  '& .MuiTabs-indicator': {
    display: 'none!important',
  },
});

const TabContainer = styled(MuiTab)({
  padding: '0px 16px',
  marginRight: '16px',
  color: colors.primary[900],
  minHeight: '36px',
  fontSize: '14px',
  fontWeight: 600,
  border: `1px solid ${colors.main.controlBorder}`,
  borderRadius: '4px',

  '&.Mui-selected': {
    backgroundColor: colors.primary[900],
    borderColor: colors.primary[900],
    color: colors.main.whiteText,
  },
});

const optionId = (option) =>
  typeof option === 'object' ? valueFromObjectKeys(option, ['id', 'value']) : option;

const optionName = (option) =>
  typeof option === 'object' ? valueFromObjectKeys(option, ['name', 'label']) : option;

const optionDisabled = (option) => (typeof option === 'object' && option.disabled) || false;

const Tabs = ({ options, selectedOption, disabled, sx, onChange }) => (
  <TabsContainer
    value={selectedOption}
    disabled={disabled}
    onChange={onChange ? (_event, value) => onChange(value) : null}
    sx={sx}
  >
    {options.map((tab) => {
      const id = optionId(tab);

      return (
        <TabContainer
          key={id}
          value={id}
          label={optionName(tab)}
          disabled={disabled || optionDisabled(tab)}
        />
      );
    })}
  </TabsContainer>
);

Tabs.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  sx: PropTypes.shape(),
  onChange: PropTypes.func,
};

Tabs.defaultProps = {
  options: [],
  selectedOption: null,
  disabled: false,
  sx: null,
  onChange: null,
};

export default Tabs;

import React from 'react';
import { FieldArray } from 'formik';
import { styled } from '@mui/material';
import { pluralize } from 'utils/text';
import colors from 'utils/colors';
import { makeValidatorSchema, stringValidator } from 'utils/validators';
import FormModal from 'components/shared/modal/FormModal';
import FormRow from 'components/shared/form/FormRow';
import TextInput from 'components/shared/form/TextInput';
import DatePicker from 'components/shared/form/DatePicker';
import FormImages from 'components/shared/uploader/FormImages';
import Button from 'components/shared/button/Button';

const AddItemContainer = styled('div')({
  width: '100%',
  height: '50px',
  display: 'flex',
  margin: '0 0 15px 0',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 'bold',
  color: colors.primary[900],
  lineHeight: '24px',
});

const ItemsContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  margin: '0 0 15px 0',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: `1px solid ${colors.main.clearTableBorder}`,
});

const ButtonContainer = styled('div')({
  height: '50px',
  display: 'flex',
});

const validationSchema = makeValidatorSchema({
  account_name: stringValidator({ required: 'Must enter a name' }),
  institution_name: stringValidator(),
  account_number: stringValidator(),
  value: stringValidator(),
  value_as_of: stringValidator(),
  maturity_date: stringValidator(),
  beneficiary: stringValidator(),
  direct_deposits: stringValidator(),
  auto_bill_payments: stringValidator(),
  direct_deposit_payee: stringValidator(),
  direct_deposit_amount: stringValidator(),
});

const FinancialAccountPropertyFormModal = ({
  subcategory,
  currentProperty,
  title,
  onSubmit,
  onClose,
}) => {
  const subcategoryKey = subcategory.key;

  const initalizeSummary = () => {
    const summary = (currentProperty && currentProperty.summary) || {};
    return {
      account_name: summary.account_name || '',
      institution_name: summary.institution_name || '',
      account_number: summary.account_number || '',
      value: summary.value || '',
      value_as_of: summary.value_as_of || '',
      maturity_date: summary.maturity_date || '',
      beneficiary: summary.beneficiary || '',
      direct_deposits: summary.direct_deposits || '',
      auto_bill_payments: summary.auto_bill_payments || '',
      direct_deposit_payee: summary.direct_deposit_payee || '',
      direct_deposit_amount: summary.direct_deposit_amount || '',
    };
  };

  const initalizeAutoDeducts = () => {
    const autoDeducts = (currentProperty && currentProperty.auto_deducts) || [];
    return {
      auto_deducts: autoDeducts.map((autoDeduct) => ({
        payee: autoDeduct.payee || '',
        date: autoDeduct.date || '',
        amount: autoDeduct.amount || '',
      })),
    };
  };

  const initializeForm = () => ({
    ...(currentProperty
      ? {
          logo: currentProperty.logo ? [currentProperty.logo] : [],
        }
      : { name: '', logo: [] }),
    ...initalizeSummary(),
    ...initalizeAutoDeducts(),
  });

  const getErrorMessage = () => (currentProperty ? `Error editing item` : `Error creating item`);

  const makeFormRequest = (values) => ({
    name: `${values.account_name} - ${pluralize(subcategory.name, 1)}`,
    logo: values.logo,
    summary: {
      account_name: values.account_name,
      institution_name: values.institution_name,
      account_number: values.account_number,
      value: values.value,
      value_as_of: values.value_as_of,
      maturity_date: values.maturity_date,
      beneficiary: values.beneficiary,
      direct_deposits: values.direct_deposits,
      auto_bill_payments: values.auto_bill_payments,
      direct_deposit_payee: values.direct_deposit_payee,
      direct_deposit_amount: values.direct_deposit_amount,
    },
    auto_deducts: values.auto_deducts,
  });

  const handleSubmit = async (values) => {
    const request = makeFormRequest(values);
    return onSubmit(request);
  };

  return (
    <FormModal
      open
      title={title}
      onClose={onClose}
      initialValues={initializeForm()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError={getErrorMessage()}
      submitButton={{ label: currentProperty ? 'Save' : 'Create' }}
      cancelButton
    >
      {(formik) => (
        <>
          <FormRow>
            <TextInput
              formik={formik}
              name="account_name"
              label="Account Name"
              sx={{ width: '100%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="institution_name"
              label="Name of Institution"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="account_number"
              label="Account #"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="value"
              label="Value"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
            <DatePicker
              formik={formik}
              name="value_as_of"
              label="Value as of"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            {subcategoryKey === 'certificate_of_deposit' && (
              <DatePicker
                formik={formik}
                name="maturity_date"
                label="Maturity Date"
                sx={{ width: '50%' }}
              />
            )}
            <TextInput
              formik={formik}
              name="beneficiary"
              label="Beneficiary"
              sx={{ width: '50%' }}
            />
          </FormRow>
          {subcategoryKey === 'checking_account' && (
            <>
              <FormRow>
                <TextInput
                  formik={formik}
                  name="direct_deposits"
                  label="Direct Deposits"
                  sx={{ width: '50%' }}
                  type="currency"
                  clearPrefix={false}
                />
                <TextInput
                  formik={formik}
                  name="auto_bill_payments"
                  label="Auto Bill Payments"
                  sx={{ width: '50%' }}
                  type="currency"
                  clearPrefix={false}
                />
              </FormRow>
              <FormRow>
                <TextInput
                  formik={formik}
                  name="direct_deposit_payee"
                  label="Direct Deposit Payee"
                  sx={{ width: '50%' }}
                />
                <TextInput
                  formik={formik}
                  name="direct_deposit_amount"
                  label="Direct Deposit Amount"
                  sx={{ width: '50%' }}
                  type="currency"
                  clearPrefix={false}
                />
              </FormRow>

              <div>
                <FieldArray
                  name="auto_deducts"
                  render={(arrayHelpers) => (
                    <>
                      <AddItemContainer>
                        Auto deducts
                        <Button
                          variant="transparent"
                          startIcon="add"
                          onClick={() => {
                            arrayHelpers.push({
                              payee: '',
                              date: '',
                              amount: '',
                            });
                          }}
                        >
                          Add New
                        </Button>
                      </AddItemContainer>
                      <div>
                        {formik.values.auto_deducts && formik.values.auto_deducts.length > 0
                          ? formik.values.auto_deducts.map((item, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <ItemsContainer key={index}>
                                <FormRow>
                                  <TextInput
                                    formik={formik}
                                    fixedValue={item.payee}
                                    name={`auto_deducts[${index}].payee`}
                                    label="Payee"
                                    sx={{ width: '33%' }}
                                  />
                                  <DatePicker
                                    formik={formik}
                                    fixedValue={item.date}
                                    name={`auto_deducts[${index}].date`}
                                    label="Date"
                                    sx={{ width: '33%' }}
                                  />
                                  <TextInput
                                    formik={formik}
                                    fixedValue={item.amount}
                                    name={`auto_deducts[${index}].amount`}
                                    label="Amount"
                                    sx={{ width: '33%' }}
                                    type="currency"
                                    clearPrefix={false}
                                  />
                                  <ButtonContainer>
                                    <Button
                                      variant="transparent"
                                      startIcon="clear"
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                    />
                                  </ButtonContainer>
                                </FormRow>
                              </ItemsContainer>
                            ))
                          : null}
                      </div>
                    </>
                  )}
                />
              </div>
            </>
          )}
          <FormImages
            formik={formik}
            title={false}
            name="logo"
            addButtonLabel="Set Logo"
            singleImage
          />
        </>
      )}
    </FormModal>
  );
};
export default FinancialAccountPropertyFormModal;

import React from 'react';
import SummaryRow from './SummaryRow';

const TrustSummary = ({ property }) => {
  const summary = property.summary || {};

  return (
    <>
      <SummaryRow name="Trust Name" value={property.name} />
      <SummaryRow name="Type of Trust" value={summary.type_of_trust} />
      <SummaryRow name="Trust Creation Date" value={summary.creation_date} type="date" />
      <SummaryRow name="Trustee Name" value={summary.trustee_name} />
      <SummaryRow name="Co Trustee Name if applicable" value={summary.co_trustee_name} />
      <SummaryRow name="Beneficiaries" value={summary.beneficiaries} />
      <SummaryRow name="Trust Assets Description" value={summary.assets_description} />
      <SummaryRow name="Asset Value" value={summary.asset_value} type="currency" />
      <SummaryRow name="Location of Asset" value={summary.location_of_asset} />
      <SummaryRow name="Trust Account #" value={summary.account_number} />
      <SummaryRow name="Bank/Financial Institution" value={summary.bank_financial_institution} />
      <SummaryRow name="Account Balance" value={summary.account_balance} type="currency" />
      <SummaryRow name="TIN#" value={summary.tin_number} />
    </>
  );
};

export default TrustSummary;

import React from 'react';
import { makeValidatorSchema } from 'utils/validators';
import useSnackbar from 'hooks/shared/useSnackBar';
import FormModal from 'components/shared/modal/FormModal';
import FormFiles from 'components/shared/uploader/FormFiles';
import { uploadFormFiles } from 'services/directUpload';
import { replaceDocument } from 'services/documents';
import { getFilenameAndExtension } from 'utils/text';

const validationSchema = makeValidatorSchema({});

const ReplaceFile = ({ onClose, document }) => {
  const { showSuccess } = useSnackbar();
  const { extension } = getFilenameAndExtension(document.file.filename);

  const initializeForm = {
    documents: [],
  };

  const handleSubmit = async (values) => {
    const uploadDocument = await uploadFormFiles({
      documents: values.documents.map((newDocument) => ({
        ...newDocument,
        filename: document.file.filename,
      })),
    });
    const request = {
      file: uploadDocument.documents[0],
    };
    await replaceDocument(document.property_id, document.id, request);
    showSuccess('File Replaced');
    onClose();
  };

  return (
    <FormModal
      open
      title={`${'Replace File'}  - ${document.name}`}
      onClose={onClose}
      initialValues={initializeForm}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError="Error uploading file"
      submitButton={true && { label: 'Replace' }}
      cancelButton
    >
      {(formik) => (
        <FormFiles
          formik={formik}
          disabled={false}
          multiple={false}
          type="custom"
          extension={extension}
        />
      )}
    </FormModal>
  );
};

export default ReplaceFile;

import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { fetchCurrentProperty } from 'slices/property';
import { fetchOpenItemCounters } from 'slices/openItem';

const useOpenItems = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const list = searchParams.get('open_items');
  const critical = list === 'critical';
  const overDue = list === 'over_due';
  const propertyId = searchParams.get('open_item_property');
  const openItemId = searchParams.get('open_item');
  const currentProperty = useSelector((state) => state.property.currentProperty);

  const updateCounters = () => {
    if (currentProperty) {
      dispatch(fetchCurrentProperty(currentProperty.id));
    }
    dispatch(fetchOpenItemCounters());
  };

  const handleShowListModal = (param) => {
    searchParams.set('open_items', param);
    setSearchParams(searchParams);
  };

  const handleCloseListModal = () => {
    searchParams.delete('open_items');
    setSearchParams(searchParams);
    updateCounters();
  };

  const handleShowDetailsModal = (showPropertyId, showOpenItemId = 'new') => {
    searchParams.set('open_item_property', showPropertyId);
    searchParams.set('open_item', showOpenItemId);
    setSearchParams(searchParams);
  };

  const handleCloseDetailsModal = () => {
    searchParams.delete('open_item_property');
    searchParams.delete('open_item');
    setSearchParams(searchParams);
  };

  return {
    list,
    critical,
    overDue,
    propertyId,
    openItemId,
    onShowList: handleShowListModal,
    onCloseList: handleCloseListModal,
    onShowDetails: handleShowDetailsModal,
    onCloseDetails: handleCloseDetailsModal,
  };
};

export default useOpenItems;

import React from 'react';
import SummaryRow from './SummaryRow';

const PersonalPropertySummary = ({ property }) => {
  const summary = property.summary || {};

  return (
    <>
      <SummaryRow name="Item Name" value={property.name} />
      <SummaryRow name="Description" value={summary.description} />
      <SummaryRow name="Purchase Date" value={summary.purchase_date} type="date" />
      <SummaryRow name="Purchase Price" value={summary.purchase_price} type="currency" />
      <SummaryRow name="Current Value" value={summary.current_value} type="currency" />
      <SummaryRow name="Location of Item" value={summary.location_of_item} />
      <SummaryRow name="Beneficiary" value={summary.beneficiary} />
      <SummaryRow name="Serial/Identification #" value={summary.serial_identification} />
    </>
  );
};

export default PersonalPropertySummary;

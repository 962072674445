import { FormControl as FormControlMui, styled } from '@mui/material';
import colors from 'utils/colors';

export const SIZE_HEIGHTS = {
  medium: 24,
  small: 16,
};

export const SIZE_MARGIN_LEFT = {
  medium: 16,
  small: 12,
};

export const SIZE_LABEL_LINE_HEIGHTS = {
  medium: 24,
  small: 18,
};

export const SIZE_LABEL_MARGIN_TOPS = {
  medium: 13,
  small: 9,
};

export const SIZE_PADDINGS = {
  medium: '22px 8px 6px 16px',
  small: '14px 4px 0px 12px',
};

export const SIZE_MARGINS = {
  medium: 20,
  small: 6,
};

export const SIZE_FONTS = {
  medium: 16,
  small: 14,
};

export const SIZE_SMALL_LABEL_FONTS = {
  medium: 12,
  small: 11,
};

export const SIZE_LEFT_LABEL_INPUT_MARGIN_TOPS = {
  medium: 6, // TODO: adjust
  small: 6,
};

export const SIZE_LEFT_LABEL_LABEL_MARGIN_TOPS = {
  medium: 13, // TODO: adjust
  small: 10,
};

export const SIZE_LEFT_LABEL_FONTS = {
  medium: 15, // TODO: adjust
  small: 13,
};

export const SIZE_LEFT_LABEL_INPUT_PADDING_RIGHTS = {
  medium: 12, // TODO: adjust
  small: 6,
};

const FormControl = styled(FormControlMui)(({ size = 'medium', leftlabel }) => ({
  marginBottom: `${SIZE_MARGINS[size]}px`,

  '& .MuiInput-root': {
    margin: '0px',
    fontSize: `${SIZE_FONTS[size]}px`,
    lineHeight: `${SIZE_HEIGHTS[size]}px`,
    fontFamily: 'Mulish',
    color: colors.main.text,
    fontWeight: 'bold',
    backgroundColor: colors.main.surfaceOverlay,
    border: '1px solid transparent',
    borderRadius: '3px',

    '&.Mui-focused': {
      borderColor: colors.main.controlBorder,
    },

    '&.Mui-error': {
      backgroundColor: colors.main.errorBackground,
      borderColor: colors.main.error,
    },

    '&::before, &::after': {
      display: 'none',
    },
  },

  '& .MuiInputLabel-root': {
    fontFamily: 'Mulish',
    marginLeft: `${SIZE_MARGIN_LEFT[size]}px`,
    fontWeight: 'bold',
    transform: 'unset',
    marginTop: `${SIZE_LABEL_MARGIN_TOPS[size]}px`,
    zIndex: 1,

    '&:not(.MuiInputLabel-shrink)': {
      fontSize: `${SIZE_FONTS[size]}px`,
      lineHeight: `${SIZE_LABEL_LINE_HEIGHTS[size]}px`,
      color: colors.main.text,
    },

    '&.MuiInputLabel-shrink': {
      fontSize: `${SIZE_SMALL_LABEL_FONTS[size]}px`,
      lineHeight: '16px',
      color: colors.primary[900],
      transform: 'translate(0, -7px)',
    },

    '&.Mui-error': {
      color: colors.main.error,
    },
  },

  '&.disabled': {
    '& .MuiInput-root': {
      backgroundColor: colors.main.disabledBackground,
      border: `1px solid ${colors.main.controlBorder}`,

      '& .MuiInput-input': {
        color: colors.main.text,
        WebkitTextFillColor: 'unset',
      },
    },

    '& .MuiInputLabel-root': {
      color: colors.primary[900],
    },
  },

  '&.left-label': {
    '& .MuiInput-root': {
      justifyContent: 'flex-end',

      '& .MuiInput-input': {
        width: `calc(100% - ${leftlabel}px)`,
        marginTop: `-${SIZE_LEFT_LABEL_INPUT_MARGIN_TOPS[size]}px`,
        paddingBottom: `${SIZE_LEFT_LABEL_INPUT_MARGIN_TOPS[size]}px`,
        paddingRight: `${SIZE_LEFT_LABEL_INPUT_PADDING_RIGHTS[size]}px`,
      },
    },

    '& .MuiInputLabel-root': {
      marginTop: `${SIZE_LEFT_LABEL_LABEL_MARGIN_TOPS[size]}px`,
      fontSize: `${SIZE_LEFT_LABEL_FONTS[size]}px`,
      transform: 'unset!important',

      '&.MuiInputLabel-shrink': {
        marginTop: `${SIZE_LEFT_LABEL_LABEL_MARGIN_TOPS[size] + 1}px`,
      },
    },
  },

  '&.indicators-variant': {
    '& .MuiInput-root': {
      color: colors.tertiary[900],
    },

    '& .MuiInputLabel-root': {
      '&:not(.MuiInputLabel-shrink)': {
        color: colors.tertiary[900],
      },

      '&.MuiInputLabel-shrink': {
        color: colors.main.greyIcon,
      },

      '&.Mui-error': {
        color: colors.main.error,
      },
    },

    '&.disabled': {
      '& .MuiInput-root': {
        '& .MuiInput-input': {
          color: colors.tertiary[900],
        },
      },

      '& .MuiInputLabel-root': {
        color: colors.main.greyIcon,
      },
    },
  },
}));

export const showFieldError = ({ isValid, submitCount, errors }, name) =>
  !isValid && submitCount > 0 && !!errors[name];

export default FormControl;

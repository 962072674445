import React from 'react';
import { makeValidatorSchema, stringValidator, dateValidator } from 'utils/validators';
import FormModal from 'components/shared/modal/FormModal';
import FormRow from 'components/shared/form/FormRow';
import TextInput from 'components/shared/form/TextInput';
import DatePicker from 'components/shared/form/DatePicker';
import FormImages from 'components/shared/uploader/FormImages';

const validationSchema = makeValidatorSchema({
  name: stringValidator({ required: 'Must enter a name' }),
  institution_name: stringValidator(),
  account_number: stringValidator(),
  value: stringValidator(),
  value_as_of: stringValidator(),
  maturity_date: dateValidator(),
  beneficiary: stringValidator(),
  fund_name: stringValidator(),
  dividends: stringValidator(),
  bond_type: stringValidator(),
  account_type: stringValidator(),
});

const InvestmentAccountPropertyModal = ({
  subcategory,
  currentProperty,
  title,
  onSubmit,
  onClose,
}) => {
  const subcategoryKey = subcategory.key;

  const initalizeSummary = () => {
    const summary = (currentProperty && currentProperty.summary) || {};
    return {
      institution_name: summary.institution_name || '',
      account_number: summary.account_number || '',
      value: summary.value || '',
      value_as_of: summary.value_as_of || '',
      beneficiary: summary.beneficiary || '',
      fund_name: summary.fund_name || '',
      dividends: summary.dividends || '',
      bond_type: summary.bond_type || '',
      account_type: summary.account_type || '',
    };
  };

  const initializeForm = () => ({
    ...(currentProperty
      ? {
          name: currentProperty.name,
          logo: currentProperty.logo ? [currentProperty.logo] : [],
        }
      : { name: '', logo: [] }),
    ...initalizeSummary(),
  });

  const getErrorMessage = () => (currentProperty ? `Error editing item` : `Error creating item`);

  const makeFormRequest = (values) => ({
    name: values.name,
    logo: values.logo,
    summary: {
      institution_name: values.institution_name,
      account_number: values.account_number,
      value: values.value,
      value_as_of: values.value_as_of,
      beneficiary: values.beneficiary,
      fund_name: values.fund_name,
      dividends: values.dividends,
      bond_type: values.bond_type,
      account_type: values.account_type,
    },
  });

  const handleSubmit = async (values) => {
    const request = makeFormRequest(values);
    return onSubmit(request);
  };

  return (
    <FormModal
      open
      title={title}
      onClose={onClose}
      initialValues={initializeForm()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError={getErrorMessage()}
      submitButton={{ label: currentProperty ? 'Save' : 'Create' }}
      cancelButton
    >
      {(formik) => (
        <>
          <FormRow>
            <TextInput formik={formik} name="name" label="Account Name" sx={{ width: '100%' }} />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="institution_name"
              label="Name of Institution"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="account_number"
              label="Account #"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="value"
              label="Value"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
            <DatePicker
              formik={formik}
              name="value_as_of"
              label="Value as of"
              sx={{ width: '50%' }}
            />
          </FormRow>
          {subcategoryKey === 'retirement_account' && (
            <FormRow>
              <TextInput
                formik={formik}
                name="account_type"
                label="Account Type"
                sx={{ width: '50%' }}
                clearPrefix={false}
              />
            </FormRow>
          )}
          {subcategoryKey === 'mutal_fund' && (
            <FormRow>
              <TextInput
                formik={formik}
                name="fund_name"
                label="Fund Name"
                sx={{ width: '50%' }}
                clearPrefix={false}
              />
            </FormRow>
          )}
          {subcategoryKey === 'etf' && (
            <FormRow>
              <TextInput
                formik={formik}
                name="fund_name"
                label="Fund Name"
                sx={{ width: '50%' }}
                clearPrefix={false}
              />
              <TextInput
                formik={formik}
                name="dividends"
                label="Dividends"
                sx={{ width: '50%' }}
                type="currency"
                clearPrefix={false}
              />
            </FormRow>
          )}
          {subcategoryKey === 'bond' && (
            <FormRow>
              <TextInput
                formik={formik}
                name="bond_type"
                label="Bond Type"
                sx={{ width: '50%' }}
                clearPrefix={false}
              />
              <TextInput
                formik={formik}
                name="dividends"
                label="Dividends"
                sx={{ width: '50%' }}
                type="currency"
                clearPrefix={false}
              />
            </FormRow>
          )}
          {subcategoryKey === 'private_equity_investment' && (
            <FormRow>
              <TextInput
                formik={formik}
                name="dividends"
                label="Dividends"
                sx={{ width: '50%' }}
                type="currency"
                clearPrefix={false}
              />
            </FormRow>
          )}
          <FormRow>
            <TextInput
              formik={formik}
              name="beneficiary"
              label="Beneficiary"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormImages
            formik={formik}
            title={false}
            name="logo"
            addButtonLabel="Set Logo"
            singleImage
          />
        </>
      )}
    </FormModal>
  );
};
export default InvestmentAccountPropertyModal;

import React from 'react';
import MainLayout from 'components/main/MainLayout';
import ReceiptsList from 'components/receipts/ReceiptsList';

const Receipts = () => (
  <MainLayout>
    <ReceiptsList />
  </MainLayout>
);

export default Receipts;

import { createSlice } from '@reduxjs/toolkit';
import { getGlCodes } from 'services/receipts';

const initialState = { glCodes: [] };

const glCodeSlice = createSlice({
  name: 'glCode',
  initialState,
  reducers: {
    setGlCodes: (state, action) => ({ ...state, glCodes: action.payload }),
    clearGlCodes: (state) => ({ ...state, glCodes: null }),
  },
});

const { reducer, actions } = glCodeSlice;

export const { clearGlCodes, setGlCodes } = actions;

export const fetchGlCodes = () => async (dispatch) => {
  const glCodes = await getGlCodes();
  dispatch(setGlCodes(glCodes));
};

export default reducer;

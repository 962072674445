import { styled } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import colors from 'utils/colors';
import { formatDate, nextYear } from 'utils/date';
import { fieldNumber } from 'utils/number';
import { updateDebtLog } from 'services/debtLog';
import { clearDebtLog, fetchDebtLog, setDebtLog } from 'slices/debtLog';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import useSnackbar from 'hooks/shared/useSnackBar';
import useAbility from 'hooks/shared/useAbility';
import TextInput from 'components/shared/form/TextInput';
import FormRow from 'components/shared/form/FormRow';
import {
  makeValidatorSchema,
  positiveNumberValidator,
  dateValidator,
  stringValidator,
} from 'utils/validators';
import RawForm from 'components/shared/form/RawForm';
import DatePicker from 'components/shared/form/DatePicker';
import Button from 'components/shared/button/Button';

const NEXT_YEARS = 3;
const ROW_GAP = '6';
const LABEL_SIZE = 140;

const INPUT_PROPERTIES = {
  width: '50%',
};
const BUTTON_PROPERTIES = {
  padding: '0px',
  minWidth: 'unset',
  '& .MuiButton-startIcon': {
    marginLeft: '0px',
    marginRight: '2px',
  },
};

const ButtonsContainer = styled('div')({
  width: '50%',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',
});

const FormTitle = styled('div')({
  width: '100%',
  margin: '6px 0px',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: colors.main.greyIcon,
});

const FormDate = styled('span')({
  color: colors.main.text,
});

const validationSchema = makeValidatorSchema({
  date: dateValidator({ required: 'Must enter consolidation date' }),
  totalLoanBalance: positiveNumberValidator({ required: 'Must enter total loan balance amount' }),
  annualDebtService: positiveNumberValidator({ required: 'Must enter annual debt service amount' }),
  monthlyDebtService: positiveNumberValidator({
    required: 'Must enter monthly debt service amount',
  }),
  ...[...new Array(NEXT_YEARS)].reduce(
    (payoffs, _, index) => ({
      ...payoffs,
      [`next${index + 1}YearProjectedPayoff`]: positiveNumberValidator({
        required: 'Must enter next years projected payoff',
      }),
      [`next${index + 1}Year`]: stringValidator({
        required: 'Must enter next years projected payoff year',
      }),
    }),
    {}
  ),
});

const DebtLog = () => {
  const { showSuccess } = useSnackbar();
  const debtLog = useSelector((state) => state.debtLog.debtLog);
  const [editing, setEditing] = useState(false);
  const ability = useAbility();
  const dispatch = useDispatch();
  const canUpdate = !editing && ability.can('update', 'DebtLog');

  const initializeForm = () => ({
    date: debtLog.date,
    totalLoanBalance: fieldNumber(debtLog.total_loan_balance),
    annualDebtService: fieldNumber(debtLog.annual_debt_service),
    monthlyDebtService: fieldNumber(debtLog.monthly_debt_service),
    ...[...new Array(NEXT_YEARS)].reduce(
      (payoffs, _, index) => ({
        ...payoffs,
        [`next${index + 1}YearProjectedPayoff`]: fieldNumber(
          debtLog.projected_payoff[index] && debtLog.projected_payoff[index].payoff
        ),
        [`next${index + 1}Year`]:
          (debtLog.projected_payoff[index] && debtLog.projected_payoff[index].year) ||
          nextYear(index + 1),
      }),
      {}
    ),
  });

  const makeFormRequest = (values) => ({
    date: values.date,
    total_loan_balance: values.totalLoanBalance,
    annual_debt_service: values.annualDebtService,
    monthly_debt_service: values.monthlyDebtService,
    projected_payoff: [...new Array(NEXT_YEARS)].map((_, index) => ({
      year: values[`next${index + 1}Year`],
      payoff: values[`next${index + 1}YearProjectedPayoff`],
    })),
  });

  const handleCancel = (formik) => {
    formik.resetForm();
    setEditing(false);
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSubmit = async (values) => {
    const request = makeFormRequest(values);
    const updatedDebtLog = await updateDebtLog(request);
    showSuccess('Debt Log Updated');
    setDebtLog(updatedDebtLog);
    setEditing(false);
  };

  const makePayoffField = (formik, index) =>
    editing ? (
      <>
        <TextInput
          formik={formik}
          name={`next${index}Year`}
          label=""
          sx={{ width: '20%' }}
          size="small"
          leftLabel={1}
          textAlign="right"
          variant="indicators"
        />
        <TextInput
          formik={formik}
          name={`next${index}YearProjectedPayoff`}
          label=""
          sx={{ width: '30%' }}
          size="small"
          type="currency"
          clearPrefix={false}
          leftLabel={1}
          textAlign="right"
          variant="indicators"
        />
      </>
    ) : (
      <TextInput
        formik={formik}
        name={`next${index}YearProjectedPayoff`}
        label={`${formik.values[`next${index}Year`]} Projected Payoff:`}
        disabled
        sx={INPUT_PROPERTIES}
        size="small"
        type="currency"
        clearPrefix={false}
        leftLabel={LABEL_SIZE}
        textAlign="right"
        variant="indicators"
      />
    );

  useAsyncEffect(
    async () => {
      dispatch(fetchDebtLog());
    },
    () => dispatch(clearDebtLog())
  );

  if (!debtLog) return null;

  return (
    <RawForm
      initialValues={initializeForm()}
      validationSchema={validationSchema}
      submit={false}
      messageAlwaysVisible={false}
      onSubmit={handleSubmit}
      onDoubleClick={canUpdate ? handleEdit : null}
      sx={{
        padding: '12px',
      }}
    >
      {(formik) => (
        <>
          {editing ? (
            <FormRow gap={ROW_GAP}>
              <DatePicker
                formik={formik}
                name="date"
                label="Debt Log As of"
                sx={{ width: '50%' }}
                size="small"
                leftLabel={LABEL_SIZE}
                textAlign="right"
                variant="indicators"
              />
              <ButtonsContainer>
                <Button
                  startIcon="cancel"
                  variant="transparent"
                  iconSize={20}
                  color={colors.tertiary[900]}
                  onClick={() => {
                    handleCancel(formik);
                  }}
                  sx={BUTTON_PROPERTIES}
                >
                  Cancel
                </Button>
                <Button
                  startIcon="confirm"
                  variant="transparent"
                  iconSize={20}
                  color={colors.primary[900]}
                  onClick={() => {
                    handleSubmit(formik.values);
                  }}
                  sx={BUTTON_PROPERTIES}
                >
                  Confirm
                </Button>
              </ButtonsContainer>
            </FormRow>
          ) : (
            <FormTitle>
              Debt Log As of <FormDate>{formatDate(formik.values.date)}</FormDate>
            </FormTitle>
          )}
          <FormRow gap={ROW_GAP}>
            <TextInput
              formik={formik}
              name="totalLoanBalance"
              label="Total Loan Balance:"
              disabled={!editing}
              sx={INPUT_PROPERTIES}
              size="small"
              type="currency"
              clearPrefix={false}
              leftLabel={LABEL_SIZE}
              textAlign="right"
              variant="indicators"
            />
            {makePayoffField(formik, 1)}
          </FormRow>
          <FormRow gap={ROW_GAP}>
            <TextInput
              formik={formik}
              name="annualDebtService"
              label="Annual Debt Service:"
              disabled={!editing}
              sx={INPUT_PROPERTIES}
              size="small"
              type="currency"
              clearPrefix={false}
              leftLabel={LABEL_SIZE}
              textAlign="right"
              variant="indicators"
            />
            {makePayoffField(formik, 2)}
          </FormRow>
          <FormRow gap={ROW_GAP}>
            <TextInput
              formik={formik}
              name="monthlyDebtService"
              label="Monthly Debt Service:"
              disabled={!editing}
              sx={INPUT_PROPERTIES}
              size="small"
              type="currency"
              clearPrefix={false}
              leftLabel={LABEL_SIZE}
              textAlign="right"
              variant="indicators"
            />
            {makePayoffField(formik, 3)}
          </FormRow>
        </>
      )}
    </RawForm>
  );
};

export default DebtLog;

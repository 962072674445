import React from 'react';
import { styled } from '@mui/material';
import colors from 'utils/colors';

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const Title = styled('div')({
  color: colors.primary[900],
  fontWeight: '700',
  marginTop: '12px',
});

const Items = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const SummaryFields = ({ title, children }) => (
  <Container>
    <Title>{title}:</Title>
    <Items>{children}</Items>
  </Container>
);

export default SummaryFields;

import { isPropertyAdmin } from 'domain/roles';

const defineRulesForOpenItems = (can, user) => {
  can('create', 'OpenItem');
  can('delete', 'OpenItem', { status: 'draft' });
  if (isPropertyAdmin(user)) {
    can('update', 'OpenItem');
    can('delete', 'OpenItem');
    can('permanentDelete', 'OpenItem');
    can('setRequestedBy', 'OpenItem');
    // can('view', 'OpenItemReports'); uncomment to show reports
  } else {
    can('update', 'OpenItem', { status: 'draft', created_by_user_id: user.id_s });
    can('update', 'OpenItem', { requested_by_user_id: user.id_s });
    can('contribute', 'OpenItem', { assigned_to_user_id: user.id_s });
  }
};

export default defineRulesForOpenItems;

import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem as MenuItemMui, styled } from '@mui/material';
import colors from 'utils/colors';
import FormControl from '../form/FormControl';

const SelectControl = styled(FormControl)({
  margin: '0px',
  width: '60px',
  height: '36px',

  '& .MuiInput-root': {
    backgroundColor: colors.main.surface,
    borderRadius: '4px',
  },

  '& .MuiSelect-select': {
    alignItems: 'center',
    padding: '7px 4px 5px 6px!important',
    backgroundColor: colors.main.surface,
  },

  '& .MuiSelect-icon': {
    right: '2px',
    color: colors.primary[900],
  },
});

const MenuItem = styled(MenuItemMui)({
  '&:hover': {
    backgroundColor: colors.main.menuHover,
  },
  '&.Mui-selected': {
    backgroundColor: 'unset',
    fontWeight: 'bold',
    textDecoration: 'underline',

    '&:hover': {
      backgroundColor: colors.main.menuHover,
    },
  },
});

const Color = styled('div')({
  width: '20px',
  height: '20px',
  borderRadius: '100%',
});

const ColorPicker = ({ value, disabled, options, onChange }) => (
  <SelectControl variant="standard">
    <Select
      value={value}
      disabled={disabled}
      onChange={!disabled ? (event) => onChange(event.target.value) : null}
      renderValue={(color) => (
        <Color
          sx={{
            backgroundColor: options.find((option) => option.id === color).color,
            border: color === 'white' ? `1px solid ${colors.main.controlBorder}` : null,
          }}
        />
      )}
    >
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          <Color
            sx={{
              backgroundColor: option.color,
              border: option.id === 'white' ? `1px solid ${colors.main.controlBorder}` : null,
            }}
          />
        </MenuItem>
      ))}
    </Select>
  </SelectControl>
);

ColorPicker.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string]),
      color: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};

ColorPicker.defaultProps = {
  options: [],
  disabled: false,
  onChange: null,
};

export default ColorPicker;

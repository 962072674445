import React from 'react';
import { makeValidatorSchema, stringValidator } from 'utils/validators';
import FormModal from 'components/shared/modal/FormModal';
import FormRow from 'components/shared/form/FormRow';
import TextInput from 'components/shared/form/TextInput';
import FormImages from 'components/shared/uploader/FormImages';

const validationSchema = makeValidatorSchema({
  name: stringValidator({ required: 'Must enter a name' }),
  type_of_business: stringValidator(),
  industry: stringValidator(),
  business_addresss: stringValidator(),
  ownership_details: stringValidator(),
});

const BusinessInterestPropertyModal = ({ currentProperty, title, onSubmit, onClose }) => {
  const initalizeSummary = () => {
    const summary = (currentProperty && currentProperty.summary) || {};
    return {
      type_of_business: summary.type_of_business || '',
      industry: summary.industry || '',
      business_addresss: summary.business_addresss || '',
      ownership_details: summary.ownership_details || '',
    };
  };

  const initializeForm = () => ({
    ...(currentProperty
      ? {
          name: currentProperty.name,
          logo: currentProperty.logo ? [currentProperty.logo] : [],
        }
      : { name: '', logo: [] }),
    ...initalizeSummary(),
  });

  const getErrorMessage = () => (currentProperty ? `Error editing item` : `Error creating item`);

  const makeFormRequest = (values) => ({
    name: values.name,
    logo: values.logo,
    summary: {
      type_of_business: values.type_of_business,
      industry: values.industry,
      business_addresss: values.business_addresss,
      ownership_details: values.ownership_details,
    },
  });

  const handleSubmit = async (values) => {
    const request = makeFormRequest(values);
    return onSubmit(request);
  };

  return (
    <FormModal
      open
      title={title}
      onClose={onClose}
      initialValues={initializeForm()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError={getErrorMessage()}
      submitButton={{ label: currentProperty ? 'Save' : 'Create' }}
      cancelButton
    >
      {(formik) => (
        <>
          <FormRow>
            <TextInput formik={formik} name="name" label="Business Name" sx={{ width: '100%' }} />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="type_of_business"
              label="Type of Businesss"
              sx={{ width: '50%' }}
            />
            <TextInput formik={formik} name="industry" label="Industry" sx={{ width: '50%' }} />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="business_addresss"
              label="Business Address"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="ownership_details"
              label="Ownership Details"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormImages
            formik={formik}
            title={false}
            name="logo"
            addButtonLabel="Set Logo"
            singleImage
          />
        </>
      )}
    </FormModal>
  );
};
export default BusinessInterestPropertyModal;

import React, { useEffect } from 'react';
import MainContainer from 'components/shared/main/MainContainer';
import useAbility from 'hooks/shared/useAbility';
import Button from 'components/shared/button/Button';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPaymentMethods } from 'slices/paymentMethod';
import { fetchVendors } from 'slices/vendor';
import { fetchGlCodes } from 'slices/glCode';
import { fetchAllProperties, fetchPropertyUsers } from 'slices/property';
import { fetchAllUsers } from 'slices/user';
import useReceipts from 'hooks/components/useReceipts';
import ReceiptsTable from './ReceiptsTable';
import ExportReceipt from './form/ExportReceipt';
import AddReceipt from './form/AddReceipt';
import UpdateReceipt from './form/UpdateReceipt';

const ReceiptsList = () => {
  const dispatch = useDispatch();
  const ability = useAbility();
  const {
    propertyId: selectedReceiptProperty,
    receiptId: selectedReceipt,
    onShowDetails,
    onCloseDetails,
  } = useReceipts();
  const paymentMethods = useSelector((state) => state.paymentMethod.paymentMethods);
  const glCodes = useSelector((state) => state.glCode.glCodes);
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const propertyUsers = useSelector((state) => state.property.users);
  const globalUsers = useSelector((state) => state.user);
  const users = currentProperty ? propertyUsers : globalUsers;
  const allProperties = useSelector((state) => state.property.allProperties);

  useEffect(() => {
    if (paymentMethods.length === 0) {
      dispatch(fetchPaymentMethods());
    }
    if (glCodes.length === 0) {
      dispatch(fetchGlCodes());
    }
    if (!users && currentProperty) {
      dispatch(fetchPropertyUsers(currentProperty.id));
    } else {
      dispatch(fetchAllUsers());
    }
    dispatch(fetchVendors());
    if (allProperties) return;
    dispatch(fetchAllProperties());
  }, []);

  const getModal = () => {
    switch (selectedReceipt) {
      case 'export':
        return <ExportReceipt propertyId={selectedReceiptProperty} onClose={onCloseDetails} />;
      case 'new':
        return <AddReceipt propertyId={selectedReceiptProperty} onClose={onCloseDetails} />;
      default:
        return (
          <UpdateReceipt
            propertyId={selectedReceiptProperty}
            receiptId={selectedReceipt}
            onClose={onCloseDetails}
          />
        );
    }
  };

  if (!currentProperty && !allProperties) return null;

  return (
    <MainContainer
      title="Receipts"
      buttons={
        <>
          {!!currentProperty && ability.can('create', 'Receipt') && (
            <Button
              variant="clear"
              startIcon="add"
              onClick={() => onShowDetails(currentProperty.id, 'new')}
              sx={{ marginRight: '120px' }}
            >
              CREATE
            </Button>
          )}
          {/* {ability.cam('export', 'Receipt') ? && ( 
            <Button variant="clear" startIcon="download" onClick={() => window.print()}>
               EXPORT
             </Button>) 
             } */}
        </>
      }
    >
      <ReceiptsTable />
      {!!selectedReceipt && getModal()}
    </MainContainer>
  );
};

export default ReceiptsList;

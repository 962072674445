import React, { useEffect } from 'react';
import { styled } from '@mui/material';
import colors from 'utils/colors';
import ChatBody from 'components/shared/chat/ChatBody';
import Button from 'components/shared/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentPrivateChat, clearCurrentPrivateChat } from 'slices/privateChat';
import { setCurrentChat, clearCurrentChat } from 'slices/chat';
import { fetchPropertyUsers } from 'slices/property';
import { getUserFullNameById } from 'domain/user';
import TransitionContainer from 'components/shared/transition/TransitionContainer';

const ChatContainer = styled('div')({
  position: 'absolute',
  bottom: '0',
  marginLeft: '300px',
  width: 'calc((100% - 299px) /2)',
  height: '100%',
  backgroundColor: '#fff',
  border: `1px solid ${colors.main.controlBorder}`,
  zIndex: '4',
});

const ChatHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  backgroundColor: colors.primary[50],
  padding: '8px 0px 8px 24px',
  fontSize: '16px',
  lineHeight: '24px',

  '& .MuiButton-startIcon': {
    margin: '0px',
  },
});

const ChatModal = ({ propertyId, privateChatId, onClose }) => {
  const dispatch = useDispatch();
  const currentPrivateChat = useSelector((state) => state.privateChat.currentPrivateChat);
  const currentChat = useSelector((state) => state.chat.currentChat);
  const propertyUsers = useSelector((state) => state.property.users);
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchPropertyUsers(propertyId));
    dispatch(fetchCurrentPrivateChat(propertyId, privateChatId));

    return () => {
      dispatch(clearCurrentPrivateChat());
      dispatch(clearCurrentChat());
    };
  }, [privateChatId]);

  useEffect(() => {
    if (currentPrivateChat && currentPrivateChat.chat) {
      dispatch(setCurrentChat(currentPrivateChat.chat));
    }
  }, [currentPrivateChat]);

  const getChatTitle = () =>
    currentPrivateChat.members
      .filter((member) => member !== currentUser.id)
      .map((member) => getUserFullNameById(propertyUsers, member))
      .join(', ');

  if (
    (currentPrivateChat && currentPrivateChat?.id !== privateChatId) ||
    !currentChat ||
    (currentChat && currentPrivateChat && currentChat.id !== currentPrivateChat.chat.id) ||
    !propertyUsers
  )
    return null;

  return (
    <TransitionContainer show>
      <ChatContainer>
        <ChatHeader>
          {getChatTitle()}
          <Button startIcon="clear" onClick={onClose} />
        </ChatHeader>
        <ChatBody bottomWindow />
      </ChatContainer>
    </TransitionContainer>
  );
};

export default ChatModal;

// eslint-disable-next-line import/prefer-default-export
export const PERMISSIONS = [
  { id: 'public_access', name: 'Public Access', shortName: 'Public', icon: 'public' },
  { id: 'private_access', name: 'Private Access', shortName: 'Private', icon: 'private' },
  { id: 'shared_access', name: 'Shared Access', shortName: 'Shared', icon: 'shared' },
];

export const getPermission = (permissionId) => PERMISSIONS.find(({ id }) => id === permissionId);

export const getPermissionLabel = (permissionId) => {
  const permission = getPermission(permissionId);
  return permission ? permission.shortName : '';
};

export const getPermissionIcon = (permissionId) => {
  const permission = getPermission(permissionId);
  return permission ? permission.icon : '';
};

export const getPermissionTooltip = (permissionId, shareds, users) => {
  switch (permissionId) {
    case 'public_access':
      return 'Public Access';
    case 'private_access':
      return 'Private Access';
    case 'shared_access':
      return `Shared with ${users
        .filter((user) => shareds.includes(user.id))
        .map((user) => user.name)
        .join(', ')}`;
    default:
      return '';
  }
};

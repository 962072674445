import React, { useState, useEffect } from 'react';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Link } from 'react-router-dom';
import useSnackbar from 'hooks/shared/useSnackBar';
import { fetchPropertyUsers, fetchAllProperties } from 'slices/property';
import { fetchAllUsers } from 'slices/user';
import { useDispatch, useSelector } from 'react-redux';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import { renderDateCell, renderActionsCell } from 'components/shared/table/Cell';
import {
  deleteDocument,
  getFolderDocuments,
  getRootDocuments,
  getDocument,
  moveDocument,
} from 'services/documents';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import { formatBytes } from 'utils/number';
import Button from 'components/shared/button/Button';
import { downloadProps } from 'components/shared/uploader/uploaderUtils';
import useAbility from 'hooks/shared/useAbility';
import Table from 'components/shared/table/Table';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import colors from 'utils/colors';
import { getUserFullNameById } from 'domain/user';
import { styled } from '@mui/material';
import { setSubject } from 'utils/ability';
import { getPermissionIcon, getPermissionTooltip } from 'domain/permissions';
import IconButton from 'components/shared/button/IconButton';
import { ReactComponent as EmptyFolderSvg } from 'assets/svg/empty_folder.svg';
import RenameDocument from './form/RenameDocument';
import ReplaceFile from './form/ReplaceFile';
import ManagePermissions from './form/ManagePermissions';

const SelectedDocumentsContainer = styled('div')({
  position: 'fixed',
  bottom: 'calc(0px - 32px)',
  right: 0,
  width: 'calc(100% - 300px)',
  height: '48px',
  display: 'flex',
  justifyContent: 'space-between',
  margin: '16px 0px 32px 0px',
  backgroundColor: '#000',
});

const SelectedDocumentsCounter = styled('div')({
  color: '#fff',
  width: '100%',
  padding: '14px 0px 14px 16px',
});

const ButtonsContainer = styled('div')({
  display: 'flex',
  right: 0,
  marginRight: '2px',
});

const NameProps = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  margin: '0px',
  cursor: 'pointer',
  fontWeight: '600',
  textDecoration: 'none',
};

const FolderName = styled(Link)({
  ...NameProps,
  color: colors.primary[900],
});

const DocumentName = styled('span')({
  ...NameProps,
  color: colors.primary[900],
});

const NameLabel = styled('span')({
  marginLeft: '12px',
});

const DocumentsTable = ({
  globalView,
  propertyId,
  folderId,
  currentFolder,
  refreshTable,
  onRefreshTable,
}) => {
  const ability = useAbility();
  const dispatch = useDispatch();
  const propertyUsers = useSelector((state) => state.property.users);
  const allProperties = useSelector((state) => state.property.allProperties);
  const { showSuccess, showError } = useSnackbar();
  const [documents, setDocuments] = useState(null);
  const currentFolderId = folderId || null;
  const globalUsers = useSelector((state) => state.user);
  const users = globalView ? globalUsers : propertyUsers;
  const [deletingFiles, setDeletingFiles] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [documentsModal, setDocumentsModal] = useState(null);
  const canDeleteBatch = selectedRows.every((row) =>
    ability.can('delete', setSubject('Documents', row))
  );

  const getDocuments = async () => {
    const docs =
      currentFolderId === null
        ? await getRootDocuments(propertyId)
        : await getFolderDocuments(propertyId, currentFolderId);
    setDocuments(docs);
  };

  const handleCancelDelete = () => {
    setDeletingFiles(null);
  };

  const handleAskDeletion = (files) => {
    setDeletingFiles(files);
  };

  const handleDelete = async () => {
    try {
      await Promise.all(
        deletingFiles.map(async (selected) => {
          await deleteDocument(selected.property_id, selected.id);
        })
      );
      showSuccess('Document Deleted');
    } catch {
      showError('Error deleting document');
    }
    setDeletingFiles(null);
  };

  const handleCanMoveFrom = (document) =>
    document.id !== 'back' && ability.can('move', setSubject('Documents', document));

  const handleCanMoveTo = (document) => document.id === 'back' || document.entry_type === 'folder';

  const handleMove = async (documentsToMove, destination) => {
    const destinationId = destination.id === 'back' ? currentFolder.folder_id : destination.id;
    try {
      await Promise.all(
        documentsToMove.map(async (selected) => {
          await moveDocument(selected.property_id, selected.id, { folder_id: destinationId });
        })
      );
      showSuccess('Document Moved');
      await getDocuments();
    } catch {
      showError('Error moving document');
    }
  };

  const handleDownload = async (document) => {
    const freshDocument = await getDocument(document.property_id, document.id);
    window.open(downloadProps(freshDocument.file).href);
  };

  const handleOpen = async (document) => {
    const freshDocument = await getDocument(document.property_id, document.id);
    window.open(freshDocument.file.url, '_blank');
  };

  useAsyncEffect(
    async () => {
      if (!refreshTable) return;

      await getDocuments();
      onRefreshTable();
    },
    undefined,
    [refreshTable]
  );

  useAsyncEffect(getDocuments, undefined, [currentFolderId, deletingFiles]);

  useEffect(() => {
    if (globalView) {
      dispatch(fetchAllUsers());
    } else {
      dispatch(fetchPropertyUsers(propertyId));
    }
  }, []);

  useEffect(() => {
    if (!globalView || allProperties) return;
    dispatch(fetchAllProperties());
  }, []);

  if (!users || (globalView && !allProperties)) return null;

  const onClose = async () => {
    setDocumentsModal(null);
    getDocuments();
  };

  const hasDownload = documents && documents.some((document) => document.entry_type !== 'folder');
  const canMoveSome =
    documents && documents.some((row) => ability.can('move', setSubject('Documents', row)));
  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 3,
      renderCell: ({ row }) => {
        if (row.id === 'back')
          return (
            <FolderName
              to={
                globalView
                  ? `/documents/${
                      currentFolder?.folder_id
                        ? `${currentFolder.property_id}_${currentFolder.folder_id}`
                        : ''
                    }`
                  : `/properties/${propertyId}/documents/${currentFolder?.folder_id || ''}`
              }
            >
              <FolderOutlinedIcon sx={{ color: colors.primary[600] }} />
              <NameLabel>...</NameLabel>
            </FolderName>
          );

        return row.entry_type === 'folder' ? (
          <FolderName
            to={
              globalView
                ? `/documents/${row.property_id}_${row.id}`
                : `/properties/${row.property_id}/documents/${row.id}`
            }
            draggable="false"
          >
            <FolderOutlinedIcon sx={{ color: colors.primary[600] }} />
            <NameLabel>{row.name}/</NameLabel>
          </FolderName>
        ) : (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <DocumentName onClick={() => handleOpen(row)}>
            <InsertDriveFileOutlinedIcon sx={{ color: colors.secondary[600] }} />
            <NameLabel>{row.name}</NameLabel>
          </DocumentName>
        );
      },
    },
    globalView && {
      headerName: 'Portfolio',
      field: 'property_name',
      flex: 1.5,
      valueGetter: ({ row }) =>
        (row.id !== 'back' && allProperties.find(({ id }) => id === row.property_id)?.name) || '',
    },
    {
      headerName: 'Created By',
      field: 'user_id',
      flex: 1.5,
      valueGetter: ({ row: { id, user_id: userId } }) =>
        id !== 'back' ? getUserFullNameById(users, userId) : '',
    },
    {
      headerName: 'Modified By',
      field: 'last_change_user_id',
      flex: 1.5,
      valueGetter: ({ row: { id, user_id: userId, last_change_user_id: lastChangeUserId } }) =>
        id !== 'back' ? getUserFullNameById(users, lastChangeUserId || userId) : '',
    },
    {
      headerName: 'Modified',
      field: 'last_change_at',
      renderCell: renderDateCell,
    },
    {
      headerName: 'Size',
      field: 'size',
      flex: 1,
      valueGetter: ({ row }) => {
        if (row.id === 'back') return '';

        return row.entry_type === 'folder'
          ? formatBytes(row.entries_size)
          : formatBytes(row.file.byte_size);
      },
    },
    {
      headerName: 'Permission',
      field: 'permission',
      flex: 1,
      align: 'center',
      renderCell: ({ row }) =>
        row.id !== 'back' ? (
          <IconButton
            icon={getPermissionIcon(row.permission)}
            tooltip={getPermissionTooltip(row.permission, row.shareds, users)}
            sx={{ marginRight: '12px' }}
          />
        ) : (
          ''
        ),
    },
    {
      headerName: 'Actions',
      field: 'actions',
      disableExport: true,
      flex: 1,
      renderCell: ({ row }) => {
        if (row.id === 'back') return '';

        const canEdit = ability.can('update', setSubject('Documents', row));
        const canDelete = ability.can('delete', setSubject('Documents', row));

        const canManagePermissions = ability.can(
          'manage_permissions',
          setSubject('Documents', row)
        );

        return renderActionsCell({
          id: row.id,
          icons: [
            row.entry_type !== 'folder' && {
              tooltip: 'Download',
              type: 'download',
              onClick: () => handleDownload(row),
            },
          ].filter((column) => column),
          iconsNumber: hasDownload ? 1 : 0,
          menuIcons: [
            canDelete && {
              tooltip: 'Rename',
              type: canEdit ? 'edit' : 'view',
              onClick: () => {
                setSelectedDocument(row);
                setDocumentsModal('rename_document');
              },
            },
            row.entry_type !== 'folder' &&
              canEdit && {
                tooltip: 'Replace',
                type: 'replace',
                onClick: () => {
                  setSelectedDocument(row);
                  setDocumentsModal('replace_file');
                },
              },
            canManagePermissions && {
              tooltip: 'Manage Permissions',
              type: 'permissions',
              onClick: () => {
                setSelectedDocument(row);
                setDocumentsModal('manage_permissions');
              },
            },
            canDelete && {
              tooltip: 'Delete',
              type: 'delete',
              onClick: () => {
                handleAskDeletion([row]);
              },
            },
          ].filter((icon) => icon),
          color: colors.primary[900],
        });
      },
    },
  ].filter((column) => column);

  const getModal = () => {
    switch (documentsModal) {
      case 'rename_document':
        return (
          <RenameDocument
            openFolderId={folderId}
            document={selectedDocument}
            onClose={() => onClose()}
          />
        );
      case 'replace_file':
        return (
          <ReplaceFile
            openFolderId={currentFolderId}
            document={selectedDocument}
            onClose={() => onClose()}
          />
        );
      case 'manage_permissions':
        return (
          <ManagePermissions
            openFolderId={currentFolderId}
            document={selectedDocument}
            onClose={() => onClose()}
          />
        );
      default:
        return null;
    }
  };

  if (!documents) return null;

  return (
    <>
      <Table
        rows={documents.filter((document) => document)}
        columns={columns}
        pageSize={50}
        checkboxSelection
        onSelectionModelChange={(ids) =>
          setSelectedRows(documents.filter((document) => ids.includes(document.id)))
        }
        NoRowsIcon={EmptyFolderSvg}
        noRowsTitle="No content found on this folder"
        draggable={canMoveSome}
        canDragRow={handleCanMoveFrom}
        canDropRow={handleCanMoveTo}
        onDropRow={(source, target) => handleMove([source], target)}
      />
      {deletingFiles && (
        <ConfirmationModal
          title="Documents will be deleted"
          description="Are you sure?"
          onCancel={handleCancelDelete}
          onConfirm={handleDelete}
        />
      )}
      {selectedRows.length > 0 && (
        <SelectedDocumentsContainer>
          <SelectedDocumentsCounter> {selectedRows.length} Selected</SelectedDocumentsCounter>
          <ButtonsContainer>
            {canDeleteBatch && (
              <Button
                variant="transparent"
                sx={{ color: colors.primary[200] }}
                onClick={() => handleAskDeletion(selectedRows)}
              >
                DELETE
              </Button>
            )}
          </ButtonsContainer>
        </SelectedDocumentsContainer>
      )}
      {!!documentsModal && getModal()}
    </>
  );
};

export default DocumentsTable;

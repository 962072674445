import React from 'react';
import { useSelector } from 'react-redux';
import useProperties from 'hooks/components/useProperties';
import { pluralize } from 'utils/text';
import PropertyFormModal from './PropertyFormModal';

const NewPropertyModal = ({ onClose }) => {
  const categories = useSelector((state) => state.category.categories);
  const { newPropertyCategoryId, newPropertySubcategoryId } = useProperties();
  if (!categories) return null;

  const selectedCategory = categories.find((category) => category.id === newPropertyCategoryId);
  const selectedSubcategory = selectedCategory.subcategories.find(
    (subcategory) => subcategory.id === newPropertySubcategoryId
  );
  if (!selectedSubcategory) return null;

  const title = `New ${pluralize(selectedSubcategory.name, 1)}`;
  return (
    <PropertyFormModal
      category={selectedCategory}
      subcategory={selectedSubcategory}
      title={title}
      onClose={onClose}
    />
  );
};
export default NewPropertyModal;

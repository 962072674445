import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import linkifyHtml from 'linkify-html';

const Control = styled('div')({
  width: '100%',
  overflow: 'auto',
});

const REGEXS = [
  { from: /\n/g, to: '<br />' },
  { from: /\*([^*]+?)\*/g, to: '<b>$1</b>' },
  { from: /~([^~]+?)~/g, to: '<s>$1</s>' },
  { from: /_([^_]+?)_/g, to: '<i>$1</i>' },
];

const makeText = (text) => REGEXS.reduce((result, { from, to }) => result.replace(from, to), text);

const CustomizableArea = ({ text, lineHeight, maxLines }) => (
  <Control
    sx={{
      minHeight: lineHeight,
      maxHeight: `calc(${lineHeight} * ${maxLines})`,
    }}
    dangerouslySetInnerHTML={{ __html: linkifyHtml(makeText(text)) }}
  />
);

CustomizableArea.propTypes = {
  text: PropTypes.string,
  lineHeight: PropTypes.string,
  maxLines: PropTypes.number,
};

CustomizableArea.defaultProps = {
  text: '',
  lineHeight: '24px',
  maxLines: 4,
};

export default CustomizableArea;

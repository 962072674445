import React from 'react';
import SideBarContainer from './shared/SideBarContainer';
import SideBarOpenItems from './shared/SideBarOpenItems';
import GlobalMenu from './global/GlobalMenu';

const GlobalSideBar = ({ children }) => (
  <SideBarContainer>
    <SideBarOpenItems />
    <GlobalMenu />
    {children}
  </SideBarContainer>
);

export default GlobalSideBar;

import { createSlice } from '@reduxjs/toolkit';
import { getCurrentUser, setUserSettings } from 'services/users';
import { getStoredSession } from 'services/auth';

const userData = getStoredSession();

const initialState = userData
  ? {
      isLoggedIn: true,
      user: { ...userData.data.data, id_s: userData.data.data.id.toString() },
      headers: userData.headers,
    }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => ({
      ...state,
      user: { ...state.user, ...action.payload, fetched: true },
    }),
    setUserSettings: (state, action) => ({
      ...state,
      user: { ...state.user, settings: action.payload },
    }),
  },
});

const { reducer, actions } = authSlice;
export const { setUser } = actions;
export const fetchCurrentUser = () => async (dispatch) => {
  const user = await getCurrentUser();
  dispatch(setUser(user));
};
export const savetUserSettngs = (settings) => async (dispatch) => {
  await setUserSettings(settings);
  dispatch(actions.setUserSettings(settings));
};
export default reducer;

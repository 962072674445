import React, { useState, useRef } from 'react';
import { styled } from '@mui/material';
import colors from 'utils/colors';
import { fieldNumber } from 'utils/number';
import EditableField from 'components/shared/editable/EditableField';
import IconButton from 'components/shared/button/IconButton';

const Container = styled('div')({
  height: '25px',
  display: 'flex',
});

const Name = styled('div')({
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: colors.main.text,
});

const Value = styled('div')({
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: colors.main.text,
});

const ButtonsContainer = styled('div')({
  marginLeft: '8px',
  display: 'flex',
  gap: '8px',
});

const HeaderIndicator = ({ indicator: { id, name, total: originalTotal }, canEdit, onUpdate }) => {
  const [total, setTotal] = useState(fieldNumber(originalTotal));
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();

  const handleEdit = () => {
    setTotal(fieldNumber(originalTotal, true));
    setEditing(true);
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  };

  const handleCancel = () => {
    setTotal(fieldNumber(originalTotal));
    setEditing(false);
  };

  const handleUpdate = async () => {
    if (await onUpdate(id, { total })) {
      setTotal(fieldNumber(total));
      setEditing(false);
    }
  };

  return (
    <Container
      onDoubleClick={canEdit && !editing ? handleEdit : null}
      sx={canEdit && !editing ? { cursor: 'pointer' } : null}
    >
      <Name>{name}</Name>
      <Value sx={{ width: 11.7 * String(total || 0).length }}>
        <EditableField
          type="currency"
          value={total}
          disabled={!editing}
          onChange={(value) => setTotal(value)}
          inputRef={inputRef}
          clearPrefix={false}
          sx={{ '& > input': { textAlign: 'right' } }}
        />
      </Value>
      {canEdit && !!editing && (
        <ButtonsContainer>
          <IconButton
            icon="cancel"
            color={colors.tertiary[900]}
            onClick={handleCancel}
            iconSize={20}
          />
          <IconButton
            icon="confirm"
            color={colors.primary[900]}
            onClick={handleUpdate}
            iconSize={20}
          />
        </ButtonsContainer>
      )}
    </Container>
  );
};

export default HeaderIndicator;

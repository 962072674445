import React, { useState } from 'react';
import { styled, Menu as MenuMui, MenuItem, Button } from '@mui/material';
import colors from 'utils/colors';
import { formatDate, DATE_FORMAT } from 'utils/date';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ViewIcon from '@mui/icons-material/Search';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import UploadIcon from '@mui/icons-material/Upload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { RawCheckbox } from '../form/Checkbox';

export const Cell = styled('p')({
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const Menu = styled(MenuMui)({
  '& .MuiPaper-root': {
    borderRadius: '0px',
    width: '230px',
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    backgroundColor: colors.main.surface,
  },
  '& .MuiList-root': {
    padding: '8px 0px',
  },
  '& .MuiMenuItem-root': {
    padding: '0px',
    '&:hover': {
      backgroundColor: colors.main.surfaceOverlay,
    },
  },
  '& .MuiButton-root': {
    width: '100%',
    justifyContent: 'space-between',
    padding: '12px 16px',
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.main.text,
    textTransform: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
    '& .MuiButton-endIcon': {
      color: colors.main.iconColor,
    },
  },
});

export const actions = {
  edit: <ModeEditOutlinedIcon />,
  view: <ViewIcon />,
  mail: <ForwardToInboxOutlinedIcon />,
  delete: <DeleteOutlinedIcon />,
  download: <FileDownloadOutlinedIcon />,
  move: <DriveFileMoveOutlinedIcon />,
  permissions: <AdminPanelSettingsIcon />,
  replace: <UploadIcon />,
  more: <MoreVertIcon />,
};

const getIcon = (icon) => actions[icon];

export const TitleCell = styled(Cell)({
  color: colors.tertiary[900],
});

export const PillCell = styled('div')({
  width: '55px',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '10px',
  fontFamily: 'Mulish',
  lineHeight: '16px',
  fontWeight: '600',
  textTransform: 'capitalize',
  border: '1px solid',
  borderRadius: '4px',
});

export const ActionCell = styled('div')((props) => ({
  display: 'flex',
  color: `${props.color}`,
  cursor: 'pointer',

  '&:not(:first-of-type)': {
    marginLeft: '12px',
  },
}));

export const LinkCell = styled('a')({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textDecoration: 'none',
  color: colors.main.text,
});

export const renderTitleCell = ({ value }) => <TitleCell>{value}</TitleCell>;

export const renderLongCell = ({ value, tooltip }) => (
  <Tooltip tooltip={tooltip || value}>
    <Cell>{value}</Cell>
  </Tooltip>
);

export const renderDateCell = ({ value }) => <Cell>{formatDate(value)}</Cell>;

export const renderLinkCell = (link, text) => <LinkCell href={link}>{text}</LinkCell>;

export const renderMailCell = ({ value }) =>
  value ? renderLinkCell(`mailto:${value}`, value) : <Cell>{value}</Cell>;

export const renderPhoneCell = ({ value }) =>
  value ? renderLinkCell(`tel:+${value}`, value) : <Cell>{value}</Cell>;

export const renderDateTimeCell = ({ value }) => (
  <Cell>{formatDate(value, DATE_FORMAT.DATE_TIME)}</Cell>
);

export const renderBooleanCell = ({ value }) => <Cell>{value ? 'Yes' : 'No'}</Cell>;

export const renderPillCell = ({ value, colors: { background, color, border } }) => (
  <PillCell
    sx={{
      backgroundColor: background,
      '&:hover': { backgroundColor: background },
      color,
      borderColor: border,
    }}
  >
    {value}
  </PillCell>
);

export const renderCheckboxCell = ({ value, onChange }) => (
  <Cell>
    {typeof value === 'boolean' ? (
      <RawCheckbox checked={value} onChange={(event) => onChange(event.target.checked)} />
    ) : null}
  </Cell>
);

const MoreOptions = ({ id, options, color, emptySpaceIcons }) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuId = `${id}_table-cell-menu`;
  const selectorId = `${id}_table-cell-menu-selector`;

  const handleMenuClick = (onClick) => {
    setShowMenu(false);
    if (onClick) onClick();
  };

  return (
    <>
      {emptySpaceIcons > 0 && (
        <div style={{ width: `${24 * emptySpaceIcons + 12 * (emptySpaceIcons - 1)}px` }} />
      )}
      <Tooltip tooltip="More Options">
        <ActionCell color={color} onClick={() => setShowMenu(true)} id={selectorId}>
          {getIcon('more')}
        </ActionCell>
      </Tooltip>
      {showMenu && (
        <Menu
          id={menuId}
          anchorEl={document.getElementById(selectorId)}
          open
          onClose={() => setShowMenu(false)}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {options.map(({ type, onClick, tooltip }) => (
            <MenuItem key={type} onClick={() => handleMenuClick(onClick)}>
              <Button endIcon={getIcon(type)}>{tooltip}</Button>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export const renderActionsCell = ({ id, icons, menuIcons, iconsNumber, color }) => (
  <>
    {icons.map(({ type, onClick, tooltip }) => (
      <Tooltip key={type} tooltip={tooltip}>
        <ActionCell color={color} onClick={onClick}>
          {getIcon(type)}
        </ActionCell>
      </Tooltip>
    ))}
    {menuIcons && menuIcons.length > 0 && (
      <MoreOptions
        id={id}
        emptySpaceIcons={iconsNumber - icons.length}
        options={menuIcons}
        color={color}
      />
    )}
  </>
);

import React from 'react';
import useCategories from 'hooks/components/useCategories';
import GlobalSettingsModal from 'components/categories/form/GlobalSettingsModal';
import NewSubcategoryModal from 'components/categories/form/NewSubcategoryModal';

const CategoryProvider = ({ children }) => {
  const { globalSettings, newSubcategory, onCloseGlobalSettings, onCloseNewSubcategory } =
    useCategories();

  return (
    <>
      {children}
      {!!globalSettings && <GlobalSettingsModal onClose={onCloseGlobalSettings} />}
      {!!newSubcategory && (
        <NewSubcategoryModal categoryId={newSubcategory} onClose={onCloseNewSubcategory} />
      )}
    </>
  );
};

export default CategoryProvider;

import React from 'react';
import {
  CircularProgress as CircularProgressMui,
  Backdrop as BackdropMui,
  styled,
} from '@mui/material';
import colors from 'utils/colors';

export const Backdrop = styled(BackdropMui)({});

export const CircularProgressControl = styled(CircularProgressMui)({
  color: colors.primary[700],
});

const CircularProgress = ({ controlSx }) => (
  <Backdrop open invisible>
    <CircularProgressControl size={120} sx={controlSx} />
  </Backdrop>
);

export default CircularProgress;

import React from 'react';
import { styled } from '@mui/material';
import colors from 'utils/colors';
import { useSelector } from 'react-redux';
import Button from 'components/shared/button/Button';
import useAbility from 'hooks/shared/useAbility';
import SearchableTitle from 'components/shared/search/SearchableTitle';
import useProperties from 'hooks/components/useProperties';
import RealEstateSummary from './RealEstateSummary';
import FinancialAccountSummary from './FinancialAccountSummary';
import InvestmentAccountSummary from './InvestmentAccountSummary';
import PersonalPropertySummary from './PersonalPropertySummary';
import BusinessInterestSummary from './BusinessInterestSummary';
import TrustSummary from './TrustSummary';
import LoanAndReceivableSummary from './LoanAndReceivableSummary';
import DigitalAssetSummary from './DigitalAssetSummary';
import EndOfLifeSummary from './EndOfLifeSummary';
import DefaultSummary from './DefaultSummary';
import Autodeducts from './Autodeducts';

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '4px',
});

const Header = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: colors.tertiary[900],
  fontSize: '24px',
  lineHeight: '36px',
  margin: '0px 0px 8px 0px',
});

const Body = styled('div')({
  width: 'calc(100% + 34px)',
  marginLeft: '-16px',
  height: 'calc(100% - 56px)',
  paddingRight: '2px',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'scroll',
  overflowX: 'hidden',
});

const SummaryDetailsContainer = styled('div')({
  position: 'relative',
  padding: '14px 20px 10px 20px',
  margin: '16px 0px 16px 16px',
  backgroundColor: colors.main.surface,
  boxShadow: '0px 1px 8px 0px #34240933, 0px 3px 4px 0px #34240924',

  '& .form-uploader': {
    borderTop: 'none',
    padding: '16px 8px',
  },
});

const SUMMARY_BUTTON = {
  justifyContent: 'space-between',
  height: '36px',
  padding: '5px 20px',

  '& .MuiSvgIcon-root': {
    color: colors.main.blackText,
  },
};

const getSummaryDetails = ({ category: { key: categoryKey } }) => {
  switch (categoryKey) {
    case 'real_estate':
      return RealEstateSummary;
    case 'financial_account':
      return FinancialAccountSummary;
    case 'investment_account':
      return InvestmentAccountSummary;
    case 'personal_property':
      return PersonalPropertySummary;
    case 'business_interest':
      return BusinessInterestSummary;
    case 'loan_and_receivable':
      return LoanAndReceivableSummary;
    case 'trust':
      return TrustSummary;
    case 'digital_asset':
      return DigitalAssetSummary;
    case 'end_of_life':
      return EndOfLifeSummary;
    default:
      return DefaultSummary;
  }
};

const Summary = ({ headerSize }) => {
  const ability = useAbility();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const { onShowEditProperty } = useProperties();
  const canEdit = ability.can('edit', 'Property');

  const handleEditProperty = () => onShowEditProperty(currentProperty.id);

  const SummaryDetails = getSummaryDetails(currentProperty);
  return (
    <Container
      sx={{
        height: `calc(100% - ${headerSize}px)`,
      }}
    >
      <Header>
        <SearchableTitle title={`${currentProperty.name}`} disabled />
      </Header>
      <Button
        sx={{ ...SUMMARY_BUTTON, cursor: canEdit ? null : 'default' }}
        endIcon={canEdit ? 'edit' : null}
        variant="white"
        onClick={canEdit ? () => handleEditProperty() : null}
      >
        SUMMARY
      </Button>
      <Body className="body-scrollbar">
        <SummaryDetailsContainer>
          <SummaryDetails property={currentProperty} />
          <Autodeducts property={currentProperty} />
        </SummaryDetailsContainer>
      </Body>
    </Container>
  );
};

export default Summary;

import React from 'react';
import { styled } from '@mui/material';
import { useSelector } from 'react-redux';
import PropertySideBar from 'components/sidebar/PropertySideBar';
import GlobalSideBar from 'components/sidebar/GlobalSideBar';
import OpenItemProvider from './OpenItemProvider';
import PrivateChatProvider from './PrivateChatProvider';
import CategoryProvider from './CategoryProvider';
import PropertyProvider from './PropertyProvider';

const Container = styled('div')({
  display: 'flex',
  width: '100vw',
  height: '100vh',
});

const Body = styled('div')({
  width: '100%',
});

const MainLayout = ({ children, sideBarContent }) => {
  const currentProperty = useSelector((state) => state.property.currentProperty);

  const SideBar = currentProperty ? PropertySideBar : GlobalSideBar;

  return (
    <Container className="main_container">
      <OpenItemProvider>
        <PrivateChatProvider>
          <CategoryProvider>
            <PropertyProvider>
              <SideBar>{sideBarContent}</SideBar>
              <Body className="main_body">{children}</Body>
            </PropertyProvider>
          </CategoryProvider>
        </PrivateChatProvider>
      </OpenItemProvider>
    </Container>
  );
};

export default MainLayout;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getPleasePay } from 'services/pleasePay';
import { getProperty } from 'services/properties';
import useAbility from 'hooks/shared/useAbility';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import PleasePayModal from './PleasePayModal';

const UpdatePleasePay = ({ propertyId, pleasePayId, onClose }) => {
  const ability = useAbility();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const [property, setProperty] = useState(null);
  const [pleasePay, setPleasePay] = useState(null);

  const disabled = !pleasePay || !ability.can('update', pleasePay);

  useAsyncEffect(async () => {
    setProperty(
      currentProperty && currentProperty.id === propertyId
        ? currentProperty
        : await getProperty(propertyId)
    );
    setPleasePay(await getPleasePay(propertyId, pleasePayId));
  });

  return (
    property &&
    pleasePay && (
      <PleasePayModal
        title={!disabled ? 'Edit Pay' : 'Please Pay'}
        disabled={disabled}
        property={property}
        editingPleasePay={pleasePay}
        onClose={onClose}
      />
    )
  );
};
export default UpdatePleasePay;

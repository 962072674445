import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert, styled } from '@mui/material';
import { Formik, Form as FormikForm } from 'formik';
import colors from 'utils/colors';
import { capitalize } from 'utils/text';
import { getErrorMessage } from 'utils/errors';
import { setCurrentChat, clearCurrentChat } from 'slices/chat';
import Modal, { ModalContainer } from './Modal';
import CircularProgress from '../form/CircularProgress';
import ChatBody from '../chat/ChatBody';
import Button from '../button/Button';

const CHAT_WIDTH = 420;

export const FormModalContainer = styled(ModalContainer)({});

const Message = styled(Alert)({
  width: '100%',
  marginTop: '-12px',
  marginBottom: '20px',
});

const Form = styled(FormikForm)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'calc(100% - 80px)',
});

const FormBody = styled('div')({
  display: 'flex',
  width: '100%',
  height: 'calc(100% - 76px)',
});

const FieldsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '31px 24px',
  overflow: ['auto', 'overlay'],
});

const Footer = styled('div')({
  width: '100%',
  height: '76px',
  display: 'flex',
  overflow: 'hidden',
});

const ButtonsContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
  alignItems: 'center',
  padding: '16px 24px',
  borderTop: `1px solid ${colors.main.controlBorder}`,
});

const LeftButtonsContainer = styled('div')({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
});

const RightButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '16px',
  alignItems: 'center',
});

const ChatHeaderContainer = styled('div')({
  marginLeft: '-24px',
  width: `${CHAT_WIDTH}px`,
  height: '100%',
});

const ChatBodyContainer = styled('div')({
  width: `${CHAT_WIDTH}px`,
  minHeight: '100%',
  maxHeight: '100%',
  borderRight: `1px solid ${colors.main.controlBorder}`,
  marginTop: '-80px',
  marginBottom: '-75px',
  borderTopLeftRadius: '16px',
  overflow: 'hidden',
});

const ChatFooterContainer = styled('div')({
  width: `${CHAT_WIDTH}px`,
  height: '100%',
});

const FormModal = ({
  width,
  minHeight,
  maxHeight,
  leftHeaderContent,
  rightHeaderContent,
  leftContent,
  rightContent,
  title,
  open,
  avoidNoChangesSave,
  onClose,
  renderContent,
  initialValues,
  onSubmit,
  validationSchema,
  submitButton,
  draftButton,
  deleteButton,
  archiveButton,
  cancelButton,
  children,
  chat,
  defaultError,
}) => {
  const dispatch = useDispatch();
  const modalWidth = `${width + (chat ? CHAT_WIDTH : 0)}px`;
  const formWidth = chat ? `calc(100% - ${CHAT_WIDTH}px)` : '100%';
  const [draftSubmit, setDraftSubmit] = useState(false);

  const handleSubmit = async (values, formik) => {
    if (values.draft) {
      setDraftSubmit(true);
    }
    formik.setSubmitting(true);
    try {
      await onSubmit(values, formik);
    } catch (error) {
      formik.setFieldError('server', error);
    }
    formik.setSubmitting(false);
  };

  const makeErrorMessage = (errors) => {
    if (errors.server) return getErrorMessage(errors.server, defaultError);
    const keys = Object.keys(errors);
    if (keys.length === 1) return capitalize(errors[keys[0]]);
    return 'Error validating fields';
  };

  if (chat) {
    const currentChat = useSelector((state) => state.chat.currentChat);

    useEffect(() => {
      dispatch(setCurrentChat(currentChat));
      return () => {
        dispatch(clearCurrentChat());
      };
    }, []);

    if (!currentChat) return null;
  }

  return (
    <Modal
      leftHeaderContent={
        <>
          {!!chat && <ChatHeaderContainer />}
          {leftHeaderContent}
        </>
      }
      rightHeaderContent={rightHeaderContent}
      title={title}
      open={open}
      onClose={onClose}
      renderContent={
        renderContent ||
        ((modalChildren) => (
          <FormModalContainer sx={{ width: modalWidth, minHeight, maxHeight }}>
            {modalChildren}
          </FormModalContainer>
        ))
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form className="modal-form" autoComplete="off" noValidate>
            <FormBody className="modal-form-body">
              {leftContent}
              {!!chat && (
                <ChatBodyContainer
                  sx={{
                    maxHeight: `${maxHeight}`,
                    '& .MuiCircularProgress-root': {
                      marginLeft: `-${width}px`,
                    },
                  }}
                >
                  <ChatBody />
                </ChatBodyContainer>
              )}
              <FieldsContainer sx={{ width: formWidth, maxHeight: `calc(${maxHeight} - 156px)` }}>
                {!formik.isValid && (draftSubmit || formik.submitCount > 0) && (
                  <Message severity="error">
                    <strong>{makeErrorMessage(formik.errors)}</strong>
                  </Message>
                )}
                {children(formik)}
              </FieldsContainer>
              {rightContent}
            </FormBody>
            <Footer>
              {!!chat && <ChatFooterContainer />}
              <ButtonsContainer sx={{ width: formWidth }}>
                <LeftButtonsContainer>
                  {!!archiveButton && (
                    <Button variant="white" onClick={archiveButton.onClick}>
                      {archiveButton.label || 'Archive'}
                    </Button>
                  )}
                  {!!deleteButton && (
                    <Button variant="white" onClick={deleteButton.onClick}>
                      {deleteButton.label || 'Discard'}
                    </Button>
                  )}
                </LeftButtonsContainer>
                <RightButtonsContainer>
                  {!!cancelButton && (
                    <Button variant="white" onClick={onClose}>
                      {cancelButton.label || 'Cancel'}
                    </Button>
                  )}
                  {!!draftButton && (
                    <Button
                      variant="white"
                      onClick={
                        draftButton.onClick ||
                        (() => handleSubmit({ ...formik.values, draft: true }, formik))
                      }
                    >
                      {draftButton.label || 'Save Draft'}
                    </Button>
                  )}
                  {!!submitButton && (
                    <Button
                      variant="clear"
                      onClick={formik.submitForm}
                      disabled={avoidNoChangesSave && !formik.dirty && !draftButton}
                    >
                      {submitButton.label || 'Submit'}
                    </Button>
                  )}
                </RightButtonsContainer>
              </ButtonsContainer>
            </Footer>
            {formik.isSubmitting && (
              <CircularProgress
                controlSx={{
                  marginLeft: `${chat ? CHAT_WIDTH : 0}px`,
                }}
              />
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const buttonShape = PropTypes.oneOfType([
  PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  PropTypes.bool,
]);

FormModal.propTypes = {
  width: PropTypes.number,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  leftHeaderContent: PropTypes.element,
  rightHeaderContent: PropTypes.element,
  leftContent: PropTypes.element,
  rightContent: PropTypes.element,
  title: PropTypes.string,
  submitButton: buttonShape,
  draftButton: buttonShape,
  deleteButton: buttonShape,
  archiveButton: buttonShape,
  cancelButton: buttonShape,
  open: PropTypes.bool,
  avoidNoChangesSave: PropTypes.bool,
  initialValues: PropTypes.shape().isRequired,
  validationSchema: PropTypes.shape(),
  chat: PropTypes.shape(),
  defaultError: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  renderContent: PropTypes.func,
};

FormModal.defaultProps = {
  width: 840,
  minHeight: '30vh',
  maxHeight: '90vh',
  leftHeaderContent: null,
  rightHeaderContent: null,
  leftContent: null,
  rightContent: null,
  open: true,
  avoidNoChangesSave: true,
  title: '',
  submitButton: true,
  draftButton: false,
  deleteButton: false,
  archiveButton: false,
  cancelButton: false,
  validationSchema: null,
  chat: null,
  onSubmit: null,
  renderContent: null,
  defaultError: 'Error saving form',
};

export default FormModal;

import React from 'react';
import { styled } from '@mui/material';
import colors from 'utils/colors';

const Container = styled('div')({
  width: 'calc(100% + 16px)',
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${colors.main.clearTableBorder}`,
  borderRadius: '4px',
  marginTop: '8px',
  marginLeft: '-8px',
  padding: '0px 8px',
});

const SummaryField = ({ children }) => <Container>{children}</Container>;

export default SummaryField;

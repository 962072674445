export const removeFile = (files, index) =>
  files[index].action === 'new'
    ? files.filter((_, fileIndex) => fileIndex !== index)
    : files.map((file, fileIndex) =>
        fileIndex === index ? { ...file, action: file.action === 'delete' ? null : 'delete' } : file
      );

export const addFiles = (files, addedFiles, singleImage = false) => [
  ...(!singleImage ? files : []),
  ...addedFiles.map((file) => ({
    action: 'new',
    filename: file.name || Date.now().toString(),
    content_type: file.type || 'text/plain',
    byte_size: file.size,
    url: URL.createObjectURL(file),
    file,
  })),
];

export const downloadProps = ({ url, filename }) => {
  if (!url) return {};

  return {
    href: url,
    target: '_blank',
    download: filename,
  };
};

import React, { useState, useEffect } from 'react';
import Table from 'components/shared/table/Table';
import useSnackbar from 'hooks/shared/useSnackBar';
import useAbility from 'hooks/shared/useAbility';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import colors from 'utils/colors';
import { renderActionsCell, renderLongCell, renderDateCell } from 'components/shared/table/Cell';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import { deleteReceipt, getReceipts } from 'services/receipts';
import { useSelector, useDispatch } from 'react-redux';
import { formatCurrency } from 'utils/number';
import { getUserFullNameById } from 'domain/user';
import { fetchPropertyUsers } from 'slices/property';
import { fetchAllUsers } from 'slices/user';
import { fetchVendors } from 'slices/vendor';
import useReceipts from 'hooks/components/useReceipts';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { clearFilters } from 'slices/receipt';
import ReceiptsFilters from './ReceiptsFilters';

const ReceiptsTable = () => {
  const dispatch = useDispatch();
  const ability = useAbility();
  const { showSuccess, showError } = useSnackbar();
  const { receiptId: selectedReceipt, onShowDetails } = useReceipts();
  const [deletingReceipt, setDeletingReceipt] = useState(null);
  const [filtersChange, setFiltersChange] = useState(false);
  const [receipts, setReceipts] = useState(null);
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const propertyUsers = useSelector((state) => state.property.users);
  const globalUsers = useSelector((state) => state.user);
  const users = currentProperty ? propertyUsers : globalUsers;
  const filters = useSelector((state) => state.receipt.filters);

  useEffect(() => {
    if (!users && currentProperty) {
      dispatch(fetchPropertyUsers(currentProperty.id));
    } else {
      dispatch(fetchAllUsers());
    }
    dispatch(clearFilters());
  }, []);

  useAsyncEffect(
    async () => {
      if (receipts && (deletingReceipt || selectedReceipt)) return;

      dispatch(fetchVendors());
      const fetchReceipts = await getReceipts(currentProperty ? currentProperty.id : null, {
        ...(filters || {}),
        all: true,
      });
      setReceipts(fetchReceipts.records);
      setFiltersChange(false);
    },
    undefined,
    [selectedReceipt, deletingReceipt, filtersChange]
  );

  const handleAskDeletion = (receipt) => {
    setDeletingReceipt(receipt);
  };

  const handleCancelDelete = () => {
    setDeletingReceipt(null);
  };

  const handleDelete = async () => {
    try {
      await deleteReceipt(deletingReceipt.property_id, deletingReceipt.id);
      showSuccess('Receipt deleted');
      setDeletingReceipt(null);
    } catch {
      showError('Error deleting receipt');
    }
  };

  const columns = [
    {
      headerName: 'Date',
      field: 'purchase_date',
      renderCell: renderDateCell,
    },
    !currentProperty && {
      headerName: 'Portfolio',
      field: 'property_name',
      flex: 2,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      flex: 1,
      valueGetter: ({ row: { amount } }) => formatCurrency(amount),
    },
    {
      headerName: 'Paid By',
      field: 'user_id',
      flex: 1.5,
      valueGetter: ({ row: { user_id: userId } }) => getUserFullNameById(users, userId),
    },
    {
      headerName: 'Vendor',
      field: 'receipt_vendor',
      flex: 1.5,
      valueGetter: ({ row: { receipt_vendor: vendor } }) => (vendor ? `${vendor.name}` : null),
    },
    {
      headerName: 'GL Codes',
      field: 'receipt_items',
      flex: 1,
      valueGetter: ({ row: { receipt_items: receiptItems } }) =>
        receiptItems
          .filter((item) => item.gl_code && item.gl_code.id)
          .map((item) => (item.gl_code.key === 'other' ? item.other_gl_code : item.gl_code.key))
          .join(', '),
      renderCell: ({ value, row: { receipt_items: receiptItems } }) =>
        renderLongCell({
          value,
          tooltip: receiptItems
            .filter((item) => item.gl_code && item.gl_code.id)
            .map((item) =>
              item.gl_code.key === 'other'
                ? item.other_gl_code
                : `${item.gl_code.key} - ${item.gl_code.name}`
            )
            .join('\n'),
        }),
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: !currentProperty ? 2 : 3,
    },
    {
      headerName: 'Paid Via',
      field: 'receipt_payment_method_id',
      flex: 1,
      valueGetter: ({ row: { receipt_payment_method: paidVia } }) => paidVia?.name,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      disableExport: true,
      flex: 1,
      renderCell: ({ row: receipt }) => {
        const canEdit = ability.can('update', receipt);
        const canDelete = ability.can('delete', receipt);

        return renderActionsCell({
          icons: [
            {
              type: canEdit ? 'edit' : 'view',
              onClick: () => onShowDetails(receipt.property_id, receipt.id),
            },
            canDelete && {
              type: 'delete',
              onClick: () => {
                handleAskDeletion(receipt);
              },
            },
          ].filter((icon) => icon),
          color: colors.primary[900],
        });
      },
    },
  ].filter((column) => column);

  const changeFilter = () => {
    setFiltersChange(true);
  };

  if (!receipts || !users) return null;

  return (
    <>
      <ReceiptsFilters changeFilter={changeFilter} />
      <Table
        rows={receipts}
        columns={columns}
        pageSize={50}
        onRowDoubleClick={({ row: receipt }) => onShowDetails(receipt.property_id, receipt.id)}
        NoRowsIcon={ReceiptOutlinedIcon}
        noRowsTitle="No receipts found"
        allowExport
      />
      {deletingReceipt && (
        <ConfirmationModal
          title="Receipt will be deleted"
          description="Are you sure?"
          onCancel={handleCancelDelete}
          onConfirm={handleDelete}
        />
      )}
    </>
  );
};

export default ReceiptsTable;

import React from 'react';
import { styled } from '@mui/material';
import { makeValidatorSchema, stringValidator } from 'utils/validators';
import { codeLogin } from 'services/auth';
import RawForm from 'components/shared/form/RawForm';
import VerificationInput from 'react-verification-input';

const FormContainer = styled('div')({
  mt: 8,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '& .container': {
    height: '50px',
    width: '300px',
    gap: '0px',
  },

  '& .character': {
    backgroundColor: 'unset',
    border: 'unset',
    outline: 'unset',
    lineHeight: '50px',
    fontSize: '36px',
    color: '#000',
    borderBottom: '2px solid rgba(108, 101, 51, 0.08)',
    marginLeft: '8px',

    '&:first-of-type': {
      marginLeft: '0',
    },

    '&.character--selected': {
      borderBottom: '2px solid #89300e',
    },
  },
});

const ConfirmationCode = styled('div')({
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
});

const CodeTitle = styled('div')({
  display: 'flex',
  padding: '21px 0 20px 0',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '20px',
});

const validationSchema = makeValidatorSchema({
  code: stringValidator({ required: 'Must enter a code', min: 6 }),
});

const initializeForm = () => ({
  code: '',
});

const CodeAuthentication = ({ step: { session }, onLogin }) => {
  const handleSubmit = async ({ code }) => {
    await codeLogin({ code }, session);
    onLogin();
  };

  return (
    <RawForm
      initialValues={initializeForm()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError="Invalid code"
      submitButton={{ label: 'Send' }}
    >
      {({ setFieldValue }) => (
        <FormContainer>
          <ConfirmationCode>
            <CodeTitle>Enter Confirmation Code</CodeTitle>
            <VerificationInput
              autoFocus
              validChars="0-9"
              removeDefaultStyles
              length={6}
              onChange={(value) => {
                setFieldValue('code', value);
              }}
              classNames={{
                container: 'container',
                character: 'character',
                characterInactive: 'character--inactive',
                characterSelected: 'character--selected',
              }}
            />
          </ConfirmationCode>
        </FormContainer>
      )}
    </RawForm>
  );
};

export default CodeAuthentication;

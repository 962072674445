import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  makeValidatorSchema,
  positiveNumberValidator,
  stringValidator,
  dateValidator,
} from 'utils/validators';
import useSnackbar from 'hooks/shared/useSnackBar';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import FormModal from 'components/shared/modal/FormModal';
import TextInput from 'components/shared/form/TextInput';
import Select from 'components/shared/form/Select';
import FormRow from 'components/shared/form/FormRow';
import { createPleasePay, updatePleasePay } from 'services/pleasePay';
import { parseNumber } from 'utils/number';
import DatePicker from 'components/shared/form/DatePicker';
import { uploadFormFiles } from 'services/directUpload';
import { getUserFullName } from 'domain/user';
import Button from 'components/shared/button/Button';
import { styled } from '@mui/material';
import colors from 'utils/colors';
import Autocomplete from 'components/shared/form/Autocomplete';
import { FieldArray } from 'formik';
import Checkbox from 'components/shared/form/Checkbox';
import { getGlCodeKeyById } from 'domain/glCodes';
import { getUsers } from 'services/users';
import FormUploader from 'components/shared/uploader/FormUploader';

const AddItemContainer = styled('div')({
  width: '100%',
  height: '50px',
  display: 'flex',
  margin: '0 0 15px 0',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 'bold',
  color: colors.primary[900],
  lineHeight: '24px',
});

const ItemsContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  margin: '0 0 15px 0',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: `1px solid ${colors.main.clearTableBorder}`,
});

const ButtonContainer = styled('div')({
  height: '50px',
  display: 'flex',
});

const PleasePayModal = ({ title, property, editingPleasePay, disabled, onClose }) => {
  const { showSuccess } = useSnackbar();
  const vendors = useSelector((state) => state.vendor.vendors);
  const glCodes = useSelector((state) => state.glCode.glCodes);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [propertyUsers, setPropertyUsers] = useState(null);

  const entityPath = editingPleasePay
    ? `properties/${property.id}/please_pays/${editingPleasePay.id}`
    : null;

  const validationSchema = makeValidatorSchema({
    amount: positiveNumberValidator({ required: 'Must enter amount' }),
    vendor: stringValidator({ required: 'Must enter vendor' }),
    paidByDate: dateValidator({ required: 'Must select purchase date' }),
    description: stringValidator({ required: 'Must enter description' }),
  });

  const initializeForm = () =>
    editingPleasePay
      ? {
          requestedBy: editingPleasePay.user_id,
          amount: parseNumber(editingPleasePay.amount),
          vendor: editingPleasePay.receipt_vendor?.name || '',
          paidByDate: editingPleasePay.paid_by_date || '',
          description: editingPleasePay.description || '',
          items: editingPleasePay.please_pay_items.map((item) => ({
            gl_code_id: item.gl_code.id || '',
            description: item.description,
            other_gl_code: item.other_gl_code || null,
          })),
          documents: editingPleasePay.documents || [],
          images: editingPleasePay.images || [],
          paid: editingPleasePay.paid,
        }
      : {
          requestedBy: currentUser.id,
          amount: '',
          vendor: '',
          paidByDate: '',
          items: [],
          documents: [],
          images: [],
          paid: false,
        };

  const makeFormRequest = (values) => ({
    amount: values.amount,
    paid_by_date: values.paidByDate,
    description: values.description,
    please_pay_items: values.items.map((item) => ({
      gl_code_id: item.gl_code_id || null,
      description: item.description,
      other_gl_code: item.other_gl_code || null,
    })),
    receipt_vendor_name: values.vendor,
    documents: values.documents,
    images: values.images,
    paid: values.paid,
  });

  const savePleasePay = async (request) =>
    editingPleasePay
      ? updatePleasePay(editingPleasePay.property_id, editingPleasePay.id, request)
      : createPleasePay(property.id, request);

  const handleSubmit = async (values) => {
    let request = makeFormRequest(values);
    request = await uploadFormFiles(request);
    await savePleasePay(request);
    showSuccess(editingPleasePay ? 'Please Pay Updated' : 'Please Pay Created');
    onClose();
  };

  const submitLabel = () => (editingPleasePay ? 'Update' : 'Create');

  useAsyncEffect(async () => {
    setPropertyUsers(await getUsers(property.id));
  });

  if (!propertyUsers) return null;

  return (
    <FormModal
      open
      title={title}
      onClose={onClose}
      initialValues={initializeForm(editingPleasePay)}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError="Error Saving Please Pay"
      submitButton={!disabled && { label: submitLabel() }}
      cancelButton
    >
      {(formik) => (
        <>
          <Checkbox
            formik={formik}
            name="paid"
            labeledVariant="background"
            checkedLabel="Paid"
            uncheckedLabel="Pending"
            disabled={disabled}
          />
          <FormRow>
            <Select
              formik={formik}
              name="requestedBy"
              label="Requested By"
              options={propertyUsers.map((user) => ({
                id: user.id,
                label: getUserFullName(user),
              }))}
              sx={{ width: '100%' }}
              disabled
            />
            <DatePicker
              formik={formik}
              name="paidByDate"
              label="Pay by Date"
              sx={{ width: '370px' }}
              disabled={disabled}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="amount"
              label="Amount"
              sx={{ width: '100%' }}
              disabled={disabled}
              type="currency"
              clearPrefix={false}
            />

            <Autocomplete
              formik={formik}
              name="vendor"
              label="Vendor"
              options={vendors}
              free
              sx={{ width: '100%' }}
              disabled={disabled}
            />
          </FormRow>
          <TextInput
            formik={formik}
            name="description"
            label="Description"
            sx={{ width: '100%' }}
            disabled={disabled}
          />

          <div>
            <FieldArray
              name="items"
              render={(arrayHelpers) => (
                <>
                  <AddItemContainer>
                    Lines
                    {!disabled && (
                      <Button
                        variant="transparent"
                        startIcon="add"
                        onClick={() => {
                          arrayHelpers.push({
                            gl_code_id: '',
                            description: '',
                            other_gl_code: null,
                          });
                        }}
                      >
                        ADD GL CODE
                      </Button>
                    )}
                  </AddItemContainer>
                  <div>
                    {formik.values.items && formik.values.items.length > 0
                      ? formik.values.items.map((item, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <ItemsContainer key={index}>
                            <FormRow>
                              <Select
                                formik={formik}
                                name={`items[${index}].gl_code_id`}
                                label="GL Code"
                                fixedValue={item.gl_code_id}
                                options={glCodes.map((glCode) => ({
                                  id: glCode.id,
                                  label:
                                    glCode.key === 'other'
                                      ? `${glCode.name}`
                                      : `${glCode.key} - ${glCode.name}`,
                                }))}
                                sx={{ width: '100%' }}
                                disabled={disabled}
                              />
                              {getGlCodeKeyById(glCodes, formik.values.items[index].gl_code_id) ===
                                'other' && (
                                <TextInput
                                  formik={formik}
                                  fixedValue={item.other_gl_code}
                                  name={`items[${index}].other_gl_code`}
                                  label="Other GL Code"
                                  sx={{ width: '100%' }}
                                  disabled={disabled}
                                />
                              )}
                              <TextInput
                                formik={formik}
                                fixedValue={item.description}
                                name={`items[${index}].description`}
                                label="Description"
                                sx={{ width: '100%' }}
                                disabled={disabled}
                              />
                              {!disabled && (
                                <ButtonContainer>
                                  <Button
                                    variant="transparent"
                                    startIcon="clear"
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                  />
                                </ButtonContainer>
                              )}
                            </FormRow>
                          </ItemsContainer>
                        ))
                      : null}
                  </div>
                </>
              )}
            />
          </div>
          <FormUploader formik={formik} disabled={disabled} entityPath={entityPath} />
        </>
      )}
    </FormModal>
  );
};

export default PleasePayModal;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { InputLabel, Input, styled } from '@mui/material';
import { DatePicker as DatePickerMui, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import colors from 'utils/colors';
import { makeDate, formatDate, DATE_FORMAT } from 'utils/date';
import { showFieldError } from './FormControl';
import { RawTextInput, TextInputControl } from './TextInput';
import useInputChange from './useInputChange';

const DatePickerTextControl = styled(TextInputControl)({
  width: '242px',

  '& .MuiInput-input': {
    padding: '0px',
  },

  '& .MuiButtonBase-root': {
    marginTop: '-8px',
    padding: '14px',
    color: colors.primary[900],

    '&:hover': {
      background: 'none',
    },
  },

  '& .MuiInput-root.Mui-error .MuiButtonBase-root': {
    color: colors.main.error,
  },
});

const DatePickerControl = styled(DatePickerMui)({});

const makeValue = (value) =>
  value && typeof value === 'string' ? makeDate(value).toDate() : value || '';

const makeChangedValue = (value) => formatDate(value, DATE_FORMAT.SERVER_DATE);

export const RawDatePicker = ({
  value: rawValue,
  error,
  onChange,
  name,
  label,
  inputFormat,
  disabled,
  sx,
  size,
  leftLabel,
  textAlign,
  variant,
}) => {
  if (disabled) {
    return (
      <RawTextInput
        value={rawValue ? formatDate(rawValue) : '-'}
        name={name}
        label={label}
        disabled
        sx={{ width: '242px', ...sx }}
        size={size}
        leftlabel={leftLabel}
        textalign={textAlign}
        variant={variant}
      />
    );
  }

  const value = makeValue(rawValue);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePickerControl
        label={label}
        inputFormat={inputFormat}
        value={value || null}
        error={error}
        disabled={disabled}
        onChange={(date) => onChange(makeChangedValue(date))}
        componentsProps={{
          actionBar: {
            actions: ['clear'],
          },
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <DatePickerTextControl
            className={classnames({
              disabled,
              [`${variant}-variant`]: true,
              'left-label': !!leftLabel,
            })}
            sx={sx}
            size={size}
            leftlabel={leftLabel ? leftLabel + 40 : null}
            textalign={textAlign}
          >
            <InputLabel error={error}>{label}</InputLabel>
            <Input
              ref={inputRef}
              endAdornment={InputProps?.endAdornment}
              {...inputProps}
              error={error}
            />
          </DatePickerTextControl>
        )}
        onOpen={() => document.body.classList.add('without-scrollbars')}
        onClose={() => document.body.classList.remove('without-scrollbars')}
      />
    </LocalizationProvider>
  );
};

const DatePicker = (props) => {
  const { name, formik, onChange, fixedValue } = props;

  const { value, onChange: onOptimizedChange } = useInputChange({
    value: fixedValue !== null ? fixedValue : formik.values[name],
    onChange: onChange || ((newValue) => formik.setFieldValue(name, newValue)),
    getEventValue: (newValue) => newValue,
  });

  return (
    <RawDatePicker
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={value}
      error={showFieldError(formik, name)}
      onChange={onOptimizedChange}
    />
  );
};

DatePicker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  fixedValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  inputFormat: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  sx: PropTypes.shape(),
  size: PropTypes.string,
  leftLabel: PropTypes.number,
  textAlign: PropTypes.string,
  variant: PropTypes.string,
};

DatePicker.defaultProps = {
  label: '',
  fixedValue: null,
  inputFormat: 'MM/dd/yyyy',
  disabled: false,
  onChange: null,
  sx: null,
  size: 'medium',
  leftLabel: null,
  textAlign: null,
  variant: 'standard',
};

export default DatePicker;

import axios from 'axios';
import AWS from 'aws-sdk';
import { ServerError } from 'utils/errors';

const makeFileChecksum = async (file) =>
  new Promise((resolve, reject) => {
    const hash = AWS.util.crypto.createHash('md5');
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = (event) => {
      const buf = new AWS.util.Buffer(new Uint8Array(event.target.result));
      hash.update(buf);
      const checksum = hash.digest('base64');
      resolve(checksum);
    };
    reader.readAsArrayBuffer(file);
  });

const signFile = async ({ filename, content_type: contentType, byte_size: biteSize, file }) => {
  const checksum = await makeFileChecksum(file);
  const blob = {
    filename,
    content_type: contentType,
    byte_size: biteSize,
    checksum,
  };
  const result = await axios.post('direct_uploads', { blob });
  return result.data;
};

export const uploadFile = async (file) => {
  const {
    direct_upload: { url, headers },
    signed_id: signedId,
  } = await signFile(file);
  await fetch(url, {
    method: 'PUT',
    headers,
    body: file.file,
  });
  return signedId;
};

export const uploadAddedFiles = async (files) =>
  Promise.all(
    files
      .filter((file) => !!file.action)
      .map(async (file) => {
        if (file.action !== 'new') return file;
        try {
          const signedId = await uploadFile(file);
          return {
            action: 'new',
            signed_id: signedId,
          };
        } catch (e) {
          throw new ServerError(`Error uploading file ${file.filename}`, e);
        }
      })
  );

export const uploadFormFiles = async (request) => ({
  ...request,
  documents: request.documents ? await uploadAddedFiles(request.documents) : null,
  images: request.images ? await uploadAddedFiles(request.images) : null,
});

export const readTextFile = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = (event) => resolve(event.target.result);
    reader.readAsText(file);
  });

import axios from 'axios';
import { ServerError } from 'utils/errors';

const storeSession = (session) => {
  localStorage.setItem('user', JSON.stringify(session));
};
const cleanSesssion = () => {
  try {
    localStorage.removeItem('user');
    // eslint-disable-next-line no-empty
  } catch {}
};

const getTwoFactorToken = () => localStorage.getItem('two_factor_token');
const storeTwoFactorToken = (token) => localStorage.setItem('two_factor_token', token);
const cleanTwoFactorToken = () => localStorage.removeItem('two_factor_token');

export const getStoredSession = () => {
  try {
    return JSON.parse(localStorage.getItem('user'));
  } catch (e) {
    cleanSesssion();
    return null;
  }
};

export const setAuthHeaders = (session) => {
  axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
  axios.defaults.headers.common = {
    CONTENT_TYPE: 'application/json',
    ACCEPT: 'application/json',
    Uid: session.headers.uid,
    'Access-Token': session.headers['access-token'],
    Client: session.headers.client,
  };
};

const handleLoginError = (error) => {
  const message =
    error.response.status === 401
      ? 'Invalid email or password'
      : (error.response && error.response.data && error.response.data.message) ||
        (error.response && error.response.data && error.response.data.errors) ||
        error.message ||
        error.toString();
  throw new ServerError(message, error);
};

export const requestLoginCode = async (authType) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}two_factor_auth/send_code`, {
    type: authType,
  });
  return response.data;
};

const getAuthType = (sesssion) => {
  if (sesssion.data.data.mobile_key) return 'push_auth';

  return 'code';
};

const handleTwoFactorAuth = async (session) => {
  setAuthHeaders(session);
  const token = getTwoFactorToken();
  if (token) {
    try {
      const result = await axios.post(`${process.env.REACT_APP_API_URL}two_factor_auth/validate`, {
        token,
      });
      storeTwoFactorToken(result.data.token);
      storeSession(session);
      return { session };
    } catch (e) {
      cleanTwoFactorToken();
    }
  }

  const authType = getAuthType(session);
  await requestLoginCode(authType);
  return { twoFactor: authType, session };
};

export const basicLogin = async (email, password) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}auth/sign_in`, {
      email,
      password,
    });
    if (!response.headers['access-token']) {
      throw new Error('server error');
    }

    if (response.data.data.two_factor_auth) return await handleTwoFactorAuth(response);

    storeSession(response);
    return { session: response };
  } catch (e) {
    return handleLoginError(e);
  }
};

export const codeLogin = async (request, session) => {
  const result = await axios.post(
    `${process.env.REACT_APP_API_URL}two_factor_auth/validate`,
    request
  );
  if (result.data.waiting) return { loggedIn: false, waiting: true };

  storeSession(session);
  storeTwoFactorToken(result.data.token);
  return { loggedIn: true, session };
};

export const makeLoginPath = (redirect = false) => {
  const path = '/login';
  if (!redirect) return path;

  return `${path}?navigation=${encodeURIComponent(window.location.href)}`;
};

export const logout = async (redirect = false) => {
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}auth/sign_out`);
    // eslint-disable-next-line no-empty
  } catch {}
  cleanSesssion();
  const loginPath = makeLoginPath(redirect);
  window.location.href = loginPath;
};

import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import colors from 'utils/colors';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from 'components/shared/button/IconButton';
import Menu from 'components/shared/menu/Menu';
import { clearPolicies, fetchPolicies } from 'slices/policy';
import { fetchIndicators } from 'slices/propertyIndicator';
import { fetchCurrentProperty, fetchAllProperties } from 'slices/property';
import useAbility from 'hooks/shared/useAbility';
import { parseNumber } from 'utils/number';
import usePropertyIndicators from 'hooks/components/usePropertyIndicators';
import {
  LIFE_INSURANCE_POLICY_SUB_CATEGORIES,
  LIFE_INSURANCE_POLICY_SUB_TYPES,
  HOME_INSURANCE_POLICY_SUB_TYPES,
  VEHICLE_INSURANCE_POLICY_SUB_TYPES,
  getPolicyKey,
} from 'domain/policies';
import { excelExportPolicies } from 'services/policies';
import useSnackbar from 'hooks/shared/useSnackBar';
import { useSet } from 'react-use';
import SearchableTitle from 'components/shared/search/SearchableTitle';
import LifeInsurancePolicyForm from './LifeInsurancePolicyForm';
import HomePolicyForm from './HomePolicyForm';
import AviationPolicyForm from './AviationPolicyForm';
import LifeInsurancePolicyBatchUpdateForm from './LifeInsurancePolicyBatchUpdateForm';
import VehiclePolicyForm from './VehiclePolicyForm';
import HomePolicyBatchUpdateForm from './HomePolicyBatchUpdateForm';
import AviationPolicyBatchUpdateForm from './AviationPolicyBatchUpdateForm';
import VehiclePolicyBatchUpdateForm from './VehiclePolicyBatchUpdateForm';
import TotalIndicator from '../../property-indicators/TotalIndicator';

const SUMMARY_FIELD_SIZE = 56;

const SummaryFields = styled('div')({
  width: '100%',
  display: 'grid',
  gap: '6px',

  '& > *': {
    marginBottom: '0px',
    backgroundColor: colors.main.surface,
  },
});

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '4px',
});

const Header = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: colors.tertiary[900],
  fontSize: '24px',
  lineHeight: '36px',
  margin: '0px 0px 8px 0px',
});

const Body = styled('div')({
  width: 'calc(100% + 34px)',
  marginLeft: '-16px',
  height: 'calc(100% - 56px)',
  paddingRight: '2px',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'scroll',
  overflowX: 'hidden',
});

const CategoryName = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 'bold',
  color: colors.main.greyIcon,
  lineHeight: '16px',
  marginBottom: '8px',
  marginLeft: '16px',
  padding: '9px 14px',
  textTransform: 'uppercase',
  border: `1px solid ${colors.main.controlBorder}`,
  borderRadius: '4px',
});

const PolicyFormContainer = styled('div')({
  position: 'relative',
  padding: '32px 16px 16px 16px',
  margin: '0px 0px 16px 16px',
  backgroundColor: colors.main.surface,
  boxShadow: '0px 1px 8px 0px #34240933, 0px 3px 4px 0px #34240924',

  '& .form-uploader': {
    borderTop: 'none',
    padding: '16px 8px',
  },
});
const COLLAPSED_FORM_PROPERTIES = {
  paddingBottom: '0px',
};

const CollapsedContainer = styled('div')({
  position: 'absolute',
  top: '0px',
  right: '0px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '4px 12px 0px 12px',
});

const PolicyTitle = styled('div')({
  marginLeft: '4px',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 'bold',
  color: colors.main.greyIcon,
});

const getPropertyForm = (policyKey) => {
  switch (policyKey) {
    case 'life':
      return LifeInsurancePolicyForm;
    case 'home':
      return HomePolicyForm;
    case 'aviation':
      return AviationPolicyForm;
    case 'vehicle':
      return VehiclePolicyForm;
    default:
      return null;
  }
};

const getPropertyBatchUpdateForm = (policyKey) => {
  switch (policyKey) {
    case 'life':
      return LifeInsurancePolicyBatchUpdateForm;
    case 'home':
      return HomePolicyBatchUpdateForm;
    case 'aviation':
      return AviationPolicyBatchUpdateForm;
    case 'vehicle':
      return VehiclePolicyBatchUpdateForm;
    default:
      return null;
  }
};

const canReportProperty = (policyKey) => {
  switch (policyKey) {
    case 'life':
    case 'home':
    case 'aviation':
    case 'vehicle':
      return true;
    default:
      return false;
  }
};

const getSummaryFieldsMetadata = (policyKey) => {
  switch (policyKey) {
    case 'life':
      return {
        group: 'beneficiary',
        groupName: 'Beneficiary',
        indicator: 'benefit',
        indicatorName: 'Total Benefit',
        filter: (policy) => policy.sub_type !== 'disability',
      };
    default:
      return null;
  }
};

const getFilterByFieldDefinition = (policyKey) => {
  switch (policyKey) {
    case 'life':
      return {
        field: 'sub_type',
        label: 'All policies',
        options: LIFE_INSURANCE_POLICY_SUB_TYPES,
      };
    case 'home':
      return {
        field: 'sub_type',
        label: 'All policies',
        options: HOME_INSURANCE_POLICY_SUB_TYPES,
      };
    case 'vehicle':
      return {
        field: 'sub_type',
        label: 'All policies',
        options: VEHICLE_INSURANCE_POLICY_SUB_TYPES,
      };
    default:
      return null;
  }
};

const getFilterByTextDefinition = (policyKey) => {
  switch (policyKey) {
    case 'life':
      return ['insured', 'beneficiary', 'insurance_company', 'policy_number'];
    case 'home':
      return ['insured', 'addl_insured', 'insurance_company', 'policy_number'];
    case 'aviation':
      return ['insured', 'addl_insured', 'insurance_company', 'policy_number'];
    case 'vehicle':
      return ['insured', 'addl_insured', 'insurance_company', 'policy_number'];
    default:
      return null;
  }
};

const splitIntoCategories = (policyKey, policies) => {
  switch (policyKey) {
    case 'life':
      return LIFE_INSURANCE_POLICY_SUB_CATEGORIES.map((category) => ({
        name: category.name,
        policies: policies.filter((policy) => category.types.includes(policy.sub_type)),
      }));
    default:
      return [{ policies }];
  }
};

const summarizeFields = ({ group, indicator }, summary, policy) => {
  const groupName = policy[group];
  if (!groupName) return summary;

  return {
    ...summary,
    [groupName]: (summary[groupName] || 0) + parseNumber(policy[indicator]),
  };
};

const getSummaryFields = (policyKey, policies) => {
  const metadata = getSummaryFieldsMetadata(policyKey);
  if (!metadata) return [];

  const fields = policies
    .filter(metadata.filter)
    .reduce((summary, policy) => summarizeFields(metadata, summary, policy), {});
  return (Object.keys(fields) || [])
    .map((groupName) => ({ name: groupName, total: fields[groupName] }))
    .sort((field1, field2) => field1.name.localeCompare(field2.name))
    .map(({ name, total }) => (
      <TotalIndicator
        key={name}
        canEdit={false}
        indicator={{
          name: `${name} ${metadata.indicatorName}`,
          total,
        }}
      />
    ));
};

const filterPolicy = (
  policy,
  filterByFieldDefinition,
  filterByField,
  filterByTextDefinition,
  filterByText
) => {
  if (
    filterByFieldDefinition &&
    filterByField !== 'all' &&
    policy[filterByFieldDefinition.field] !== filterByField
  )
    return false;

  if (
    filterByTextDefinition &&
    filterByText &&
    !filterByTextDefinition.some(
      (field) => (policy[field] || '').toLowerCase().indexOf(filterByText) >= 0
    )
  )
    return false;

  return true;
};

const filterPolicies = (
  policies,
  filterByFieldDefinition,
  filterByField,
  filterByTextDefinition,
  filterByText
) =>
  policies.filter((policy) =>
    filterPolicy(
      policy,
      filterByFieldDefinition,
      filterByField,
      filterByTextDefinition,
      filterByText
    )
  );

const InsurancePolicies = ({ headerSize }) => {
  const dispatch = useDispatch();
  const { showError } = useSnackbar();
  let policies = useSelector((state) => state.policy.policies);
  const allProperties = useSelector((state) => state.property.allProperties);
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const categoryId = currentProperty.category.id;
  const subcategoryId = currentProperty.subcategory.id;
  const policyKey = getPolicyKey(currentProperty);
  const [editingPolicy, setEditingPolicy] = useState(null);
  const [filterByField, setFilterByField] = useState('all');
  const [batchEditing, setBatchEditing] = useState(false);
  const [searchText, setSearchText] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [_openPolicies, { has: isOpenPolicy, add: openPolicy, toggle: toggleOpenPolicy }] = useSet(
    new Set()
  );
  const ability = useAbility();
  const { hasIndicators } = usePropertyIndicators();

  const PolicyForm = getPropertyForm(policyKey);
  const BatchUpdatePolicyForm = getPropertyBatchUpdateForm(policyKey);
  if (!PolicyForm) return null;

  const canCreate = !editingPolicy && ability.can('create', 'Policy');
  const canUpdate = !editingPolicy && ability.can('update', 'Policy');
  const canBatchUpdate =
    !editingPolicy && ability.can('batchUpdate', 'Policy') && !!BatchUpdatePolicyForm;
  const canDelete = !editingPolicy && ability.can('delete', 'Policy');
  const canReport =
    !editingPolicy && ability.can('report', 'Policy') && canReportProperty(policyKey);

  const handleLoadPolicies = (reload = false) => {
    dispatch(fetchPolicies(currentProperty.id, policyKey, { all: true }));
    if (reload) {
      if (hasIndicators) dispatch(fetchIndicators(currentProperty.id, categoryId, subcategoryId));
      dispatch(fetchCurrentProperty(currentProperty.id));
    }
  };

  const handleReport = async () => {
    try {
      await excelExportPolicies(currentProperty.id, policyKey, `${currentProperty.name} Report`);
    } catch {
      showError('Error running report');
    }
  };

  useEffect(() => {
    handleLoadPolicies();
    if (!allProperties) dispatch(fetchAllProperties());

    return () => {
      dispatch(clearPolicies());
    };
  }, []);

  if (!policies || !allProperties) return null;

  const handleAdd = () => {
    setEditingPolicy('new');
  };

  const handleEdit = (policy) => {
    setEditingPolicy(policy.id);
  };

  const handleCancel = () => {
    setEditingPolicy(null);
  };

  const handleSave = (savedPolicy) => {
    openPolicy(savedPolicy.id);
    setEditingPolicy(null);
    handleLoadPolicies(true);
  };

  const handleDeleted = () => {
    setEditingPolicy(null);
    handleLoadPolicies(true);
  };

  const handleBatchSave = () => {
    dispatch(clearPolicies());
    setBatchEditing(false);
    setEditingPolicy(null);
    handleLoadPolicies(true);
  };

  const summaryFields = getSummaryFields(policyKey, policies);
  const filterByFieldDefinition = getFilterByFieldDefinition(policyKey);
  const filterByTextDefinition = getFilterByTextDefinition(policyKey);
  policies = filterPolicies(
    policies,
    filterByFieldDefinition,
    filterByField,
    filterByTextDefinition,
    searchText.toLowerCase()
  );

  return (
    <>
      {summaryFields.length > 0 && <SummaryFields>{summaryFields}</SummaryFields>}
      <Container
        sx={{
          height: `calc(100% - ${headerSize + summaryFields.length * SUMMARY_FIELD_SIZE}px)`,
        }}
      >
        <Header>
          <SearchableTitle
            title={`${currentProperty.name} Policies`}
            onChange={setSearchText}
            rightContent={
              <>
                {filterByFieldDefinition && (
                  <Menu
                    id="insurance-policies-filter"
                    options={[
                      { id: 'all', name: filterByFieldDefinition.label },
                      ...filterByFieldDefinition.options,
                    ].map((option) => ({
                      key: option.id,
                      icon: 'filter',
                      label: option.name,
                      selected: option.id === filterByField,
                      onClick: () => setFilterByField(option.id),
                    }))}
                    hideOnEmpty
                    renderButton={({ id, onClick, disabled }) => (
                      <IconButton
                        icon="filter"
                        id={id}
                        onClick={onClick}
                        disabled={disabled}
                        selected={filterByField !== 'all'}
                      />
                    )}
                  />
                )}
                <Menu
                  id="insurance-policies-global-actions"
                  options={[
                    canCreate && {
                      key: 'add',
                      icon: 'add',
                      label: 'Add Policy',
                      onClick: handleAdd,
                    },
                    canBatchUpdate && {
                      key: 'edit',
                      icon: 'edit',
                      label: 'Batch Update',
                      onClick: () => setBatchEditing(true),
                    },
                    canReport && {
                      key: 'report',
                      icon: 'report',
                      label: 'Report',
                      onClick: handleReport,
                    },
                  ].filter((option) => option)}
                  hideOnEmpty
                />
              </>
            }
          />
        </Header>
        <Body className="body-scrollbar">
          {editingPolicy === 'new' && (
            <PolicyFormContainer>
              <CollapsedContainer>
                <PolicyTitle>#NEW</PolicyTitle>
                <IconButton icon="upArrow" disabled />
              </CollapsedContainer>
              <PolicyForm editing onSave={handleSave} onCancel={handleCancel} />
            </PolicyFormContainer>
          )}
          {splitIntoCategories(policyKey, policies).map(
            ({ name: categoryName, policies: categoryPolicies }) =>
              categoryPolicies.length > 0 && (
                <React.Fragment key={categoryName || 'all'}>
                  {!!categoryName && <CategoryName>{categoryName}</CategoryName>}
                  {categoryPolicies.map((policy, index) => {
                    const editing = editingPolicy === policy.id;
                    const collapsed = !editing && !isOpenPolicy(policy.id);
                    const handleSelect = !editing ? () => toggleOpenPolicy(policy.id) : null;

                    return (
                      <PolicyFormContainer
                        key={policy.id}
                        sx={collapsed ? COLLAPSED_FORM_PROPERTIES : null}
                      >
                        <CollapsedContainer
                          sx={{ cursor: !editing ? 'pointer' : null }}
                          onClick={handleSelect}
                        >
                          <PolicyTitle>#{index + 1}</PolicyTitle>
                          <IconButton
                            icon={collapsed ? 'downArrow' : 'upArrow'}
                            disabled={editing}
                            stopPropagation
                          />
                        </CollapsedContainer>
                        <PolicyForm
                          policy={policy}
                          editing={editing}
                          collapsed={collapsed}
                          canUpdate={canUpdate}
                          canDelete={canDelete}
                          onSave={handleSave}
                          onDeleted={handleDeleted}
                          onCancel={handleCancel}
                          onEdit={() => handleEdit(policy)}
                          onSelect={collapsed ? handleSelect : null}
                        />
                      </PolicyFormContainer>
                    );
                  })}
                </React.Fragment>
              )
          )}
        </Body>
      </Container>
      {batchEditing && (
        <BatchUpdatePolicyForm
          currentProperty={currentProperty}
          policies={policies}
          onSave={handleBatchSave}
          onClose={() => setBatchEditing(false)}
        />
      )}
    </>
  );
};
export default InsurancePolicies;

// eslint-disable-next-line import/prefer-default-export
export const getGlCodeKeyById = (glCodes, id = null) => {
  if (id) {
    const glCode = glCodes.find((gl) => gl.id === id);
    return glCode?.key;
  }
  return null;
};

export const getGlCodeIdByKey = (glCodes, key = null) => {
  if (key) {
    const glCode = glCodes.find((gl) => gl.key === key);
    return glCode?.id;
  }
  return null;
};

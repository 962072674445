import { isAdmin } from 'domain/roles';

const defineRulesForDirectory = (can, user) => {
  can('view', 'PropertyDirectory');
  can('view', 'Directory');

  if (isAdmin(user)) {
    can('create', 'Directory');
    can('update', 'Directory');
    can('manage_permissions', 'Directory');
    can('delete', 'Directory');
  }
};

export default defineRulesForDirectory;

import React from 'react';
import { styled } from '@mui/material';
import { ReactComponent as Svg } from 'assets/svg/logo_login.svg';

const MainContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',
  flexDirection: 'column',
  width: '371px',
  height: '100%',
});

const Text = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: '#6E4A10',
  fontWeight: 'bold',
  textAlign: 'center',
});

const SvgContainer = styled('div')({
  marginTop: '28px',
});

const TextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '32px',
});

const Logo = () => (
  <MainContainer>
    <SvgContainer>
      <Svg style={{ width: '128px', height: 'auto' }} />
    </SvgContainer>
    <TextContainer>
      <Text />
      <Text />
      <Text />
    </TextContainer>
  </MainContainer>
);

export default Logo;

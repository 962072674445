import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { updateConsolidatedDate } from 'services/propertyIndicators';
import useCategories from 'hooks/components/useCategories';
import { fetchIndicators } from 'slices/propertyIndicator';
import IconButton from 'components/shared/button/IconButton';
import { RawDatePicker } from 'components/shared/form/DatePicker';
import { formatDate } from 'utils/date';
import colors from 'utils/colors';
import useAbility from 'hooks/shared/useAbility';
import useSnackbar from 'hooks/shared/useSnackBar';

const ConsolidatedContainer = styled('div')({
  height: '25px',
  display: 'flex',
  gap: '8px',
});

const Title = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: colors.main.text,
});

const DateTitle = styled('span')({
  marginLeft: '4px',
  fontSize: '14px',
  color: colors.main.text,
});

const ConsolidatedDate = () => {
  const ability = useAbility();
  const dispatch = useDispatch();
  const { showSuccess, showError } = useSnackbar();

  const currentProperty = useSelector((state) => state.property.currentProperty);
  const propertyId = currentProperty ? currentProperty.id : null;
  const consolidatedDate = useSelector((state) => state.propertyIndicator.consolidatedDate);
  const { categoryId, subcategoryId } = useCategories();
  const canEdit = ability.can('update', 'PropertyIndicator');

  const [editingDate, setEditingDate] = useState(null);

  const handleEdit = () => {
    setEditingDate(consolidatedDate || '');
  };

  const handleCancelEdit = () => {
    setEditingDate(null);
  };

  const handleUpdate = async () => {
    try {
      await updateConsolidatedDate(propertyId, editingDate || null);
      dispatch(fetchIndicators(propertyId, categoryId, subcategoryId));
      setEditingDate(null);
      showSuccess('Date updated');
    } catch {
      showError('Error updating date');
    }
  };

  return (
    <ConsolidatedContainer
      onDoubleClick={canEdit && !editingDate ? handleEdit : null}
      sx={canEdit && !editingDate ? { cursor: 'pointer' } : null}
    >
      {editingDate === null ? (
        <Title>
          As of
          <DateTitle>{consolidatedDate ? formatDate(consolidatedDate) : ' -'}</DateTitle>
        </Title>
      ) : (
        <>
          <RawDatePicker
            inputFormat="MM/dd/yyyy"
            name="date"
            label="Date"
            value={editingDate}
            onChange={(date) => setEditingDate(date)}
            sx={{ width: '50%', marginTop: '-20px' }}
          />
          <ConsolidatedContainer>
            <IconButton icon="cancel" iconSize={20} onClick={handleCancelEdit} />
            <IconButton icon="confirm" iconSize={20} onClick={handleUpdate} />
          </ConsolidatedContainer>
        </>
      )}
    </ConsolidatedContainer>
  );
};

export default ConsolidatedDate;

import { getCategoryColor, isCategoryDarkColor } from './categories';

export const getPropertyColor = ({ category }) => getCategoryColor(category);

export const isPropertyDarkColor = ({ category }) => isCategoryDarkColor(category);

export const getPropertyLogoFormat = ({ key }) => {
  switch (key) {
    default:
      return { margin: 4 };
  }
};

export const hasBalance = ({ category: { key: categoryKey } }) => {
  switch (categoryKey) {
    case 'philanthropy':
    case 'life_insurance':
    case 'personal_property':
      return false;
    default:
      return true;
  }
};

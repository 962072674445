import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, Input, InputAdornment as InputAdornmentMui, styled } from '@mui/material';
import classnames from 'classnames';
import CancelIcon from '@mui/icons-material/Clear';
import colors from 'utils/colors';
import FormControl, { showFieldError, SIZE_PADDINGS } from './FormControl';
import NumberInput from '../inputs/NumberInput';
import PhoneInput from '../inputs/PhoneInput';
import useInputChange from './useInputChange';

export const TextInputControl = styled(FormControl)(({ size = 'medium', textalign }) => ({
  '& .MuiInput-root': {
    padding: SIZE_PADDINGS[size],
  },

  '& .MuiInput-input': {
    background: 'none',
    padding: '0px',
    textAlign: textalign || null,
  },
}));

export const InputAdornment = styled(InputAdornmentMui)({
  cursor: 'pointer',
  marginTop: '-13px',
  color: colors.primary[900],
});

const makeInputComponent = (type) => {
  switch (type) {
    case 'currency':
    case 'number':
      return NumberInput;
    case 'phone':
      return PhoneInput;

    default:
      return undefined;
  }
};

const makeInputProps = (type, clearPrefix) => {
  switch (type) {
    case 'currency':
      return {
        currency: true,
        showPrefix: !clearPrefix,
      };

    default:
      return undefined;
  }
};

const isEmptyValue = ({ value, type }) => !value || (type === 'phone' && value === '(');

const inputValue = ({ value, disabled, type }) => {
  if (!isEmptyValue({ value, type })) return value;

  return disabled ? '-' : '';
};

const inputType = ({ disabled, type }) => {
  if (type === 'phone' && disabled) return null;

  return type;
};

const makeInputEndAdornment = (onCancel) => {
  if (!onCancel) return null;

  return (
    <InputAdornment position="end" onClick={onCancel}>
      <CancelIcon />
    </InputAdornment>
  );
};

const selectOnFocus = ({ disabled, type }) => {
  if (disabled) return false;

  switch (type) {
    case 'currency':
    case 'number':
    case 'phone':
      return true;
    default:
      return false;
  }
};

const makeOnFocus = ({ disabled, type }) => {
  if (!selectOnFocus({ disabled, type })) return null;

  return (e) => window.setTimeout(() => e.target.select(), 5);
};

export const RawTextInput = ({
  value,
  error,
  onChange,
  onCancel,
  name,
  label,
  multiline,
  type,
  maxRows,
  disabled,
  clearPrefix,
  sx,
  size,
  leftLabel,
  textAlign,
  variant,
}) => {
  const effectiveType = inputType({ disabled, type });
  const effectiveValue = inputValue({ value, disabled, type });
  const inputComponent = makeInputComponent(effectiveType);
  const inputProps = makeInputProps(effectiveType, clearPrefix);
  const endAdornment = makeInputEndAdornment(onCancel);
  const onFocus = makeOnFocus({ disabled, type });

  return (
    <TextInputControl
      className={classnames({ disabled, [`${variant}-variant`]: true, 'left-label': !!leftLabel })}
      sx={sx}
      size={size}
      leftlabel={leftLabel}
      textalign={textAlign}
    >
      <InputLabel error={error}>{label}</InputLabel>
      <Input
        name={name}
        multiline={multiline}
        maxRows={multiline ? maxRows : null}
        value={effectiveValue}
        error={error}
        type={effectiveType}
        disabled={disabled}
        inputComponent={inputComponent}
        inputProps={inputProps}
        onChange={onChange}
        onFocus={onFocus}
        endAdornment={endAdornment}
      />
    </TextInputControl>
  );
};

const TextInput = (props) => {
  const { name, formik, onChange, fixedValue } = props;

  const { value, onChange: onOptimizedChange } = useInputChange({
    value: fixedValue !== null ? fixedValue : formik.values[name],
    onChange: onChange || formik.handleChange,
  });

  return (
    <RawTextInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={value}
      error={showFieldError(formik, name)}
      onChange={onOptimizedChange}
    />
  );
};

TextInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  fixedValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  type: PropTypes.string,
  maxRows: PropTypes.number,
  disabled: PropTypes.bool,
  sx: PropTypes.shape(),
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  clearPrefix: PropTypes.bool,
  size: PropTypes.string,
  leftLabel: PropTypes.number,
  textAlign: PropTypes.string,
  variant: PropTypes.string,
};

TextInput.defaultProps = {
  label: '',
  fixedValue: null,
  multiline: null,
  maxRows: 4,
  disabled: false,
  type: null,
  sx: null,
  onChange: null,
  onCancel: null,
  clearPrefix: true,
  size: 'medium',
  leftLabel: null,
  textAlign: null,
  variant: 'standard',
};

export default TextInput;

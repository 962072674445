import React, { useEffect } from 'react';
import { styled } from '@mui/material';
import colors from 'utils/colors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPropertyUsers } from 'slices/property';
import { getUserFullNameById } from 'domain/user';
import Button from 'components/shared/button/Button';
import { useInterval } from 'react-use';
import { fetchPrivateChats, clearPrivateChats } from 'slices/privateChat';
import usePrivateChats from 'hooks/components/usePrivateChats';

const MainContainer = styled('div')({
  display: 'flex',
  width: '100%',
  alignContent: 'center',
  padding: '4px 8px',
  flexDirection: 'column',
});

const ChatContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  borderRadius: '4px',
  backgroundColor: colors.primary[25],
});

const Title = styled('div')({
  fontWeight: 'bold',
  marginLeft: '12px',
});

const ChatItem = styled('div')((props) => ({
  padding: '8px 12px',
  fontSize: '14px',
  borderRadius: '4px',
  cursor: 'pointer',
  marginTop: '4px',
  backgroundColor: props.selected ? colors.primary[100] : 'transparent',
  '&:hover': {
    backgroundColor: colors.primary[50],
  },
  '&:active': {
    backgroundColor: colors.primary[200],
  },
  fontWeight: props.unread ? 'bold' : '',
}));

const ChatsList = () => {
  const dispatch = useDispatch();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const propertyUsers = useSelector((state) => state.property.users);
  const privateChats = useSelector((state) => state.privateChat.privateChats);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { privateChatId: currentPrivateChatId, onShowDetails, onCloseDetails } = usePrivateChats();

  useEffect(() => {
    dispatch(fetchPropertyUsers(currentProperty.id));
    dispatch(fetchPrivateChats(currentProperty.id));

    return () => dispatch(clearPrivateChats());
  }, []);

  useInterval(async () => {
    dispatch(fetchPrivateChats(currentProperty.id));
  }, 10000);

  const handleNewChat = () => onShowDetails(currentProperty.id);

  const handleOpenChat = (privateChat) => {
    if (privateChat.id === currentPrivateChatId) {
      onCloseDetails();
    } else {
      onShowDetails(currentProperty.id, privateChat.id);
    }
  };

  if (!propertyUsers) return null;

  return (
    <MainContainer>
      <ChatContainer>
        <Title>Private Chats</Title>
        <Button variant="transparent" endIcon="add" onClick={handleNewChat} />
      </ChatContainer>

      {(privateChats || []).map((privateChat) => (
        <ChatItem
          key={privateChat.id}
          onClick={() => handleOpenChat(privateChat)}
          selected={privateChat.id === currentPrivateChatId}
          unread={privateChat.chat.unread_messages ? 1 : 0}
        >
          {privateChat.members
            .filter((member) => member !== currentUser.id)
            .map((member) => getUserFullNameById(propertyUsers, member))
            .join(', ')}
        </ChatItem>
      ))}
    </MainContainer>
  );
};

export default ChatsList;

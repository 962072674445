import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Input,
  InputLabel,
  Select as SelectMui,
  Chip,
  MenuItem as MenuItemMui,
  styled,
} from '@mui/material';
import classnames from 'classnames';
import { valueFromObjectKeys } from 'utils/values';
import colors from 'utils/colors';
import FormControl, { showFieldError, SIZE_HEIGHTS } from './FormControl';
import { RawTextInput } from './TextInput';
import useInputChange from './useInputChange';

const SIZE_PADDINGS = {
  medium: '22px 36px 6px 16px',
  small: '16px 32px 2px 12px',
};

const SIZE_ICON_RIGHT = {
  medium: 16,
  small: 12,
};

const SelectControl = styled(FormControl)(({ size = 'medium' }) => ({
  '& .MuiSelect-select': {
    minHeight: `${SIZE_HEIGHTS[size]}px!important`,
    background: 'none',
    padding: `${SIZE_PADDINGS[size]}!important`,
  },

  '& .MuiSelect-icon': {
    right: `${SIZE_ICON_RIGHT[size]}px`,
    color: colors.primary[900],
  },

  '&.disabled': {
    '& .MuiChip-root': {
      backgroundColor: colors.main.disabledBackground,
      border: `1px solid ${colors.main.controlBorder}`,
      cursor: 'default',
    },

    '& .MuiSelect-icon': {
      display: 'none',
    },
  },

  '& .MuiPaper-root': {
    background: 'red',
  },
}));

const SelectControlWithoutLabel = styled(SelectControl)({
  '& .MuiSelect-select': {
    padding: '6px 36px 6px 16px!important',
  },
});

const SelectControlClean = styled(SelectControl)({
  marginBottom: '0px',

  '& .MuiInput-root': {
    backgroundColor: 'unset',
    border: 'unset',

    '&.Mui-error, &.Mui-focused, &.Mui-focused': {
      backgroundColor: 'unset',
      borderColor: 'unset',
    },
  },

  '& .MuiSelect-select': {
    padding: '0px 36px 0px 0px!important',

    '&:focus': {
      backgroundColor: 'unset',
    },
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '200px',
    },
  },
};

const MultiSelectContainer = styled(Box)({
  marginTop: '9px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '7px',
});

const MultiSelectChip = styled(Chip)({
  padding: '6px 12px',
  border: 'none',
  borderRadius: '4px',
  backgroundColor: colors.primary[50],
  cursor: 'pointer',

  '& .MuiChip-label': {
    padding: '0px',
    fontSize: '14px',
    lineHeight: '20px',
  },
});

const MenuItem = styled(MenuItemMui)({
  '&:hover': {
    backgroundColor: colors.main.menuHover,
  },
  '&.Mui-selected': {
    backgroundColor: colors.main.surfaceOverlay,
    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: colors.main.menuHover,
    },
  },
});

const optionId = (option) =>
  typeof option === 'object' ? valueFromObjectKeys(option, ['id', 'value']) : option;

const optionName = (option) =>
  typeof option === 'object' ? valueFromObjectKeys(option, ['name', 'label']) : option;

const getControl = (cleanControl, label) => {
  if (cleanControl) return SelectControlClean;

  if (!label) return SelectControlWithoutLabel;

  return SelectControl;
};

export const RawSelect = ({
  value,
  error,
  onChange,
  name,
  label,
  multiple,
  disabled,
  options: rawOptions,
  sx,
  cleanControl,
  size,
}) => {
  const options = (rawOptions || []).map((option) => ({
    id: optionId(option),
    name: optionName(option),
    itemLabel: option.itemLabel,
  }));

  if (disabled && (!value || (multiple && value.length === 0))) {
    return <RawTextInput size={size} value="-" name={name} label={label} disabled sx={sx} />;
  }

  const Control = getControl(cleanControl, label);

  return (
    <Control size={size} variant="standard" className={classnames({ disabled })} sx={sx}>
      {label && <InputLabel error={error}>{label}</InputLabel>}
      <SelectMui
        name={name}
        label={label}
        multiple={multiple}
        value={value}
        error={error}
        disabled={disabled}
        onChange={onChange}
        input={multiple ? <Input /> : null}
        renderValue={
          multiple
            ? (selected) => (
                <MultiSelectContainer>
                  {options
                    .filter((option) => selected.includes(optionId(option)))
                    .map((option) => (
                      <MultiSelectChip key={option.id} label={option.name} />
                    ))}
                </MultiSelectContainer>
              )
            : null
        }
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.itemLabel || option.name}
          </MenuItem>
        ))}
      </SelectMui>
    </Control>
  );
};

const Select = (props) => {
  const { name, formik, onChange, fixedValue, multiple } = props;

  const { value, onChange: onOptimizedChange } = useInputChange({
    value: fixedValue !== null ? fixedValue : formik.values[name],
    onChange: onChange || formik.handleChange,
    initialValue: multiple ? [] : '',
  });

  return (
    <RawSelect
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={value}
      error={showFieldError(formik, name)}
      onChange={onOptimizedChange}
    />
  );
};

Select.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  fixedValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  sx: PropTypes.shape(),
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
        label: PropTypes.string,
        menuLabel: PropTypes.string,
      }),
    ])
  ),
  onChange: PropTypes.func,
  size: PropTypes.string,
};

Select.defaultProps = {
  label: '',
  multiple: null,
  fixedValue: null,
  options: [],
  disabled: false,
  sx: null,
  onChange: null,
  size: 'medium',
};

export default Select;

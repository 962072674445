import { createSlice } from '@reduxjs/toolkit';
import { getDebtLog } from 'services/debtLog';

const initialState = {
  debtLog: null,
};

const debtLogSlice = createSlice({
  name: 'debtLog',
  initialState,
  reducers: {
    setDebtLog: (state, action) => ({ ...state, debtLog: action.payload }),
    clearDebtLog: (state) => ({ ...state, debtLog: null }),
  },
});

const { reducer, actions } = debtLogSlice;

export const { setDebtLog, clearDebtLog } = actions;

export const fetchDebtLog = () => async (dispatch) => {
  const debtLog = await getDebtLog();
  dispatch(setDebtLog(debtLog));
};

export default reducer;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useOpenItems from 'hooks/components/useOpenItems';
import { isOverdue, isOpen } from 'domain/openItems';
import colors from 'utils/colors';
import { zeroLeftNumber } from 'utils/number';
import { shortName } from 'utils/text';
import { fetchOpenItems, clearOpenItems } from 'slices/openItem';
import ListModal from 'components/shared/modal/ListModal';
import { renderTitleCell, renderDateCell, renderPillCell } from 'components/shared/table/Cell';
import useAbility from 'hooks/shared/useAbility';
import EventNoteIcon from '@mui/icons-material/EventNote';

const OpenItemsModal = ({ critical, overDue, onClose }) => {
  const dispatch = useDispatch();
  const { openItemId: showDetailsModal, onShowDetails } = useOpenItems();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const openItems = useSelector((state) => state.openItem.openItems);
  const ability = useAbility();

  const makeTitle = () => {
    if (overDue) {
      return 'Over Due Items';
    }
    if (critical) {
      return 'Critical Items';
    }

    return 'Open Items';
  };

  useEffect(
    () => () => {
      dispatch(clearOpenItems());
    },
    []
  );

  useEffect(() => {
    if (showDetailsModal) return;
    if (overDue) {
      dispatch(fetchOpenItems(currentProperty && currentProperty.id, { all: true }));
    } else {
      dispatch(fetchOpenItems(currentProperty && currentProperty.id, { critical, all: true }));
    }
  }, [showDetailsModal]);

  const columns = [
    {
      headerName: 'N°',
      field: 'number',
      flex: 0.2,
      valueGetter: ({ row: { number } }) => (number ? zeroLeftNumber(number) : '-'),
      renderCell: renderTitleCell,
    },
    !currentProperty && {
      headerName: 'Portfolio',
      field: 'property_name',
      flex: 3,
    },
    {
      headerName: 'Assigned From',
      field: 'requested_by_user_name',
      valueGetter: ({ value }) => (value ? shortName(value) : ''),
      flex: 1.5,
    },
    {
      headerName: 'Assigned To',
      field: 'assigned_to_user_name',
      valueGetter: ({ value }) => (value ? shortName(value) : ''),
      flex: 1.5,
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: currentProperty ? 6 : 4,
    },
    {
      headerName: 'Created',
      field: 'created_at',
      renderCell: renderDateCell,
    },
    {
      headerName: 'Date Due',
      field: 'due_date',
      renderCell: renderDateCell,
    },
    {
      headerName: 'Resolved On',
      field: 'resolved_at',
      renderCell: renderDateCell,
    },
    {
      headerName: 'Status',
      field: 'status',
      flex: 1,
      cellClassName: 'status',
      valueGetter: ({ row: openItem }) => (isOverdue(openItem) ? 'over due' : openItem.status),
      renderCell: ({ value }) =>
        renderPillCell({ value, colors: colors.pills[value] || colors.pills.draft }),
    },
  ].filter((column) => !!column);

  if (!openItems) return null;

  const getRowClassName = ({ row: openItem }) => {
    if (!isOpen(openItem)) return 'disabled-row';
    if (isOverdue(openItem)) return 'alerted-row';
    return null;
  };

  return (
    <ListModal
      title={makeTitle()}
      addButton={
        currentProperty && !overDue && ability.can('create', 'OpenItem')
          ? {
              label: `Add ${critical ? 'critical' : 'open'} item`,
              onClick: () => onShowDetails(currentProperty.id),
            }
          : null
      }
      onClose={onClose}
      pageSize={50}
      rows={
        overDue ? openItems.records.filter((openItem) => isOverdue(openItem)) : openItems.records
      }
      columns={columns}
      onRowClick={({ row: openItem }) => onShowDetails(openItem.property_id, openItem.id)}
      getRowClassName={getRowClassName}
      NoRowsIcon={EventNoteIcon}
      noRowsTitle={`No ${critical ? 'Critical' : 'Open'} Items found`}
    />
  );
};

export default OpenItemsModal;

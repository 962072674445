import React from 'react';
import { NumericFormat } from 'react-number-format';

const NumberInput = React.forwardRef((props, ref) => {
  const { onChange, showPrefix, currency, allowNegative, ...other } = props;

  return (
    <NumericFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix={showPrefix && currency ? '$ ' : ''}
      allowNegative={allowNegative}
      fixedDecimalScale={2}
    />
  );
});

export default NumberInput;

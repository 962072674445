import { isAdmin } from 'domain/roles';

const defineRulesForProperties = (can, user) => {
  if (isAdmin(user)) {
    can('add', 'Property');
    can('edit', 'Property');
  }
};

export default defineRulesForProperties;

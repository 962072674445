import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  makeValidatorSchema,
  passwordValidator,
  passwordConfirmationValidator,
  emailValidator,
  stringValidator,
} from 'utils/validators';
import useSnackbar from 'hooks/shared/useSnackBar';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import { newUser, updateUser, inviteUserToProperty, getUsers } from 'services/users';
import { fetchAllProperties } from 'slices/property';
import {
  sortedRoles,
  rolesLabels,
  hasPropertyScope,
  isGlobalUser,
  belongsToProperty,
} from 'domain/roles';
import FormModal from 'components/shared/modal/FormModal';
import TextInput from 'components/shared/form/TextInput';
import Select from 'components/shared/form/Select';
import { RawRadioGroup } from 'components/shared/form/RadioGroup';
import FormRow from 'components/shared/form/FormRow';
import Checkbox from 'components/shared/form/Checkbox';

const INVITE_USER_OPTIONS = [
  { id: 'existent_user', name: 'Existent User' },
  { id: 'new_user', name: 'New User' },
];

const UserModal = ({ title, onClose, editingUser, disabled }) => {
  const dispatch = useDispatch();
  const { showSuccess } = useSnackbar();
  const allProperties = useSelector((state) => state.property.allProperties);
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const canInviteExistent = !!currentProperty && !editingUser;
  const [availableUsers, setAvailableUsers] = useState(null);
  const [inviteExistent, setInviteExistent] = useState(canInviteExistent);

  const validationSchema = makeValidatorSchema(
    inviteExistent
      ? {
          userId: stringValidator({ required: canInviteExistent ? 'Must select an user' : '' }),
        }
      : {
          password: passwordValidator({
            required: !editingUser ? 'Must enter a password' : false,
          }),
          passwordConfirmation: passwordConfirmationValidator(),
          email: emailValidator({ required: 'Must enter an email address' }),
          firstName: stringValidator({ required: 'Must enter first name' }),
          lastName: stringValidator({ required: 'Must enter lastname' }),
          role: stringValidator({ required: 'Must select a role' }),
        }
  );

  const initializeForm = (user) =>
    user
      ? {
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          phoneNumber: user.phone_number || '',
          role: user.role,
          title: user.title || '',
          allProperties: !hasPropertyScope(user) || user.all_properties || false,
          properties: user.properties || [],
          password: '',
          passwordConfirmation: '',
          userId: user.id,
          setPassword: false,
        }
      : {
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          role: sortedRoles[0],
          title: '',
          allProperties: !hasPropertyScope({ role: sortedRoles[0] }),
          properties: currentProperty ? [currentProperty.id] : [],
          password: '',
          passwordConfirmation: '',
          userId: '',
          setPassword: true,
        };

  const makeFormRequest = (values) => ({
    first_name: values.firstName,
    last_name: values.lastName,
    email: values.email,
    phone_number: values.phoneNumber,
    role: values.role,
    title: values.title,
    all_properties: values.allProperties,
    properties: values.properties,
    password: (values.setPassword && values.password) || null,
  });

  const saveUser = async (request) =>
    editingUser ? updateUser(editingUser.id, request) : newUser(request);

  const handleSubmit = async (values) => {
    if (inviteExistent) {
      await inviteUserToProperty(currentProperty.id, values.userId);
      showSuccess('User Added');
    } else {
      const request = makeFormRequest(values);
      await saveUser(request);
      showSuccess(editingUser ? 'User Updated' : 'User Created');
    }
    onClose();
  };

  const handleInviteExistentChange = ({ setValues, handleReset }, mode) => {
    handleReset();
    setValues(initializeForm());
    setInviteExistent(mode === 'existent_user');
  };

  const handleInvitingUserChange = ({ setValues }, userId) => {
    setValues(initializeForm(availableUsers.find((user) => user.id === userId)));
  };

  const handleRoleChange = ({ values, setFieldValue }, role) => {
    if (hasPropertyScope({ role: values.role }) !== hasPropertyScope({ role }))
      setFieldValue('allProperties', !hasPropertyScope({ role }));
    setFieldValue('role', role);
  };

  const submitLabel = () => {
    if (editingUser) return 'Save';
    if (inviteExistent) return 'Add';
    return 'Create';
  };

  useEffect(() => {
    if (allProperties) return;
    dispatch(fetchAllProperties());
  }, []);

  useAsyncEffect(async () => {
    if (!canInviteExistent) return;

    setAvailableUsers(
      (await getUsers()).filter(
        (user) => !isGlobalUser(user) && !belongsToProperty(user, currentProperty.id)
      )
    );
  });

  if (!allProperties || (canInviteExistent && !availableUsers)) return null;

  return (
    <FormModal
      open
      title={title}
      onClose={onClose}
      initialValues={initializeForm(editingUser)}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError="Error saving User"
      submitButton={!disabled && { label: submitLabel() }}
      cancelButton
    >
      {(formik) => (
        <>
          {canInviteExistent && (
            <>
              <RawRadioGroup
                name="inviteExistent"
                value={inviteExistent ? 'existent_user' : 'new_user'}
                options={INVITE_USER_OPTIONS}
                onChange={(event) => handleInviteExistentChange(formik, event.target.value)}
                disabled={disabled}
              />
              {inviteExistent && (
                <Select
                  formik={formik}
                  name="userId"
                  label="User"
                  options={availableUsers}
                  onChange={(event) => handleInvitingUserChange(formik, event.target.value)}
                  disabled={disabled}
                />
              )}
            </>
          )}
          {!inviteExistent && (
            <FormRow>
              <TextInput
                formik={formik}
                name="firstName"
                label="First Name"
                disabled={disabled || inviteExistent}
                sx={{ width: '50%' }}
              />
              <TextInput
                formik={formik}
                name="lastName"
                label="Last Name"
                disabled={disabled || inviteExistent}
                sx={{ width: '50%' }}
              />
            </FormRow>
          )}
          <FormRow>
            <TextInput
              formik={formik}
              name="email"
              label="Email"
              disabled={disabled || inviteExistent}
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="phoneNumber"
              label="Phone Number"
              disabled={disabled || inviteExistent}
              sx={{ width: '50%' }}
              type="phone"
            />
          </FormRow>
          {!inviteExistent && !editingUser && !disabled && (
            <FormRow>
              <TextInput
                formik={formik}
                name="password"
                label={editingUser ? 'Change Password' : 'Password'}
                type="password"
                sx={{ width: '50%' }}
              />
              <TextInput
                formik={formik}
                name="passwordConfirmation"
                label="Password Confirmation"
                type="password"
                sx={{ width: '50%' }}
              />
            </FormRow>
          )}

          <FormRow>
            <Select
              formik={formik}
              name="role"
              label="Role"
              options={sortedRoles.map((role) => ({ id: role, label: rolesLabels[role] }))}
              disabled={disabled || inviteExistent}
              onChange={(event) => handleRoleChange(formik, event.target.value)}
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="title"
              label="Title"
              disabled={disabled || inviteExistent}
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <Checkbox
              formik={formik}
              name="allProperties"
              label="All Assets"
              disabled={
                disabled || canInviteExistent || !hasPropertyScope({ role: formik.values.role })
              }
            />
          </FormRow>

          {!isGlobalUser({
            role: formik.values.role,
            all_properties: formik.values.allProperties,
          }) && (
            <Select
              formik={formik}
              name="properties"
              label="Portfolio"
              multiple
              options={allProperties.map((property) => ({
                ...property,
                itemLabel: `${property.category.name} - ${property.name}`,
              }))}
              disabled={disabled || canInviteExistent}
            />
          )}

          {editingUser && !disabled && (
            <>
              <Checkbox formik={formik} name="setPassword" label="Change Password" />
              {formik.values.setPassword && (
                <FormRow>
                  <TextInput
                    formik={formik}
                    name="password"
                    label="Password"
                    type="password"
                    sx={{ width: '50%' }}
                  />
                  <TextInput
                    formik={formik}
                    name="passwordConfirmation"
                    label="Password Confirmation"
                    type="password"
                    sx={{ width: '50%' }}
                  />
                </FormRow>
              )}
            </>
          )}
        </>
      )}
    </FormModal>
  );
};

export default UserModal;

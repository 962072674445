import React from 'react';
import { makeValidatorSchema } from 'utils/validators';
import useSnackbar from 'hooks/shared/useSnackBar';
import FormModal from 'components/shared/modal/FormModal';
import TextInput from 'components/shared/form/TextInput';
import Select from 'components/shared/form/Select';
import DatePicker from 'components/shared/form/DatePicker';
import FormRow from 'components/shared/form/FormRow';
import { batchUpdatePolicies } from 'services/policies';
import { fieldNumber } from 'utils/number';
import { FieldArray } from 'formik';
import { getPolicyKey, POLICY_PREMIUM_FRECUENCIES } from 'domain/policies';

const ROW_GAP = '6';

const validationSchema = makeValidatorSchema({});

const AviationPolicyBatchUpdateForm = ({ currentProperty, policies, onSave, onClose }) => {
  const { showSuccess } = useSnackbar();
  const propertyId = currentProperty.id;
  const policyKey = getPolicyKey(currentProperty);

  const initializeForm = () => ({
    policies: policies.map((policy) => ({
      id: policy.id,
      insured: policy.insured,
      policy: policy.policy_number,
      premium: fieldNumber(policy.premium),
      premiumDueDate: policy.premium_due_date,
      premiumFrecuency: policy.premium_frequency,
    })),
  });

  const handleSubmit = async (values) => {
    const updatedPolicies = values.policies.map((policy) => ({
      id: policy.id,
      premium: policy.premium || '0',
      premium_due_date: policy.premiumDueDate || null,
      premium_frequency: policy.premiumFrecuency,
    }));
    await batchUpdatePolicies(propertyId, policyKey, updatedPolicies);
    showSuccess('Policies updated');
    onSave();
  };

  return (
    <FormModal
      open
      title="Batch update policies"
      onClose={onClose}
      initialValues={initializeForm()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError="Error batch updating policies"
      cancelButton
    >
      {(formik) => (
        <FieldArray
          name="policies"
          render={() => (
            <>
              {formik.values.policies.map((policy, index) => (
                <React.Fragment key={policy.id}>
                  <FormRow gap={ROW_GAP}>
                    <TextInput
                      formik={formik}
                      name={`policies[${index}].insured`}
                      fixedValue={policy.insured}
                      label="Insured"
                      disabled
                      sx={{ width: '100%' }}
                      size="small"
                    />
                    <TextInput
                      formik={formik}
                      name={`policies[${index}].policy`}
                      fixedValue={policy.policy}
                      label="Policy Number"
                      disabled
                      sx={{ width: '100%' }}
                      size="small"
                    />
                    <DatePicker
                      formik={formik}
                      name={`policies[${index}].premiumDueDate`}
                      fixedValue={policy.premiumDueDate}
                      label="Premium Due Date"
                      sx={{ width: '100%' }}
                      size="small"
                    />
                    <Select
                      formik={formik}
                      name={`policies[${index}].premiumFrecuency`}
                      fixedValue={policy.premiumFrecuency}
                      label="Premium Frequency"
                      options={POLICY_PREMIUM_FRECUENCIES.map((type) => ({
                        id: type.id,
                        label: type.name,
                      }))}
                      sx={{ width: '100%' }}
                      size="small"
                    />
                    <TextInput
                      formik={formik}
                      name={`policies[${index}].premium`}
                      fixedValue={policy.premium}
                      label="Premium"
                      sx={{ width: '100%' }}
                      size="small"
                      type="currency"
                      clearPrefix={false}
                    />
                  </FormRow>
                </React.Fragment>
              ))}
            </>
          )}
        />
      )}
    </FormModal>
  );
};

export default AviationPolicyBatchUpdateForm;

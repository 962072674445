/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import colors from 'utils/colors';
import { ReactComponent as UploaddSvg } from 'assets/svg/upload.svg';
import Button from '../button/Button';
import {} from './uploaderUtils';

const MAX_SIZE = 100 * 1024 * 1024; // 100MB

const Container = styled('div')({});

const Dragger = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '84px',
  border: `1px dashed ${colors.main.controlBorder}`,
  borderRadius: '4px',
  backgroundColor: colors.main.draggerBackground,
  cursor: 'pointer',
  '& > *:not(:first-of-type)': {
    marginLeft: '12px',
  },
});

const DraggerIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
});

const DraggerLabel = styled('div')({
  fontSize: '14px',
  lineHeight: '20px',
  color: colors.main.text,
});

const fileTypes = {
  image: {
    label: 'Photo',
    labelPlural: 'Photos',
    accept: { 'image/*': ['.jpg', '.jpeg', '.gif', '.png', '.dwg'] },
  },
  document: {
    accept: {
      'application/pdf': [],
      'text/plain': ['.doc', '.docx', '.xls', '.xlsx', '.txt'],
    },
    label: 'Document',
    labelPlural: 'Documents',
  },
  any: {
    accept: {
      'image/*': ['.jpg', '.jpeg', '.gif', '.png', '.dwg'],
      'audio/*': ['.mp3', '.wav', '.wma', '.mid', '.midi'],
      'video/*': ['.avi', '.m4v', '.mov', '.mp4', '.mpg', '.mpeg', '.wmv', '.swf'],
      'application/pdf': [],
      'text/plain': [
        '.doc',
        '.docx',
        '.xls',
        '.xlsx',
        '.7z',
        '.rar',
        '.zip',
        '.ios',
        '.csv',
        '.log',
        '.xml',
        '.email',
        '.eml',
        '.emlx',
        '.msg',
        '.oft',
        '.ost',
        '.pst',
        '.vcf',
        '.otf',
        '.ttf',
        '.ai',
        '.indd',
        '.bmp',
        '.ico',
        '.ps',
        '.eps',
        '.psd',
        '.svg',
        '.tif',
        '.tiff',
        '.pcx',
        '.rle',
        '.dib',
        '.htm',
        '.key',
        '.pps',
        '.ppt',
        '.pptx',
        '.xlsm',
        '.rtf',
        '.txt',
        '.dxf',
        '.cad',
        '.wire',
        '.fbx',
        '.cmp',
        '.gcd',
        '.skp',
        '.rfa',
        '.rvt',
        '.rte',
        '.nwd',
        '.ifc',
        '.ifcxml',
        '.cobie',
        '.fig',
        '.dwt',
        '.dxf',
        '.dwf',
        '.dst',
        '.mpp',
        '.vsd',
        '.3ds',
        '.3dxl',
        '.3ds',
      ],
    },
    label: 'File',
    labelPlural: 'Files',
  },
  pdf: {
    accept: { 'application/pdf': [] },
    label: 'PDFs',
    labelPlural: 'PDF',
  },
  csv: {
    accept: { 'text/csv': [] },
    label: 'CSVs',
    labelPlural: 'CSV',
  },
  custom: {
    label: 'File',
    labelPlural: 'Files',
  },
};

const makeLabel = (type, variant, plural) => {
  const typeLabel = type[plural ? 'labelPlural' : 'label'];
  switch (variant) {
    case 'button':
      return `Add ${typeLabel}`;
    default:
      return `Drag ${typeLabel} Here`;
  }
};

const makeControl = (variant, label) => {
  switch (variant) {
    case 'button':
      return (
        <Button variant="transparent" startIcon="add">
          {label}
        </Button>
      );
    default:
      return (
        <Dragger>
          <DraggerIcon>
            <UploaddSvg />
          </DraggerIcon>
          <DraggerLabel>{label}</DraggerLabel>
        </Dragger>
      );
  }
};

const Uploader = ({
  type: sourceType,
  extension,
  variant,
  label: sourceLabel,
  maxFiles,
  maxSize,
  multiple,
  noClick,
  noDrag,
  onChange,
}) => {
  const type = typeof sourceType === 'string' ? fileTypes[sourceType] : sourceType;
  const label = sourceLabel || makeLabel(type, variant, maxFiles !== 1);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles,
    maxSize,
    multiple,
    noClick,
    noDrag,
    accept: !type.accept ? `.${extension}` : type.accept,
    onDrop: onChange,
  });

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      {makeControl(variant, label)}
    </Container>
  );
};

Uploader.propTypes = {
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  variant: PropTypes.string,
  label: PropTypes.string,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  onChange: PropTypes.func,
};

Uploader.defaultProps = {
  type: 'any',
  variant: 'dragger',
  label: null,
  maxFiles: 0,
  maxSize: MAX_SIZE,
  multiple: true,
  noClick: false,
  noDrag: false,
  onChange: null,
};

export default Uploader;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { arrayValidator, makeValidatorSchema } from 'utils/validators';
import useSnackbar from 'hooks/shared/useSnackBar';
import useCategories from 'hooks/components/useCategories';
import FormModal from 'components/shared/modal/FormModal';
import FormDocuments from 'components/shared/uploader/FormDocuments';
import { readTextFile } from 'services/directUpload';
import { fetchIndicators } from 'slices/propertyIndicator';
import { batchUpdateIndicators } from 'services/propertyIndicators';
import { formatDate } from 'utils/date';

const validationSchema = makeValidatorSchema({
  balance: arrayValidator({ min: 1, minLabel: 'Must select Consolidated Balance CSV' }),
  profit_and_loss: arrayValidator({ min: 1, minLabel: 'Must select Consolidated P&L CSV' }),
  profit_and_loss_month: arrayValidator({ min: 1, minLabel: 'Must select Monthly P&L CSV' }),
});

const LastUpdate = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
});

const BatchUpdateIndicators = ({ onClose }) => {
  const dispatch = useDispatch();
  const { showSuccess } = useSnackbar();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const propertyId = currentProperty ? currentProperty.id : null;
  const { categoryId, subcategoryId } = useCategories();
  const batchUpdateAt = useSelector((state) => state.propertyIndicator.batchUpdateAt);

  const initializeForm = {
    balance: [],
    profit_and_loss: [],
    profit_and_loss_month: [],
  };

  const handleSubmit = async (values) => {
    const request = {
      balance: await readTextFile(values.balance[0].file),
      profit_and_loss: await readTextFile(values.profit_and_loss[0].file),
      profit_and_loss_month: await readTextFile(values.profit_and_loss_month[0].file),
    };
    await batchUpdateIndicators(request);
    showSuccess('Indicators Updated');
    dispatch(fetchIndicators(propertyId, categoryId, subcategoryId));
    onClose();
  };

  return (
    <FormModal
      open
      title="Update Numbers from CSV"
      onClose={onClose}
      initialValues={initializeForm}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError="Error updating numbers"
      cancelButton
    >
      {(formik) => (
        <>
          <FormDocuments
            formik={formik}
            disabled={false}
            multiple={false}
            type="csv"
            name="balance"
            title="Consolidated Balance"
          />
          <br />
          <FormDocuments
            formik={formik}
            disabled={false}
            multiple={false}
            type="csv"
            name="profit_and_loss"
            title="Consolidated Profit & Loss"
          />
          <br />
          <FormDocuments
            formik={formik}
            disabled={false}
            multiple={false}
            type="csv"
            name="profit_and_loss_month"
            title="Monthly Profit & Loss"
          />
          <br />
          <LastUpdate>
            Date of Last Upload: {batchUpdateAt ? formatDate(batchUpdateAt) : '-'}
          </LastUpdate>
        </>
      )}
    </FormModal>
  );
};

export default BatchUpdateIndicators;

const colors = {
  main: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    surfaceOverlay: '#EDF2F266',
    error: '#B3261E',
    errorBackground: '#F9EAEA',
    text: 'rgba(0, 0, 0, 0.87)',
    blackText: '#000',
    whiteText: 'rgba(255, 255, 255, 0.85)',
    controlBorder: 'rgba(0, 0, 0, 0.12)',
    menuHover: '#EDF2F2',
    draggerBackground: '#EDF2F266',
    overlayFocused: 'rgba(108, 101, 51, 0.12)',
    disabledBackground: 'rgba(255, 255, 255, 0.38)',
    disabledLabel: 'rgba(0, 0, 0, 0.38)',
    greyIcon: 'rgba(0, 0, 0, 0.6)',
    iconColor: 'rgba(0, 0, 0, 0.6)',
    clearTableBorder: 'rgba(33, 33, 33, 0.08)',
    success: '#286C00',
    read: '#00B1AC',
    emptyTableIcon: '#EAF0F0',
    whiteOpacity:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.9) 40%, rgba(255, 255, 255, 0.99) 70%)',
  },
  sidebar: {
    headerBackground: '#F5F5F4',
  },
  rightsection: {
    background: 'linear-gradient(0deg, #F5F5F4, #F5F5F4), #FFFFFF',
  },
  pills: {
    draft: {
      background: 'rgba(0, 0, 0, 0.08)',
      color: 'rgba(0, 0, 0, 0.87)',
      border: 'rgba(0, 0, 0, 0.87)',
    },
    pending: { background: '#EBEAD2', color: 'rgba(0, 0, 0, 0.87)', border: '#6C6533' },
    resolved: { background: '#6C6533', color: '#FFFFFF', border: 'rgba(0, 0, 0, 0.12)' },
    'over due': { background: '#993816', color: '#FFFFFF', border: '#89300E' },
    paid: { background: '#6C6533', color: '#FFFFFF', border: 'rgba(0, 0, 0, 0.12)' },
  },
  categories: {
    default: '#9E9E9E',
    real_estate: '#425567',
    investment_account: '#7690A7',
    financial_account: '#50677C',
    life_insurance: '#A2B4C3',
    personal_property: '#527572',
    business_interest: '#67938F',
    trust: '#A2BEBB',
    loan_and_receivable: '#C9D9D8',
    digital_asset: '#575757',
    end_of_life: '#D5E6E4',
    philanthropy: '#DEF0EF',
    other: '#9E9E9E',
  },
  inputs: {
    red: '#D7978B',
    yellow: '#E5BF7F',
    green: '#99AE7D',
    blue: '#88B8FD',
    purple: '#9999CC',
    orange: '#EDA269',
    grey: '#A3A3A3',
    white: '#FFFFFF',
  },
  primary: {
    25: '#EDF2F2',
    50: '#EDF2F2',
    100: '#EAF0F0',
    200: '#DEE8E7',
    300: '#D5E2E0',
    400: '#C9D9D8',
    500: '#C0D3D1',
    600: '#A2BEBB',
    700: '#84A9A5',
    800: '#67938F',
    900: '#527572',
  },
  secondary: {
    50: '#EFF2F5',
    100: '#E3E8ED',
    200: '#CDD7DF',
    300: '#B8C5D1',
    400: '#A2B4C3',
    500: '#8DA3B6',
    600: '#7690A7',
    700: '#607C95',
    800: '#50677C',
    900: '#425567',
  },
  tertiary: {
    50: '#F2F2F2',
    100: '#E8E8E8',
    200: '#CFCFCF',
    300: '#B8B8B8',
    400: '#9E9E9E',
    500: '#878787',
    600: '#707070',
    700: '#474747',
    800: '#474747',
    900: '#3B3B3B',
  },
};

export default colors;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MobileAppProvider from 'app/MobileAppProvider';
import AuthProvider from 'app/AuthProvider';
import ThemeProvider from 'app/ThemeProvider';
import SnackbarProvider from 'app/SnackbarProvider';
import { AbilityContext } from 'app/Can';
import ability from 'ability/ability';
import Login from 'pages/Login/Login';
import Dashboard from 'pages/Dashboard/Dashboard';
import Directory from 'pages/Directory/Directory';
import OpenItemsReport from 'pages/OpenItemsReport/OpenItemsReport';
import Property from 'pages/Property/Property';
import Receipts from 'pages/Receipts/Receipts';
import PleasePay from 'pages/PleasePay/PleasePay';
import Documents from 'pages/Documents/Documents';
import PrivateRoute from './PrivateRoute';

const sections = [
  {
    component: Dashboard,
    path: '/',
  },
  {
    component: Directory,
    permission: 'Directory',
    path: '/directory',
  },
  {
    component: Documents,
    permission: 'Documents',
    path: '/documents/*',
  },
  {
    component: OpenItemsReport,
    permission: 'OpenItemReports',
    path: '/open-items-report',
  },
  {
    component: Property,
    path: '/properties/:id/:section/*',
  },
  {
    component: Receipts,
    permission: 'Receipt',
    path: '/receipts',
  },
  {
    component: PleasePay,
    permission: 'PleasePay',
    path: '/please-pay',
  },
];

const App = () => (
  <MobileAppProvider>
    <AuthProvider>
      <AbilityContext.Provider value={ability}>
        <ThemeProvider>
          <SnackbarProvider>
            <Router>
              <Routes>
                <Route path="/login" element={<Login />} />

                {sections.map(({ component: Component, permission, path }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <PrivateRoute permission={permission}>
                        <Component />
                      </PrivateRoute>
                    }
                  />
                ))}
              </Routes>
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </AbilityContext.Provider>
    </AuthProvider>
  </MobileAppProvider>
);

export default App;

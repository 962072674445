import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getReceipt } from 'services/receipts';
import { getProperty } from 'services/properties';
import useAbility from 'hooks/shared/useAbility';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import ReceiptModal from './ReceiptModal';

const UpdateReceipt = ({ propertyId, receiptId, onClose }) => {
  const ability = useAbility();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const [property, setProperty] = useState(null);
  const [receipt, setReceipt] = useState(null);

  const disabled = !receipt || !ability.can('update', receipt);

  useAsyncEffect(async () => {
    setProperty(
      currentProperty && currentProperty.id === propertyId
        ? currentProperty
        : await getProperty(propertyId)
    );
    setReceipt(await getReceipt(propertyId, receiptId));
  });

  return (
    property &&
    receipt && (
      <ReceiptModal
        title={!disabled ? 'Edit Receipt' : 'Receipt'}
        disabled={disabled}
        property={property}
        editingReceipt={receipt}
        onClose={onClose}
      />
    )
  );
};
export default UpdateReceipt;

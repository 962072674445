import React, { useState, useEffect } from 'react';
import Table from 'components/shared/table/Table';
import useSnackbar from 'hooks/shared/useSnackBar';
import useAbility from 'hooks/shared/useAbility';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import colors from 'utils/colors';
import {
  renderActionsCell,
  renderLongCell,
  renderDateCell,
  renderPillCell,
} from 'components/shared/table/Cell';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import { deletePleasePay, getPleasePays } from 'services/pleasePay';
import { fetchVendors } from 'slices/vendor';
import { useSelector, useDispatch } from 'react-redux';
import { formatCurrency } from 'utils/number';
import { getUserFullNameById } from 'domain/user';
import { fetchPropertyUsers } from 'slices/property';
import { fetchAllUsers } from 'slices/user';
import usePleasePays from 'hooks/components/usePleasePays';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { clearFilters } from 'slices/pleasePay';
import PleasePayFilters from './PleasePayFilters';

const PleasePayTable = () => {
  const dispatch = useDispatch();
  const ability = useAbility();
  const { showSuccess, showError } = useSnackbar();
  const { pleasePayId: selectedPleasePay, updateCounters, onShowDetails } = usePleasePays();
  const [deletingPleasePay, setDeletingPleasePay] = useState(null);
  const [filtersChange, setFiltersChange] = useState(false);
  const [pleasePays, setPleasePays] = useState(null);
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const propertyUsers = useSelector((state) => state.property.users);
  const globalUsers = useSelector((state) => state.user);
  const filters = useSelector((state) => state.pleasePay.filters);
  const users = currentProperty ? propertyUsers : globalUsers;

  useEffect(() => {
    if (!users && currentProperty) {
      dispatch(fetchPropertyUsers(currentProperty.id));
    } else {
      dispatch(fetchAllUsers());
    }
    dispatch(clearFilters());
  }, []);

  useAsyncEffect(
    async () => {
      if (pleasePays && (deletingPleasePay || selectedPleasePay)) return;

      dispatch(fetchVendors());
      const fetchPleasePay = await getPleasePays(currentProperty ? currentProperty.id : null, {
        ...(filters || {}),
        all: true,
      });
      setPleasePays(fetchPleasePay.records);
      setFiltersChange(false);
    },
    undefined,
    [selectedPleasePay, deletingPleasePay, filtersChange]
  );

  const handleAskDeletion = (pleasePay) => {
    setDeletingPleasePay(pleasePay);
  };

  const handleCancelDelete = () => {
    setDeletingPleasePay(null);
  };

  const handleDelete = async () => {
    try {
      await deletePleasePay(deletingPleasePay.property_id, deletingPleasePay.id);
      showSuccess('Please Pay deleted');
      setDeletingPleasePay(null);
      updateCounters();
    } catch {
      showError('Error deleting Please Pay');
    }
  };

  const columns = [
    {
      headerName: 'Due Date',
      field: 'paid_by_date',
      renderCell: renderDateCell,
    },
    !currentProperty && {
      headerName: 'Portfolio',
      field: 'property_name',
      flex: 2,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      flex: 1,
      valueGetter: ({ row: { amount } }) => formatCurrency(amount),
    },
    {
      headerName: 'Requested By',
      field: 'user_id',
      flex: 1.5,
      valueGetter: ({ row: { user_id: userId } }) => getUserFullNameById(users, userId),
    },
    {
      headerName: 'Vendor',
      field: 'receipt_vendor',
      flex: 1.5,
      valueGetter: ({ row: { receipt_vendor: vendor } }) => (vendor ? `${vendor.name}` : null),
    },
    {
      headerName: 'GL Codes',
      field: 'please_pay_items',
      flex: 1,
      valueGetter: ({ row: { please_pay_items: pleasePayItems } }) =>
        pleasePayItems
          .filter((item) => item.gl_code && item.gl_code.id)
          .map((item) => (item.gl_code.key === 'other' ? item.other_gl_code : item.gl_code.key))
          .join(', '),
      renderCell: ({ value, row: { please_pay_items: pleasePayItems } }) =>
        renderLongCell({
          value,
          tooltip: pleasePayItems
            .filter((item) => item.gl_code && item.gl_code.id)
            .map((item) =>
              item.gl_code.key === 'other'
                ? item.other_gl_code
                : `${item.gl_code.key} - ${item.gl_code.name}`
            )
            .join('\n'),
        }),
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: !currentProperty ? 2 : 3,
    },
    {
      headerName: 'Status',
      field: 'paid',
      flex: 1,
      valueGetter: ({ row: { paid } }) => (paid ? 'paid' : 'pending'),
      renderCell: ({ value }) => renderPillCell({ value, colors: colors.pills[value] }),
    },
    {
      headerName: 'Actions',
      field: 'actions',
      flex: 1,
      renderCell: ({ row: pleasePay }) => {
        const canEdit = ability.can('update', pleasePay);
        const canDelete = ability.can('delete', pleasePay);
        return renderActionsCell({
          icons: [
            {
              type: canEdit ? 'edit' : 'view',
              onClick: () => onShowDetails(pleasePay.property_id, pleasePay.id),
            },
            canDelete && {
              type: 'delete',
              onClick: () => {
                handleAskDeletion(pleasePay);
              },
            },
          ].filter((icon) => icon),
          color: colors.primary[900],
        });
      },
    },
  ].filter((column) => column);

  const changeFilter = () => {
    setFiltersChange(true);
  };

  if (!pleasePays || !users) return null;

  return (
    <>
      <PleasePayFilters changeFilter={changeFilter} />
      <Table
        rows={pleasePays}
        columns={columns}
        pageSize={50}
        onRowDoubleClick={({ row: pleasePay }) =>
          onShowDetails(pleasePay.property_id, pleasePay.id)
        }
        getRowClassName={({ row: { paid } }) => (paid ? 'disabled-row' : null)}
        NoRowsIcon={ReceiptLongOutlinedIcon}
        noRowsTitle="No please pay found"
        allowExport
      />
      {deletingPleasePay && (
        <ConfirmationModal
          title="Please Pay will be deleted"
          description="Are you sure?"
          onCancel={handleCancelDelete}
          onConfirm={handleDelete}
        />
      )}
    </>
  );
};

export default PleasePayTable;

import * as Yup from 'yup';

const DEFAULT_DATE_OPTIONS = {
  min: new Date('1900-01-01'), // needed to incomplete dates
};

const DEFAULT_PASSWORD_OPTIONS = {
  min: 6,
};

const getMessage = (value) => (typeof value === 'string' ? value : null);

const minValidator = (object, { min, minLabel }) => (min ? object.min(min, minLabel) : object);

const maxValidator = (object, { max, maxLabel }) => (max ? object.max(max, maxLabel) : object);

const requiredValidator = (object, { required }) =>
  required ? object.required(getMessage(required)) : object.nullable();

const BASIC_VALIDATORS = [minValidator, maxValidator, requiredValidator];

export const basicValidator = (object, options) =>
  BASIC_VALIDATORS.reduce((result, validator) => validator(result, options), object);

export const dateValidator = (options = {}) =>
  basicValidator(Yup.date(), { ...DEFAULT_DATE_OPTIONS, ...options });

export const stringValidator = (options = {}) => basicValidator(Yup.string(), options);

export const emailValidator = (options = {}) => basicValidator(Yup.string().email(), options);

export const arrayValidator = (options = {}) => basicValidator(Yup.array(), options);

export const positiveNumberValidator = (options = {}) =>
  basicValidator(
    Yup.number().typeError('Must specify a number').min(0, 'Value must be a positive number'),
    options
  );

export const makeValidatorSchema = (fields) => Yup.object().shape(fields);

export const passwordValidator = (options = {}) =>
  basicValidator(Yup.string(), { ...DEFAULT_PASSWORD_OPTIONS, ...options });
export const passwordConfirmationValidator = (options = {}) =>
  basicValidator(Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'), options);

// export const itemsValidator = (firstField, secondField, options = {}) => {
//   basicValidator(
//     Yup.array().of(
//       Yup.object().shape({
//         firstField: Yup.string(),
//         secondField: Yup.string(),
//       })
//     ),
//     options
//   );
// };

import axios from 'axios';

import { setListSubject } from 'utils/ability';
import { makeListUrl } from './base';

export const getNotifications = async (type, filters = {}, page = 0) => {
  const res = await axios.get(makeListUrl(`notifications`, { ...filters, type }, page));
  return setListSubject('Notifications', res.data);
};

export const getNotificationsCounters = async () => {
  const res = await axios.get(`notifications/counters`);
  return res.data;
};

export const setAllNotificationsAsRead = async (filters = {}) => {
  const res = await axios.post(makeListUrl(`notifications/read_all`, { ...filters }));
  return res.data;
};

export const setAllNotificationsAsUnread = async (filters = {}) => {
  const res = await axios.post(makeListUrl(`notifications/unread_all`, { ...filters }));
  return res.data;
};

export const setNotificationAsRead = async (notificationId, type, filters = {}) => {
  const res = await axios.post(
    makeListUrl(`notifications/${notificationId}/read`, { ...filters, type })
  );
  return res.data;
};

export const setNotificationAsUnread = async (notificationId, type, filters = {}) => {
  const res = await axios.post(
    makeListUrl(`notifications/${notificationId}/unread`, { ...filters, type })
  );
  return res.data;
};

import React from 'react';
import SummaryFields from './SummaryFields';
import SummaryField from './SummaryField';
import SummaryRow from './SummaryRow';

const FinancialAccountSummary = ({ property }) => {
  const autoDeducts = property.auto_deducts || [];
  if (!autoDeducts.length) return null;

  return (
    <SummaryFields title="Auto Deducts">
      {autoDeducts.map((autoDeduct, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SummaryField key={index}>
          <SummaryRow name="Payee" value={autoDeduct.payee} />
          <SummaryRow name="Date" value={autoDeduct.date} type="date" />
          <SummaryRow name="Amount" value={autoDeduct.amount} type="currency" />
        </SummaryField>
      ))}
    </SummaryFields>
  );
};

export default FinancialAccountSummary;

import React from 'react';
import { styled } from '@mui/material';
import { useSelector } from 'react-redux';
import PropertyIndicators from 'components/property-indicators/PropertyIndicators';
import PropertyInputs from 'components/property-inputs/PropertyInputs';
import { Can } from 'app/Can';
import useAbility from 'hooks/shared/useAbility';

const Container = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
});

const PropertyIndicatorsContainer = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '32px 24px',
  gap: '12px',
});

const PropertyInputsContainer = styled('div')({
  height: '100%',
  display: 'flex',

  '&:first-of-type': {
    width: '100%',
  },

  '& .property-inputs-list': {
    paddingTop: '30px',
  },
});

const getPropertyLayout = ({ category: { key: categoryKey } }) => {
  switch (categoryKey) {
    default:
      return 50;
  }
};

const Balance = () => {
  const ability = useAbility();
  const hasIndicators = ability.can('view', 'PropertyIndicator');
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const layout = getPropertyLayout(currentProperty);

  return (
    <Container>
      {hasIndicators && (
        <PropertyIndicatorsContainer style={{ width: `${layout}%` }}>
          {hasIndicators && (
            <PropertyIndicators
              withComposedIndicators
              sx={{
                height: '100%',
              }}
            />
          )}
        </PropertyIndicatorsContainer>
      )}
      <Can I="view" a="PropertyInput" ability={ability}>
        <PropertyInputsContainer style={{ width: `${100 - layout}%` }}>
          <PropertyInputs />
        </PropertyInputsContainer>
      </Can>
    </Container>
  );
};

export default Balance;

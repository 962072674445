import React from 'react';
import { styled } from '@mui/material';
import colors from 'utils/colors';
import { formatDate } from 'utils/date';
import { formatCurrency, formatNumber } from 'utils/number';

const Row = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  color: colors.main.blackText,
  fontSize: '14px',
  lineHeight: '20px',
  marginBottom: '4px',
});

const Name = styled('div')({
  fontWeight: '700',
});

const Value = styled('div')({
  '& .MuiInputBase-input': {
    textAlign: 'end',
  },
});

const getValue = (value, type) => {
  switch (type) {
    case 'date':
      return value ? formatDate(value) : '-';
    case 'currency':
      return formatCurrency(value);
    case 'number':
      return formatNumber(value);
    default:
      return value || '-';
  }
};

const SummaryRow = ({ name, value, type }) => (
  <Row>
    <Name>{name}:</Name>
    <Value>{getValue(value, type)}</Value>
  </Row>
);

export default SummaryRow;

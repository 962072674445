import { createSlice } from '@reduxjs/toolkit';
import { getCounters, getOpenItems, getOpenItem } from 'services/openItems';

const initialState = {
  counters: null,
  critical: false,
  openItems: null,
  currentOpenItem: null,
};

const openItemSlice = createSlice({
  name: 'openItem',
  initialState,
  reducers: {
    setCounters: (state, action) => ({ ...state, counters: action.payload }),
    setOpenItems: (state, action) => ({ ...state, openItems: action.payload }),
    clearOpenItems: (state) => ({ ...state, openItems: null }),
    setCurrentOpenItem: (state, action) => ({ ...state, currentOpenItem: action.payload }),
    clearCurrentOpenItem: (state) => ({ ...state, currentOpenItem: null }),
  },
});

const { reducer, actions } = openItemSlice;

export const {
  setCounters,
  setOpenItems,
  clearOpenItems,
  setCurrentOpenItem,
  clearCurrentOpenItem,
} = actions;

export const fetchOpenItemCounters = () => async (dispatch) => {
  const counters = await getCounters();
  dispatch(setCounters(counters));
};

export const fetchOpenItems =
  (propertyId = null, filters = {}, page = 0) =>
  async (dispatch) => {
    const openItems = await getOpenItems(propertyId, filters, page);
    dispatch(setOpenItems(openItems));
  };

export const fetchCurrentOpenItem = (propertyId, openItemId) => async (dispatch) => {
  const openItem = await getOpenItem(propertyId, openItemId);
  dispatch(setCurrentOpenItem(openItem));
};

export default reducer;

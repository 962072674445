import { useSnackbar as useSnackbarRoot } from 'notistack';
import { capitalize } from 'utils/text';

const DEFAULT_ENQUEUE = {
  autoHideDuration: 3000,
};
const VARIANTS = ['error', 'success', 'warning', 'info'];

const useSnackbar = () => {
  const { enqueueSnackbar: enqueueSnackbarRoot, closeSnackbar: closeSnackbarRoot } =
    useSnackbarRoot();

  const options = {
    enqueueSnackbar: (message, props) =>
      enqueueSnackbarRoot(message, { ...DEFAULT_ENQUEUE, ...props }),
    closeSnackbar: (message) => closeSnackbarRoot(message),
    cleanSnackbar: () => closeSnackbarRoot(),
  };
  return {
    ...options,
    ...VARIANTS.reduce(
      (methods, variant) => ({
        ...methods,
        [`show${capitalize(variant)}`]: (message, props = {}) =>
          options.enqueueSnackbar(message, { ...props, variant }),
      }),
      {}
    ),
  };
};

export default useSnackbar;

import React, { useState } from 'react';
import { makeValidatorSchema } from 'utils/validators';
import useSnackbar from 'hooks/shared/useSnackBar';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import FormModal from 'components/shared/modal/FormModal';
import FormFiles from 'components/shared/uploader/FormFiles';
import { uploadFormFiles } from 'services/directUpload';
import { createDocument } from 'services/documents';
import { getUsers } from 'services/users';
import Select from 'components/shared/form/Select';
import { PERMISSIONS } from 'domain/permissions';

const validationSchema = makeValidatorSchema({});

const UploadFile = ({ onClose, propertyId, openFolderId }) => {
  const { showSuccess } = useSnackbar();
  const [propertyUsers, setPropertyUsers] = useState(null);

  const initializeForm = {
    documents: [],
    permission: 'public_access',
    shareds: [],
  };

  const handleSubmit = async (values) => {
    const uploadedDocuments = await uploadFormFiles({ documents: values.documents });
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < values.documents.length; i++) {
      const request = {
        entry_type: 'document',
        name: values.documents[i].filename,
        folder_id: openFolderId,
        file: uploadedDocuments.documents[i],
        permission: values.permission,
        shareds: values.shareds,
      };
      // eslint-disable-next-line no-await-in-loop
      await createDocument(propertyId, request);
    }
    showSuccess('File Uploaded');
    onClose();
  };

  useAsyncEffect(async () => {
    setPropertyUsers(await getUsers(propertyId));
  });

  if (!propertyUsers) return null;

  return (
    <FormModal
      open
      title="Upload File"
      onClose={onClose}
      initialValues={initializeForm}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError="Error uploading file"
      submitButton={true && { label: 'Upload' }}
      cancelButton
    >
      {(formik) => (
        <>
          <Select
            formik={formik}
            name="permission"
            label="Permission"
            options={PERMISSIONS}
            sx={{ width: '100%' }}
          />
          {formik.values.permission === 'shared_access' && (
            <Select formik={formik} name="shareds" label="Users" multiple options={propertyUsers} />
          )}
          <FormFiles formik={formik} disabled={false} multiple type="any" />
        </>
      )}
    </FormModal>
  );
};

export default UploadFile;

import React from 'react';
import { styled } from '@mui/material';

const Row = styled('div')({
  display: 'flex',
  width: '100%',
});

const FormRow = ({ children, gap = '32', sx = {}, onClick }) => (
  <Row sx={{ gap: `${gap}px`, ...sx }} onClick={onClick}>
    {children}
  </Row>
);

export default FormRow;

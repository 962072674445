/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import { useMap } from 'react-use';
import colors from 'utils/colors';
import { formatDate } from 'utils/date';
import EditableField from 'components/shared/editable/EditableField';
import IconButton from 'components/shared/button/IconButton';
import ColorPicker from 'components/shared/color-picker/ColorPicker';
import CustomizableArea from 'components/shared/inputs/CustomizableArea';
import { RawSelect } from 'components/shared/form/Select';
import { PERMISSIONS, getPermissionIcon, getPermissionTooltip } from 'domain/permissions';

const COLORS = ['white', 'red', 'yellow', 'green', 'blue', 'purple', 'orange', 'grey'];

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  borderRadius: '4px',

  '& a': {
    color: colors.tertiary[900],
  },
});

const Title = styled('div')({
  width: '100%',
  fontSize: '20px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: colors.tertiary[900],
  marginBottom: '12px',
});

const Description = styled('div')({
  width: '100%',
  fontSize: '16px',
  lineHeight: '24px',
  color: colors.main.text,
  marginBottom: '12px',
});

const Footer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Creator = styled('div')({
  fontSize: '12px',
  lineHeight: '24px',
  color: colors.main.text,
  fontWeight: 'bold',
  marginRight: '24px',
});

const UpdatedAt = styled('div')({
  fontSize: '12px',
  lineHeight: '24px',
  color: colors.main.text,
  fontWeight: 'bold',
});

const ButtonsContainer = styled('div')({
  marginLeft: 'auto',
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});

const SharedUsersContainer = styled('div')({
  width: 'calc(100% - 56px)',
  display: 'flex',
  marginTop: '12px',
});

const PropertyInput = ({
  input: originalInput,
  index,
  users,
  canEdit,
  isEditing,
  onEdit,
  onDelete,
  onSave,
  onCancel,
}) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [input, { set: setField }] = useMap(originalInput);
  const {
    id,
    title,
    description,
    color,
    permission,
    shareds,
    updated_at: updatedAt,
    user_id: userId,
  } = isEditing ? input : originalInput;
  const colorHex = colors.inputs[color];

  const canSave = () => !!title && !!description;

  const getDescription = () => {
    if (isEditing) {
      return (
        <EditableField
          multiline
          value={description}
          disabled={!isEditing}
          onChange={(changedValue) => setField('description', changedValue)}
          placeholder="Description"
        />
      );
    }
    return <CustomizableArea text={description} />;
  };

  const getCreatorUser = () => {
    if (userId) return users.find((user) => user.id === userId);

    if (isEditing) return currentUser;

    return null;
  };
  const creatorUser = getCreatorUser();

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={!canEdit}>
      {(provided) => (
        <Container
          sx={{
            backgroundColor: `${colorHex}35`,
            border: `1px solid ${color === 'white' ? colors.main.controlBorder : colorHex}`,
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Title>
            <EditableField
              value={title}
              disabled={!isEditing}
              onChange={(changedValue) => setField('title', changedValue)}
              placeholder="Title"
            />
          </Title>
          <Description>{getDescription()}</Description>
          <Footer>
            {isEditing ? (
              <ColorPicker
                options={COLORS.map((optionColor) => ({
                  id: optionColor,
                  color: colors.inputs[optionColor],
                }))}
                value={color}
                onChange={(changedValue) => setField('color', changedValue)}
              />
            ) : (
              <>
                {creatorUser && <Creator>{`Created By: ${creatorUser.name}`}</Creator>}
                {updatedAt && <UpdatedAt>{`Modified: ${formatDate(updatedAt)}`}</UpdatedAt>}
              </>
            )}
            <ButtonsContainer>
              {!isEditing && (
                <IconButton
                  icon={getPermissionIcon(permission)}
                  tooltip={getPermissionTooltip(permission, shareds, users)}
                />
              )}
              {canEdit && (
                <>
                  <IconButton icon="edit" onClick={() => onEdit(input)} />
                  <IconButton icon="delete" onClick={onDelete} />
                </>
              )}
              {isEditing && (
                <>
                  <RawSelect
                    name="permission"
                    options={PERMISSIONS}
                    value={permission}
                    onChange={(e) => setField('permission', e.target.value)}
                    sx={{ width: '200px', marginBottom: '0px' }}
                  />
                  <IconButton icon="cancel" color={colors.tertiary[900]} onClick={onCancel} />
                  <IconButton
                    icon="confirm"
                    color={colors.primary[900]}
                    onClick={() => onSave(input)}
                    disabled={!canSave()}
                  />
                </>
              )}
            </ButtonsContainer>
          </Footer>
          {isEditing && permission === 'shared_access' && (
            <SharedUsersContainer>
              <RawSelect
                name="users"
                label="Users"
                value={shareds}
                multiple
                options={users.map((user) => ({
                  id: user.id,
                  label: user.name,
                }))}
                onChange={(e) => setField('shareds', e.target.value)}
                sx={{ width: '100%', marginBottom: '0px' }}
              />
            </SharedUsersContainer>
          )}
        </Container>
      )}
    </Draggable>
  );
};

export default PropertyInput;

import React from 'react';
import { makeValidatorSchema, stringValidator, dateValidator } from 'utils/validators';
import FormModal from 'components/shared/modal/FormModal';
import FormRow from 'components/shared/form/FormRow';
import TextInput from 'components/shared/form/TextInput';
import DatePicker from 'components/shared/form/DatePicker';
import FormImages from 'components/shared/uploader/FormImages';

const validationSchema = makeValidatorSchema({
  name: stringValidator({ required: 'Must enter a name' }),
  description: stringValidator(),
  purchase_date: dateValidator(),
  purchase_price: stringValidator(),
  current_value: stringValidator(),
  location_of_item: stringValidator(),
  beneficiary: stringValidator(),
  serial_identification: stringValidator(),
});

const PersonalPropertyPropertyModal = ({ currentProperty, title, onSubmit, onClose }) => {
  const initalizeSummary = () => {
    const summary = (currentProperty && currentProperty.summary) || {};
    return {
      description: summary.description || '',
      purchase_date: summary.purchase_date || '',
      purchase_price: summary.purchase_price || '',
      current_value: summary.current_value || '',
      location_of_item: summary.location_of_item || '',
      beneficiary: summary.beneficiary || '',
      serial_identification: summary.serial_identification || '',
    };
  };

  const initializeForm = () => ({
    ...(currentProperty
      ? {
          name: currentProperty.name,
          logo: currentProperty.logo ? [currentProperty.logo] : [],
        }
      : { name: '', logo: [] }),
    ...initalizeSummary(),
  });

  const getErrorMessage = () => (currentProperty ? `Error editing item` : `Error creating item`);

  const makeFormRequest = (values) => ({
    name: values.name,
    logo: values.logo,
    summary: {
      description: values.description,
      purchase_date: values.purchase_date,
      purchase_price: values.purchase_price,
      current_value: values.current_value,
      location_of_item: values.location_of_item,
      maturity_date: values.maturity_date,
      beneficiary: values.beneficiary,
      serial_identification: values.serial_identification,
    },
  });

  const handleSubmit = async (values) => {
    const request = makeFormRequest(values);
    return onSubmit(request);
  };

  return (
    <FormModal
      open
      title={title}
      onClose={onClose}
      initialValues={initializeForm()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError={getErrorMessage()}
      submitButton={{ label: currentProperty ? 'Save' : 'Create' }}
      cancelButton
    >
      {(formik) => (
        <>
          <FormRow>
            <TextInput formik={formik} name="name" label="Item Name" sx={{ width: '50%' }} />
            <TextInput
              formik={formik}
              name="description"
              label="Description"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <DatePicker
              formik={formik}
              name="purchase_date"
              label="Purchase Date"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="purchase_price"
              label="Purchase Price"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="current_value"
              label="Current Value"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
            <TextInput
              formik={formik}
              name="location_of_item"
              label="Location of Item"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="beneficiary"
              label="Beneficiary"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="serial_identification"
              label="Serial/Identification #"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormImages
            formik={formik}
            title={false}
            name="logo"
            addButtonLabel="Set Logo"
            singleImage
          />
        </>
      )}
    </FormModal>
  );
};
export default PersonalPropertyPropertyModal;

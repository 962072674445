import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import Modal from './Modal';
import Table from '../table/Table';
import Button from '../button/Button';

const TableContainer = styled('div')({
  width: 'calc(100% - 48px)',
  height: 'calc(100% - 128px)',
  margin: '24px',
});

const FIXED_HEIGHT = 128;

const ListModal = ({
  maximized,
  leftHeaderContent,
  rightHeaderContent,
  title,
  open,
  width,
  height,
  onClose,
  addButton,
  rows,
  columns,
  pageSize,
  onRowClick,
  renderContent,
  getRowClassName,
  noRowsTitle,
  NoRowsIcon,
}) => (
  <Modal
    maximized={maximized}
    open={open}
    onClose={onClose}
    title={title}
    leftHeaderContent={leftHeaderContent}
    rightHeaderContent={
      <>
        {rightHeaderContent}
        {!!addButton && (
          <Button variant="clear" onClick={addButton.onClick}>
            {addButton.label}
          </Button>
        )}
      </>
    }
    renderContent={renderContent}
  >
    <TableContainer
      sx={{
        width: !maximized ? width : null,
        height: !maximized ? `calc(${height} - ${FIXED_HEIGHT}px)` : null,
      }}
    >
      <Table
        stretch
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onRowClick={onRowClick}
        getRowClassName={getRowClassName}
        noRowsTitle={noRowsTitle}
        NoRowsIcon={NoRowsIcon}
      />
    </TableContainer>
  </Modal>
);

ListModal.propTypes = {
  leftHeaderContent: PropTypes.element,
  rightHeaderContent: PropTypes.element,
  title: PropTypes.string,
  addButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  maximized: PropTypes.bool,
  open: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pageSize: PropTypes.number,
  onRowClick: PropTypes.func,
  renderContent: PropTypes.func,
  getRowClassName: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  NoRowsIcon: PropTypes.any,
  noRowsTitle: PropTypes.string,
};

ListModal.defaultProps = {
  maximized: true,
  open: true,
  width: '1100px',
  height: '90vh',
  leftHeaderContent: null,
  rightHeaderContent: null,
  title: '',
  addButton: null,
  rows: [],
  pageSize: null,
  onRowClick: null,
  renderContent: null,
  getRowClassName: null,
  noRowsTitle: Table.defaultProps.noRowsTitle,
  NoRowsIcon: Table.defaultProps.NoRowsIcon,
};

export default ListModal;

import React, { useState, useEffect } from 'react';
import { makeValidatorSchema, stringValidator } from 'utils/validators';
import useSnackbar from 'hooks/shared/useSnackBar';
import FormModal from 'components/shared/modal/FormModal';
import TextInput from 'components/shared/form/TextInput';
import { renameDocument } from 'services/documents';
import { getFilenameAndExtension } from 'utils/text';

const validationSchema = makeValidatorSchema({
  documentName: stringValidator({ required: 'Must enter folder name' }),
});

const RenameDocument = ({ onClose, openFolderId, document }) => {
  const { showSuccess } = useSnackbar();
  const [filename, setFilename] = useState(null);
  const [extension, setExtension] = useState(null);
  const isDocument = document?.entry_type === 'document';

  useEffect(() => {
    if (!isDocument) return;

    const file = getFilenameAndExtension(document.name);
    setFilename(file.filename);
    setExtension(file.extension);
  }, []);

  const initializeForm = {
    documentName: isDocument ? filename : document.name,
  };

  const makeFormRequest = (values) =>
    isDocument
      ? {
          name: `${values.documentName}.${extension}`,
        }
      : {
          entry_type: 'folder',
          name: values.documentName,
          folder_id: openFolderId,
        };

  const handleSubmit = async (values) => {
    const request = makeFormRequest(values);
    await renameDocument(document.property_id, document.id, request);
    showSuccess('Document Renamed');
    onClose();
  };

  return (
    <FormModal
      open
      title={`Rename Document - ${document.name}`}
      onClose={onClose}
      initialValues={initializeForm}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError="Error doing rename"
      submitButton={true && { label: 'Save' }}
      cancelButton
    >
      {(formik) => (
        <TextInput
          formik={formik}
          name="documentName"
          label={`${isDocument ? 'Document Name' : 'Folder Name'}`}
          disabled={false}
        />
      )}
    </FormModal>
  );
};
export default RenameDocument;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useList } from 'react-use';
import { styled } from '@mui/material';
import classnames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import colors from 'utils/colors';
import { fieldNumber, formatPercentage, parseNumber } from 'utils/number';
import { monthName } from 'utils/date';
import { reorderArrayElement } from 'utils/array';
import { scrollBottom } from 'utils/scroll';
import {
  fetchHistoricalIndicators,
  setHistoricalIndicators,
  setSelectedHistoricalDate,
} from 'slices/propertyIndicator';
import EditableField from 'components/shared/editable/EditableField';
import Button from 'components/shared/button/Button';
import IconButton from 'components/shared/button/IconButton';
import { RawSelect } from 'components/shared/form/Select';
import Tabs from 'components/shared/tabs/Tabs';

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  backgroundColor: colors.main.surface,

  '& .edit-indicators-button': {
    display: 'none',
  },

  '&:hover .edit-indicators-button': {
    display: 'block',
  },
});

const TabsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});

const Header = styled('div')({
  width: '100%',
  minHeight: '32px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '8px',
});

const Name = styled('div')({
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 'bold',
  color: colors.main.text,
});

const TotalValue = styled('div')({
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 'bold',
  color: colors.main.text,

  '& .MuiInputBase-input': {
    textAlign: 'right',
  },
});

const ValueContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ButtonsContainer = styled('div')({
  marginLeft: 'auto',
  display: 'flex',
  gap: '8px',
});

const Fields = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const BaseFieldContainer = styled('div')({
  width: '100%',
  minHeight: '48px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: colors.main.surface,
  '&:not(:last-child)': {
    borderBottom: `1px solid ${colors.main.clearTableBorder}`,
  },
});

const EditableFieldContainer = styled(BaseFieldContainer)({});

const ReadonlyFieldContainer = styled(BaseFieldContainer)({
  position: 'relative',
  padding: '0px 16px 0px 0px',

  '& > *': {
    zIndex: '2',
  },

  '&.bar-charts': {
    width: 'calc(100% - 4px)',
    margin: '0px 2px 2px 2px',
    backgroundColor: '#b4be8320',

    '&:not(:last-child)': {
      borderBottom: 'none',
    },

    '& .field-bar': {
      zIndex: '1',
      backgroundColor: '#b4be83',
    },

    '&.negative-value': {
      backgroundColor: '#D7978B20',

      '& .field-bar': {
        backgroundColor: '#D7978B',
      },
    },
  },
});

const FieldBar = styled('div')({
  position: 'absolute',
  height: '48px',
  borderRadius: '0px 8px 8px 0px',
});

const FieldPercentage = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '60px',
  marginRight: '8px',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '600',
  color: colors.main.text,
});

const FieldName = styled('div')({
  fontSize: '14px',
  lineHeight: '20px',
  color: colors.main.text,
  width: 'calc(100% - 168px)',
});

const FieldValueContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  width: '160px',
});

const FieldValue = styled('div')({
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: colors.main.text,

  '& .MuiInputBase-input': {
    textAlign: 'right',
  },
});

const makeFields = (fields, editing = false) =>
  fields.map((field) => ({ ...field, value: fieldNumber(field.value, editing) }));

const ComposedIndicatorField = ({
  field: { id, name, value },
  barCharts,
  total,
  index,
  editing,
  onChange,
  onRemove,
}) => {
  const FieldContainer = editing ? EditableFieldContainer : ReadonlyFieldContainer;
  const percentage = formatPercentage(value, total);

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={!editing}>
      {(provided) => (
        <FieldContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classnames({
            'bar-charts': barCharts,
            'negative-value': value < 0,
          })}
        >
          {!editing && (
            <>
              {barCharts && (
                <FieldBar className="field-bar" sx={{ width: `${percentage || 0}%` }} />
              )}
              <FieldPercentage>
                {percentage !== '' ? `${value < 0 ? '-' : ''}${percentage}%` : ''}
              </FieldPercentage>
            </>
          )}
          <FieldName sx={{ fontWeight: !editing && barCharts ? '600' : null }}>
            <EditableField
              value={name}
              disabled={!editing}
              onChange={(changedValue) => onChange('name', changedValue)}
              placeholder="Field Name"
              multiline
            />
          </FieldName>
          <FieldValueContainer>
            <FieldValue>
              <EditableField
                type="currency"
                clearPrefix={false}
                value={value}
                disabled={!editing}
                onChange={(changedValue) => onChange('value', changedValue)}
              />
            </FieldValue>
            {editing && <IconButton icon="delete" onClick={onRemove} />}
          </FieldValueContainer>
        </FieldContainer>
      )}
    </Draggable>
  );
};

const makeTitle = (name, consolidatedDate) =>
  `${consolidatedDate !== '-' ? `${monthName(consolidatedDate)} ` : ''}${name}`;

const ComposedIndicator = ({
  indicator: { key, id, name, total, fields: defaultFields },
  allIndicators,
  headerSize,
  canEdit,
  onUpdate,
  onChangeIndicator,
}) => {
  const dispatch = useDispatch();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const propertyId = currentProperty ? currentProperty.id : null;
  const consolidatedDate = useSelector((state) => state.propertyIndicator.consolidatedDate) || '-';
  const selectedHistoricalDate =
    useSelector((state) => state.propertyIndicator.selectedHistoricalDate) || consolidatedDate;
  const historicalDates = useSelector((state) => state.propertyIndicator.historicalDates) || [];
  const dateOptions = [...historicalDates, consolidatedDate].map((date) => ({
    value: date,
    label: makeTitle(name, date),
  }));
  const [editing, setEditing] = useState(false);
  const [fields, { set: setFields, push: addField, updateAt: updateField, removeAt: removeField }] =
    useList(makeFields(defaultFields));

  const handleEdit = () => {
    setFields(makeFields(fields, true));
    setEditing(true);
  };

  const handleAdd = () => {
    addField({ id: uuid(), name: '', value: '' });
    setTimeout(() => {
      scrollBottom(`composed-indicator-fields-${id}`);
    }, 100);
  };

  const handleOnChange = (index, field) => updateField(index, field);

  const handleOnRemove = (index) => removeField(index);

  const handleCancel = () => {
    setFields(makeFields(defaultFields));
    setEditing(false);
  };

  const handleUpdate = async () => {
    if (
      await onUpdate(id, {
        fields: fields.map((field, index) => ({
          name: field.name,
          value: field.value || 0,
          position: index + 1,
        })),
      })
    ) {
      setFields(makeFields(fields));
      setEditing(false);
    }
  };

  const canUpdate = () => !fields.find((field) => !field.name);

  const onDragEnd = ({ source, destination }) => {
    if (!destination || destination.index === source.index) return;

    setFields(reorderArrayElement(fields, source.index, destination.index));
  };

  const handleSelectHistoricalDate = (date) => {
    if (date !== consolidatedDate) {
      dispatch(setSelectedHistoricalDate(date));
      dispatch(fetchHistoricalIndicators(propertyId, date));
    } else {
      dispatch(setSelectedHistoricalDate(null));
      dispatch(setHistoricalIndicators(null));
    }
  };

  const values = fields.map(({ value }) => parseNumber(value));
  const totalPositive = values
    .filter((value) => value > 0)
    .reduce((current, value) => current + value, 0);
  const totalNegative = values
    .filter((value) => value < 0)
    .reduce((current, value) => current + value, 0);
  const barCharts = false;

  return (
    <Container
      sx={{
        height: `calc(100% - ${headerSize}px)`,
        cursor: canEdit && !editing ? 'pointer' : null,
      }}
      onDoubleClick={canEdit && !editing ? handleEdit : null}
    >
      <TabsContainer>
        {(allIndicators.length > 1 || canEdit) && (
          <Tabs
            options={allIndicators.map((indicator) => ({
              value: indicator.key,
              label: indicator.name,
            }))}
            selectedOption={key}
            onChange={onChangeIndicator}
          />
        )}
        {canEdit &&
          (editing ? (
            <ButtonsContainer>
              <IconButton
                icon="cancel"
                iconSize={20}
                color={colors.tertiary[900]}
                onClick={handleCancel}
              />
              <IconButton
                icon="confirm"
                iconSize={20}
                color={colors.primary[900]}
                onClick={handleUpdate}
                disabled={!canUpdate()}
              />
            </ButtonsContainer>
          ) : (
            <IconButton
              sx={{ marginLeft: 'auto' }}
              icon="edit"
              color={colors.primary[900]}
              onClick={handleEdit}
              className="edit-indicators-button"
            />
          ))}
      </TabsContainer>
      <Header>
        <ValueContainer>
          <Name>
            <RawSelect
              options={dateOptions}
              value={selectedHistoricalDate}
              cleanControl
              onChange={(e) => handleSelectHistoricalDate(e.target.value)}
            />
          </Name>
          <ButtonsContainer>
            {editing ? (
              <Button
                variant="transparent"
                startIcon="add"
                onClick={handleAdd}
                sx={{ padding: '0px' }}
              >
                Add Field
              </Button>
            ) : (
              <TotalValue>
                <EditableField
                  type="currency"
                  clearPrefix={false}
                  value={fieldNumber(total)}
                  disabled
                />
              </TotalValue>
            )}
          </ButtonsContainer>
        </ValueContainer>
      </Header>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId={`composed-indicator-fields-dropable-${id}`}
          isDropDisabled={!editing}
        >
          {(provided) => (
            <div
              id={`composed-indicator-fields-${id}`}
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ overflow: 'auto' }}
            >
              <Fields>
                {(editing
                  ? fields
                  : fields.sort((field1, field2) => field2.value - field1.value)
                ).map((field, index) => (
                  <ComposedIndicatorField
                    key={field.id}
                    barCharts={barCharts}
                    index={index}
                    field={field}
                    total={field.value > 0 ? totalPositive : totalNegative}
                    editing={editing}
                    onChange={(fieldKey, changedValue) =>
                      handleOnChange(index, { ...field, [fieldKey]: changedValue })
                    }
                    onRemove={() => handleOnRemove(index)}
                  />
                ))}
              </Fields>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
};

export default ComposedIndicator;

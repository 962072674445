import React from 'react';
import MainLayout from 'components/main/MainLayout';
import PleasePayList from 'components/please-pay/PleasePayList';

const PleasePay = () => (
  <MainLayout>
    <PleasePayList />
  </MainLayout>
);

export default PleasePay;

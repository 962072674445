import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import useAbility from 'hooks/shared/useAbility';
import useSnackbar from 'hooks/shared/useSnackBar';
import { fetchInputs, clearInputs, setInputs } from 'slices/propertyInput';
import { updateInput, createInput, deleteInput } from 'services/propertyInputs';
import colors from 'utils/colors';
import { scrollBottom } from 'utils/scroll';
import { reorderArrayElement } from 'utils/array';
import IconButton from 'components/shared/button/IconButton';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import { isStaff } from 'domain/roles';
import { fetchPropertyUsers } from 'slices/property';
import { fetchAllUsers } from 'slices/user';
import PropertyInput from './PropertyInput';

const Container = styled('div')({
  width: '100%',
  height: '100%',
  borderLeft: `1px solid ${colors.main.controlBorder}`,
  background: 'linear-gradient(0deg, #F5F5F4, #F5F5F4), #FFFFFF',
});

const InputsContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '12px',
  overflow: 'auto',
});

const IconButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
  height: '68px',
  padding: '8px 12px',

  '& .MuiButton-root': {
    width: '40px',
    height: '40px',
  },
});

const staffInput = {
  category: null,
  color: 'white',
  id: '-1',
  position: 1,
  title: 'A Note From Your Devs:',
  description:
    'Hello, and welcome to the Fornextgen APP! As we continue to build the app and try to ' +
    'improve your experience, please report any bugs or suggestions to fornextgenapp@gmail.com.',
};

const PropertyInputs = () => {
  const ability = useAbility();
  const dispatch = useDispatch();
  const { showSuccess, showError } = useSnackbar();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const propertyId = currentProperty ? currentProperty.id : null;
  const inputs = useSelector((state) => state.propertyInput.inputs);
  const [editingInput, setEditingInput] = useState(null);
  const [newInput, setNewInput] = useState(null);
  const [deletingInput, setDeletingInput] = useState(null);
  const canCreate = ability.can('create', 'PropertyInput');
  const canEdit = !editingInput && !newInput;
  const { user: currentUser } = useSelector((state) => state.auth);
  const propertyUsers = useSelector((state) => state.property.users);
  const globalUsers = useSelector((state) => state.user);
  const users = currentProperty ? propertyUsers : globalUsers;

  const handleAddInput = () => {
    setNewInput({
      id: uuid(),
      title: '',
      description: '',
      color: 'white',
      permission: 'public_access',
      shareds: [],
    });
    setTimeout(() => {
      scrollBottom('property-inputs');
    }, 100);
  };

  const makeInputRequest = (values, index) => ({
    title: values.title,
    description: values.description,
    color: values.color,
    position: index + 1,
    permission: values.permission,
    shareds: values.shareds,
  });

  const handleCreateInput = async (values) => {
    try {
      const request = makeInputRequest(values, inputs.length);
      const createdInput = await createInput(propertyId, request);
      dispatch(setInputs([...inputs, createdInput]));
      showSuccess('Note created');
      setNewInput(null);
    } catch {
      showError('Error creating note');
    }
  };

  const handleUpdateInput = async (values, index) => {
    try {
      const request = makeInputRequest(values, index);
      const updatedInput = await updateInput(propertyId, editingInput, request);
      dispatch(
        setInputs(inputs.map((input) => (input.id === editingInput ? updatedInput : input)))
      );
      showSuccess('Note updated');
      setEditingInput(null);
    } catch {
      showError('Error updating note');
    }
  };

  const onDragEnd = async ({ source, destination }) => {
    if (!destination || destination.index === source.index) return;
    try {
      dispatch(setInputs(reorderArrayElement(inputs, source.index, destination.index)));

      const sourceInput = inputs[source.index];
      const destinationInput = inputs[destination.index];

      if (ability.can('update', sourceInput))
        await updateInput(propertyId, sourceInput.id, {
          position: destination.index + 1,
        });
      if (ability.can('update', destinationInput))
        await updateInput(propertyId, destinationInput.id, {
          position: source.index + 1,
        });
      showSuccess('Notes sorted');
    } catch {
      dispatch(setInputs(inputs));
      showError('Error sorting notes');
    }
  };

  const handleDeleteInput = async () => {
    try {
      await deleteInput(propertyId, deletingInput);
      dispatch(setInputs(inputs.filter((input) => input.id !== deletingInput)));
      showSuccess('Note deleted');
      setDeletingInput(null);
    } catch {
      showError('Error deleting note');
    }
  };

  const handleCancelCreation = () => setNewInput(null);

  const handleEdit = (input) => setEditingInput(input.id);

  const handleCancelEdit = () => setEditingInput(null);

  const handleAskDeletion = (input) => setDeletingInput(input.id);

  const handleCancelDelete = () => setDeletingInput(null);

  useEffect(() => {
    dispatch(fetchInputs(propertyId));
    if (currentProperty) {
      dispatch(fetchPropertyUsers(currentProperty.id));
    } else {
      dispatch(fetchAllUsers());
    }

    return () => dispatch(clearInputs());
  }, []);

  if (!inputs || !users) return null;

  return (
    <Container className="property-inputs-container">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="property-inputs-droppable" isDropDisabled={!canEdit}>
          {(provided) => (
            <InputsContainer
              sx={
                canCreate
                  ? {
                      height: 'calc(100% - 68px)',
                    }
                  : null
              }
              id="property-inputs"
              className="property-inputs-list"
              ref={provided.innerRef}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...provided.droppableProps}
            >
              {isStaff(currentUser) && (
                <PropertyInput key={staffInput.id} input={staffInput} index={-1} canEdit={false} />
              )}
              {inputs.map((input, index) => (
                <PropertyInput
                  key={input.id}
                  input={input}
                  index={index}
                  users={users}
                  canEdit={canEdit && ability.can('update', input)}
                  isEditing={editingInput === input.id}
                  onEdit={() => handleEdit(input)}
                  onDelete={() => handleAskDeletion(input)}
                  onSave={(values) => handleUpdateInput(values, index)}
                  onCancel={handleCancelEdit}
                />
              ))}

              {!!newInput && (
                <PropertyInput
                  input={newInput}
                  index={inputs.length}
                  users={users}
                  isEditing
                  onSave={handleCreateInput}
                  onCancel={handleCancelCreation}
                />
              )}
              {provided.placeholder}
            </InputsContainer>
          )}
        </Droppable>
      </DragDropContext>
      <IconButtonContainer>
        {canCreate && (
          <IconButton
            variant="clearFloater"
            icon="add"
            onClick={handleAddInput}
            color={colors.primary[600]}
            disabled={!canEdit}
          />
        )}
      </IconButtonContainer>
      {deletingInput && (
        <ConfirmationModal
          title="Note will be deleted"
          description="Are you sure?"
          onCancel={handleCancelDelete}
          onConfirm={handleDeleteInput}
        />
      )}
    </Container>
  );
};

export default PropertyInputs;

import { useSearchParams } from 'react-router-dom';

const useProperties = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const newProperty = searchParams.has('new_property');
  const newPropertyCategoryId = searchParams.get('new_property_category_id');
  const newPropertySubcategoryId = searchParams.get('new_property_subcategory_id');
  const editProperty = searchParams.get('edit_property');

  const handleShowNewPropertyModal = (categoryId, subcategoryId) => {
    searchParams.delete('new_subcategory');
    searchParams.set('new_property', true);
    searchParams.set('new_property_category_id', categoryId);
    searchParams.set('new_property_subcategory_id', subcategoryId);
    setSearchParams(searchParams);
  };

  const handleCloseNewPropertyModal = () => {
    searchParams.delete('new_property');
    searchParams.delete('new_property_category_id');
    searchParams.delete('new_property_subcategory_id');
    setSearchParams(searchParams);
  };

  const handleShowEditPropertyModal = (propertyId) => {
    searchParams.set('edit_property', propertyId);
    setSearchParams(searchParams);
  };

  const handleCloseEditPropertyModal = () => {
    searchParams.delete('edit_property');
    setSearchParams(searchParams);
  };

  return {
    newProperty,
    newPropertyCategoryId,
    newPropertySubcategoryId,
    editProperty,
    onShowNewProperty: handleShowNewPropertyModal,
    onCloseNewProperty: handleCloseNewPropertyModal,
    onShowEditProperty: handleShowEditPropertyModal,
    onCloseEditProperty: handleCloseEditPropertyModal,
  };
};

export default useProperties;

import React from 'react';
import { makeValidatorSchema, stringValidator, dateValidator } from 'utils/validators';
import FormModal from 'components/shared/modal/FormModal';
import FormRow from 'components/shared/form/FormRow';
import TextInput from 'components/shared/form/TextInput';
import DatePicker from 'components/shared/form/DatePicker';
import FormImages from 'components/shared/uploader/FormImages';
import { styled } from '@mui/material';
import colors from 'utils/colors';

const validationSchema = makeValidatorSchema({
  name: stringValidator({ required: 'Must enter a name' }),
  full_name: stringValidator(),
  contact_details: stringValidator(),
  identification_number: stringValidator(),
  loan_type: stringValidator(),
  principal_amount: stringValidator(),
  interest_rate: stringValidator(),
  start_date: dateValidator(),
  end_date: dateValidator(),
  repayment_schedule: stringValidator(),
  payment_due_date: dateValidator(),
  payment_amount: stringValidator(),
  payment_date: dateValidator(),
  payment_method: stringValidator(),
  outstanding_balance: stringValidator(),
  late_payment_fees: stringValidator(),
  description_of_collateral: stringValidator(),
  value_of_colleteral: stringValidator(),
  collateral_location: stringValidator(),
});

const SummaryTitle = styled('div')({
  width: '100%',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: colors.main.blackText,
  marginTop: '12px',
  marginBottom: '4px',
});

const LoanAndReceivablePropertyModal = ({ currentProperty, title, onSubmit, onClose }) => {
  const initalizeSummary = () => {
    const summary = (currentProperty && currentProperty.summary) || {};
    return {
      full_name: summary.full_name || '',
      contact_details: summary.contact_details || '',
      identification_number: summary.identification_number || '',
      loan_type: summary.loan_type || '',
      principal_amount: summary.principal_amount || '',
      interest_rate: summary.interest_rate || '',
      start_date: summary.start_date || '',
      end_date: summary.end_date || '',
      repayment_schedule: summary.repayment_schedule || '',
      payment_due_date: summary.payment_due_date || '',
      payment_amount: summary.payment_amount || '',
      payment_date: summary.payment_date || '',
      payment_method: summary.payment_method || '',
      outstanding_balance: summary.outstanding_balance || '',
      late_payment_fees: summary.late_payment_fees || '',
      description_of_collateral: summary.description_of_collateral || '',
      value_of_colleteral: summary.value_of_colleteral || '',
      collateral_location: summary.collateral_location || '',
    };
  };

  const initializeForm = () => ({
    ...(currentProperty
      ? {
          name: currentProperty.name,
          logo: currentProperty.logo ? [currentProperty.logo] : [],
        }
      : { name: '', logo: [] }),
    ...initalizeSummary(),
  });

  const getErrorMessage = () => (currentProperty ? `Error editing item` : `Error creating item`);

  const makeFormRequest = (values) => ({
    name: values.name,
    logo: values.logo,
    summary: {
      full_name: values.full_name,
      contact_details: values.contact_details,
      identification_number: values.identification_number,
      loan_type: values.loan_type,
      principal_amount: values.principal_amount,
      interest_rate: values.interest_rate,
      start_date: values.start_date,
      end_date: values.end_date,
      repayment_schedule: values.repayment_schedule,
      payment_due_date: values.payment_due_date,
      payment_amount: values.payment_amount,
      payment_date: values.payment_date,
      payment_method: values.payment_method,
      outstanding_balance: values.outstanding_balance,
      late_payment_fees: values.late_payment_fees,
      description_of_collateral: values.description_of_collateral,
      value_of_colleteral: values.value_of_colleteral,
      collateral_location: values.collateral_location,
    },
  });

  const handleSubmit = async (values) => {
    const request = makeFormRequest(values);
    return onSubmit(request);
  };

  return (
    <FormModal
      open
      title={title}
      onClose={onClose}
      initialValues={initializeForm()}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      defaultError={getErrorMessage()}
      submitButton={{ label: currentProperty ? 'Save' : 'Create' }}
      cancelButton
    >
      {(formik) => (
        <>
          <FormRow>
            <TextInput formik={formik} name="name" label="Account Name" sx={{ width: '50%' }} />
            <TextInput formik={formik} name="full_name" label="Full Name" sx={{ width: '50%' }} />
          </FormRow>
          <SummaryTitle>Borrower Information:</SummaryTitle>
          <FormRow>
            <TextInput
              formik={formik}
              name="contact_details"
              label="Contact Details (Phone, Email, Address)"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="identification_number"
              label="Identification Number (e.g., Social Security Number or equivalent)"
              sx={{ width: '50%' }}
            />
          </FormRow>

          <SummaryTitle>Loan Details:</SummaryTitle>
          <FormRow>
            <TextInput
              formik={formik}
              name="loan_type"
              label="Loan Type (e.g., personal, mortgage, business)"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="principal_amount"
              label="Principal Amount"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="interest_rate"
              label="Interest Rate"
              sx={{ width: '50%' }}
              type="number"
              clearPrefix={false}
            />
          </FormRow>
          <FormRow>
            <DatePicker
              formik={formik}
              name="start_date"
              label="Loan Term Start Date"
              sx={{ width: '50%' }}
            />
            <DatePicker
              formik={formik}
              name="end_date"
              label="Loan Term End Date"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="repayment_schedule"
              label="Repayment Schedule (e.g., monthly, quarterly)"
              sx={{ width: '50%' }}
            />
            <DatePicker
              formik={formik}
              name="payment_due_date"
              label="Payment Due Dates"
              sx={{ width: '50%' }}
            />
          </FormRow>

          <SummaryTitle>Payment Information:</SummaryTitle>
          <FormRow>
            <TextInput
              formik={formik}
              name="payment_amount"
              label="Payment Amount"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
            <DatePicker
              formik={formik}
              name="payment_date"
              label="Payment Date"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="payment_method"
              label="Payment Method (e.g., bank transfer, check)"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="outstanding_balance"
              label="Outstanding Balance"
              sx={{ width: '50%' }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="outstanding_balance"
              label="Late Payment Fees (if applicable)"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="late_payment_fees"
              label="Late Payment Fees (if applicable)"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
          </FormRow>

          <SummaryTitle>Collateral Information (if applicable):</SummaryTitle>
          <FormRow>
            <TextInput
              formik={formik}
              name="description_of_collateral"
              label="Description of Collateral"
              sx={{ width: '50%' }}
            />
            <TextInput
              formik={formik}
              name="value_of_colleteral"
              label="Value of Collateral"
              sx={{ width: '50%' }}
              type="currency"
              clearPrefix={false}
            />
          </FormRow>
          <FormRow>
            <TextInput
              formik={formik}
              name="collateral_location"
              label="Collateral Location"
              sx={{ width: '50%' }}
            />
          </FormRow>

          <FormImages
            formik={formik}
            title={false}
            name="logo"
            addButtonLabel="Set Logo"
            singleImage
          />
        </>
      )}
    </FormModal>
  );
};
export default LoanAndReceivablePropertyModal;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import classnames from 'classnames';
import ClearIcon from '@mui/icons-material/Clear';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import colors from 'utils/colors';
import { getDocumentUrl } from 'services/documents';
import { downloadProps } from './uploaderUtils';

const Control = styled('div')({
  display: 'flex',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  cursor: 'pointer',
  '&.deleted': {
    opacity: 0.8,
  },
});

const IconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '20px',
  height: '20px',
  marginLeft: 'auto',
  borderRadius: '100%',
  backgroundColor: colors.primary[900],
  color: colors.primary[50],

  '& .MuiSvgIcon-root': {
    width: '16px',
    height: '16px',
  },
});

const getIcon = (deleted, onDelete) => {
  if (deleted) return <RestoreFromTrashIcon />;
  if (onDelete) return <ClearIcon />;
  return null;
};

const getDownloadUrl = async ({ entityPath, id, url }) => {
  if (!entityPath || !id) return url;

  try {
    return await getDocumentUrl(entityPath, id);
  } catch (_e) {
    return null;
  }
};

const getEntityPath = (entityPath, image) =>
  typeof entityPath === 'function' ? entityPath(image) : entityPath;

const ImageItem = ({ image, width, height, entityPath, onDelete }) => {
  const deleted = image.action === 'delete';

  const handleDownload = async () => {
    const url = await getDownloadUrl({ ...image, entityPath: getEntityPath(entityPath, image) });
    if (!url) return;

    window.open(downloadProps({ ...image, url }).href);
  };

  return (
    <Control
      sx={{ backgroundImage: `url(${image.url})`, width, height }}
      className={classnames('image-item', { deleted })}
      onClick={handleDownload}
    >
      {onDelete && (
        <IconContainer
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onDelete();
            return false;
          }}
        >
          {getIcon(deleted, onDelete)}
        </IconContainer>
      )}
    </Control>
  );
};

ImageItem.propTypes = {
  image: PropTypes.shape().isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  entityPath: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onDelete: PropTypes.func,
};

ImageItem.defaultProps = {
  onDelete: null,
  entityPath: null,
  width: '101px',
  height: '95px',
};

export default ImageItem;

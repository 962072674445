import React, { useState } from 'react';
import { getUser } from 'services/users';
import useAbility from 'hooks/shared/useAbility';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import UserModal from './UserModal';

const EditUser = ({ onClose, userId }) => {
  const [user, setUser] = useState(null);
  const ability = useAbility();
  const disabled = !ability.can('update', 'Directory');

  useAsyncEffect(async () => {
    setUser(await getUser(userId));
  });

  if (!user) return null;

  return (
    <UserModal
      title={!disabled ? 'Edit User' : 'User'}
      disabled={disabled}
      onClose={onClose}
      editingUser={user}
    />
  );
};
export default EditUser;

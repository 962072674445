import { createSlice } from '@reduxjs/toolkit';
import { getChatMessages } from 'services/chat';

const initialState = { currentChat: null, currentChatMessages: null };

const openItemSlice = createSlice({
  name: 'openItem',
  initialState,
  reducers: {
    setCurrentChat: (state, action) => ({ ...state, currentChat: action.payload }),
    clearCurrentChat: (state) => ({ ...state, currentChat: null }),
    setCurrentChatMessages: (state, action) => ({ ...state, currentChatMessages: action.payload }),
    clearCurrentChatMessages: (state) => ({ ...state, currentChatMessages: null }),
  },
});

const { reducer, actions } = openItemSlice;

export const {
  setCurrentChat,
  clearCurrentChat,
  setCurrentChatMessages,
  clearCurrentChatMessages,
} = actions;

// TODO: just fetch new messages, move this one into a hook and improve it
export const fetchCurrentChatMessages = (chatId, messages) => async (dispatch) => {
  const messagesById = (messages?.records || []).reduce(
    (byId, message) => ({ ...byId, [message.id]: message }),
    {}
  );
  const result = await getChatMessages(chatId);
  result.records = result.records.map((message) => messagesById[message.id] || message);
  dispatch(setCurrentChatMessages(result));
};

export default reducer;

import numeral from 'numeral';

// eslint-disable-next-line import/prefer-default-export
export const zeroLeftNumber = (number, length = 2) => {
  const str = number.toString();
  return `${
    str.length < length ? [...Array(length - str.length)].reduce((text) => `${text}0`, '') : ''
  }${str}`;
};

export const hasDecimals = (number) => !!(number % 1);

export const formatNumber = (number, decimals = true) =>
  numeral(number).format(`0,0${decimals ? '.00' : ''}`);

export const parseNumber = (number) =>
  typeof number === 'string' ? parseFloat(number.replace(/,/g, '')) : number;

export const fieldNumber = (originalNumber, isEdit = false) => {
  const number = parseNumber(originalNumber);
  if (!number && isEdit) return '';

  return numeral(number).format('0.00');
};

export const formatCurrency = (number, decimals = true) => {
  let formattedNumber = formatNumber(parseNumber(number), decimals);
  const isNegative = formattedNumber[0] === '-';
  if (isNegative) formattedNumber = formattedNumber.substring(1);
  return `${isNegative ? '-' : ''}$ ${formattedNumber}`;
};

export const formatBytes = (bytes, decimals = 0) => {
  if (bytes === 0) return '-';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const formatPercentage = (value, total) => {
  const valueNumber = parseNumber(value);
  const totalNumber = parseNumber(total);
  if (valueNumber === 0) return '0';

  const percentage = (valueNumber * 100) / totalNumber;
  const fixedValue = percentage.toFixed(percentage < 1 ? 1 : 0);
  return parseFloat(fixedValue) !== 0 ? fixedValue : '0';
};

import React from 'react';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import Collapse from '@mui/material/Collapse';
import Slide from '@mui/material/Slide';

const transitionControl = (variant) => {
  switch (variant) {
    case 'collapse':
      return Collapse;
    case 'slide':
      return Slide;
    default:
      return Fade;
  }
};

const TransitionContainer = ({ variant, transitionProps, show, children }) => {
  const Control = transitionControl(variant);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Control appear {...transitionProps} in={show}>
      {children}
    </Control>
  );
};

TransitionContainer.propTypes = {
  variant: PropTypes.string,
  transitionProps: PropTypes.shape(),
  show: PropTypes.bool,
};

TransitionContainer.defaultProps = {
  variant: null,
  transitionProps: {},
  show: false,
};

export default TransitionContainer;

import { subject } from '@casl/ability';

export const setSubject = (type, object) => subject(type, object);

export const setCollectionSubject = (type, collection) =>
  collection.map((object) => setSubject(type, object));

export const setListSubject = (type, list) => {
  if (Array.isArray(list)) return setCollectionSubject(type, list);

  return {
    ...list,
    records: list.records ? setCollectionSubject(type, list.records) : null,
  };
};

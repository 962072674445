import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentOpenItem, clearCurrentOpenItem } from 'slices/openItem';
import { setCurrentChat, clearCurrentChat, clearCurrentChatMessages } from 'slices/chat';
import OpenItemFormModal from './OpenItemFormModal';

const EditOpenItemModal = ({ propertyId, openItemId, onClose }) => {
  const dispatch = useDispatch();
  const currentOpenItem = useSelector((state) => state.openItem.currentOpenItem);
  const critical = currentOpenItem && currentOpenItem.critical;

  useEffect(() => {
    dispatch(fetchCurrentOpenItem(propertyId, openItemId));

    return () => {
      dispatch(clearCurrentOpenItem());
      dispatch(clearCurrentChat());
      dispatch(clearCurrentChatMessages());
    };
  }, []);

  useEffect(() => {
    if (currentOpenItem && currentOpenItem.chat) {
      dispatch(setCurrentChat(currentOpenItem.chat));
    }
  }, [currentOpenItem]);

  return currentOpenItem ? (
    <OpenItemFormModal
      title={`${currentOpenItem.status === 'draft' ? 'Draft ' : ''} ${
        critical ? 'Critical' : 'Open'
      } Item ${currentOpenItem.number || ''} - ${currentOpenItem.property_name}`}
      onClose={onClose}
      critical={!!critical}
    />
  ) : (
    <div />
  );
};

export default EditOpenItemModal;

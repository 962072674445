import React, { useState } from 'react';
import { styled } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import colors from 'utils/colors';
import Logo from './Logo';
import WelcomeBack from './WelcomeBack';
import BasicAuthentication from './BasicAuthentication';
import CodeAuthentication from './CodeAuthentication';
import WaitingApproval from './WaitingApproval';

const BackgroundContainer = styled('div')({
  width: '100%',
  height: '100vh',
  position: 'absolute',
  zIndex: -1,
});

const BackgroundImage = styled('div')({
  width: '100%',
  height: '100vh',
  backgroundColor: colors.main.surfaceOverlay,
  position: 'absolute',
  zIndex: 2,
});

const MainContainer = styled('div')({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
});

const Form = styled('div')({
  marginTop: '-5%',
  height: '450px',
  width: '813px',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  background: colors.main.surface,
  boxShadow: '3px 4px 26px rgba(52, 36, 9, 0.15)',
  borderRadius: '0px 8px 8px 0px',
});

const LoginContainer = styled('div')({
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: 'calc(100% - 371px)',
  padding: '24px',
});

const WelcomeContainer = styled('div')({
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
});

const GetStepForm = (step) => {
  switch (step.type) {
    case 'code':
      return CodeAuthentication;
    case 'push_auth':
      return WaitingApproval;
    default:
      return BasicAuthentication;
  }
};

const Login = () => {
  const [step, setStep] = useState({ type: 'basic' });
  const StepForm = GetStepForm(step);
  const [searchParams] = useSearchParams();
  const navigationPath = searchParams.get('navigation');

  const handleLoggedIn = () => {
    window.location.replace(navigationPath || '/');
  };
  const handleNextStep = (nextStep) => setStep(nextStep);

  return (
    <>
      <BackgroundContainer>
        <BackgroundImage />
      </BackgroundContainer>
      <MainContainer>
        <Form>
          <Logo />
          <LoginContainer>
            <WelcomeContainer>
              <WelcomeBack />
            </WelcomeContainer>
            <StepForm step={step} onNextStep={handleNextStep} onLogin={handleLoggedIn} />
          </LoginContainer>
        </Form>
      </MainContainer>
    </>
  );
};

export default Login;

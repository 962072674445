import React from 'react';
import { styled } from '@mui/material';
import colors from 'utils/colors';

const Section = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '8px 8px 4px 8px',
  marginTop: '8px',
  border: `1px solid ${colors.main.controlBorder}`,
  backgroundColor: colors.main.surface,
  borderRadius: '4px',
});

const FormSection = ({ children, sx = {} }) => <Section sx={sx}>{children}</Section>;

export default FormSection;

import { createSlice } from '@reduxjs/toolkit';
import { getPolicies } from 'services/policies';

const initialState = { policies: [] };

const policySlice = createSlice({
  name: 'policy',
  initialState,
  reducers: {
    setPolicies: (state, action) => ({
      ...state,
      policies: action.payload,
    }),
    clearPolicies: (state) => ({ ...state, policies: null }),
  },
});

const { reducer, actions } = policySlice;

export const { setPolicies, clearPolicies } = actions;

export const fetchPolicies =
  (propertyId, propertyKey, filters = {}, page = 0) =>
  async (dispatch) => {
    const policies = await getPolicies(propertyId, propertyKey, filters, page);
    dispatch(setPolicies(policies));
  };

export default reducer;

import { useSearchParams } from 'react-router-dom';

const useReceipts = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const propertyId = searchParams.get('receipt_property');
  const receiptId = searchParams.get('receipt');

  const handleShowDetailsModal = (showProperty, showReceiptId = 'new') => {
    searchParams.set('receipt_property', showProperty);
    searchParams.set('receipt', showReceiptId);
    setSearchParams(searchParams);
  };

  const handleCloseDetailsModal = () => {
    searchParams.delete('receipt_property');
    searchParams.delete('receipt');
    setSearchParams(searchParams);
  };

  return {
    propertyId,
    receiptId,
    onShowDetails: handleShowDetailsModal,
    onCloseDetails: handleCloseDetailsModal,
  };
};

export default useReceipts;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getProperty } from 'services/properties';
import useAbility from 'hooks/shared/useAbility';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import PleasePayModal from './PleasePayModal';

const AddPleasePay = ({ propertyId, onClose }) => {
  const ability = useAbility();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const [property, setProperty] = useState(null);

  const disabled = !ability.can('create', 'PleasePay');

  useAsyncEffect(async () => {
    setProperty(
      currentProperty && currentProperty.id === propertyId
        ? currentProperty
        : await getProperty(propertyId)
    );
  });

  return (
    property && (
      <PleasePayModal
        title="Create Pay"
        property={property}
        disabled={disabled}
        onClose={onClose}
      />
    )
  );
};
export default AddPleasePay;

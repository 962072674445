import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, styled } from '@mui/material';
import { ReactComponent as InfoSvg } from 'assets/svg/property_info.svg';
import colors from 'utils/colors';

const PropertyContainer = styled('div')({
  width: '100%',
  height: '48px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 16px',
  backgroundColor: 'rgba(0, 0, 0, 0.38)',
});

const PropertyName = styled(Typography)({
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 'bold',
  color: colors.main.whiteText,
});

const CurrentProperty = () => {
  const currentProperty = useSelector((state) => state.property.currentProperty);

  if (!currentProperty) return <div />;

  return (
    <PropertyContainer>
      <PropertyName variant="body1">{currentProperty.name}</PropertyName>
      <InfoSvg />
    </PropertyContainer>
  );
};

export default CurrentProperty;

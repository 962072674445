import React from 'react';
import MainContainer from 'components/shared/main/MainContainer';
import { styled } from '@mui/material';
import useAbility from 'hooks/shared/useAbility';
import useDirectory from 'hooks/components/useDirectory';
import Button from 'components/shared/button/Button';
import { sortedRoles, rolesPluralLabels } from 'domain/roles';
import DirectoryTable from './DirectoryTable';
import InviteUser from './form/InviteUser';
import EditUser from './form/EditUser';
import UserPermissions from './form/UserPermissions';

const TabsContainer = styled('div')({
  display: 'flex',
  gap: '8px',
  marginBottom: '16px',

  '& .MuiButton-root': {
    padding: '7px 12px',
  },
});

const DirectoryList = () => {
  const ability = useAbility();
  const {
    roleFilter,
    userId: selectedUser,
    userModal: selectedUserModal,
    onSetRoleFilter,
    onShowDetails,
    onCloseDetails,
  } = useDirectory();

  const renderModal = () => {
    if (!selectedUser) return null;
    switch (selectedUserModal) {
      case 'permissions':
        return <UserPermissions userId={selectedUser} onClose={onCloseDetails} />;
      default:
        return selectedUser === 'new' ? (
          <InviteUser onClose={onCloseDetails} />
        ) : (
          <EditUser userId={selectedUser} onClose={onCloseDetails} />
        );
    }
  };

  return (
    <MainContainer
      title="Directory"
      buttons={
        ability.can('create', 'Directory') ? (
          <Button variant="clear" startIcon="add" onClick={() => onShowDetails()}>
            ADD USER
          </Button>
        ) : null
      }
    >
      <TabsContainer>
        {[null, ...sortedRoles].map((role) => {
          const isSelected = role === roleFilter;
          return (
            <Button
              variant={isSelected ? 'overlay' : 'white'}
              onClick={() => onSetRoleFilter(role)}
              startIcon={isSelected ? 'tick' : null}
              key={role}
            >
              {role ? rolesPluralLabels[role] : 'All'}
            </Button>
          );
        })}
      </TabsContainer>
      <DirectoryTable />
      {renderModal()}
    </MainContainer>
  );
};

export default DirectoryList;

import React from 'react';
import SummaryRow from './SummaryRow';

const RealEstateSummary = ({ property }) => {
  const summary = property.summary || {};

  return (
    <>
      <SummaryRow name="Property Address" value={property.name} />
      <SummaryRow name="Owner Name(s)" value={summary.owner_name} />
      <SummaryRow name="Purchase Date" value={summary.purchase_date} type="date" />
      <SummaryRow name="Purchase Price" value={summary.purchase_price} type="currency" />
      <SummaryRow name="Appraised Value" value={summary.appraised_value} type="currency" />
      <SummaryRow name="Appraisal Date" value={summary.appraisal_date} type="date" />
      <SummaryRow name="Property Tax Authority" value={summary.tax_assessesment} />
      <SummaryRow name="Property Tax Amount" value={summary.tax_amount} type="currency" />
      <SummaryRow
        name="Property Tax Payment Due Dates"
        value={summary.tax_payment_due_date}
        type="date"
      />
      <SummaryRow name="Maintenace Schedule" value={summary.maintenance_schedule} />
      <SummaryRow name="Mortage Lender" value={summary.mortage_lender} />
      <SummaryRow name="Mortgage Loan #" value={summary.mortage_loan_number} />
      <SummaryRow name="Principal Balance" value={summary.principal_balance} type="currency" />
      <SummaryRow
        name="Principal Balance as of"
        value={summary.principal_balance_as_of}
        type="date"
      />
    </>
  );
};

export default RealEstateSummary;

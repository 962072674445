import { isAdmin } from 'domain/roles';

const defineRulesForCategories = (can, user) => {
  if (isAdmin(user)) {
    can('manage', 'Category');
    can('add', 'Subcategory');
    can('delete', 'Subcategory');
  }
};

export default defineRulesForCategories;

// eslint-disable-next-line no-console
export const logException = (exception) => console.error(exception, exception.stack);

export class ServerError extends Error {
  constructor(message, source) {
    logException(source);
    super(message);
    this.name = 'ServerError';
    this.source = source;
  }
}

export const getErrorMessage = (error, defaultMessage = 'Server Error') => {
  if (error.name === 'ServerError' && error.message) return error.message;
  if (
    error.response &&
    error.response.data &&
    error.response.data.errors &&
    typeof error.response.data.errors === 'string'
  )
    return error.response.data.errors;

  return defaultMessage;
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as DialogMui,
  DialogActions as DialogActionsMui,
  DialogContent as DialogContentMui,
  DialogContentText as DialogContentTextMui,
  DialogTitle as DialogTitleMui,
  styled,
} from '@mui/material';
import Button from '../button/Button';

const Dialog = styled(DialogMui)({
  '& .MuiDialog-paper': {
    minWidth: '400px',
  },
});

const DialogContent = styled(DialogContentMui)({});

const DialogContentText = styled(DialogContentTextMui)({});

const DialogTitle = styled(DialogTitleMui)({});

const DialogActions = styled(DialogActionsMui)({
  '& .MuiButton-root': {
    minWidth: '80px',
  },
});

const ConfirmationModal = ({
  title,
  description,
  cancelLabel,
  confirmationLabel,
  onCancel,
  onConfirm,
}) => (
  <Dialog
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    open
    onClose={onCancel}
  >
    {!!title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
    {!!description && (
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
    )}
    <DialogActions>
      <Button variant="white" onClick={onCancel}>
        {cancelLabel}
      </Button>
      <Button variant="clear" onClick={onConfirm}>
        {confirmationLabel}
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmationLabel: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ConfirmationModal.defaultProps = {
  title: null,
  description: null,
  cancelLabel: 'No',
  confirmationLabel: 'Yes',
};

export default ConfirmationModal;

import React from 'react';
import SummaryRow from './SummaryRow';

const InvestmentAccountSummary = ({ property }) => {
  const summary = property.summary || {};
  const subcategoryKey = property.subcategory.key;

  return (
    <>
      <SummaryRow name="Account Name" value={property.name} />
      <SummaryRow name="Name of Institution" value={summary.institution_name} />
      <SummaryRow name="Account #" value={summary.account_number} />
      <SummaryRow name="Value" value={summary.value} type="currency" />
      <SummaryRow name="Value as of" value={summary.value_as_of} type="date" />
      {subcategoryKey === 'retirement_account' && (
        <SummaryRow name="Account Type" value={summary.account_type} />
      )}
      {subcategoryKey === 'mutal_fund' && <SummaryRow name="Fund Name" value={summary.fund_name} />}
      {subcategoryKey === 'etf' && (
        <>
          <SummaryRow name="Fund Name" value={summary.fund_name} />
          <SummaryRow name="Dividends" value={summary.dividends} type="currency" />
        </>
      )}
      {subcategoryKey === 'bond' && (
        <>
          <SummaryRow name="Bond Type" value={summary.bond_type} />
          <SummaryRow name="Dividends" value={summary.dividends} type="currency" />
        </>
      )}
      {subcategoryKey === 'private_equity_investment' && (
        <SummaryRow name="Dividends" value={summary.dividends} type="currency" />
      )}
      <SummaryRow name="Beneficiary Name" value={summary.beneficiary} />
    </>
  );
};

export default InvestmentAccountSummary;

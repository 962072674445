import { useSelector } from 'react-redux';
import useAbility from 'hooks/shared/useAbility';
import useCategories from 'hooks/components/useCategories';

const PROPERTY_INDICATORS_FIXED_SIZE = 72;
const GLOBAL_INDICATORS_FIXED_SIZE = 72;
const DEBT_LOG_FIXED_SIZE = 188;
const INDICATORS_CARD_SIZE = 56;

const getIndicatorsColumns = (indicatorsCount) => {
  if (indicatorsCount < 2) return 1;
  if (indicatorsCount === 2 || indicatorsCount === 4) return 2;
  return 3;
};

const getIndicatorsSize = (indicatorsCount) =>
  Math.ceil(indicatorsCount / getIndicatorsColumns(indicatorsCount)) * INDICATORS_CARD_SIZE;

const getFixedSize = ({ hasIndicators, propertyId, hasDebtLog, availableIndicators }) => {
  if (propertyId)
    return hasIndicators && availableIndicators.length ? PROPERTY_INDICATORS_FIXED_SIZE : 0;

  let size = hasIndicators ? GLOBAL_INDICATORS_FIXED_SIZE : 0;
  if (hasDebtLog) size += DEBT_LOG_FIXED_SIZE;
  return size;
};

const usePropertyIndicators = () => {
  const ability = useAbility();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const propertyId = currentProperty ? currentProperty.id : null;
  const hasIndicators = ability.can('view', propertyId ? 'PropertyIndicator' : 'GlobalIndicator');
  const { categoryId } = useCategories();
  const indicators = useSelector((state) => state.propertyIndicator.indicators);
  const historicalIndicators = useSelector((state) => state.propertyIndicator.historicalIndicators);
  const selectedComposedIndicatorKey = useSelector(
    (state) => state.propertyIndicator.selectedComposedIndicatorKey
  );

  const hasDebtLog = ability.can('view', 'DebtLog') && categoryId;

  const safetyIndicators = indicators || [];
  const availableIndicators =
    !propertyId && !categoryId
      ? safetyIndicators.filter((indicator) => !indicator.category_indicator)
      : safetyIndicators;
  const headerIndicator = availableIndicators.find((indicator) => indicator.header_indicator);
  const bodyIndicators = availableIndicators.filter((indicator) => !indicator.header_indicator);
  const totalIndicators = propertyId
    ? bodyIndicators.filter((indicator) => !indicator.with_fields)
    : bodyIndicators;
  const composedIndicators = propertyId
    ? (historicalIndicators || bodyIndicators).filter((indicator) => indicator.with_fields)
    : [];
  const selectedComposedIndicator =
    (selectedComposedIndicatorKey &&
      composedIndicators.find(({ key }) => key === selectedComposedIndicatorKey)) ||
    (composedIndicators.length > 0 && composedIndicators[0]) ||
    null;
  const indicatorsSize =
    getIndicatorsSize(totalIndicators.length) +
    getFixedSize({ hasIndicators, propertyId, hasDebtLog, availableIndicators });

  return {
    indicators,
    historicalIndicators,
    headerIndicator,
    totalIndicators,
    composedIndicators,
    selectedComposedIndicator,
    indicatorsSize,
    hasDebtLog,
    hasIndicators, // TODO: use this one
  };
};

export default usePropertyIndicators;

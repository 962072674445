import { createSlice } from '@reduxjs/toolkit';
import { getInputs } from 'services/propertyInputs';

const initialState = {
  inputs: null,
};

const propertyInputSlice = createSlice({
  name: 'propertyInput',
  initialState,
  reducers: {
    setInputs: (state, action) => ({ ...state, inputs: action.payload }),
    clearInputs: (state) => ({ ...state, inputs: null }),
  },
});

const { reducer, actions } = propertyInputSlice;

export const { setInputs, clearInputs } = actions;

export const fetchInputs = (propertyId) => async (dispatch) => {
  const inputs = await getInputs(propertyId);
  dispatch(setInputs(inputs));
};

export default reducer;

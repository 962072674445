import React from 'react';
import { IMaskInput } from 'react-imask';

const DEFAULT_MASK = '(###) ###-####';

const PhoneInput = React.forwardRef((props, ref) => {
  const { onChange, mask, ...other } = props;

  return (
    <IMaskInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...Object.keys(other).reduce(
        (otherSent, key) => ({
          ...otherSent,
          [key === 'value' ? 'defaultValue' : key]: other[key],
        }),
        {}
      )}
      mask={mask || DEFAULT_MASK}
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export default PhoneInput;

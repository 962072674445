import React from 'react';
import { styled } from '@mui/material';
import colors from 'utils/colors';

const Container = styled('div')({
  paddingTop: '18px',
  fontFamily: 'Mulish',
  fontSize: '24px',
  lineHeight: '24px',
  fontWeight: '300',
  color: colors.tertiary[900],
  textAlign: 'center',
  letterSpacing: '8px',
  marginBottom: '32px',
  borderRadius: '30px',
});
const Welcome = styled('div')({
  fontSize: '36px',
  fontWeight: '400',
  lineHeight: '48px',
  color: colors.tertiary[900],
  textAlign: 'center',
  letterSpacing: '13px',
  padding: '10px',
});

const WelcomeBack = () => (
  <Container>
    <Welcome>ForNextGen</Welcome>
    Welcome Back
  </Container>
);

export default WelcomeBack;

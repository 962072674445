import React from 'react';
import PropTypes from 'prop-types';
import { Alert, styled } from '@mui/material';
import { Formik, Form as FormikForm } from 'formik';
import { capitalize } from 'utils/text';
import { getErrorMessage } from 'utils/errors';
import CircularProgress from './CircularProgress';
import Button from '../button/Button';

const MessageContainer = styled('div')({
  width: '100%',
  height: '25px',
  marginTop: '24px',
  paddingTop: '-12px',
  marginBottom: '20px',
});

const Message = styled(Alert)({
  width: '100%',
  marginTop: '-12px',
  marginBottom: '20px',
});

const Form = styled(FormikForm)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const FormBody = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
});

const FieldsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden',
});

const Footer = styled('div')({
  width: '100%',
  display: 'flex',
  overflow: 'hidden',
});

const ButtonsContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '16px',
  alignItems: 'center',
  padding: '24px 0',
});

const fieldsContainerProps = ({ formWidth, maxHeight, scroll }) => ({
  width: formWidth,
  maxHeight: maxHeight ? `${maxHeight}` : null,
  ...(scroll ? { overflow: ['auto', 'overlay'] } : {}),
});

const RawForm = ({
  maxHeight,
  children,
  leftContent,
  rightContent,
  onClose,
  initialValues,
  onSubmit,
  validationSchema,
  submitButton,
  draftButton,
  deleteButton,
  cancelButton,
  defaultError,
  submit,
  messageAlwaysVisible,
  scroll,
  sx,
  onDoubleClick,
}) => {
  const formWidth = '100%';

  const handleSubmit = async (values, formik) => {
    formik.setSubmitting(true);
    try {
      await onSubmit(values, formik);
    } catch (error) {
      formik.setFieldError('server', error);
    }
    formik.setSubmitting(false);
  };

  const makeErrorMessage = (errors) => {
    if (errors.server) return getErrorMessage(errors.server, defaultError);
    const keys = Object.keys(errors);
    if (keys.length === 1) return capitalize(errors[keys[0]]);
    return 'Error validating fields';
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form className="raw-form" autoComplete="off" noValidate sx={sx}>
          <FormBody className="raw-form-body" onDoubleClick={onDoubleClick}>
            {leftContent}

            <FieldsContainer sx={fieldsContainerProps({ formWidth, maxHeight, scroll })}>
              {!formik.isValid && formik.submitCount > 0 ? (
                <MessageContainer>
                  <Message severity="error">
                    <strong>{makeErrorMessage(formik.errors)}</strong>
                  </Message>
                </MessageContainer>
              ) : (
                messageAlwaysVisible && <MessageContainer />
              )}
              {children(formik)}
            </FieldsContainer>

            {rightContent}
          </FormBody>
          <Footer>
            {submit ? (
              <ButtonsContainer sx={{ width: formWidth }}>
                {!!deleteButton && (
                  <Button
                    sx={{ marginRight: 'auto' }}
                    variant="white"
                    startIcon="delete"
                    onClick={deleteButton.onClick}
                  >
                    {deleteButton.label || 'Discard'}
                  </Button>
                )}
                {!!cancelButton && (
                  <Button variant="white" onClick={onClose}>
                    {cancelButton.label || 'Cancel'}
                  </Button>
                )}
                {!!draftButton && (
                  <Button variant="clear" onClick={() => handleSubmit(formik)}>
                    {draftButton.label || 'Save Draft'}
                  </Button>
                )}
                {!!submitButton && (
                  <Button
                    variant="dark"
                    onClick={formik.submitForm}
                    disabled={!formik.dirty && !draftButton}
                  >
                    {submitButton.label || 'Submit'}
                  </Button>
                )}
              </ButtonsContainer>
            ) : null}
          </Footer>
          {formik.isSubmitting && (
            <CircularProgress
              controlSx={{
                marginLeft: '0px',
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

const buttonShape = PropTypes.oneOfType([
  PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  PropTypes.bool,
]);

RawForm.propTypes = {
  maxHeight: PropTypes.string,

  leftContent: PropTypes.element,
  rightContent: PropTypes.element,
  submitButton: buttonShape,
  draftButton: buttonShape,
  deleteButton: buttonShape,
  cancelButton: buttonShape,
  initialValues: PropTypes.shape().isRequired,
  validationSchema: PropTypes.shape(),
  defaultError: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  submit: PropTypes.bool,
  messageAlwaysVisible: PropTypes.bool,
  scroll: PropTypes.bool,
  sx: PropTypes.shape(),
  onDoubleClick: PropTypes.func,
};

RawForm.defaultProps = {
  maxHeight: '90vh',
  leftContent: null,
  rightContent: null,
  submitButton: false,
  draftButton: false,
  deleteButton: false,
  cancelButton: false,
  validationSchema: null,
  onSubmit: null,
  onClose: null,
  defaultError: 'Error saving form',
  submit: true,
  messageAlwaysVisible: true,
  scroll: true,
  sx: null,
  onDoubleClick: null,
};

export default RawForm;

import {
  styled,
  Menu as MenuMui,
  MenuItem as MenuItemMui,
  Button as ButtonMui,
} from '@mui/material';
import React, { useState } from 'react';
import colors from 'utils/colors';
import DatePicker from 'components/shared/form/DatePicker';
import TextInput from 'components/shared/form/TextInput';
import Autocomplete from 'components/shared/form/Autocomplete';
import Select from 'components/shared/form/Select';
import Checkbox from 'components/shared/form/Checkbox';
import FormRow from 'components/shared/form/FormRow';
import {
  dateValidator,
  makeValidatorSchema,
  positiveNumberValidator,
  stringValidator,
} from 'utils/validators';
import { fieldNumber } from 'utils/number';
import RawForm from 'components/shared/form/RawForm';
import Button from 'components/shared/button/Button';
import { useSelector } from 'react-redux';
import { createPolicy, deletePolicy, updatePolicy } from 'services/policies';
import useSnackbar from 'hooks/shared/useSnackBar';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import FormUploader from 'components/shared/uploader/FormUploader';
import {
  LIFE_INSURANCE_POLICY_SUB_TYPES,
  LIFE_INSURANCE_EXTRA_OPEN_ITEMS,
  POLICY_PREMIUM_FRECUENCIES,
  getPolicyKey,
} from 'domain/policies';
import useOpenItems from 'hooks/components/useOpenItems';

const ROW_GAP = '6';
const INPUT_PROPERTIES = {
  width: `calc(${100 / 3}% - 3px)`,
};
const BUTTON_PROPERTIES = {
  padding: '0px',
  minWidth: 'unset',
  '& .MuiButton-startIcon': {
    marginLeft: '0px',
    marginRight: '2px',
  },
};
const COLLPASED_BUTTONS_PROPERTIES = {
  position: 'absolute',
  bottom: '0px',
  right: '0px',
  marginTop: '4px',
  padding: '12px 16px 16px 16px',
  background: colors.main.whiteOpacity,
  zIndex: '1',
};

const ButtonsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',
});

// TODO: refactor and abstract this one
const OpenItemMenu = styled(MenuMui)({
  '& .MuiPaper-root': {
    borderRadius: '0px',
    width: '230px',
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    backgroundColor: colors.main.surface,
  },
  '& .MuiList-root': {
    padding: '8px 0px',
  },
  '& .MuiMenuItem-root': {
    padding: '0px',
    '&:hover': {
      backgroundColor: colors.main.surfaceOverlay,
    },
  },
  '& .MuiButton-root': {
    width: '100%',
    justifyContent: 'space-between',
    padding: '12px 16px',
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.main.text,
    textTransform: 'unset',
    '&:hover': {
      backgroundColor: 'unset',
    },
    '& .MuiButton-endIcon': {
      color: colors.main.iconColor,
    },
  },
});

const validationSchema = makeValidatorSchema({
  insuranceCompany: stringValidator(),
  insured: stringValidator(),
  policy: stringValidator(),
  beneficiary: stringValidator(),
  type: stringValidator({ required: 'Must enter policy type' }),
  premium: positiveNumberValidator(),
  premiumDueDate: dateValidator(),
  policyExpirationDate: dateValidator(),
  autoDebit: stringValidator(),
  autoDebitAccount: stringValidator(),
  notes: stringValidator(),
  agencyName: stringValidator(),
  agentContact: stringValidator(),
  agentEmail: stringValidator(),
  agentPhoneNo: stringValidator(),
  premiumFrecuency: stringValidator(),
});

const initializeForm = (policy = null) =>
  policy
    ? {
        id: policy.id,
        insuranceCompany: policy.insurance_company,
        insured: policy.insured,
        policy: policy.policy_number,
        beneficiary: policy.beneficiary,
        type: policy.sub_type,
        premium: fieldNumber(policy.premium),
        benefit: fieldNumber(policy.benefit),
        cashValue: fieldNumber(policy.cash_value),
        premiumDueDate: policy.premium_due_date,
        policyExpirationDate: policy.policy_expiration_date,
        autoDebit: policy.auto_debit,
        autoDebitAccount: policy.auto_debit_account,
        agencyName: policy.agency_name,
        agentContact: policy.agent_contact,
        agentEmail: policy.agent_email,
        agentPhoneNo: policy.agent_phone_no,
        premiumFrecuency: policy.premium_frequency,
        notes: policy.notes,
        documents: policy.documents || [],
        images: policy.images || [],
      }
    : {
        id: '',
        insuranceCompany: '',
        insured: '',
        policy: '',
        beneficiary: '',
        type: LIFE_INSURANCE_POLICY_SUB_TYPES[0].id,
        premium: '',
        benefit: '',
        cashValue: '',
        premiumDueDate: '',
        policyExpirationDate: '',
        autoDebit: false,
        autoDebitAccount: '',
        agencyName: '',
        agentContact: '',
        agentEmail: '',
        agentPhoneNo: '',
        premiumFrecuency: POLICY_PREMIUM_FRECUENCIES[0].id,
        notes: '',
        documents: [],
        images: [],
      };

const LifeInsurancePolicyForm = ({
  policy,
  editing,
  collapsed,
  canUpdate,
  canDelete,
  onSave,
  onDeleted,
  onCancel,
  onEdit,
  onSelect,
}) => {
  const { showSuccess, showError } = useSnackbar();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const policies = useSelector((state) => state.policy.policies);
  const propertyId = currentProperty.id;
  const policyKey = getPolicyKey(currentProperty);
  const { onShowDetails: handleShowOpenItem } = useOpenItems();
  const openItemId = (policy && policy.open_item_id) || null;

  const [showOpenItemMenu, setShowOpenItemMenu] = useState(false);
  const [deletingInsurance, setDeletingInsurance] = useState(false);

  const openItemMenuId = `life-insurance-policy-menu-${policy?.id || 'new'}`;

  const makeFormRequest = (values) => ({
    insurance_company: values.insuranceCompany,
    insured: values.insured,
    policy_number: values.policy,
    beneficiary: values.beneficiary,
    sub_type: values.type,
    premium: values.premium || '0',
    benefit: values.benefit || '0',
    cash_value: values.cashValue || '0',
    premium_due_date: values.premiumDueDate || null,
    policy_expiration_date: values.policyExpirationDate || null,
    auto_debit: values.autoDebit,
    auto_debit_account: values.autoDebit ? values.autoDebitAccount : null,
    agency_name: values.agencyName,
    agent_contact: values.agentContact,
    agent_email: values.agentEmail,
    agent_phone_no: values.agentPhoneNo,
    premium_frequency: values.premiumFrecuency,
    notes: values.notes,
  });

  const handleGetEntityPath = (document) =>
    `properties/${propertyId}/open_items/${document.record_id}`;

  const handleCancel = (formik) => {
    formik.resetForm();
    onCancel();
  };

  const handleEdit = () => {
    onEdit();
  };

  const handleDelete = async (lifeInsurancePolicyId) => {
    try {
      await deletePolicy(propertyId, policyKey, lifeInsurancePolicyId);
      showSuccess('Policy Deleted');
      onDeleted();
    } catch {
      showError('Error deleting policy');
    }
    setDeletingInsurance(false);
  };

  const handleAskDeletion = () => {
    setDeletingInsurance(true);
  };

  const handleCancelDelete = () => {
    setDeletingInsurance(false);
  };

  const savePolicy = async (request) =>
    policy
      ? updatePolicy(propertyId, policyKey, policy.id, request)
      : createPolicy(propertyId, policyKey, request);

  const handleSubmit = async (values, formik) => {
    const request = makeFormRequest(values);
    const savedPolicy = await savePolicy(request);
    formik.resetForm({ values: initializeForm(savedPolicy) });
    showSuccess(policy ? 'Policy Updated' : 'Policy Created');
    onSave(savedPolicy);
  };

  return (
    <RawForm
      initialValues={initializeForm(policy)}
      validationSchema={validationSchema}
      submit={false}
      messageAlwaysVisible={false}
      onSubmit={handleSubmit}
      scroll={false}
      maxHeight={null}
    >
      {(formik) => {
        const formHeaders = (
          <>
            <FormRow gap={ROW_GAP} onClick={onSelect}>
              <TextInput
                formik={formik}
                name="insured"
                label="Named Insured"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <Autocomplete
                formik={formik}
                name="beneficiary"
                label="Beneficiary"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                free
                size="small"
                options={[
                  ...new Set(
                    policies
                      .map(({ beneficiary }) => beneficiary)
                      .filter((beneficiary) => beneficiary)
                  ),
                ]}
              />
              <TextInput
                formik={formik}
                name="insuranceCompany"
                label="Insurance Company"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
            </FormRow>
            <FormRow gap={ROW_GAP} onClick={onSelect}>
              <TextInput
                formik={formik}
                name="policy"
                label="Policy Number"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <Select
                formik={formik}
                name="type"
                label="Policy Type"
                options={LIFE_INSURANCE_POLICY_SUB_TYPES.map((type) => ({
                  id: type.id,
                  label: type.name,
                }))}
                sx={INPUT_PROPERTIES}
                disabled={!editing}
                size="small"
              />
              {formik.values.autoDebit ? (
                <TextInput
                  formik={formik}
                  name="autoDebitAccount"
                  label="Debit Account"
                  disabled={!editing}
                  sx={INPUT_PROPERTIES}
                  size="small"
                  onCancel={editing ? () => formik.setFieldValue('autoDebit', false) : null}
                />
              ) : (
                <Checkbox
                  sx={INPUT_PROPERTIES}
                  formik={formik}
                  name="autoDebit"
                  label="Auto Debit"
                  disabled={!editing}
                  size="small"
                />
              )}
            </FormRow>
            <FormRow gap={ROW_GAP} onClick={onSelect}>
              <DatePicker
                formik={formik}
                name="premiumDueDate"
                label="Premium Due Date"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <Select
                formik={formik}
                name="premiumFrecuency"
                label="Premium Frequency"
                options={POLICY_PREMIUM_FRECUENCIES.map((type) => ({
                  id: type.id,
                  label: type.name,
                }))}
                sx={INPUT_PROPERTIES}
                disabled={!editing}
                size="small"
              />
              <TextInput
                formik={formik}
                name="premium"
                label="Annual Premium"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="currency"
                clearPrefix={false}
              />
            </FormRow>
            <FormRow gap={ROW_GAP} onClick={onSelect}>
              <DatePicker
                formik={formik}
                name="policyExpirationDate"
                label="Policy Exp. Date"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="cashValue"
                label="Cash Value"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="currency"
                clearPrefix={false}
              />
              <TextInput
                formik={formik}
                name="benefit"
                label="Benefit"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="currency"
                clearPrefix={false}
              />
            </FormRow>
          </>
        );

        const formButtons = (
          <>
            <FormRow gap={ROW_GAP} sx={collapsed ? COLLPASED_BUTTONS_PROPERTIES : null}>
              <ButtonsContainer>
                {editing ? (
                  <>
                    <Button
                      startIcon="cancel"
                      variant="transparent"
                      iconSize={20}
                      color={colors.tertiary[900]}
                      onClick={() => {
                        handleCancel(formik);
                      }}
                      sx={BUTTON_PROPERTIES}
                    >
                      Cancel
                    </Button>
                    <Button
                      startIcon="confirm"
                      variant="transparent"
                      iconSize={20}
                      color={colors.primary[900]}
                      onClick={() => formik.submitForm()}
                      sx={BUTTON_PROPERTIES}
                    >
                      Confirm
                    </Button>
                  </>
                ) : (
                  <>
                    {canDelete && (
                      <Button
                        startIcon="delete"
                        variant="transparent"
                        iconSize={20}
                        sx={BUTTON_PROPERTIES}
                        onClick={() => {
                          handleAskDeletion();
                        }}
                      >
                        Delete
                      </Button>
                    )}
                    {canUpdate && (
                      <Button
                        startIcon="edit"
                        variant="transparent"
                        iconSize={20}
                        sx={BUTTON_PROPERTIES}
                        onClick={handleEdit}
                      >
                        Edit
                      </Button>
                    )}
                    <Button
                      id={openItemMenuId}
                      startIcon="openItem"
                      variant="transparent"
                      iconSize={20}
                      sx={BUTTON_PROPERTIES}
                      onClick={() => setShowOpenItemMenu(true)}
                    >
                      Open Item
                    </Button>
                    {showOpenItemMenu && (
                      <OpenItemMenu
                        id={openItemMenuId}
                        anchorEl={document.getElementById(openItemMenuId)}
                        open
                        onClose={() => setShowOpenItemMenu(false)}
                        elevation={0}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        {[
                          { id: openItemId, name: 'Policy Expiration Date' },
                          ...LIFE_INSURANCE_EXTRA_OPEN_ITEMS.map(({ id, name }) => ({
                            id: policy[`${id}_open_item_id`],
                            name,
                          })),
                        ]
                          .filter(({ id }) => id)
                          .map(({ id, name }) => (
                            <MenuItemMui
                              key={id}
                              onClick={() => {
                                handleShowOpenItem(currentProperty.id, id);
                                setShowOpenItemMenu(false);
                              }}
                            >
                              <ButtonMui>{name}</ButtonMui>
                            </MenuItemMui>
                          ))}
                      </OpenItemMenu>
                    )}
                  </>
                )}
              </ButtonsContainer>
            </FormRow>
            {deletingInsurance && (
              <ConfirmationModal
                title="Policy will be deleted"
                description="Are you sure?"
                onCancel={handleCancelDelete}
                onConfirm={() => {
                  handleDelete(policy.id);
                }}
              />
            )}
          </>
        );

        if (collapsed)
          return (
            <>
              {formHeaders}
              {formButtons}
            </>
          );

        return (
          <>
            {formHeaders}
            <FormRow gap={ROW_GAP}>
              <TextInput
                formik={formik}
                name="agentContact"
                label="Agent Contact"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="agentEmail"
                label="Agent Email"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="agentPhoneNo"
                label="Agent Phone No"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
                type="phone"
              />
            </FormRow>
            <FormRow gap={ROW_GAP}>
              <TextInput
                formik={formik}
                name="agencyName"
                label="Agent Company"
                disabled={!editing}
                sx={INPUT_PROPERTIES}
                size="small"
              />
              <TextInput
                formik={formik}
                name="notes"
                label="Notes"
                disabled={!editing}
                sx={{
                  width: `calc(${200 / 3}% - 3px)`,
                }}
                size="small"
                multiline
              />
            </FormRow>
            {!editing &&
              (formik.values.documents.length > 0 || formik.values.images.length > 0) && (
                <FormUploader formik={formik} disabled entityPath={handleGetEntityPath} />
              )}
            {formButtons}
          </>
        );
      }}
    </RawForm>
  );
};

export default LifeInsurancePolicyForm;
